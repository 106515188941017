import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import KPIChart from '../KPIChart';
import PieChartComponent from '../PieChart';
import TimeChart from '../TimeChart';
import WaterfallChart from '../WaterfallChart';
import { Chart } from './types';

export interface ChartCardProps {
  chartData: Chart;
  headerConfig?: React.ReactNode;
  chartOptions?: React.ReactNode;
  onOptionsChange?: (options: any) => void;
  minDate?: Date;
  maxDate?: Date;
  disableScrollbar?: boolean;
}
export interface StyledChartCardProps extends HTMLAttributes<HTMLDivElement> {}

export const StyledChartCard = styled.div<StyledChartCardProps>``;

const ChartCard: React.FC<ChartCardProps> = ({
  chartData,
  headerConfig,
  chartOptions,
  onOptionsChange,
  minDate,
  maxDate,
  disableScrollbar,
}) => {
  if (chartData.chart_type === 'time_chart') {
    return (
      <TimeChart
        onDateRangeChanged={onOptionsChange}
        config={chartData}
        headerConfig={headerConfig}
        rightContent={chartOptions}
        minDate={minDate}
        maxDate={maxDate}
        disableScrollbar={disableScrollbar}
      />
    );
  }
  if (chartData.chart_type === 'waterfall_chart') {
    return <WaterfallChart config={chartData} headerConfig={headerConfig} rightContent={chartOptions} />;
  }
  if (chartData.chart_type === 'pie_chart') {
    return <PieChartComponent config={chartData} headerConfig={headerConfig} rightContent={chartOptions} />;
  }
  if (chartData.chart_type === 'kpi_chart') {
    return <KPIChart config={chartData} rightContent={chartOptions} />;
  }
  return null;
};
export default ChartCard;
