import produce from 'immer';

import {
  ORGANISATIONS_PIPELINES_LOAD,
  ORGANISATIONS_PIPELINE_STAGE_UPDATE,
  ORGANISATIONS_PIPELINE_UPDATE,
} from './actions';
import { OrganisationPipelines, PipelineActions } from './types';

const pipelines = (prevState: OrganisationPipelines = {}, action: PipelineActions): OrganisationPipelines => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_PIPELINES_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload,
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      case ORGANISATIONS_PIPELINE_UPDATE: {
        const { organisationId, pipelineId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value:
                state[organisationId]?.value?.map(pipeline => {
                  if (pipeline.id === pipelineId) {
                    return {
                      ...pipeline,
                      [action.params.field]: action.params.value,
                    };
                  }
                  return pipeline;
                }) ?? [],
            };

            return state;
        }
        return state;
      }

      case ORGANISATIONS_PIPELINE_STAGE_UPDATE: {
        const { organisationId, pipelineId, stageId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value:
                state[organisationId]?.value?.map(pipeline => {
                  if (pipeline.id === pipelineId) {
                    return {
                      ...pipeline,
                      stages: pipeline.stages.map(stage => {
                        if (stage.id === stageId) {
                          return {
                            ...stage,
                            [action.params.field]: action.params.value,
                          };
                        }
                        return stage;
                      }),
                    };
                  }
                  return pipeline;
                }) ?? [],
            };
            return state;
        }
        return state;
      }
      default:
        return state;
    }
  });
};

export default pipelines;
