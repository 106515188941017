import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { HSL } from '../../../features/report/rows/ReportRowCell';

export interface ColorCircleProps extends Omit<HTMLAttributes<HTMLDivElement>, 'color' | 'selected'> {
  hsl: HSL;
  selected?: boolean;
}

const StyledColorCircle = styled.div<ColorCircleProps>`
  ${({ theme }) =>
    `
      height: ${theme.sizing(3)};
      width: ${theme.sizing(3)};
      border-radius: ${theme.sizing(3)};
      border: 0.5px solid ${theme.palette.white};
      margin-left: 5px;
      cursor: pointer;
  `}

  background: hsl(${({ hsl }) => `${hsl.h}, ${hsl.s}%, ${hsl.l}%`});
  box-shadow: ${({ selected }) => (selected ? '0 0 0 1pt #345671' : 'none')};
`;

const ColorCircle: React.FC<React.PropsWithChildren<ColorCircleProps>> = props => {
  return <StyledColorCircle {...props} />;
};

export default ColorCircle;
