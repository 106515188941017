import produce from 'immer';

import {
  ORGANISATIONS_ORGANISATION_LOAD,
  OrganisationsActions,
  ORGANISATIONS_ORGANISATION_UPDATE,
  ORGANISATIONS_ORGANISATION_LANDING_KPI_UPDATE,
  ORGANISATIONS_ORGANISATION_LANDING_CHART_UPDATE,
  ORGANISATIONS_ORGANISATION_LANDING_SHORTCUT_UPDATE,
} from './actions';
import { Organisation, OrganisationOrganisations } from './types';

const organisations = (
  prevState: OrganisationOrganisations = {},
  action: OrganisationsActions,
): OrganisationOrganisations => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_ORGANISATION_LOAD:
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload,
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      case ORGANISATIONS_ORGANISATION_UPDATE: {
        const { organisationId, organisation } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: {
                ...state[organisationId].value,
                ...organisation,
              } as Organisation,
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      case ORGANISATIONS_ORGANISATION_LANDING_KPI_UPDATE: {
        const { organisationId, organisation } = action.params;
        switch (action.status) {
          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: {
                ...state[organisationId].value,
                landing_page: {
                  ...state[organisationId].value?.landing_page,
                  kpiCards: organisation.landing_page?.kpiCards,
                },
              } as Organisation,
            };
            return;
          default:
            return state;
        }
      }
      case ORGANISATIONS_ORGANISATION_LANDING_CHART_UPDATE: {
        const { organisationId, organisation } = action.params;
        switch (action.status) {
          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: {
                ...state[organisationId].value,
                landing_page: {
                  ...state[organisationId].value?.landing_page,
                  chartId: organisation.landing_page?.chartId,
                },
              } as Organisation,
            };
            return;
          default:
            return state;
        }
      }
      case ORGANISATIONS_ORGANISATION_LANDING_SHORTCUT_UPDATE: {
        const { organisationId, organisation } = action.params;
        switch (action.status) {
          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: {
                ...state[organisationId].value,
                landing_page: {
                  ...state[organisationId].value?.landing_page,
                  shortcuts: {
                    ...state[organisationId].value?.landing_page?.shortcuts,
                    ...organisation.landing_page?.shortcuts,
                  },
                },
              } as Organisation,
            };
            return;
          default:
            return state;
        }
      }

      default:
        return state;
    }
  });
};

export default organisations;
