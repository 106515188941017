import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export interface CounterProps extends HTMLAttributes<HTMLDivElement> {}

const StyledCounter = styled.div<CounterProps>`
  ${({ theme }) =>
    `
    height: 20px;
    padding: 4px;
    background: ${theme.palette.primary.main};
    border-radius: 4px;
    font-size: 12px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
const Counter: React.FC<React.PropsWithChildren<CounterProps>> = ({ children, ...other }) => {
  return <StyledCounter {...other}>{children}</StyledCounter>;
};
export default Counter;
