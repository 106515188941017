import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import TextButton from 'scalexp/components/atoms/TextButton';
import { MetricSchema2, MetricSchema2Complex } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import Typography from '../../../components/atoms/Typography';
import useActiveOrganisationId from '../../../components/contexts/OrganisationContext/useActiveOrganisationId';
import BlurredModal from '../../../components/molecules/BlurredModal';
import { DerivedMetric } from '../../../store/state/derivedMetrics/types';
import { useUpdateReport } from '../../../store/state/reports/hooks';
import { Report } from '../../../store/state/reports/types';
import FormulaEditor from '../../formula-editor/FormulaEditor';
import usePickerOptions from '../ReportEditor/usePickerOptions';
import { FormulaPickerOption } from '../ReportEditorAddRowSelect';

interface ReportEditorDerivedMetricComputationProps {
  rowId: string;
  showComputationModal: boolean;
  setShowComputationModal: (showComputationModal: boolean) => void;
  derivedMetric: DerivedMetric;
  handleReplaceWithComplexMetricSchema?: (name: string, metricSchema: MetricSchema2) => void;
  report: Report | null;
}

const StyledContainer = styled.div``;

const StyledExtraInfo = styled(Typography)`
  padding: ${({ theme }) => `0 ${theme.spacing(2)}`};
  border: 1px solid #4b5d78;
  border-radius: 4px;
`;

const ReportEditorDerivedMetricComputation: React.FC<
  React.PropsWithChildren<ReportEditorDerivedMetricComputationProps>
> = ({
  rowId,
  showComputationModal,
  setShowComputationModal,
  derivedMetric,
  handleReplaceWithComplexMetricSchema,
  report,
}) => {
  const history = useHistory();

  const organisationId = useActiveOrganisationId();
  const updateReport = useUpdateReport();

  const [name, setName] = useState(derivedMetric.name);

  const pickerOptions = usePickerOptions(report || undefined);
  const normalizedPickerOptions: FormulaPickerOption[] = useMemo(() => {
    return [
      {
        label: 'Fixed Number',
        value: 'constant',
        group: 'Constant',
      },
      ...pickerOptions
        .filter(option => option.id !== 'number' && option.id !== `row:${rowId}`)
        .map(option => ({ ...option, label: option.name, value: option.id, group: option.role })),
    ];
  }, [pickerOptions]);

  const handleShowModal: React.MouseEventHandler<HTMLSpanElement> = e => {
    e.stopPropagation();
    setShowComputationModal(true);
  };

  const onHideModal = () => {
    setShowComputationModal(false);
  };

  const handleSave = (updatedMetricSchema: MetricSchema2Complex) => {
    handleReplaceWithComplexMetricSchema?.(name, updatedMetricSchema);
  };

  const handleGoToMetricLibrary = async () => {
    if (report) {
      updateReport(organisationId, Number(report.id), { ...report });
    }
    history.push(`/settings/metrics-library`, { metricId: derivedMetric.metricId });
  };

  return (
    <>
      <TextButton variant="secondary" iconRight="settings" onClick={handleShowModal}>
        Edit Formula
      </TextButton>
      <BlurredModal
        width="80%"
        showModal={showComputationModal}
        onHide={onHideModal}
        showCloseButton={false}
        isBlurred={false}
      >
        <StyledContainer>
          <FormulaEditor
            pickerOptions={normalizedPickerOptions}
            metricSchema={derivedMetric.metricSchema}
            name={name}
            handleNameChange={setName}
            handleCancel={() => setShowComputationModal(false)}
            handleSave={handleSave}
            report={report}
            extraInfo={
              <StyledExtraInfo size="medium" color="secondary" weight="regular">
                {derivedMetric.name} is re-used throughout the system, click{' '}
                <Typography
                  size="medium"
                  color="primary"
                  weight="regular"
                  style={{ cursor: 'pointer' }}
                  onClick={handleGoToMetricLibrary}
                >
                  here
                </Typography>{' '}
                to update the definition everywhere
              </StyledExtraInfo>
            }
          />
        </StyledContainer>
      </BlurredModal>
    </>
  );
};

export default ReportEditorDerivedMetricComputation;
