import { useAuth0 } from '@auth0/auth0-react';

import Divider from '../../../components/atoms/Divider';
import Icon from '../../../components/atoms/Icon';
import Typography from '../../../components/atoms/Typography';
import Row from '../../../components/layout/Row';
import DropDown from '../../../components/molecules/DropDown';
import {
  HelpContainer,
  LogoContainer,
  HeaderNavBar,
  ProfilePicture,
  ProfileDetailsContainer,
} from '../../../components/organisms/Header/Header.styles';
import { clearSession } from '../../../utils/auth';

const OnboardingHeader = () => {
  const { user: auth0User, isAuthenticated, logout } = useAuth0();
  const logoutAction = async () => {
    clearSession();
    await logout();
  };

  return (
    <HeaderNavBar>
      <Row width="100%" hAlign="space-between" vAlign="center">
        <LogoContainer>
          <img alt="ScaleXP Logo" className="logo" />
        </LogoContainer>
        <a href="https://help.scalexp.com/" target="_blank" rel="noreferrer">
          <HelpContainer>
            <Icon name="help" color="white" marginRight={0} />
            <Typography weight="semibold" color="white">
              Help
            </Typography>
          </HelpContainer>
        </a>
      </Row>
      {isAuthenticated && (
        <DropDown placement="bottomEnd" customButton={<ProfilePicture src={auth0User?.picture} />}>
          <ProfileDetailsContainer>
            <Typography>
              {auth0User?.given_name ?? ''} {auth0User?.family_name ?? ''}
            </Typography>
            <Typography size="tiny">{auth0User?.email ?? ''}</Typography>
          </ProfileDetailsContainer>
          <Divider />

          <DropDown.Item onClick={logoutAction} icon="logout">
            Logout
          </DropDown.Item>
        </DropDown>
      )}
    </HeaderNavBar>
  );
};

export default OnboardingHeader;
