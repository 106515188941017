import React, { useState, useEffect, useRef, PropsWithChildren, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import colors from '../../../colors';
import Checkbox from '../../atoms/Checkbox';
import Counter from '../../atoms/Counter';
import Icon from '../../atoms/Icon';
import TextButton from '../../atoms/TextButton';
import Typography from '../../atoms/Typography';
import { useValidationContext } from '../../contexts/ValidationContext';
import Row from '../../layout/Row';
import { usePortalPositioning } from '../DropDown/usePortalPositioning';
import SearchInput from '../SearchInput';
import { SelectPlacement } from '../Select';
import { useFirstRender, useOutsideAlerter } from './hooks';

type MultiSelectSize = 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
export type MultiSelectPlacement = 'topStart' | 'bottomStart' | 'bottomEnd';
export interface DataItem<Value> {
  label: string;
  value: Value;
  group?: string;
}

type Groups<Value> = {
  [group: string]: { items: DataItem<Value>[]; isExpanded: boolean };
};

export interface MultiSelectProps<Value> {
  data: DataItem<Value>[];
  size?: MultiSelectSize;
  width?: string;
  placeholder?: string;
  selectWidth?: number | 'maxOptionWidth';
  selectPlaceholder?: string;
  values?: Value[];
  placement?: MultiSelectPlacement;
  prefixIconName?: string;
  showCheckBoxs?: boolean;
  searchable?: boolean;
  expandableGroups?: boolean;
  showSelectAll?: boolean;
  showDeSelectAll?: boolean;
  disabled?: boolean;
  orderGroups?: 'ASCENDING' | 'DESCENDING';
  selectHeight?: number;
  showCounter?: boolean;
  renderSelectItem?: (value: Value, item: DataItem<Value>) => React.ReactNode;
  onChange?: (values: Value[], value: Value | undefined) => void;
  onSelect?: (value: Value) => void;
  onClose?: (values: Value[]) => void;
}

const StyledContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2.5)};
`;

const StyledValue = styled.div<{
  size: MultiSelectSize;
  showSelect: boolean;
  hasError?: boolean;
  disabled?: boolean;
  placement: SelectPlacement;
}>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.palette.fieldGrey}`};
  background: ${({ theme }) => theme.palette.white};
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ size, showSelect, theme }) => {
    switch (size) {
      case 'tiny': {
        return css`
          height: 20px;
          padding: 0 ${theme.spacing(6)} ${showSelect ? '1px' : '0'} ${theme.spacing(2)};
          border-radius: ${theme.spacing(1)};
          & > .material-icons-round {
            right: 4px;
          }
        `;
      }
      case 'small': {
        return css`
          height: 28px;
          padding: 0 ${theme.spacing(7)} ${showSelect ? '1px' : '0'} ${theme.spacing(2)};
          border-radius: ${theme.spacing(1)};
          & > .material-icons-round {
            right: 4px;
          }
        `;
      }
      case 'medium': {
        return css`
          height: 36px;
          padding: 0 ${theme.spacing(8)} ${showSelect ? '1px' : '0'} ${theme.spacing(3)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 6px;
          }
        `;
      }
      case 'large': {
        return css`
          height: 40px;
          padding: 0 ${theme.spacing(10)} ${showSelect ? '1px' : '0'} ${theme.spacing(4)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 8px;
          }
        `;
      }
      case 'xlarge': {
        return css`
          height: 44px;
          padding: 0 ${theme.spacing(11)} ${showSelect ? '1px' : '0'} ${theme.spacing(5)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 10px;
          }
        `;
      }
      default:
        return '';
    }
  }};
  ${({ hasError, theme }) => {
    if (hasError) {
      return css`
        border: 2px solid ${theme.palette.danger.dark};
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
      `;
    }
  }}

  // removing the border and raduis from the bottom when the select is shown
  ${({ showSelect, placement }) =>
    showSelect && placement.includes('bottom')
      ? css`
          border-bottom: unset;
          border-bottom-left-radius: unset;
          border-bottom-right-radius: unset;
        `
      : showSelect && placement.includes('top')
      ? css`
          border-top: unset;
          border-top-left-radius: unset;
          border-top-right-radius: unset;
        `
      : ''}

  &:hover,
  &:focus {
    filter: brightness(85%);
  }
  &:active {
    filter: brightness(70%);
  }

  & > .material-icons-round {
    position: absolute;
    color: ${({ theme }) => theme.palette.primary.main};
  }

  span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledSelect = styled.div<{
  size: MultiSelectSize;
  customWidth?: number | 'maxOptionWidth';
  selectHeight: number;
  placement: MultiSelectPlacement;
  container: DOMRect;
  select: { width: number; height: number };
}>`
  position: absolute;
  width: ${({ customWidth, container }) => {
    if (customWidth === 'maxOptionWidth') {
      return `unset`;
    }
    return `${customWidth ?? container.width}px`;
  }};
  & > div:first-child {
    max-height: ${({ selectHeight }) => `${selectHeight}px`};
    width: 100%;
    overflow-y: auto;
    top: 0;
  }
  & > div:last-child {
    width: 100%;
    height: 46px;
    background-color: white;
    padding: 0 20px;
  }
  z-index: ${({ theme }) => theme.zIndex.highest};
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  border-top: unset;
  background-color: ${({ theme }) => theme.palette.white};

  ${({
    placement,
    container: { top, left, width, height },
    select: { height: selectHeight, width: selectWidth },
    customWidth,
  }) => {
    const usedWidth = customWidth === 'maxOptionWidth' ? selectWidth : customWidth ?? selectWidth;
    switch (placement) {
      case 'topStart':
        return css`
          border-top: 1px solid ${({ theme }) => theme.palette.fieldGrey};
          border-bottom: unset;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: ${({ theme }) => theme.spacing(1)};
          border-top-right-radius: ${({ theme }) => theme.spacing(1)};
          top: calc(${top}px - ${selectHeight}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'bottomStart':
        return css`
          border-bottom-left-radius: ${({ theme }) => theme.spacing(1)};
          border-bottom-right-radius: ${({ theme }) => theme.spacing(1)};
          top: calc(${top}px + ${height}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'bottomEnd':
        return css`
          top: ${top + height + window.scrollY}px;
          left: ${left - usedWidth + width + window.scrollX}px;
        `;
    }
  }};

  ${({ size, theme, placement }) => {
    if (!placement.includes('top')) {
      switch (size) {
        case 'tiny':
        case 'small': {
          return css`
            border-bottom-left-radius: ${theme.spacing(1)};
            border-bottom-right-radius: ${theme.spacing(1)};
          `;
        }
        case 'medium':
        case 'large':
        case 'xlarge': {
          return css`
            border-bottom-left-radius: ${theme.spacing(2)};
            border-bottom-right-radius: ${theme.spacing(2)};
          `;
        }
        default:
          return '';
      }
    } else {
      switch (size) {
        case 'tiny':
        case 'small': {
          return css`
            border-top-left-radius: ${theme.spacing(1)};
            border-top-right-radius: ${theme.spacing(1)};
          `;
        }
        case 'medium':
        case 'large':
        case 'xlarge': {
          return css`
            border-top-left-radius: ${theme.spacing(2)};
            border-top-right-radius: ${theme.spacing(2)};
          `;
        }
        default:
          return '';
      }
    }
  }}
`;

const StyledGroup = styled.div<{ selected?: boolean }>`
  height: ${({ theme }) => `${theme.spacing(9.5)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  font-weight: ${({ theme, selected }) => (selected ? theme.font.weight.semibold : theme.font.weight.regular)};
  font-size: ${({ theme }) => theme.font.size.small};
  cursor: pointer;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledSelectPlaceholder = styled.div`
  height: ${({ theme }) => `${theme.spacing(7)}`};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  color: ${({ theme }) => theme.palette.granite};
  font-size: ${({ theme }) => theme.font.size.tiny};
  line-height: ${({ theme }) => theme.font.lineheight.tiny};
`;

const StyledSection = styled.div<{ pointer?: boolean }>`
  min-height: ${({ theme }) => theme.spacing(13)};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};
  border-bottom: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  ${({ pointer }) => (pointer ? 'cursor: pointer' : '')};
`;

const StyledSelectItem = styled.div<{ selected?: boolean }>`
  height: ${({ theme }) => theme.spacing(9)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  background-color: ${({ theme, selected }) => (selected ? theme.palette.primary.offwhite : theme.palette.white)};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  font-weight: ${({ theme, selected }) => (selected ? theme.font.weight.semibold : theme.font.weight.regular)};
  font-size: ${({ theme }) => theme.font.size.medium};
  cursor: pointer;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

function MultiSelect<Value = string>({
  size = 'small',
  width = 'unset',
  placeholder = 'add items',
  selectWidth,
  selectPlaceholder,
  data,
  values,
  placement,
  orderGroups = 'ASCENDING',
  prefixIconName,
  showCheckBoxs = true,
  searchable,
  expandableGroups,
  showSelectAll,
  showDeSelectAll,
  disabled,
  showCounter = true,
  selectHeight = 386,
  renderSelectItem,
  onChange,
  onSelect,
  onClose,
}: PropsWithChildren<MultiSelectProps<Value>>) {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const validation = useValidationContext();

  const [showSelect, setShowSelect] = useState(false);
  const {
    containerCallbackRef,
    containerRef,
    containerRect,
    elementRef: selectRef,
    elementCallbackRef: selectCallbackRef,
    elementDimensions: selectDimensions,
  } = usePortalPositioning(showSelect, setShowSelect);

  const [innerValues, setInnerValues] = useState<Value[]>(values ?? []);
  const [search, setSearch] = useState('');
  const [groups, setGroups] = useState<Groups<Value>>({});

  const usedValues = values || innerValues;

  const handleItemClick = (itemValue: Value) => {
    onSelect?.(itemValue);

    const newValues = [...usedValues];
    const index = newValues.findIndex(value => value === itemValue);
    if (index === -1) {
      newValues.push(itemValue);
    } else {
      newValues.splice(index, 1);
    }
    setInnerValues([...newValues]);
    onChange?.([...newValues], itemValue);
  };

  const selectedValuesData = useMemo(() => data.filter(item => usedValues.includes(item.value)), [data, usedValues]);
  const searchedValuesData = useMemo(
    () => data.filter(item => item.label.toLowerCase().includes(search.toLowerCase())),
    [data, search],
  );
  useEffect(() => {
    const grouped = searchedValuesData.reduce((groups, item) => {
      const category = item.group;
      if (category) {
        groups[category] = groups[category] ?? { items: [], isExpanded: true };
        groups[category].items.push(item);

        return groups;
      }

      groups[''] = groups[''] ?? { items: [], isExpanded: true };
      groups[''].items.push(item);

      return groups;
    }, {} as Groups<Value>);
    setGroups(grouped);
  }, [searchedValuesData]);

  const handleGroupExpand = (groupName: string) => {
    setGroups({ ...groups, [groupName]: { ...groups[groupName], isExpanded: !groups[groupName].isExpanded } });
  };

  const allChecked = useMemo(
    () => data.every(item => selectedValuesData.find(selectedValue => selectedValue.value === item.value)),
    [data, selectedValuesData],
  );
  const allIndeterminate =
    !allChecked && data.some(item => selectedValuesData.find(selectedValue => selectedValue.value === item.value));

  const handleSelectAll = () => {
    if (allChecked) {
      setInnerValues([]);
      onChange?.([], undefined);
      return;
    }

    setInnerValues(data.map(item => item.value));
    onChange?.(
      data.map(item => item.value),
      undefined,
    );
  };

  const handleDeSelectAll = () => {
    setInnerValues([]);
    onChange?.([], undefined);
  };

  const focusInput = () => {
    searchInputRef.current?.focus();
  };

  const isFirstRender = useFirstRender();
  useEffect(() => {
    // on close
    if (!showSelect) {
      // clear search on select close
      setSearch('');

      // if it is not the first render we invoke onClose
      if (!isFirstRender) {
        onClose?.(usedValues);
      }
    }

    // focus input on key down
    if (showSelect) {
      focusInput();
      document.addEventListener('keydown', focusInput);
    }
    return () => document.removeEventListener('keydown', focusInput);
  }, [showSelect]);

  useOutsideAlerter(selectRef, () => setShowSelect(false));

  // placement
  const distanceToBottom = window.innerHeight - (containerRef.current?.getBoundingClientRect().bottom || 0);
  const usedPlacement: MultiSelectPlacement = placement
    ? placement
    : distanceToBottom > selectHeight
    ? 'bottomEnd'
    : 'topStart';

  const typographySize =
    size === 'small' || size === 'medium' ? 'small' : size === 'large' || size === 'xlarge' ? 'medium' : size;
  const iconSize = size === 'small' ? 4.5 : size === 'medium' ? 5 : size === 'large' ? 5.5 : size === 'xlarge' ? 6 : 4;

  const hasError = validation?.hasError && !selectedValuesData.length;

  return (
    <StyledContainer ref={containerCallbackRef} onKeyDown={focusInput} width={width}>
      <StyledValue
        hasError={hasError}
        size={size}
        placement={usedPlacement}
        disabled={disabled}
        onClick={() => !disabled && setShowSelect(!showSelect)}
        showSelect={showSelect}
      >
        <Row vAlign="center" spacing="small">
          {!!prefixIconName && <Icon name={prefixIconName} rounded marginRight={0} />}
          <Typography size={typographySize} color="secondary">
            {placeholder}
          </Typography>
          {showCounter && !!selectedValuesData.length && <Counter>{selectedValuesData.length}</Counter>}
        </Row>
        <Icon size={iconSize} name="expand_more" rounded marginRight={0} />
      </StyledValue>
      {showSelect &&
        createPortal(
          <StyledSelect
            ref={selectCallbackRef}
            size={size}
            selectHeight={selectHeight - 46}
            placement={usedPlacement}
            container={containerRect}
            select={selectDimensions}
            customWidth={selectWidth}
          >
            <div>
              {selectPlaceholder && <StyledSelectPlaceholder>{selectPlaceholder}</StyledSelectPlaceholder>}
              {searchable && (
                <StyledSection>
                  <SearchInput ref={searchInputRef} value={search} setValue={setSearch} />
                </StyledSection>
              )}
              {showSelectAll && (
                <StyledSection onClick={handleSelectAll} pointer>
                  <Row vAlign="center" spacing="small">
                    {showCheckBoxs && <Checkbox checked={allChecked} indeterminate={allIndeterminate} />}
                    <span>Select all</span>
                  </Row>
                </StyledSection>
              )}
              {showDeSelectAll && (
                <StyledSection>
                  <Row vAlign="center" spacing="tiny">
                    <TextButton variant={'secondary'} onClick={handleDeSelectAll}>
                      Deselect All
                    </TextButton>
                  </Row>
                </StyledSection>
              )}
              {searchable && searchedValuesData.length === 0 ? (
                <StyledSelectItem>
                  <Row vAlign="center" spacing="small">
                    <Icon size={5} name="dangerous" color={colors.granite} marginRight={0} rounded />
                    <Typography weight="regular">No results found</Typography>
                  </Row>
                </StyledSelectItem>
              ) : (
                Object.entries(groups)
                  .sort(([a], [b]) => (orderGroups === 'ASCENDING' ? a.localeCompare(b) : b.localeCompare(a)))
                  .map(([groupName, group]) => (
                    <>
                      {!!groupName && (
                        <StyledGroup key={groupName} onClick={() => expandableGroups && handleGroupExpand(groupName)}>
                          {groupName.toUpperCase()}
                          {expandableGroups && (
                            <Icon
                              name={group.isExpanded ? 'expand_less' : 'expand_more'}
                              size={6}
                              color={colors.blue[600]}
                              marginRight={0}
                            />
                          )}
                        </StyledGroup>
                      )}
                      {group.isExpanded &&
                        group.items.map(item => {
                          const isSelected = usedValues.includes(item.value);
                          return (
                            <StyledSelectItem
                              key={`${item.label}${item.value}`}
                              onClick={() => handleItemClick(item.value)}
                              selected={isSelected}
                            >
                              <Row vAlign="center" spacing="small">
                                {showCheckBoxs && <Checkbox checked={isSelected} />}
                                {renderSelectItem ? renderSelectItem(item.value, item) : <span>{item.label}</span>}
                              </Row>
                              {isSelected && <Icon size={5} name="done" color={colors.blue[600]} rounded />}
                            </StyledSelectItem>
                          );
                        })}
                    </>
                  ))
              )}
            </div>
            <Row width="100%" hAlign="flex-end" vAlign="center">
              <TextButton width="80px" onClick={() => setShowSelect(false)}>
                Done
              </TextButton>
            </Row>
          </StyledSelect>,
          document.body,
        )}
    </StyledContainer>
  );
}
export default MultiSelect;
