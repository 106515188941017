import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Input from '../../../components/atoms/Input';
import Loader from '../../../components/atoms/Loader';
import SidebarWrapper from '../../../components/atoms/SidebarWrapper';
import { useEditChartContext } from '../../../components/contexts/NewEditChartContext';
import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import Message from '../../../components/molecules/Message';
import Switcher from '../../../components/molecules/Switcher';
import { useBudgetSets } from '../../../store/state/budgetsets/hooks';
import { useConsolidatedBudgetSets } from '../../../store/state/consolidatedBudgetsets/hooks';
import { useDerivedMetrics } from '../../../store/state/derivedMetrics/hooks';
import { useNativeMetricNames } from '../../../store/state/nativeMetrics/hooks';
import ChartConfiguration from '../../chart/ChartConfiguration';
import KPIChartOptions from '../KPIChartOptions';
import PieChartOptions from '../PieChartOptions';
import TimeChartOptions from '../TimeChartOptions';
import WaterfallChartOptions from '../WaterfallChartOptions';

export interface ChartsSidebarProps {}
export const NUMBER_OF_MONTHS = 24;
export const FUTURE_MONTH_OPTIONS = (period = 'month', customMonthNumber?: number) => [
  { label: '0', value: 0 },
  ...[...new Array(customMonthNumber ?? NUMBER_OF_MONTHS)].map((_, index) => ({
    label: `${index + 1}`,
    value: index + 1,
  })),
];
export const PAST_MONTH_OPTIONS = (period = 'month', customMonthNumber?: number) => [
  { label: '-1', value: 1 },
  { label: '0', value: 0 },
  ...[...new Array(customMonthNumber ?? NUMBER_OF_MONTHS)].map((_, index) => ({
    label: `${index + 1}`,
    value: (index + 1) * -1,
  })),
];

export const ACTUAL_BUDGET_OPTIONS = [
  { label: 'Actual', value: 'ACTUAL' },
  { label: 'Budget', value: 'BUDGET' },
];

export const PERIODS_OPTIONS = [
  { label: 'Month', value: 'MONTH' },
  { label: 'Quarter', value: 'QUARTER' },
  { label: 'Year', value: 'YEAR' },
  { label: 'Year to date', value: 'YEAR_TO_DATE' },
];

export const SERIES_TYPES_OPTIONS = [
  { label: 'Column', value: 'ColumnSeries', img: '/images/charts/bar-chart-type.svg' },
  { label: 'Line', value: 'LineSeries', img: '/images/charts/line-chart-type.svg' },
  { label: 'Stacked Column', value: 'Stacked', img: '/images/charts/stacked-bar-chart-type.svg' },
];

export const SeriesSectionTitle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.palette.backgroundGrey};
    padding: ${theme.spacing(4)} ${theme.spacing(5)};
    border-top: 1px solid ${theme.palette.silver};
    .material-icons {
      cursor: pointer;
    }
  `}
`;
const ChartsSidebarWrapper = styled(SidebarWrapper)`
  ${({ theme }) => css`
    width: ${theme.sizing(93.75)};
    border: none;
    box-shadow: inset -1px 0px 0px #e2e2ea;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    align-items: center;
    padding: 0;
    background-color: ${theme.palette.white};
    & > *:first-child {
      height: 74px;
      flex: 0 0 auto;
      padding: 0 16px;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #dfdfdf;
    }
    & > *:nth-child(2) {
      overflow: auto;
    }
  `}
`;
export const DeleteSeriesContainer = styled.div<{ visibility: boolean }>`
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.3s;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.backgroundGrey};
  z-index: ${({ theme }) => theme.zIndex.low};
  display: flex;
  border-radius: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme, visibility }) => {
    if (visibility) {
      return css`
        opacity: 1;
        z-index: ${theme.zIndex.regular};
      `;
    }
  }}
  & div span:nth-child(3) {
    background: #ffffff;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 236px;
    min-height: 36px;
  }
`;

export const SeriesOptionContainer = styled.div<{ active?: boolean }>`
  background: ${({ theme }) => theme.palette.backgroundGrey};
  border-radius: ${({ theme }) => theme.sizing(3)};
  width: 100%;
  height: auto;
  padding: ${({ theme }) => theme.spacing(5)} ${({ theme }) => theme.spacing(3.75)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  position: relative;
  border: 2px solid transparent;

  ${({ theme, active }) => {
    if (active) {
      return css`
        opacity: 1;
        border: 2px solid ${theme.palette.primary.main};
      `;
    }
    if (active === false) {
      return css`
        opacity: 0.8;
      `;
    }
  }}
`;

export const StyledSidebarInput = styled(Input)`
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  height: ${({ theme }) => theme.sizing(9)};
  border-radius: ${({ theme }) => theme.sizing(2)}; ;
`;

export const StyledDisabledSeriesName = styled(Row)`
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  height: ${({ theme }) => theme.sizing(9)};
  border-radius: ${({ theme }) => theme.sizing(2)};
  cursor: not-allowed;
  width: ${({ theme }) => theme.spacing(61.75)};
  background: ${({ theme }) => theme.palette.silver};
  padding-left: ${({ theme }) => theme.spacing(3)};
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledSidebarColorPicker = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.sizing(2)};
  width: ${({ theme }) => theme.sizing(8)};
  height: ${({ theme }) => theme.sizing(8)};
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    display: none;
  }
  & > div {
    border: none;
    border-radius: 4px;
    width: auto;
  }
  & > div > div {
    border-radius: 4px;
  }
`;
export const SwitcherSidebarWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  border-radius: ${({ theme }) => theme.sizing(2)};
  & > div {
    background-color: ${({ theme }) => theme.palette.white};
    border: none;
    border-radius: inherit;
    gap: 0;
  }
  & > div > div {
    border-radius: 4px;
    font-weight: ${({ theme }) => theme.font.weight.medium};
    font-size: ${({ theme }) => theme.sizing(3)};
    line-height: ${({ theme }) => theme.sizing(4.5)};
  }
`;

export const StyledNodeChoice = styled(Row)`
  width: 100%;
  overflow: hidden;
  & > span:first-child {
    width: ${({ theme }) => theme.sizing(6.25)};
  }
  & > span:nth-child(2) {
    overflow: hidden;
    width: calc(100% - ${({ theme }) => theme.sizing(7.5)});
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & > span:only-child {
    width: 100%;
  }
`;

const ChartsSidebar: React.FC<ChartsSidebarProps> = () => {
  const organisation = useOrganisationContext();
  const [activeMenu, setActiveMenu] = useState<'CHART_SERIES_CONFIGURATION' | 'CHART_CONFIGURATION'>(
    'CHART_SERIES_CONFIGURATION',
  );
  const organisationId = organisation.organisation_id;
  const { chart } = useEditChartContext();
  const nativeMetricNamesVS = useNativeMetricNames();
  const derivedMetricsVS = useDerivedMetrics(organisationId);
  const budgetSetsVS = useBudgetSets(organisationId);
  const consolidatedbudgetSetsVS = useConsolidatedBudgetSets(organisationId);

  if (
    nativeMetricNamesVS.status === 'pending' ||
    derivedMetricsVS.status === 'pending' ||
    budgetSetsVS.status === 'pending' ||
    consolidatedbudgetSetsVS.status === 'pending'
  ) {
    return <Loader content="Loading..." />;
  }

  if (
    nativeMetricNamesVS.status === 'error' ||
    derivedMetricsVS.status === 'error' ||
    budgetSetsVS.status === 'error' ||
    consolidatedbudgetSetsVS.status === 'error'
  ) {
    return <Message variant="danger"> Couldn't load metric names.</Message>;
  }

  const budgetOptions = [
    { label: 'default Budget', value: null },
    ...budgetSetsVS.value.map(budgetSet => ({ label: budgetSet.name, value: budgetSet.id })),
  ];
  const consolidatedBudgetOptions = [
    { label: 'default Budget', value: null },
    ...consolidatedbudgetSetsVS.value?.map(budgetSet => ({
      label: budgetSet.name,
      value: budgetSet.id,
    })),
  ];
  const isPieChart = chart.chart_type === 'pie_chart';
  const isWaterfallChart = chart.chart_type === 'waterfall_chart';
  const isTimeChart = chart.chart_type === 'time_chart';
  const isKPIChart = chart.chart_type === 'kpi_chart';

  return (
    <ChartsSidebarWrapper role="navigation">
      <Column width="100%">
        <Switcher
          width="100%"
          size="medium"
          value={activeMenu}
          onChange={(value: 'CHART_SERIES_CONFIGURATION' | 'CHART_CONFIGURATION') => setActiveMenu(value)}
          data={[
            { label: isKPIChart ? 'KPI Series' : 'Chart Series', value: 'CHART_SERIES_CONFIGURATION' },
            { label: `${isKPIChart ? 'KPI Name' : 'Chart Name'} & Settings`, value: 'CHART_CONFIGURATION' },
          ]}
        />
      </Column>
      <Column width="100%">
        {activeMenu === 'CHART_CONFIGURATION' ? (
          <ChartConfiguration isKPIChart={isKPIChart} />
        ) : isTimeChart ? (
          <TimeChartOptions
            consolidatedBudgetOptions={consolidatedBudgetOptions}
            budgetOptions={budgetOptions}
            chart={chart}
          />
        ) : isKPIChart ? (
          <KPIChartOptions
            consolidatedBudgetOptions={consolidatedBudgetOptions}
            budgetOptions={budgetOptions}
            chart={chart}
          />
        ) : isWaterfallChart ? (
          <WaterfallChartOptions
            consolidatedBudgetOptions={consolidatedBudgetOptions}
            budgetOptions={budgetOptions}
            chart={chart}
          />
        ) : (
          isPieChart && (
            <PieChartOptions
              consolidatedBudgetOptions={consolidatedBudgetOptions}
              budgetOptions={budgetOptions}
              chart={chart}
            />
          )
        )}
      </Column>
    </ChartsSidebarWrapper>
  );
};

export default ChartsSidebar;
