import React, { useEffect, useRef } from 'react';
import ReactCrop, { PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';

import { theme } from '../../../../theme';
import Icon from '../../../atoms/Icon';
import TextButton from '../../../atoms/TextButton';
import Typography from '../../../atoms/Typography';
import Column from '../../../layout/Column';
import Row from '../../../layout/Row';
import BlurredModal from '../../../molecules/BlurredModal';

const CropContainer = styled.div`
  margin-top: 80px;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(4)}`};
`;

interface LogoCropModalProps {
  logoUrl: string;
  showCropModal: boolean;
  isUploading: boolean;
  resetCompanyLogo: () => void;
  setShowCropModal: (show: boolean) => void;
  setCrop: (c: PixelCrop) => void;
  handleCroppedImageUpload: (image: HTMLImageElement | null) => void;
  crop?: PixelCrop;
}
const LogoCropModal: React.FC<React.PropsWithChildren<LogoCropModalProps>> = ({
  logoUrl,
  showCropModal,
  isUploading,
  setCrop,
  setShowCropModal,
  resetCompanyLogo,
  handleCroppedImageUpload,
  crop,
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (!imageRef.current || !showCropModal) {
      return;
    }
    imageRef.current.onload = () => {
      setCrop({
        ...crop,
        x: 0,
        y: 0,
        height: imageRef.current!.height,
        width: imageRef.current!.width,
        unit: 'px',
      });
    };
  }, [showCropModal]);

  const handleHideModal = () => {
    resetCompanyLogo();
    setShowCropModal(false);
  };
  return (
    <BlurredModal
      footerContent={
        <Row hAlign="flex-end" spacing="medium">
          <TextButton width="100px" onClick={handleHideModal} variant="secondary">
            Cancel
          </TextButton>
          <TextButton
            loading={isUploading}
            width="100px"
            onClick={() => handleCroppedImageUpload(imageRef.current)}
            variant="primary"
          >
            Save
          </TextButton>
        </Row>
      }
      showModal={showCropModal}
      onHide={handleHideModal}
    >
      <Column vAlign="center" hAlign="center">
        <Row hAlign="center" vAlign="center">
          <CropContainer>
            <ReactCrop minWidth={50} minHeight={50} crop={crop} keepSelection ruleOfThirds onChange={c => setCrop(c)}>
              <img ref={imageRef} crossOrigin="anonymous" src={logoUrl} alt="Company Logo" />
            </ReactCrop>
          </CropContainer>
        </Row>
        <Row hAlign="center" spacing="tiny" vAlign="center">
          <Icon name="info" color={theme.palette.fieldGrey} />
          <Typography color="secondary">
            Click and drag to crop the image then click <b> Save</b> to upload
          </Typography>
        </Row>
        <div />
      </Column>
    </BlurredModal>
  );
};

export default LogoCropModal;
