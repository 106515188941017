import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import React, { useEffect, useRef, useState } from 'react';
import { Loader } from 'rsuite';
import styled from 'styled-components';

import { useChartDataType } from '../../../components/contexts/NewEditChartContext/useChartDataType';
import Row from '../../../components/layout/Row';
import useCurrencySelection from '../../../components/molecules/CurrencySelect/useCurrencySelection';
import ChartLegend from '../ChartLegend';
import ChartTitle from '../ChartTitle';
import { getTimeChartInstance } from './helpers';
import { TimeChart as ITimeChart } from './types';
import { useTimeChartConfig } from './useTimeChartConfig';

export const StyledTimeChartContainer = styled.div`
  border-radius: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
`;
export const StyledFigureContainer = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  flex-grow: 1;
`;

export type TimeChartProps = {
  config: ITimeChart;
  parentCallback?: (chart?: am4charts.XYChart) => void;
  onDateRangeChanged?: (options: { minDate?: Date; maxDate?: Date }) => void;
  minDate?: Date;
  maxDate?: Date;
  disableScrollbar?: boolean;
  rightContent?: React.ReactNode;
  headerConfig?: React.ReactNode;
};

const TimeChart: React.FC<TimeChartProps> = ({
  config,
  onDateRangeChanged,
  headerConfig,
  rightContent,
  disableScrollbar = false,
  parentCallback,
  minDate,
  maxDate,
}) => {
  const [chartState, setChartState] = useState<am4charts.XYChart | undefined>(undefined);
  const chartRef = useRef<HTMLDivElement>(null);
  const amChartRef = useRef<am4charts.XYChart | null>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { getSeriesDataType } = useChartDataType(config);
  const firstRightSeries = config.chart_data.series.find(series => series.y_axis_side === 'RIGHT');
  let firstRightSeriesType: string | undefined = undefined;
  if (firstRightSeries) {
    firstRightSeriesType = getSeriesDataType(firstRightSeries);
  }
  const firstLeftSeries = config.chart_data.series.find(series => series.y_axis_side === 'LEFT');
  let firstLeftSeriesType: string | undefined = undefined;
  if (firstLeftSeries) {
    firstLeftSeriesType = getSeriesDataType(firstLeftSeries);
  }

  const chartConfig = useTimeChartConfig(config);
  const [chartReady, setChartReady] = useState(false);
  const [currencyCode] = useCurrencySelection('currencyCode');
  const [dateRangeChangedListener, setDateRangeChangedListener] = useState<am4core.IDisposer | undefined>(undefined);

  useEffect(() => {
    if (!chartConfig.value || chartReady) {
      return;
    }

    setChartReady(true);
    const cRef = chartRef.current;

    const chart = getTimeChartInstance(
      // @ts-ignore
      chartConfig.value,
      cRef,
      firstLeftSeriesType,
      firstRightSeriesType,
      currencyCode,
      minDate,
      maxDate,
    );
    amChartRef.current = chart;
    if (disableScrollbar) {
      chart.scrollbarX.disabled = true;
      chart.cursor.behavior = 'none';
    }
    // The toolbar is not visible on the first render because the chart object is empty. So we add event to update the state and show the toolbar.
    chart.events.once('appeared', () => {
      setChartState(chart);
    });
    parentCallback?.(chart);
    attachEventListeners(chart, (minDate, maxDate) =>
      onDateRangeChanged?.({
        minDate,
        maxDate,
      }),
    );
  }, [chartConfig]);

  useEffect(() => {
    return () => {
      let chart = amChartRef.current;
      if (chart) {
        chart.dispose();
      }
    };
  }, []);

  const attachEventListeners = (
    chart: am4charts.XYChart,
    onDateRangeChanged?: (minDate: Date, maxDate: Date) => void,
  ) => {
    // If we're an XYChart with a DateAxis, let's hook into the zoom so we can
    //
    //  1. set the range
    //  2. pickup any changes
    //
    // This is useful, for instance in the boardpacks to allow people to specify
    // the range they want to export

    if (!(chart instanceof am4charts.XYChart)) {
      return;
    }
    const xAxis = chart.xAxes.getIndex(0);
    if (xAxis instanceof am4charts.DateAxis) {
      if (dateRangeChangedListener) {
        dateRangeChangedListener.dispose();
      }
      if (onDateRangeChanged) {
        setDateRangeChangedListener(
          xAxis.events.on('startendchanged', event => {
            onDateRangeChanged(new Date(event.target.minZoomed), new Date(event.target.maxZoomed));
          }),
        );
      }
    }
  };
  return (
    <StyledTimeChartContainer className="chart-card" ref={chartContainerRef}>
      {!chartReady && <Loader center content="Loading..." />}

      <>
        <ChartTitle title={config.name} headerConfig={headerConfig} rightContent={rightContent} />
        <ChartLegend chart={chartState} />

        {chartConfig?.value?.isEmpty ? (
          <Row height="100%" width="100%" vAlign="center" hAlign="center">
            No data available
          </Row>
        ) : (
          <StyledFigureContainer ref={chartRef} role="figure" aria-label="Time Chart" />
        )}
      </>
    </StyledTimeChartContainer>
  );
};

export default TimeChart;
