import { getAccessTokenPermissions, getAccessTokenResourceAccess } from '../utils/accessToken';

export const useHasWriteAllPermission = (): boolean => {
  return getAccessTokenPermissions().includes('write:all');
};

export const useUserHasResourcePermission = (): [string | null, string | null] => {
  const resources = getAccessTokenResourceAccess();
  const hasWriteAllPermission = useHasWriteAllPermission();
  let [page, redirectUrl]: [string | null, string | null] = [null, null];
  if (!resources) {
    return [page, redirectUrl];
  }
  const dashboards = resources.dashboards;
  const reports = resources.reports;
  const presentations = resources.presentations;

  const resourcesLength = dashboards.length + reports.length + presentations.length;
  if (!hasWriteAllPermission && resourcesLength > 0) {
    if (dashboards.length > 0) {
      redirectUrl = `/dashboards/${dashboards[0]}`;
      page = '/dashboards';
    } else if (reports.length > 0) {
      redirectUrl = `/reports/${reports[0]}`;
      page = '/reports';
    } else if (presentations.length > 0) {
      redirectUrl = presentations.length === 1 ? `/presentations/${presentations[0]}/edit` : '/presentations';
      page = '/presentations';
    }
  }
  return [page, redirectUrl];
};

export const useHasReadAllPermission = (): boolean => {
  return getAccessTokenPermissions().includes('read:all');
};
