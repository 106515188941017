import qs from 'query-string';
import React from 'react';
import { useParams } from 'react-router';
import { ref, object, string } from 'yup';

import Background from '../../components/atoms/Background';
import Box from '../../components/atoms/Box';
import TextButton from '../../components/atoms/TextButton';
import Typography from '../../components/atoms/Typography';
import Spacer from '../../components/layout/Spacer';
import Form from '../../components/molecules/Form';
import FormFieldInput from '../../components/molecules/FormFieldInput';
import Message from '../../components/molecules/Message';
import WidePage from '../../components/templates/WidePage';
import fetcher from '../../utils/fetcher';

const ResetPasswordSchema = object().shape({
  new_password1: string()
    .required('Password is required')
    .min(8, 'Password should contain more than 8 characters')
    .matches(/[a-zA-Z]/i, {
      message: 'Password needs to contain at least 1 character',
    })
    .matches(/[0-9]/i, {
      message: 'Password needs to contain at least 1 number',
    }),
  new_password2: string()
    .required('Password is required')
    .oneOf([ref('new_password1'), null], 'Passwords must match'),
});

const ResetPasswordPage = () => {
  const params: {
    uidb64: string;
    token: string;
  } = useParams();
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');

  const handleResetPassword = async ({
    new_password1,
    new_password2,
  }: {
    new_password1?: string;
    new_password2?: string;
  }) => {
    try {
      let data = qs.stringify({
        new_password1, //gave the values directly for testing
        new_password2,
      });
      await fetcher(`/reset-password/${params.uidb64}/set-password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
      });

      setMessage('Password was updated successfuly, you will be redirected to login in a few seconds...');
      setTimeout(() => {
        window.location.href = '/signin/';
      }, 3000);
    } catch (error) {
      setError('Something went wrong. Please retry later.');
    }
  };
  return (
    <WidePage>
      <Background>
        <Box width={480}>
          <Spacer margin={{ bottom: 8 }}>
            <Typography size="h5" weight="bold" display="block">
              Reset password
            </Typography>
            <Spacer margin={{ top: 1 }} />
            <Typography display="block" color="secondary">
              Please enter your new password.
            </Typography>
          </Spacer>
          {message && (
            <Spacer margin={{ bottom: 6 }}>
              <Message variant="info">{message}</Message>
            </Spacer>
          )}
          {error && (
            <Spacer margin={{ bottom: 6 }}>
              <Message onHide={() => setError('')}>{error}</Message>
            </Spacer>
          )}
          <Form
            onSubmit={handleResetPassword}
            schema={ResetPasswordSchema}
            actions={<TextButton type="submit">Reset password</TextButton>}
          >
            <FormFieldInput width="100%" name="new_password1" label="New Password" type="password" />
            <FormFieldInput width="100%" name="new_password2" label="Confirm new password" type="password" />
          </Form>
        </Box>
      </Background>
    </WidePage>
  );
};
export default ResetPasswordPage;
