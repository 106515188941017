import { Job } from 'scalexp/features/connections/sync-context/types';

const getProcessingInvoiceAccountJobToShow = (processingInvoiceAccountRulesJobs: Job[]) => {
  const invoiceProcessingInProgress = processingInvoiceAccountRulesJobs.filter(job => job.status === 'Running');
  const successfulInvoiceProcessingJob = processingInvoiceAccountRulesJobs.filter(job => job.status === 'Completed');
  const invoiceProcessingJobsToShow =
    invoiceProcessingInProgress.length > 0
      ? [invoiceProcessingInProgress[0]]
      : successfulInvoiceProcessingJob.length > 0
      ? [successfulInvoiceProcessingJob[0]]
      : [];

  return invoiceProcessingJobsToShow;
};

export default getProcessingInvoiceAccountJobToShow;
