import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useUserContext } from 'scalexp/components/contexts/UserContext';
import styled from 'styled-components';

import Checkbox from '../../../components/atoms/Checkbox';
import Notification from '../../../components/atoms/Notification';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import { useOnboardingContext } from '../../../components/contexts/OnboardingContext';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import Spacer from '../../../components/layout/Spacer';
import OnboardingPageTitle from '../../../components/molecules/OnboardingPageTitle';
import { Organisation } from '../../../service';
import fetcher from '../../../utils/fetcher';
import { logUndefinedOrgFunc } from '../../../utils/logger';
import { setCurrentOrganisation } from '../../../utils/organisation';
import { SECTORS_CHOICES } from '../OnboardingOrganisationInfo';

let useCases = [
  {
    title: 'Automate Revenue Recognition (Deferred revenue)',
    description: 'Automate deferred income and MRR calculations.',
    img: '/images/onboarding/money-icon.png',
    value: 'AUTOMATE_REVENUE_RECOGNITION',
  },
  {
    title: 'Automate Prepaid Expenses',
    description: 'Automate prepaid expense calculations and journals',
    img: '/images/onboarding/recycle-money-icon.png',
    value: 'AUTOMATE_PREPAYMENTS_AND_EXPENSES',
  },
  {
    title: 'Subscription & Contract Management ',
    description: 'Issue invoices from Hubspot deals; easily track contract renewals',
    img: '/images/onboarding/subscription-management.svg',
    value: 'SUBSCRIPTION_MANAGEMENT',
  },
  {
    title: 'Revenue Forecasting',
    description: 'Easily create comprehensive revenue forecast from CRM + invoice data',
    img: '/images/onboarding/sales-icon.png',
    value: 'CRM_SALES_DATA',
  },
  {
    title: 'Stripe integration',
    description: 'Connect your Stripe  to replicate Stripe invoices in Xero or Quickbooks',
    img: '/images/onboarding/stripe.svg',
    value: 'STRIPE_INTEGRATION',
  },
];

const OnboardingHowWillUseContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const UseChoice = styled(Row)`
  width: 900px;
  cursor: pointer;
  height: 80px;
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  border-radius: 10px;
  padding: 0 20px;
`;

interface OrganisationUseCaseResponse {
  use_case: string[];
  consolidation_type: Organisation['consolidation_type'];
  is_onboarding_paused: boolean;
  organisation: Organisation;
}

const OnboardingHowWillUse: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [checkedUseCases, setCheckedUseCases] = React.useState<string[]>([]);
  const [listCases, setListCases] = useState(useCases);

  const history = useHistory();
  const { organisationId } = useParams<{ organisationId: string }>();
  const { onboardingConfig, setOnboardingConfig } = useOnboardingContext();
  const { loginWithRedirect } = useAuth0();
  const user = useUserContext();
  useEffect(() => {
    if (useCases.length === 5) {
      useCases = [
        ...useCases.slice(0, 2),
        {
          title: `Automate ${
            SECTORS_CHOICES.find(sector => sector.value === onboardingConfig.organisation.sector)?.label
          }  metrics`,
          description: 'Instant and customisable metrics, tailored to your sector',
          img: '/images/onboarding/metric-icon.png',
          value: 'SAAS_SUBSCRIPTION_METRICS',
        },
        onboardingConfig.organisation.children.length > 0
          ? {
              title: 'Consolidate across companies or currencies',
              description: 'Instant financial consolidation of across companies, systems, currencies',
              img: '/images/onboarding/currency-icon.png',
              value: 'CONSOLIDATE_COMPANIES_CURRENCIES',
            }
          : {
              title: 'Improve Reporting & Analytics',
              description: 'Instant, personalised reports and visuals integrating financial and CRM data',
              img: '/images/onboarding/reporting.svg',
              value: 'REPORTING',
            },
        ...useCases.slice(2),
      ];
    }

    setListCases(useCases);
  }, []);
  const handleSave = () => {
    setIsLoading(true);
    try {
      fetcher(`/api/v2/organisation/${organisationId}/update_use_case`, {
        method: 'PUT',
        body: JSON.stringify({
          use_case: checkedUseCases,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((OrganisationUseCaseResponse: OrganisationUseCaseResponse) => {
        setIsLoading(false);
        setOnboardingConfig({
          ...onboardingConfig,
          user: {
            ...onboardingConfig.user,
            useCases: checkedUseCases,
          },
        });
        setCurrentOrganisation(OrganisationUseCaseResponse.organisation);

        // convert checkedUseCases to an object with value as boolean
        const resultObject = useCases.reduce((obj, item, index) => {
          // @ts-ignore
          obj[`Benefit_${item.value.toLowerCase()}`] = checkedUseCases.includes(item.value);
          return obj;
        }, {});
        // @ts-ignore
        resultObject['Organisation_id'] = OrganisationUseCaseResponse.organisation.organisation_id;
        // @ts-ignore
        resultObject['Organisation_name'] = OrganisationUseCaseResponse.organisation.name;
        // @ts-ignore
        resultObject['Organisation_selection'] = OrganisationUseCaseResponse.organisation.consolidation_type;
        // since the user is creating an org, we can assume they are on trial
        // @ts-ignore
        resultObject['Account_status'] = 'trial';
        // @ts-ignore
        window.initializeUserGuiding();
        // @ts-ignore
        window.userGuiding.identify(user.user_id, resultObject);

        OrganisationUseCaseResponse.organisation === undefined && logUndefinedOrgFunc('OnboardingHowWillUse');
        if (OrganisationUseCaseResponse.consolidation_type === 'PARENT') {
          history.push(`/onboarding/${organisationId}/consolidated-organisation`);
        } else {
          if (OrganisationUseCaseResponse.is_onboarding_paused) {
            history.push(`/onboarding/rejection`);
          } else {
            const redirectConfig = {
              organization: OrganisationUseCaseResponse.organisation.auth0_id,
              redirectUri: `${window.location.origin}/auth0-callback`,
            };
            // @ts-ignore
            loginWithRedirect(redirectConfig);
          }
        }
      });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      Notification.error({
        title: 'Something went wrong, please try again.',
      });
    }
  };

  const handleCheck = (value: string) => {
    if (checkedUseCases.includes(value)) {
      setCheckedUseCases(checkedUseCases.filter(useCase => useCase !== value));
    } else {
      setCheckedUseCases([...checkedUseCases, value]);
    }
  };

  React.useEffect(() => {
    setCheckedUseCases(onboardingConfig.user.useCases);
  }, []);

  return (
    <OnboardingHowWillUseContainer>
      <OnboardingPageTitle
        title="How will you use ScaleXP?"
        subtitle="Tell us how you will use the system so we can personalise your onboarding:"
        backLink="/onboarding/organisation-info"
      />
      <Spacer margin={{ top: 6 }}>
        <Column>
          {listCases.map(useCase => (
            <UseChoice
              vAlign="center"
              hAlign="space-between"
              key={useCase.title}
              onClick={() => handleCheck(useCase.value)}
            >
              <Row spacing="large" vAlign="center">
                <img src={useCase.img} alt="on boarding icon" width={48} height={48} />
                <p>
                  <Typography size="h6" weight="regular" color="secondary">
                    {useCase.title}
                  </Typography>
                  <br />
                  <Typography size="medium" weight="regular" color="black">
                    {useCase.description}
                  </Typography>
                </p>
              </Row>
              <Checkbox customSize="large" checked={checkedUseCases.includes(useCase.value)} />
            </UseChoice>
          ))}
        </Column>
      </Spacer>
      <Spacer margin={{ top: 12 }} padding={{ bottom: 10 }}>
        <Row width="900px" vAlign="center" hAlign="flex-end">
          <TextButton
            width="130px"
            variant="primary"
            size="large"
            loading={isLoading}
            disabled={isLoading || checkedUseCases.length < 1}
            onClick={handleSave}
          >
            Save
          </TextButton>
        </Row>
      </Spacer>
    </OnboardingHowWillUseContainer>
  );
};

export default OnboardingHowWillUse;
