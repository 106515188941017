import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../index';
import { loadDerivedMetricsAction, updateDerivedMetricAction, UpdateDerivedMetricActionResult } from './actions';
import { selectDerivedMetrics } from './selectors';
import { DerivedMetric, DerivedMetricsVS } from './types';

export const useDerivedMetrics = (organisationId: number): DerivedMetricsVS => {
  const derivedMetrics: DerivedMetricsVS | undefined = useSelector<Store, ReturnType<typeof selectDerivedMetrics>>(
    state => selectDerivedMetrics(state),
  );
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadDerivedMetricsAction(organisationId));
  }, []);

  if (derivedMetrics === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...derivedMetrics,
    reload,
  };
};

export const useUpdateDerivedMetric = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (
      organisationId: number,
      derivedMetricId: DerivedMetric['metricId'],
      name: DerivedMetric['name'],
      metricSchema: DerivedMetric['metricSchema'],
    ): Promise<UpdateDerivedMetricActionResult> => {
      const res = await dispatch(updateDerivedMetricAction(organisationId, derivedMetricId, name, metricSchema));
      return (res as unknown) as UpdateDerivedMetricActionResult;
    },
    [],
  );
};
