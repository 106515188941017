import produce from 'immer';

import {
  ReportGroupingActions,
  ACTION_REPORT_GROUPING_CLEAR_SELECTION,
  ACTION_REPORT_GROUPING_SET_SELECTION,
  ACTION_REPORT_GROUPING_SET_CREATING_NEW_GROUP,
  ACTION_REPORT_GROUPING_SET_SELECTED_LAYOUT,
} from './actions';
import { ReportGrouping } from './types';

const reportGrouping = (
  prevState: ReportGrouping = {
    selection: {},
    creatingNewGroup: null,
    selectedLayout: 'profit-and-loss',
  },
  action: ReportGroupingActions,
): ReportGrouping => {
  return produce(prevState, state => {
    switch (action.type) {
      case ACTION_REPORT_GROUPING_SET_SELECTION: {
        const { selection } = action.params;
        state.selection = selection;
        break;
      }
      case ACTION_REPORT_GROUPING_SET_CREATING_NEW_GROUP: {
        const { creatingNewGroup } = action.params;
        state.creatingNewGroup = creatingNewGroup;
        break;
      }
      case ACTION_REPORT_GROUPING_SET_SELECTED_LAYOUT: {
        const { selectedLayout } = action.params;
        state.selectedLayout = selectedLayout;
        break;
      }
      case ACTION_REPORT_GROUPING_CLEAR_SELECTION: {
        state.selection = {};
        break;
      }
      default:
        return state;
    }
    return state;
  });
};

export default reportGrouping;
