import React, { useMemo } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';

import fetcher from '../../../utils/fetcher';
import Icon from '../../atoms/Icon';
import Typography from '../../atoms/Typography';
import useActiveOrganisationId from '../../contexts/OrganisationContext/useActiveOrganisationId';
import Row from '../../layout/Row';
import { StyledChartImageContainer } from '../../organisms/ChartConfigWidget';
import Select from '../Select';

export interface ChartsDropdownProps {
  btnText?: string;
  handleSelect: (chartId: string) => void;
}
type DashboardChart = {
  id: string;
  i: string;
  name: string;
  chart_type: 'time_chart' | 'pie_chart' | 'kpi_chart' | 'waterfall_chart';
};
type DashboardCharts = DashboardChart[];

export const useDashboardsCharts = () => {
  const organisationId = useActiveOrganisationId();
  const endpoint = `/api/v1/organisations/${organisationId}/dashboards/charts`;
  const { data: charts, isValidating } = useSWR<DashboardCharts>([endpoint], key => {
    return fetcher(endpoint);
  });
  return { charts, isValidating };
};

const StyledItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.sizing(3)} ${theme.sizing(4)}`};
  cursor: pointer;
`;
export const DescriptionContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  & > span:not(.material-icons-round) {
    white-space: normal;
  }
`;

export const StyledChartImage = styled.img`
  height: 50px;
`;

const NewChartsDropdown: React.FC<React.PropsWithChildren<ChartsDropdownProps>> = ({
  btnText = 'Chart',
  handleSelect,
}) => {
  const { charts, isValidating } = useDashboardsCharts();
  const data = useMemo(
    () =>
      charts
        ?.filter(chart => chart.chart_type !== 'kpi_chart')
        .map(chart => ({
          label: chart.name,
          value: chart.id,
          chart_type: chart.chart_type,
        })),
    [charts],
  );
  if (!charts || isValidating) {
    return null;
  }

  return (
    <Select
      selectWidth={400}
      searchable
      data={data ?? []}
      placeholder={
        <Row vAlign="center" spacing="small">
          <Icon name="assessment" outlined marginRight={0} />
          <Typography color="black">{btnText}</Typography>
        </Row>
      }
      renderSelectItem={(_, item) => {
        return (
          <Row width="100%" onClick={() => handleSelect(item.value)}>
            <StyledItem>
              <DescriptionContainer>
                <Typography size="tiny" weight="bold">
                  {item.label}
                </Typography>
              </DescriptionContainer>
              <StyledChartImageContainer>
                <StyledChartImage src={` /images/charts/${item.chart_type}.png`} alt="chart" />
              </StyledChartImageContainer>
            </StyledItem>
          </Row>
        );
      }}
    />
  );
};
export default NewChartsDropdown;
