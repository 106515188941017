import produce from 'immer';

import {
  ORGANISATIONS_PREPAYMENTS_PRESETS_LOAD,
  ORGANISATIONS_PREPAYMENTS_PRESETS_CREATE,
  ORGANISATIONS_PREPAYMENTS_PRESETS_UPDATE,
  ORGANISATIONS_PREPAYMENTS_PRESETS_DELETE,
  PrepaymentsPresetsActions,
} from './actions';
import { OrganisationPrepaymentsPresets } from './types';

const prepaymentsPresets = (
  prevState: OrganisationPrepaymentsPresets = {},
  action: PrepaymentsPresetsActions,
): OrganisationPrepaymentsPresets => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_PREPAYMENTS_PRESETS_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload ?? { prepayment: {}, prepayment_journal: {} },
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      case ORGANISATIONS_PREPAYMENTS_PRESETS_CREATE:
      case ORGANISATIONS_PREPAYMENTS_PRESETS_UPDATE: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload,
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      case ORGANISATIONS_PREPAYMENTS_PRESETS_DELETE: {
        const { organisationId, presetId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            delete state[organisationId].value?.prepayment?.[presetId];
            delete state[organisationId].value?.prepayment_journal?.[presetId];
            state[organisationId].status = 'success';
            state[organisationId].error = undefined;
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      default:
        return state;
    }
  });
};

export default prepaymentsPresets;
