import React from 'react';
import { MetricSchema2Decimals } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import Typography from '../../../../components/atoms/Typography';
import useActiveOrganisation from '../../../../components/contexts/OrganisationContext/useActiveOrganisation';
import { usePipelines } from '../../../../store/state/pipelines/hooks';
import { PipelineCategory, PipelineMetricsTypes } from '../../../../store/state/pipelines/types';
import ReportEditorDisplayPipelineRow from '../ReportEditorDisplayPipelineRow';

interface ReportEditorDisplayAllPipelinesRowProps {
  category: PipelineCategory;
  metric: PipelineMetricsTypes;
  decimals: MetricSchema2Decimals;
}

const StyledGroupsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing(1)} 0`};
  border-radius: ${({ theme }) => `0 0 ${theme.spacing(2)} ${theme.spacing(2)}`};
`;

const StyledNoPipelinesContainer = styled.div`
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: 20px 1fr 160px 160px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding-left: ${({ theme }) => theme.spacing(4)};
`;

const ReportEditorDisplayAllPipelinesRow: React.FC<
  React.PropsWithChildren<ReportEditorDisplayAllPipelinesRowProps>
> = ({ category, metric, decimals }) => {
  const organisation = useActiveOrganisation();
  const pipelinesVS = usePipelines(organisation.organisation_id);

  if (pipelinesVS.status !== 'success') {
    return null;
  }

  const pipelines = pipelinesVS.value.filter(
    pipeline => pipeline.status === 'ACTIVE' && pipeline.category === category,
  );

  return (
    <StyledGroupsContainer>
      {pipelines.length === 0 && (
        <StyledNoPipelinesContainer>
          <div />
          <Typography>
            No pipelines are configured as {category === 'NEW_SALES' ? 'New Sales' : 'Renewals'} pipelines
          </Typography>
        </StyledNoPipelinesContainer>
      )}
      {pipelines.map(pipeline => (
        <ReportEditorDisplayPipelineRow pipeline={pipeline} metric={metric} decimals={decimals} />
      ))}
    </StyledGroupsContainer>
  );
};

export default ReportEditorDisplayAllPipelinesRow;
