import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useActiveOrganisationId from '../../../components/contexts/OrganisationContext/useActiveOrganisationId';
import { NATIVE_METRIC_DISPLAY_NAMES, NATIVE_METRIC_IDS } from '../../../service/types/metricSchema';
import { ValueState } from '../../values';
import { Store } from '../index';
import { loadNativeMetricsAction, updateNativeMetricAction, UpdateNativeMetricActionResult } from './actions';
import { selectNativeMetrics } from './selectors';
import { NativeMetric, NativeMetricsVS } from './types';

export const useNativeMetrics = (): NativeMetricsVS => {
  const organisationId = useActiveOrganisationId();
  const nativeMetrics: NativeMetricsVS | undefined = useSelector<Store, ReturnType<typeof selectNativeMetrics>>(state =>
    selectNativeMetrics(state, organisationId),
  );
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadNativeMetricsAction(organisationId));
  }, [organisationId]);

  if (nativeMetrics === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...nativeMetrics,
    reload,
  };
};

export const useCreateUpdateNativeMetric = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (
      organisationId: number,
      tagName: string,
      nativeMetric: NativeMetric,
    ): Promise<UpdateNativeMetricActionResult> => {
      const res = dispatch(updateNativeMetricAction(organisationId, tagName, nativeMetric));
      return (res as unknown) as UpdateNativeMetricActionResult;
    },
    [],
  );
};

export type NativeMetricNames = { [nativeMetricTag: string]: string };
export type NativeMetricNamesValueState = ValueState<NativeMetricNames>;

export const useNativeMetricNames = (): NativeMetricNamesValueState => {
  const nativeMetricsVS = useNativeMetrics();

  const namesById: NativeMetricNames = useMemo(() => {
    if (nativeMetricsVS.status !== 'success') {
      return {};
    }
    return Object.fromEntries(
      NATIVE_METRIC_IDS.map(nativeMetric => {
        const nativeMetricName =
          nativeMetricsVS.value[nativeMetric]?.name || NATIVE_METRIC_DISPLAY_NAMES[nativeMetric] || nativeMetric;
        return [nativeMetric, nativeMetricName];
      }),
    );
  }, [nativeMetricsVS]);

  if (nativeMetricsVS.status !== 'success') {
    return nativeMetricsVS as NativeMetricNamesValueState;
  }

  return {
    ...nativeMetricsVS,
    value: namesById,
  };
};

export const useNativeMetricName = (tag: string): ValueState<string> => {
  const nativeMetricNamesVS = useNativeMetricNames();
  if (nativeMetricNamesVS.status !== 'success') {
    return nativeMetricNamesVS as ValueState<string>;
  }
  return {
    ...nativeMetricNamesVS,
    value: nativeMetricNamesVS.value[tag] || NATIVE_METRIC_DISPLAY_NAMES[tag] || tag,
  };
};
