import { createSelector } from 'reselect';

import { Store } from '../index';
import { Report, ReportsVS } from './types';

export const selectReports = (state: Store, organisationId: number): ReportsVS | undefined => {
  return state.reports[organisationId];
};

export const selectReportIds = createSelector([selectReports], reportsState => {
  if (!reportsState?.value) {
    return [];
  }

  // Take just the report objects
  const reports: Report[] = Object.values(reportsState.value);

  // Sort the reports alphabetically
  const sortedReports = reports.sort((a, b) => a.name?.localeCompare(b.name));

  // Parse reports to ids, with pinned reports at the top
  return [
    ...sortedReports
      .filter(report => report?.pinned && !report.implicitly_accessible)
      .map(report => parseInt(report.id)),
    ...sortedReports
      .filter(report => !report?.pinned && !report.implicitly_accessible)
      .map(report => parseInt(report.id)),
  ] as number[];
});
