export type Job = ImportJob | ProcessingJob | DownloadJob;

export type ImportJob = {
  task: 'IMPORT';
  id: string;
  name: string;
  status: 'Running' | 'Completed' | 'Error';
  completedSteps: number;
  organisationName: string;
};

export type ProcessingJob = {
  task:
    | 'PROCESSING_INVOICE_ACCOUNT_RULES'
    | 'PROCESSING_CRM_CUSTOM_MAPPING_DEAL_VALUES'
    | 'PROCESSING_CRM_CLOSED_ON_DATE'
    | 'PROCESSING_RENEWAL_CONTRACTS';
  id: string;
  name: string;
  status: 'Running' | 'Completed' | 'Error';
};

export const DOWNLOAD_JOB_TASKS = [
  'PROCESSING_INVOICE_LINES_DOWNLOAD',
  'PROCESSING_CUSTOMERS_REVENUE_DOWNLOAD',
  'DEALS_IN_PROGRESS_DOWNLOAD',
  'ACCRUALS_DOWNLOAD',
  'PREPAYMENTS_DOWNLOAD',
] as const;

type DownloadJobTask = typeof DOWNLOAD_JOB_TASKS[number];

type RunningDownloadJob = {
  task: DownloadJobTask;
  id: string;
  name: string;
  status: 'Running';
};

type CompletedDownloadJob = {
  task: DownloadJobTask;
  id: string;
  name: string;
  status: 'Completed';
  payload: { fileUrl: string };
};

type ErrorDownloadJob = {
  task: DownloadJobTask;
  id: string;
  name: string;
  status: 'Error';
  error: string;
};

export type DownloadJob = RunningDownloadJob | CompletedDownloadJob | ErrorDownloadJob;

export type ImportJobResponse = {
  id: string;
  name: string;
  status: 'Running' | 'Completed' | 'Error';
  steps: {
    [key: string]: { status: 'Running' | 'Completed' | 'Error' };
  };
  completedSteps: number;
};

export type DownloadJobResponse =
  | {
      status: 'Running';
    }
  | {
      status: 'Completed';
      data: {
        file_url: string;
      };
    };
