import Notification from '../../../components/atoms/Notification';
import { CalculationRequestManager } from '../../../utils/metrics/calculationRequestManager';
import { PayloadAction, promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { AccountGroup } from '../accountGroups/types';
import { selectAccounts } from './selectors';
import { Account } from './types';

export const ORGANISATIONS_ACCOUNTS_LOAD = '[accounts] LOAD';
export const ORGANISATIONS_ACCOUNTS_ACKNOWLEDGE = '[accounts] ACKNOWLEDGE';
export const ORGANISATIONS_ACCOUNTS_ELIMINATE_ACCOUNT = '[accounts] ELIMINATE_ACCOUNT';
export const ORGANISATIONS_ACCOUNTS_UNELIMINATE_ACCOUNT = '[accounts] UNELIMINATE_ACCOUNT';
export const ORGANISATIONS_ACCOUNTS_MANUAL_CREATE = '[accounts] MANUAL_ACCOUNT_CREATE';
export const ORGANISATIONS_ACCOUNTS_MANUAL_UPDATE = '[accounts] MANUAL_ACCOUNT_UPDATE';
export const ORGANISATIONS_ACCOUNTS_MANUAL_DELETE = '[accounts] MANUAL_ACCOUNT_DELETE';
export const ORGANISATIONS_ACCOUNTS_SET_GROUP_ID = '[accounts] SET_GROUP_ID';

export type AccountsActions =
  | LoadAccountsAction
  | AcknowledgeAccountsAction
  | eliminateAccountAction
  | uneliminateAccountAction
  | createManualAccountAction
  | updateManualAccountAction
  | deleteManualAccountAction
  | setAccountGroupIdAction;

export type LoadAccountsAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNTS_LOAD,
  Account[],
  { organisationId: number }
>;

export type AcknowledgeAccountsAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNTS_ACKNOWLEDGE,
  AcknowledgeAccountsResponse,
  {
    organisationId: number;
    accountIds: Account['account_id'][];
  }
>;

export type eliminateAccountAction = PayloadAction<
  typeof ORGANISATIONS_ACCOUNTS_ELIMINATE_ACCOUNT,
  {
    organisationId: number;
    accountId: Account['account_id'];
  }
>;

export type uneliminateAccountAction = PayloadAction<
  typeof ORGANISATIONS_ACCOUNTS_UNELIMINATE_ACCOUNT,
  {
    organisationId: number;
    accountId: Account['account_id'];
  }
>;

export type createManualAccountAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNTS_MANUAL_CREATE,
  Account,
  {
    organisationId: number;
    tagName: string;
    accountName: string;
  }
>;

export type updateManualAccountAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNTS_MANUAL_UPDATE,
  Account,
  {
    organisationId: number;
    accountId: Account['account_id'];
    name: string;
  }
>;

export type deleteManualAccountAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNTS_MANUAL_DELETE,
  void,
  {
    organisationId: number;
    accountId: Account['account_id'];
  }
>;
export type setAccountGroupIdAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNTS_SET_GROUP_ID,
  void,
  {
    organisationId: number;
    accountIds: Account['account_id'][];
    groupId: AccountGroup['id'] | null;
    tag: string | null;
  }
>;

type AccountsResponse = {
  accounts: Account[];
};

export const loadAccountsAction = (organisationId: number): ThunkAction<AccountsActions> => async (
  dispatch: ThunkDispatch<AccountsActions>,
  getState,
) => {
  const state = getState();
  const accounts = selectAccounts(state, organisationId);

  if (accounts?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNTS_LOAD,
        () =>
          http<Account[], AccountsResponse>(
            `/api/v1/organisations/${organisationId}/accounts`,
            {},
            response => response.accounts,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

type AcknowledgeAccountsResponse = {
  acknowledged_by: string;
  acknowledged_at: string;
};
export const acknowledgeAccounts = (
  organisationId: number,
  accountIds: Account['account_id'][],
): ThunkAction<AccountsActions> => async (dispatch: ThunkDispatch<AccountsActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNTS_ACKNOWLEDGE,
        () =>
          http<AcknowledgeAccountsResponse, AcknowledgeAccountsResponse>(
            `/api/v1/organisations/${organisationId}/accounts/acknowledge`,
            {
              body: { account_ids: accountIds },
              method: 'POST',
            },
          ),
        {
          organisationId,
          accountIds,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const eliminateAccount = (
  organisationId: number,
  accountId: Account['account_id'],
): ThunkAction<eliminateAccountAction> => async (dispatch: ThunkDispatch<AccountsActions>) => {
  try {
    await http<string, void>(`/api/v1/organisations/${organisationId}/accounts/${accountId}/eliminate`, {
      method: 'POST',
    });

    dispatch({
      type: ORGANISATIONS_ACCOUNTS_ELIMINATE_ACCOUNT,
      params: { organisationId, accountId },
    });
  } catch (e) {
    console.log('Error dispatching action', e);
    Notification.error({ title: 'Account elimination failed', placement: 'bottomEnd' });
  }
};

export const uneliminateAccount = (
  organisationId: number,
  accountId: Account['account_id'],
): ThunkAction<uneliminateAccountAction> => async (dispatch: ThunkDispatch<AccountsActions>) => {
  try {
    await http<string, void>(`/api/v1/organisations/${organisationId}/accounts/${accountId}/eliminate`, {
      method: 'DELETE',
    });

    dispatch({
      type: ORGANISATIONS_ACCOUNTS_UNELIMINATE_ACCOUNT,
      params: { organisationId, accountId },
    });
  } catch (e) {
    console.log('Error dispatching action', e);
    Notification.error({ title: 'Account elimination failed', placement: 'bottomEnd' });
  }
};

export const createManualAccount = (
  organisationId: number,
  tagName: string,
  accountName: string,
): ThunkAction<uneliminateAccountAction> => async (dispatch: ThunkDispatch<AccountsActions>) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNTS_MANUAL_CREATE,
        () =>
          http<Account, { account: Account }>(
            `/api/v1/organisations/${organisationId}/manual/accounts`,
            {
              method: 'POST',
              body: {
                name: accountName,
                tag: tagName,
              },
            },
            res => res.account,
          ),
        {
          organisationId,
          tagName,
          accountName,
        },
      ),
    );
    Notification.success({ title: 'Successfully create new manual data series', placement: 'bottomEnd' });
  } catch (e) {
    console.log('Error dispatching action', e);
    Notification.error({ title: 'Failed to create new manual data series', placement: 'bottomEnd' });
  }
};

export const updateManualAccount = (
  organisationId: number,
  accountId: Account['account_id'],
  name: string,
): ThunkAction<uneliminateAccountAction> => async (dispatch: ThunkDispatch<AccountsActions>) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNTS_MANUAL_UPDATE,
        () =>
          http<Account, { account: Account }>(
            `/api/v1/organisations/${organisationId}/manual/accounts/${accountId}`,
            {
              method: 'PATCH',
              body: {
                name,
              },
            },
            res => res.account,
          ),
        {
          organisationId,
          accountId,
          name,
        },
      ),
    );
    Notification.success({ title: 'Successfully updated manual data series', placement: 'bottomEnd' });
  } catch (e) {
    console.log('Error dispatching action', e);
    Notification.error({ title: 'Failed to update manual data series', placement: 'bottomEnd' });
  }
};

type UpdateParams = {
  organisationId: number;
  accountId: Account['account_id'];
  dateFor: string;
  value: number | null;
};
const debouncedUpdateManualAccountEntry = async ({ organisationId, accountId, dateFor, value }: UpdateParams) =>
  await http(`/api/v1/organisations/${organisationId}/manual/accounts/${accountId}`, {
    method: 'PATCH',
    body: {
      entries: [
        {
          date_for: dateFor,
          value,
        },
      ],
    },
  });

export const updateManualAccountEntry = (
  organisationId: number,
  accountId: Account['account_id'],
  dateFor: string,
  value: number | null,
): ThunkAction<uneliminateAccountAction> => async (dispatch: ThunkDispatch<AccountsActions>) => {
  try {
    await debouncedUpdateManualAccountEntry({ organisationId, accountId, dateFor, value });
  } catch (e) {
    console.log('Error dispatching action', e);
    Notification.error({ title: 'Failed to update manual data series', placement: 'bottomEnd' });
  }
};

export const deleteManualAccount = (
  organisationId: number,
  accountId: Account['account_id'],
): ThunkAction<uneliminateAccountAction> => async (dispatch: ThunkDispatch<AccountsActions>) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNTS_MANUAL_DELETE,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/manual/accounts/${accountId}`, {
            method: 'DELETE',
          }),
        {
          organisationId,
          accountId,
        },
      ),
    );
    Notification.success({ title: 'Successfully deleted manual data series', placement: 'bottomEnd' });
  } catch (e) {
    console.log('Error dispatching action', e);
    Notification.error({ title: 'Failed to deleted manual data series', placement: 'bottomEnd' });
  }
};

export const setAccountGroupId = (
  organisationId: number,
  accountIds: Account['account_id'][],
  groupId: AccountGroup['id'] | undefined | null,
  tag: string | null,
): ThunkAction<setAccountGroupIdAction> => async (dispatch: ThunkDispatch<AccountsActions>) => {
  // @ts-ignore
  dispatch({
    type: ORGANISATIONS_ACCOUNTS_SET_GROUP_ID,
    params: { organisationId, accountIds, groupId, tag },
    payload: { organisationId, accountIds, groupId, tag },
    status: 'success',
  });
  CalculationRequestManager.clearStoredRequests();
};
