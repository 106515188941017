import React, { useState, ReactNode, HTMLAttributes, MouseEvent } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import DropDownItem, { DropDownItemProps } from '../../atoms/DropDownItem';
import TextButton from '../../atoms/TextButton';
import Typography from '../../atoms/Typography';
import { useOutsideAlerter } from '../MultiSelect/hooks';
import { usePortalPositioning } from './usePortalPositioning';

type DropDownPlacement = 'topStart' | 'topEnd' | 'bottomStart' | 'bottomEnd';

interface DropDownProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  size?: 'tiny' | 'small' | 'medium';
  icon?: string;
  showCaret?: boolean;
  customButton?: ReactNode;
  placement?: DropDownPlacement;
  menuWidth?: string;
  onClose?: VoidFunction;
  zIndex?: 'high' | 'highest';
}

interface DropDownSubComponents {
  Item: React.FC<React.PropsWithChildren<DropDownItemProps>>;
}

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDropDown = styled.div<{
  placement: DropDownPlacement;
  container: DOMRect;
  dropDown: { width: number; height: number };
  menuWidth?: string;
  zIndex: 'high' | 'highest';
}>`
  width: ${({ menuWidth }) => (menuWidth ? menuWidth : 'auto')};
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(2)} 0`};
  background: #fff;
  border: 1px solid ${({ theme }) => theme.palette.silver};
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.05);
  border-radius: ${({ theme }) => theme.spacing(1)};
  z-index: ${({ theme, zIndex }) => theme.zIndex[zIndex]};

  ${({
    placement,
    container: { top, left, height, width },
    dropDown: { width: dropDownWidth, height: dropDownHeight },
  }) => {
    switch (placement) {
      case 'topStart':
        return `
        top: calc(${top}px - ${dropDownHeight}px + ${window.scrollY}px - 1px);
        left: calc(${left}px +  ${window.scrollX}px);
      `;
      case 'topEnd':
        return `
        top: calc(${top}px - ${dropDownHeight}px + ${window.scrollY}px - 1px);
        left: calc(${left}px - ${dropDownWidth}px + ${width}px +  ${window.scrollX}px);
      `;
      case 'bottomStart':
        return `
        top: calc(${top}px + ${height}px + ${window.scrollY}px + 1px);
        left: calc(${left}px +  ${window.scrollX}px);
      `;
      case 'bottomEnd':
        return `
        top: calc(${top}px + ${height}px + ${window.scrollY}px + 1px);
        left: calc(${left}px - ${dropDownWidth}px + ${width}px +  ${window.scrollX}px);
      `;
    }
  }};
`;

const StyledButton = styled(TextButton)`
  border: ${({ theme }) => `1.2px solid ${theme.palette.silver}`};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.midnight};
    border: 1.2px solid ${({ theme }) => theme.palette.midnight};

    span {
      color: ${({ theme }) => theme.palette.midnight};
    }
  }
`;

const DropDown: React.FC<React.PropsWithChildren<DropDownProps>> & DropDownSubComponents = ({
  title,
  size = 'small',
  icon,
  showCaret = true,
  children,
  customButton,
  onClose,
  placement = 'bottomStart',
  onClick,
  menuWidth,
  zIndex = 'high',
  ...rest
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const {
    containerCallbackRef,
    containerRect,
    elementRef: dropDownRef,
    elementCallbackRef: dropDownCallbackRef,
    elementDimensions: dropDownDimensions,
  } = usePortalPositioning(showDropDown);

  useOutsideAlerter(dropDownRef, () => {
    setShowDropDown(false);
    onClose?.();
  });

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    setShowDropDown(!showDropDown);
    onClick && onClick(e);
  };

  return (
    <StyledContainer ref={containerCallbackRef} onClick={handleClick} {...rest}>
      {customButton ? (
        customButton
      ) : (
        <StyledButton
          variant="secondary"
          size={size}
          iconLeft={icon}
          iconRight={showCaret ? 'arrow_drop_down' : undefined}
        >
          <Typography size={size} color="inherit">
            {title}
          </Typography>
        </StyledButton>
      )}

      {showDropDown &&
        createPortal(
          <StyledDropDown
            ref={dropDownCallbackRef}
            placement={placement}
            menuWidth={menuWidth}
            container={containerRect}
            dropDown={dropDownDimensions}
            zIndex={zIndex}
          >
            {children}
          </StyledDropDown>,
          document.body,
        )}
    </StyledContainer>
  );
};

DropDown.Item = DropDownItem;

export default DropDown;
