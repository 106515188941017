import React from 'react';
import styled from 'styled-components';

import Typography from '../../../components/atoms/Typography';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';

const StyledRejectionContainer = styled(Row)`
  gap: 120px;
`;
const StyledBookDemoContainer = styled.div`
  width: 700px;
  height: 500px;
  margin-right: 120px;
  position: relative;
  top: -50px;
  box-shadow: none !important;
  & * {
    box-shadow: none !important;
  }
`;

const OnboaringRejection = () => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);
  return (
    <StyledRejectionContainer width="100%">
      <Column width="377px" spacing="large">
        <Typography size="h2" color="black" weight="regular">
          Thanks for signing up for your free trial!{' '}
        </Typography>
        <Typography size="medium" color="secondary" weight="regular">
          We are not able to connect you immediately but look forward to welcoming you to ScaleXP via a guided
          onboarding with one of our product specialists.
        </Typography>
        <Typography size="medium" color="secondary" weight="regular">
          Please use the calendar on the right to choose a convenient time to get started. Click on the date you would
          like, then select a time. If you need to move to the next month, use the arrows next to the month name.
        </Typography>
        <Typography size="medium" color="secondary" weight="regular">
          At this session, you will get dedicated 1:1 support to ensure you get the most from the system .
        </Typography>
        <Typography size="medium" color="secondary" weight="regular">
          If you have any questions in the meantime, please contact us at support@scalexp.com
        </Typography>
      </Column>
      <StyledBookDemoContainer
        className="meetings-iframe-container"
        data-src="https://meetings.hubspot.com/scalexp/scalexp-onboarding-deferred-revenue?embed=true"
      />
    </StyledRejectionContainer>
  );
};

export default OnboaringRejection;
