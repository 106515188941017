import { useAuth0 } from '@auth0/auth0-react';
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';

import Loader from '../../components/atoms/Loader';
import BlockUserMessage from '../../components/molecules/BlockUserMessage';
import OnboardingEmailVerification from '../../features/onboarding/OnboardingEmailVerification';
import { getAccessToken, setAccessToken } from '../../utils/accessToken';
import { clearSession } from '../../utils/auth';
import fetcher from '../../utils/fetcher';
import { logUndefinedOrgFunc } from '../../utils/logger';
import { setCurrentOrganisation } from '../../utils/organisation';

const SignInPage = () => {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    error,
    logout: auth0Logout,
  } = useAuth0();

  const params = new URLSearchParams(window.location.search);
  const organization = params.get('organization');
  const invitation = params.get('invitation');

  if (isLoading) {
    return null;
  }

  const performLogout = () => {
    clearSession();
    auth0Logout();
  };

  if (error) {
    if (error.message.startsWith('parameter organization is invalid:')) {
      performLogout();
      return null;
    }
  }

  const acceptInvitation = async () => {
    try {
      if (!getAccessToken()) {
        const token = await getAccessTokenSilently({ detailedResponse: true });
        setAccessToken(token.access_token);
      }
      const organisation = await fetcher('/api/v1/invitations/accept', {
        method: 'POST',
        body: JSON.stringify({
          organisation_auth0_id: organization,
          invitation_ticket_id: invitation,
        }),
      });
      setCurrentOrganisation(organisation);
      organisation === undefined && logUndefinedOrgFunc('acceptInvitation');
      window.location.href = '/';
    } catch (error) {
      // unable to automatically accept invite, redirect user auth0 flow to accept invite
      loginWithRedirect({
        invitation: invitation ? invitation : '',
        organization: organization ? organization : '',
      });
    }
  };

  const checkInviteeUserExists = async () => {
    try {
      await fetcher('/api/v1/invitations/check_invitee_user_exists', {
        method: 'POST',
        body: JSON.stringify({
          organisation_auth0_id: organization,
          invitation_ticket_id: invitation,
        }),
      });
      loginWithRedirect({
        appState: {
          redirectUri: `${window.location.origin}/signin?invitation=${invitation}&organization=${organization}`,
        },
      });
    } catch (error) {
      loginWithRedirect({
        invitation: invitation ? invitation : '',
        organization: organization ? organization : '',
      });
    }
  };

  // initiate the login flow for the membership invites
  if (organization && invitation) {
    if (isAuthenticated) {
      // when user is already logged in, we directly add them to the organisation
      acceptInvitation();
    } else {
      // when user exists already we log them in and then add them to the organisation
      // when user doesn't exist we redirect them to the auth0 user sign up page and then add them to the organisation
      checkInviteeUserExists();
    }
    return <Loader center vertical />;
  }

  // when user is not verified their email
  const isAccessDenied = params.get('error') === 'access_denied';
  if (isAccessDenied) {
    return <OnboardingEmailVerification performLogout={performLogout} />;
  }

  const isUserBlocked = params.get('error') === 'unauthorized' && params.get('error_description') === 'user is blocked';
  if (isUserBlocked) {
    return <BlockUserMessage />;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }
  getAccessTokenSilently({ detailedResponse: true }).then((token: GetTokenSilentlyVerboseResponse) => {
    console.log('signin token-', token);
    setAccessToken(token.access_token);
    window.location.href = '/';
  });
  return null;
};
export default SignInPage;
