import { useAuth0 } from '@auth0/auth0-react';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import { useSelf } from 'scalexp/service';

const usePosthogOrganisationGroup = () => {
  const { data: user } = useSelf();
  const activeOrganisation = useActiveOrganisation();
  const { user: auth0User } = useAuth0();

  useEffect(() => {
    if (user) {
      posthog.identify(user.username, {
        current_organisation_name: activeOrganisation.parent ? activeOrganisation.parent.name : activeOrganisation.name,
        current_organisation_id: activeOrganisation.parent?.id || activeOrganisation.organisation_id,
        auth0_id: activeOrganisation.parent?.auth0_id || activeOrganisation?.auth0_id,
        first_name: auth0User?.given_name,
        last_name: auth0User?.family_name,
        is_staff: user.is_staff,
        email: auth0User?.email,
        user_id: user.user_id,
        username: user.username,
      });
      posthog.group('company', `id:${activeOrganisation.parent?.id || activeOrganisation.organisation_id}`, {
        organisation_name: activeOrganisation.parent ? activeOrganisation.parent.name : activeOrganisation?.name,
        is_consolidated: activeOrganisation.consolidation_type !== 'SINGLE',
        consolidation_type:
          activeOrganisation.parent || activeOrganisation.consolidation_type === 'PARENT' ? 'PARENT' : 'SINGLE',
      });
    }
  }, [user, activeOrganisation.organisation_id, auth0User]);
};

export default usePosthogOrganisationGroup;
