import produce from 'immer';

import {
  DerivedMetricsActions,
  ORGANISATIONS_DERIVED_METRICS_LOAD,
  ORGANISATIONS_DERIVED_METRICS_UPDATE_METRIC,
} from './actions';
import { DerivedMetrics, SystemDerivedMetrics } from './types';

const derivedMetrics = (prevState: SystemDerivedMetrics = {}, action: DerivedMetricsActions): SystemDerivedMetrics => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_DERIVED_METRICS_LOAD:
        switch (action.status) {
          case 'pending':
            state.value = {
              status: 'pending',
              error: undefined,
            };
            return;

          case 'success':
            state.value = {
              status: 'success',
              value: action.payload.reduce((acc, sdm) => {
                acc[sdm.metricId] = sdm;
                return acc;
              }, {} as DerivedMetrics),
              error: undefined,
            };
            return;

          case 'error':
            state.value = {
              status: 'error',
              value: undefined,
              error: action.error,
            };
            return;

          default:
            return state;
        }
      case ORGANISATIONS_DERIVED_METRICS_UPDATE_METRIC:
        switch (action.status) {
          case 'pending':
            return;

          case 'success':
            state.value = {
              status: 'success',
              value: {
                ...state.value?.value,
                // @ts-ignore
                [action.payload.data.metric_id]: {
                  // @ts-ignore
                  metricId: action.payload.data.metric_id,
                  // @ts-ignore
                  name: action.payload.data.name,
                  // @ts-ignore
                  metricSchema: action.payload.data.metric_schema,
                },
              },
              error: undefined,
            };
            return;

          case 'error':
            state.value = {
              status: 'error',
              value: undefined,
              error: action.error,
            };
            return;

          default:
            return state;
        }
      default:
        return state;
    }
  });
};

export default derivedMetrics;
