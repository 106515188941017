import produce from 'immer';

import {
  BudgetExchangeRatesActions,
  ORGANISATIONS_BUDGET_EXCHANGE_RATES_LOAD,
  ORGANISATIONS_BUDGET_EXCHANGE_RATES_UPDATE,
} from './actions';
import { OrganisationBudgetExchangeRates } from './types';

const exchangeRates = (
  prevState: OrganisationBudgetExchangeRates = {},
  action: BudgetExchangeRatesActions,
): OrganisationBudgetExchangeRates => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_BUDGET_EXCHANGE_RATES_LOAD:
        switch (action.status) {
          case 'pending': {
            const { organisationId } = action.params;
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: undefined,
            };
            return;
          }
          case 'success': {
            const { organisationId } = action.params;
            state[organisationId] = {
              status: 'success',
              value: action.payload,
              error: undefined,
            };
            return;
          }
          case 'error': {
            const { organisationId } = action.params;
            state[organisationId] = {
              status: 'error',
              value: undefined,
              error: action.error,
            };
            return;
          }
          default:
            return state;
        }
      case ORGANISATIONS_BUDGET_EXCHANGE_RATES_UPDATE:
        switch (action.status) {
          case 'success':
            let { organisationId, currencyCode, targetCurrencyCode, date, value } = action.params;
            if (!state[organisationId].value![currencyCode]) {
              state[organisationId].value![currencyCode] = {};
            }
            if (!state[organisationId].value![currencyCode][targetCurrencyCode]) {
              state[organisationId].value![currencyCode][targetCurrencyCode] = {};
            }

            if (value) {
              state[organisationId].value![currencyCode][targetCurrencyCode][date] = value;
            } else {
              delete state[organisationId].value![currencyCode][targetCurrencyCode][date];
            }

            return;
          default:
            return state;
        }
      default:
        return state;
    }
  });
};

export default exchangeRates;
