import { API_URL } from '../config';
import { addAccessTokenToCookies } from './accessToken';

const launchPopup = (path: string) => {
  addAccessTokenToCookies();
  const target = path.startsWith('http') ? path : API_URL + path;
  window.location.replace(target);
};

export default launchPopup;
