import { PromiseAction } from '../../actions';
import { ValueState } from '../../values';
import { OrganisationData } from '../types';

export type OrganisationBudgets = OrganisationData<BudgetSets>;

export type BudgetSetVS = ValueState<BudgetSet>;

export interface BudgetSets {
  [budgetsetId: number]: BudgetSetVS;
}

export interface DateValues {
  [date: string]: number | null;
}

export interface BudgetSet {
  [accountId: string]: DateValues;
}

export const ORGANISATIONS_BUDGET_LOAD = '[Budget] LOAD';

export type budgetSetsActions = loadBudgetAction;

export type loadBudgetAction = PromiseAction<
  typeof ORGANISATIONS_BUDGET_LOAD,
  BudgetSet,
  { organisationId: number; budgetsetId: number }
>;
