import React, { ReactNode } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import Loader from 'scalexp/components/atoms/Loader';
import TextButton, { TextButtonProps } from 'scalexp/components/atoms/TextButton';
import { useTrialInfoContext } from 'scalexp/components/contexts/TrialContext';
import PanelPage from 'scalexp/components/organisms/PanelPage';
import OnboardingChatSupport from 'scalexp/features/onboarding/OnboardingChatSupport';
import styled from 'styled-components';

const StyledContactButton = styled(TextButton)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(8)};
`;

enum BlockedStatus {
  CANCELLED = 'cancelled',
  LATE_PAYER = 'late_payer',
  TRIAL_ENDED = 'trial_ended',
}
type Message = {
  title: string;
  text: ReactNode;
  action: TextButtonProps;
  bottomAction?: TextButtonProps;
};

const handleLiveChatOpen = () => {
  // @ts-ignore
  window.HubSpotConversations?.widget.open();
};

const openLiveChat: TextButtonProps = {
  onClick: handleLiveChatOpen,
  children: 'Contact Support',
};

const MESSAGES: Record<BlockedStatus, Message> = {
  [BlockedStatus.CANCELLED]: {
    title: 'Access Revoked: Cancelled',
    text: (
      <>
        Your ScaleXP account has been cancelled. Please contact{' '}
        <a href="mailto:support@scalexp.com">support@scalexp.com</a> or book a meeting with our team below.
      </>
    ),
    action: openLiveChat,
  },
  [BlockedStatus.LATE_PAYER]: {
    title: 'Access Paused: Payment Overdue',
    text: (
      <>You have outstanding payments for ScaleXP. To reinstate access, click below to update your payment details.</>
    ),
    action: {
      children: 'Update payment details',
      onClick: () => {
        window.open('http://billing.stripe.com/p/login/fZe9CDdZl1c7dy0fYY', '_blank');
      },
    },
    bottomAction: openLiveChat,
  },
  [BlockedStatus.TRIAL_ENDED]: {
    title: 'Access Paused: Trial Expired',
    text: (
      <>
        Your free trial has expired. Please contact <a href="mailto:support@scalexp.com">support@scalexp.com</a> or book
        a meeting with our team below.
      </>
    ),
    action: openLiveChat,
  },
};

export const isRequireBlocking = (status: string): status is BlockedStatus => {
  const values: string[] = Object.values(BlockedStatus);
  return values.includes(status);
};

const BlockedPage: React.FC<React.PropsWithChildren<RouteChildrenProps>> = () => {
  const { trialInfo } = useTrialInfoContext();
  if (!trialInfo) {
    return <Loader center vertical />;
  }
  const { status } = trialInfo;
  if (!isRequireBlocking(status)) {
    throw new Error(`Unsupported subscription status: ${status}`);
  }
  const { bottomAction, ...pageProps } = MESSAGES[status];
  return (
    <>
      <PanelPage
        {...pageProps}
        image={{
          src: '/images/scalexp_blocked.png',
          alt: 'ScaleXP Blocked Account',
        }}
      >
        {!!bottomAction && <StyledContactButton size="xlarge" variant="outlined" {...bottomAction} />}
      </PanelPage>
      <OnboardingChatSupport />
    </>
  );
};

export default BlockedPage;
