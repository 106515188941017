import produce from 'immer';

import {
  ORGANISATIONS_ACCOUNT_RULES_LOAD,
  ORGANISATIONS_ACCOUNT_RULE_CREATE,
  ORGANISATIONS_ACCOUNT_RULE_UPDATE,
  ORGANISATIONS_ACCOUNT_RULE_DELETE,
  ORGANISATIONS_ACCOUNT_RULE_CREATE_EMPTY,
  AccountRulesActions,
  ORGANISATIONS_ACCOUNT_RULE_UPDATE_EMPTY,
  ORGANISATIONS_ACCOUNT_RULE_DELETE_EMPTY,
} from './actions';
import { OrganisationAccountRules } from './types';

const accountRules = (
  prevState: OrganisationAccountRules = {},
  action: AccountRulesActions,
): OrganisationAccountRules => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_ACCOUNT_RULES_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return state;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload,
            };
            return state;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return state;

          default:
            return state;
        }
      }
      case ORGANISATIONS_ACCOUNT_RULE_CREATE:
      case ORGANISATIONS_ACCOUNT_RULE_UPDATE: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return state;

          case 'success':
            if (action?.params?.accountRule?._front_end_id) {
              const newRule = state[organisationId].value?.find(
                rule => rule?._front_end_id === action.params.accountRule._front_end_id,
              );
              if (newRule && 'rule' in action.payload) {
                newRule.id = action.payload.rule.id;
                newRule._front_end_id = undefined;
                newRule.config = action.payload.rule.config;
                newRule.month_percentage = action.payload.rule.month_percentage;
                newRule.account_id = action.payload.rule.account_id;
                newRule.use_issued_on_as_start_date = action.payload.rule.use_issued_on_as_start_date;
              } else if (newRule && 'account_id' in action.payload) {
                newRule.id = action.payload.id;
                newRule._front_end_id = undefined;
                newRule.config = action.payload.config;
                newRule.month_percentage = action.payload.month_percentage;
                newRule.account_id = action.payload.account_id;
                newRule.use_issued_on_as_start_date = action.payload.use_issued_on_as_start_date;
              }

              state[organisationId].status = 'success';
              state[organisationId].error = undefined;

              return state;
            }
            if ('rule' in action.payload) {
              let rule = state[organisationId]?.value?.find(
                rule => 'rule' in action.payload && action.payload.rule.id === rule.id,
              );
              if (rule) {
                rule._front_end_id = undefined;
                rule.config = action.payload.rule.config;
                rule.month_percentage = action.payload.rule.month_percentage;
                rule.account_id = action.payload.rule.account_id;
                rule.use_issued_on_as_start_date = action.payload.rule.use_issued_on_as_start_date;
              }
              state[organisationId].status = 'success';
              state[organisationId].error = undefined;
              return state;
            }

            state[organisationId].status = 'success';
            state[organisationId].error = undefined;

            return state;

          default:
            return state;
        }
      }
      case ORGANISATIONS_ACCOUNT_RULE_CREATE_EMPTY: {
        const { organisationId } = action.params;
        state[organisationId].value?.push(action.payload);
        return;
      }

      case ORGANISATIONS_ACCOUNT_RULE_UPDATE_EMPTY: {
        const { organisationId } = action.params;
        let rule = state[organisationId].value?.find(rule => rule._front_end_id === action.payload._front_end_id);
        if (rule) {
          rule.account_id = action.payload.account_id;
          rule.config = action.payload.config;
          rule.use_issued_on_as_start_date = action.payload.use_issued_on_as_start_date;
          rule.month_percentage = action.payload.month_percentage;
        }
        return;
      }
      case ORGANISATIONS_ACCOUNT_RULE_DELETE_EMPTY: {
        const { organisationId } = action.params;
        state[organisationId].value = state[organisationId].value?.filter(
          rule => rule._front_end_id !== action.payload._front_end_id,
        );
        return;
      }
      case ORGANISATIONS_ACCOUNT_RULE_DELETE: {
        const { organisationId, accountRule } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId].value = state[organisationId].value?.filter(rule => rule.id !== accountRule.id);
            state[organisationId].status = 'pending';
            state[organisationId].error = undefined;
            return;

          case 'success':
            state[organisationId].status = 'success';
            state[organisationId].error = undefined;
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      default:
        return state;
    }
  });
};

export default accountRules;
