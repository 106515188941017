import dayjs from 'dayjs';

import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { BillsFilter } from '../prepaymentsPresets/types';
import { selectDeferredBillsByAccount } from './selectors';
import { DeferredBillsByAccount } from './types';

export const ORGANISATIONS_DEFERRED_BILLS_BY_ACCOUNT_LOAD = '[deferredBillsByAccount] LOAD';

export type DeferredBillsByAccountActions = PromiseAction<
  typeof ORGANISATIONS_DEFERRED_BILLS_BY_ACCOUNT_LOAD,
  DeferredBillsByAccount,
  { organisationId: number }
>;

type DeferredDataResponse = DeferredBillsByAccount;

export const loadDeferredBillsByAccountPageAction = (
  organisationId: number,
  fromDate: Date,
  toDate: Date,
  accountsFilter: string[],
  billsFilter: BillsFilter | null,
  originalAccount: boolean = false,
): ThunkAction<DeferredBillsByAccountActions> => async (
  dispatch: ThunkDispatch<DeferredBillsByAccountActions>,
  getState,
) => {
  const state = getState();
  const deferredBillsByAccount = selectDeferredBillsByAccount(state, organisationId);

  if (deferredBillsByAccount?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_DEFERRED_BILLS_BY_ACCOUNT_LOAD,
        () =>
          http<DeferredBillsByAccount[], DeferredDataResponse>(
            `/api/v1/organisations/${organisationId}/bills/deferred/by_account`,
            {
              body: {
                start: dayjs(fromDate).format('YYYY-MM'),
                end: dayjs(toDate).format('YYYY-MM'),
                account_ids: accountsFilter,
                bill_filter: billsFilter,
                original_account: originalAccount,
              },
            },
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
