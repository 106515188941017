import { promiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectPipelines } from './selectors';
import { PipelinesResponse, PipelineActions as PipelinesActions, PipelineStage, Pipeline } from './types';

export const ORGANISATIONS_PIPELINES_LOAD = 'ORGANISATIONS_PIPELINES_LOAD';
export const ORGANISATIONS_PIPELINE_STAGE_UPDATE = 'ORGANISATIONS_PIPELINE_STAGE_UPDATE';
export const ORGANISATIONS_PIPELINE_UPDATE = 'ORGANISATIONS_PIPELINE_UPDATE';

export const loadPipelinesAction = (organisationId: number): ThunkAction<PipelinesActions> => async (
  dispatch: ThunkDispatch<PipelinesActions>,
  getState,
) => {
  const state = getState();
  const pipelines = selectPipelines(state, organisationId);

  if (pipelines?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_PIPELINES_LOAD,
        () =>
          http<PipelinesResponse, PipelinesResponse>(
            `/api/v1/organisations/${organisationId}/crm/pipelines`,
            {},
            response => response,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const updatePipelineAction = (
  organisationId: number,
  pipelineId: string,
  field: string,
  value: string,
): ThunkAction<PipelinesActions> => async (dispatch: ThunkDispatch<PipelinesActions>, getState) => {
  const state = getState();
  const pipelines = selectPipelines(state, organisationId);

  if (pipelines?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_PIPELINE_UPDATE,
        () =>
          http<Pipeline, Pipeline>(
            `/api/v1/organisations/${organisationId}/crm/pipelines/${pipelineId}`,
            {
              body: {
                [field]: value,
              },
              method: 'PATCH',
            },
            response => response,
          ),
        {
          organisationId,
          pipelineId,
          field,
          value,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const updatePipelineStageAction = (
  organisationId: number,
  stageId: string,
  pipelineId: string,
  field: string,
  value: string | boolean | number,
): ThunkAction<PipelinesActions> => async (dispatch: ThunkDispatch<PipelinesActions>, getState) => {
  const state = getState();
  const pipelines = selectPipelines(state, organisationId);

  if (pipelines?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_PIPELINE_STAGE_UPDATE,
        () =>
          http<PipelineStage, PipelineStage>(
            `/api/v1/organisations/${organisationId}/crm/stages/${stageId}`,
            {
              body: {
                [field]: value,
              },
              method: 'PATCH',
            },
            response => response,
          ),
        {
          organisationId,
          pipelineId,
          stageId,
          field,
          value,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
