import React, { useMemo } from 'react';
import { useDeferredRevenueConfigs } from 'scalexp/features/deferred-revenue/deferredRevenueConfigHooks';
import { DeferredRevenueConfig } from 'scalexp/features/deferred-revenue/types';
import ReportEditorUpdateDecimals from 'scalexp/features/report-editor/ReportEditorSelectDecimals';
import { PipelineMetricsTypes, PipelineStageMetricsTypes } from 'scalexp/store/state/pipelines/types';
import {
  MetricSchema2Complex,
  MetricSchema2Data,
  MetricSchema2DataSource,
  MetricSchema2DataSourceDerivedMetric,
  MetricSchema2DataSourceEntityAccount,
  MetricSchema2DataSourceEntityPipeline,
  MetricSchema2DataSourceEntityPipelineStage,
  MetricSchema2DataSourceInvoicedRevenue,
  MetricSchema2DataSourceNativeMetric,
  MetricSchema2DataSourceSalesMetric,
  MetricSchema2Simple,
} from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import Icon from '../../../components/atoms/Icon';
import Typography from '../../../components/atoms/Typography';
import { useEditChartContext } from '../../../components/contexts/NewEditChartContext';
import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import Select from '../../../components/molecules/Select';
import Switcher from '../../../components/molecules/Switcher';
import FormulaEditorModal from '../../../components/organisms/FormulaEditorModal';
import { InvoicedRevenueMetricId, SalesMetricIds } from '../../../service/types/metricSchema';
import { theme } from '../../../theme';
import { Period } from '../../chart/ChartCard/types';
import { KPIChart, KPIVersusType } from '../../chart/KPIChart/types';
import usePickerOptions from '../../report-editor/ReportEditor/usePickerOptions';
import ChartEditorAddRowSelect from '../ChartEditorAddRowSelect';
import { PERIODS_OPTIONS, SwitcherSidebarWrapper } from '../NewChartsSidebar';
import { pipelineMetricOptions, pipelineTypeOptions, stageMetricOptions } from '../TimeChartSeries/options';

const KPIOptionsWrapper = styled(Column)`
  padding: 19px 16px;

  & > div {
    flex-shrink: 0;
  }
`;
const VERSUS_OPTIONS = [
  {
    label: 'Last period',
    value: 'LAST',
  },
  {
    label: 'Budget',
    value: 'BUDGET',
  },
];

interface KPIChartOptionsProps {
  budgetOptions: ({ label: string; value: number } | { label: string; value: null })[];
  consolidatedBudgetOptions: ({ label: string; value: number } | { label: string; value: null })[];
  chart: KPIChart;
}

const KPIChartOptions = ({ chart, budgetOptions, consolidatedBudgetOptions }: KPIChartOptionsProps) => {
  const { setConfig } = useEditChartContext();
  const organisation = useOrganisationContext();
  const [configureDetails, setConfigureDetails] = React.useState<null | {
    metricSchema: MetricSchema2Complex;
    updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => void;
  }>(null);
  const [showModal, setShowModal] = React.useState(false);
  const { data: deferredRevenueConfigs } = useDeferredRevenueConfigs();
  const period = chart.chart_data.period;
  const versus = chart.chart_data.versus;
  const budgetId = chart.chart_data.budget;
  const consolidatedBudgetId = chart.chart_data.consolidated_budget;
  const pickerOptions = usePickerOptions(undefined, true);
  const metricSchema = chart.chart_data.metric_schema;
  const firstNode: MetricSchema2Data | null = metricSchema.schemaType === 'simple' ? metricSchema.nodes[0] : null;
  const isPipeline = firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline';
  const isStage = firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage';
  const isSales = firstNode?.data?.operator === 'sales';
  const isInvoicedRevenue = firstNode?.data?.operator === 'invoiced-revenue';
  const isComplexCase: boolean = metricSchema.schemaType === 'complex';

  const configOptions: {
    value: DeferredRevenueConfig['id'] | null;
    label: string;
  }[] = [
    ...Object.values(deferredRevenueConfigs || {}).map(config => ({
      value: config.id,
      label: config.name,
    })),
    {
      value: null,
      label: organisation.default_deferred_revenue_config_id
        ? `Default (${deferredRevenueConfigs?.[organisation.default_deferred_revenue_config_id].name})`
        : 'Default',
    },
  ].sort((a, b) => a.label.localeCompare(b.label));

  const normalizedPickerOptions = useMemo(() => {
    const options = [];
    pickerOptions.forEach(option => {
      if (['dm', 'nm', 'account', 'sm', 'pm', 'ps', 'pt', 'ir'].includes(option.id.split(':')[0])) {
        options.push({
          ...option,
          label: option.name,
          value: option.id,
          group: option.role,
        });
      }
    });
    if (isComplexCase) {
      options.push({
        id: '',
        group: '',
        source_id: '',
        role: '',
        name: 'Custom Formula',
        label: 'Custom Formula',
        value: '',
      });
    }
    return options;
  }, [pickerOptions, metricSchema]);

  const getSelectedValue = () => {
    // complex formula
    if (metricSchema.schemaType === 'complex') {
      return '';
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline') {
      return `pm:${firstNode.data.pipelineId}`;
    } else if (firstNode?.data?.operator === 'sales' && firstNode?.data?.metricId) {
      const isRenewal = firstNode.data.metricId.startsWith('RENEWALS');
      return `pt:${isRenewal ? 'RENEWALS' : 'NEW_SALES'}`;
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage') {
      return `ps:${firstNode.data.stageId}:${firstNode.data.pipelineId}`;
    }
    // get value from the choices
    else {
      return normalizedPickerOptions.find(option =>
        [
          (firstNode!.data as MetricSchema2DataSourceNativeMetric | MetricSchema2DataSourceDerivedMetric).metricId,
          (firstNode!.data as MetricSchema2DataSourceEntityAccount).accountId?.toString(),
        ].includes(option.id.split(':')[1]),
      )?.id;
    }
  };
  const getSelectedPipelineMetricValue = () => {
    if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline') {
      return firstNode.data.pipelineMetricId;
    }
  };

  const getSelectedPipelineStageMetricValue = () => {
    if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage') {
      return firstNode.data.stageMetricId;
    }
  };

  const getSelectedSalesMetricValue = () => {
    if (firstNode?.data?.operator === 'sales' && firstNode?.data?.metricId) {
      return firstNode.data.metricId;
    }
  };

  const handleAccountSelection = (newValue: string | null) => {
    if (!newValue) {
      return;
    }
    const [type, newId, secondId] = newValue.split(':');
    let newSimpleMetricSchemaDataSource: MetricSchema2DataSource;
    if (type === 'pt') {
      newSimpleMetricSchemaDataSource = {
        operator: 'sales',
        metricId: `${newId}_IN_PROGRESS_ACV` as SalesMetricIds,
      };
    } else if (type === 'nm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'native',
        metricId: newId,
        isCashflow: false,
      };
    } else if (type === 'dm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'derived',
        metricId: newId,
      };
    } else if (type === 'sm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'sales',
        metricId: newId as SalesMetricIds,
      };
    } else if (type === 'ir') {
      newSimpleMetricSchemaDataSource = {
        operator: 'invoiced-revenue',
        metricId: newId as InvoicedRevenueMetricId,
      };
    } else if (type === 'pm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'pipeline',
        pipelineId: newId,
        pipelineMetricId: 'metric_deals_won',
      };
    } else if (type === 'ps' && secondId) {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'stage',
        pipelineId: secondId,
        stageId: newId,
        stageMetricId: 'metric_deals',
      };
    } else if (type === 'account') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'account',
        accountId: parseInt(newId),
        isCashflow: false,
      };
    } else if (type === 'group') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'group',
        groupId: parseInt(newId),
        isCashflow: false,
      };
    } else {
      throw new Error('Unknown type');
    }

    const newSimpleMetricSchema: MetricSchema2Simple = {
      schemaType: 'simple',
      nodes: [
        {
          operator: 'add',
          data: newSimpleMetricSchemaDataSource,
        },
      ],
      decimals: 0,
    };

    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        metric_schema: newSimpleMetricSchema,
      },
    });
  };
  const handleAggregationPeriodChange = (period: Period) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        period,
      },
    });
  };
  const handleChangeVersusType = (versus_type: KPIVersusType) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        versus_type,
      },
    });
  };
  const handleVersusChange = (versus: 'LAST' | 'BUDGET') => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        versus,
        // null refer to default budget and undefined refer to actual source (no budgets)
        budget: null,
        consolidated_budget: null,
      },
    });
  };
  const handleBudgetSet = (budget: number | null) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        budget: organisation.consolidation_type === 'PARENT' ? null : budget,
        consolidated_budget: organisation.consolidation_type === 'PARENT' ? budget : null,
      },
    });
  };
  const handlePipelineMetricSelection = (newValue: string | null) => {
    if (!newValue || !firstNode) {
      return;
    }
    const metric: MetricSchema2Simple = {
      ...chart.chart_data.metric_schema,
      schemaType: 'simple',
      decimals: 0,
      nodes: [
        {
          operator: chart.chart_data.metric_schema.nodes[0].operator,
          data: {
            ...(firstNode.data as MetricSchema2DataSourceEntityPipeline),
            pipelineMetricId: newValue as PipelineMetricsTypes,
          },
        },
      ],
    };
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        metric_schema: metric,
      },
    });
  };
  const handleStageMetricSelection = (newValue: string | null) => {
    if (!newValue || !firstNode) {
      return;
    }
    const metric: MetricSchema2Simple = {
      ...chart.chart_data.metric_schema,
      schemaType: 'simple',
      decimals: 0,
      nodes: [
        {
          operator: chart.chart_data.metric_schema.nodes[0].operator,
          data: {
            ...(firstNode.data as MetricSchema2DataSourceEntityPipelineStage),
            stageMetricId: newValue as PipelineStageMetricsTypes,
          },
        },
      ],
    };
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        metric_schema: metric,
      },
    });
  };

  const handleSalesMetricSelection = (salesMetricId: string | null, isRenewal: boolean) => {
    if (!salesMetricId || !firstNode) {
      return;
    }
    const prefix = isRenewal ? 'RENEWALS' : 'NEW_SALES';
    const metric: MetricSchema2Simple = {
      ...chart.chart_data.metric_schema,
      schemaType: 'simple',
      decimals: 0,
      nodes: [
        {
          operator: chart.chart_data.metric_schema.nodes[0].operator,
          data: {
            ...firstNode.data,
            metricId: `${prefix}_${salesMetricId}` as SalesMetricIds,
          } as MetricSchema2DataSourceSalesMetric,
        },
      ],
    };
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        metric_schema: metric,
      },
    });
  };
  const handleDeferredRevenueSelection = (configId: string | null) => {
    if (!firstNode) {
      return;
    }
    const metric: MetricSchema2Simple = {
      ...chart.chart_data.metric_schema,
      schemaType: 'simple',
      decimals: 0,
      nodes: [
        {
          operator: chart.chart_data.metric_schema.nodes[0].operator,
          data: {
            ...firstNode.data,
            configId,
          } as MetricSchema2DataSourceInvoicedRevenue,
        },
      ],
    };
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        metric_schema: metric,
      },
    });
  };
  const getInvoicedRevenueValue = () => {
    if (firstNode?.data?.operator === 'invoiced-revenue' && firstNode?.data.configId) {
      return firstNode.data.configId;
    }
  };

  const pickerOptionValue = getSelectedValue();
  const salesMetricValue = getSelectedSalesMetricValue()?.replace('RENEWALS_', '').replace('NEW_SALES_', '');

  return (
    <KPIOptionsWrapper spacing="xlarge" width="100%">
      <Column width="100%">
        <Typography size="small" color="secondary" weight="medium">
          Data source:
        </Typography>
        <Row width="100%" hAlign="space-between" vAlign="center">
          <ChartEditorAddRowSelect
            size="medium"
            width={metricSchema.schemaType === 'complex' ? '260px' : '338px'}
            selectWidth={345}
            includeFixedNumber={false}
            includeBrackets={false}
            data={normalizedPickerOptions}
            onChange={handleAccountSelection}
            handleFormulaCategorySelected={() => {
              setConfigureDetails({
                metricSchema: {
                  schemaType: 'complex',
                  nodes: [],
                  decimals: 0,
                  dataType: 'monetary',
                  impact: 'neutral',
                  aggregation: 'sum',
                },
                updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => {
                  setConfig({
                    ...chart,
                    chart_data: {
                      ...chart.chart_data,
                      metric_schema: updatedMetricSchema,
                    },
                  });
                },
              });
              setShowModal(true);
            }}
            value={pickerOptionValue!}
          />

          {metricSchema.schemaType === 'complex' && (
            <Icon
              clickable
              name="settings"
              size={6}
              color={theme.palette.granite}
              onClick={() => {
                setConfigureDetails({
                  metricSchema: {
                    schemaType: 'complex',
                    nodes: metricSchema.nodes,
                    decimals: 0,
                    dataType: 'monetary',
                    impact: 'neutral',
                    aggregation: 'sum',
                  } as MetricSchema2Complex,
                  updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => {
                    setConfig({
                      ...chart,
                      chart_data: {
                        ...chart.chart_data,
                        metric_schema: updatedMetricSchema,
                      },
                    });
                  },
                });
                setShowModal(true);
              }}
            />
          )}
        </Row>

        {isSales && (
          <Row hAlign="space-between" vAlign="center">
            <Select
              size="medium"
              width="100%"
              placeholder={'Select a sales metric'}
              selectHeight={300}
              selectWidth={338}
              data={pipelineTypeOptions}
              onChange={value =>
                handleSalesMetricSelection(value, !!getSelectedSalesMetricValue()?.startsWith('RENEWALS_'))
              }
              value={salesMetricValue}
            />
          </Row>
        )}

        {isPipeline && (
          <Row width="100%" hAlign="space-between" vAlign="center">
            <Select
              size="medium"
              width="100%"
              placeholder={'Select a pipeline metric'}
              selectHeight={300}
              selectWidth={338}
              data={pipelineMetricOptions}
              onChange={handlePipelineMetricSelection}
              value={getSelectedPipelineMetricValue()}
            />
          </Row>
        )}

        {isStage && (
          <Row width="100%" hAlign="space-between" vAlign="center">
            <Select
              size="medium"
              width="100%"
              placeholder={'Select a stage metric'}
              selectHeight={300}
              selectWidth={338}
              data={stageMetricOptions}
              onChange={handleStageMetricSelection}
              value={getSelectedPipelineStageMetricValue()}
            />
          </Row>
        )}
        {isInvoicedRevenue && (
          <Row hAlign="space-between" vAlign="center" width="100%">
            <Select<DeferredRevenueConfig['id'] | null>
              width="100%"
              size="medium"
              selectWidth={300}
              data={configOptions}
              value={getInvoicedRevenueValue()}
              onChange={value => {
                handleDeferredRevenueSelection?.(value);
              }}
              placeholder={'Config'}
              onClick={e => e.stopPropagation()}
            />
          </Row>
        )}
      </Column>
      <Row width="100%" hAlign="space-between" vAlign="start">
        <Column width="100%">
          <Typography color="secondary" weight="medium" size="small">
            Select period:
          </Typography>
          <Select
            size="medium"
            value={period}
            width="100%"
            onChange={value => handleAggregationPeriodChange(value as Period)}
            data={PERIODS_OPTIONS.filter(option => option.value !== 'YEAR_TO_DATE')}
          />
        </Column>
      </Row>
      <Row width="100%" hAlign="space-between" vAlign="start">
        <Column width="100%">
          <Typography color="secondary" weight="medium" size="small">
            Select what to compare against:
          </Typography>
          <Select
            size="medium"
            value={versus}
            width="100%"
            onChange={value => handleVersusChange(value as 'LAST' | 'BUDGET')}
            data={VERSUS_OPTIONS}
          />
          {organisation.consolidation_type !== 'PARENT' && versus === 'BUDGET' && (
            <Select
              size="medium"
              selectWidth={300}
              width="100%"
              placeholder="Budget name"
              data={budgetOptions}
              value={budgetId}
              onChange={value => {
                handleBudgetSet(value);
              }}
            />
          )}

          {organisation.consolidation_type === 'PARENT' && versus === 'BUDGET' && (
            <Select
              size="medium"
              selectWidth={300}
              width="100%"
              placeholder="Budget name"
              data={consolidatedBudgetOptions}
              value={consolidatedBudgetId}
              onChange={value => {
                handleBudgetSet(value);
              }}
            />
          )}
        </Column>
      </Row>

      <Column width="100%">
        <Typography color="secondary" weight="medium" size="small">
          Select format of comparison:
        </Typography>
        <SwitcherSidebarWrapper>
          <Switcher
            width="100%"
            size="small"
            data={[
              { label: 'Percentage', value: 'PERCENTAGE' },
              { label: 'Absolute value', value: 'ABSOLUTE' },
            ]}
            defaultColor={theme.palette.white}
            activeColor={theme.palette.granite}
            value={chart.chart_data.versus_type}
            onChange={(value: KPIVersusType) => handleChangeVersusType(value)}
          />
        </SwitcherSidebarWrapper>
      </Column>
      <Column width="100%">
        <Typography color="secondary" weight="medium" size="small">
          Decimals:
        </Typography>
        <ReportEditorUpdateDecimals
          type="input"
          decimals={chart.chart_data.metric_schema.decimals}
          handleUpdateDecimals={value =>
            setConfig({
              ...chart,
              chart_data: {
                ...chart.chart_data,
                metric_schema: { ...chart.chart_data.metric_schema, decimals: value },
              },
            })
          }
        />
      </Column>
      {showModal && configureDetails != null && (
        <FormulaEditorModal configureDetails={configureDetails} onHide={() => setShowModal(false)} />
      )}
    </KPIOptionsWrapper>
  );
};
export default KPIChartOptions;
