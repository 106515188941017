import { useAuth0 } from '@auth0/auth0-react';

const SignUpPage = () => {
  const { isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return null;
  }

  loginWithRedirect({
    screen_hint: 'signup',
  });

  return null;
};

export default SignUpPage;
