import { useState, useMemo, PropsWithChildren, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import colors from '../../../colors';
import Icon from '../../../components/atoms/Icon';
import Typography from '../../../components/atoms/Typography';
import Row from '../../../components/layout/Row';
import { usePortalPositioning } from '../../../components/molecules/DropDown/usePortalPositioning';
import { useOutsideAlerter } from '../../../components/molecules/MultiSelect/hooks';
import SearchInput from '../../../components/molecules/SearchInput';
import { iconLookup } from '../../../components/organisms/OauthSidebar';
import { theme } from '../../../theme';
import useCRMPipeLinesConnections from '../../connections/ConnectionsContext/useCRMPipeLinesConnections';
import useFinancialAccountsConnections from '../../connections/ConnectionsContext/useFinancialAccountsConnections';
import { filterByCategory } from './helpers';

export type SelectSize = 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
export type SelectPlacement = 'topStart' | 'bottomStart' | 'bottomEnd';

export interface FormulaPickerOption {
  label: string;
  value: string;
  group?: string;
  name?: string;
  id?: string;
  role?: string;
  source_id?: string;
}

interface Category {
  label: string;
  value: string;
  icon: React.ReactNode;
}

export interface ChartEditorAddRowSelectProps {
  size?: SelectSize;
  width?: string;
  zIndex?: 'high' | 'highest';
  selectWidth?: number;
  value: string;
  onChange: (value: string) => void;
  data: FormulaPickerOption[];
  handleFormulaCategorySelected?: () => void;
  placement?: SelectPlacement;
  includeFormula?: boolean;
  includeFixedNumber?: boolean;
  includeRows?: boolean;
  includeBrackets?: boolean;
}

const StyledContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  position: relative;
  cursor: pointer;

  .expand-icon {
    position: absolute;
    right: 5px;
  }
`;

const StyledSelect = styled.div<{
  size: SelectSize;
  customWidth?: number | 'maxOptionWidth';
  height: number;
  placement: SelectPlacement;
  container: DOMRect;
  select: { width: number; height: number };
  zIndex: 'high' | 'highest';
  containerWidth: string;
}>`
  /* padding: 8px; */
  position: absolute;
  width: ${({ customWidth, container }) => {
    if (customWidth === 'maxOptionWidth') {
      return `unset`;
    }
    return `${customWidth ?? container.width}px`;
  }};
  min-width: ${({ customWidth, containerWidth }) => {
    if (customWidth === 'maxOptionWidth' && containerWidth.includes('px')) {
      return containerWidth;
    }
  }};
  max-height: ${({ height }) => `${height}px`};
  overflow-y: auto;
  z-index: ${({ theme, zIndex }) => theme.zIndex[zIndex]};
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  border-top: unset;
  background-color: ${({ theme }) => theme.palette.white};

  ${({
    placement,
    container: { top, left, width, height },
    select: { height: selectHeight, width: selectWidth },
    customWidth,
  }) => {
    const usedWidth = customWidth === 'maxOptionWidth' ? selectWidth : customWidth ?? selectWidth;
    switch (placement) {
      case 'topStart':
        return css`
          top: calc(${top}px - ${selectHeight}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'bottomStart':
        return css`
          top: calc(${top}px + ${height}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'bottomEnd':
        return css`
          top: ${top + height + window.scrollY}px;
          left: ${left - usedWidth + width + window.scrollX}px;
        `;
    }
  }};

  ${({ size, theme }) => {
    switch (size) {
      case 'tiny':
      case 'small': {
        return css`
          border-bottom-left-radius: ${theme.spacing(1)};
          border-bottom-right-radius: ${theme.spacing(1)};
        `;
      }
      case 'medium':
      case 'large':
      case 'xlarge': {
        return css`
          border-bottom-left-radius: ${theme.spacing(2)};
          border-bottom-right-radius: ${theme.spacing(2)};
        `;
      }
      default:
        return '';
    }
  }}
  input {
    width: calc(100% - 2px);
    margin: ${({ theme }) => theme.spacing(0.5)};
  }
  .expand-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const StyledSearchInputContainer = styled.div`
  position: sticky;
  top: 0;
  padding: 0 8px;
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 10;
`;

const StyledGroup = styled.div<{ selected?: boolean }>`
  height: ${({ theme }) => `${theme.spacing(8)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  font-weight: ${({ theme, selected }) => (selected ? theme.font.weight.semibold : theme.font.weight.regular)};
  font-size: ${({ theme }) => theme.font.size.small};

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:empty {
    display: none;
  }
`;

const StyledMenuItem = styled.div<{ selected?: boolean; depth?: number }>`
  min-height: ${({ theme }) => `${theme.spacing(9)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  padding-left: ${({ theme, depth = 4 }) => theme.spacing(depth)};
  background-color: ${({ theme, selected }) => (selected ? theme.palette.primary.offwhite : theme.palette.white)};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  font-weight: ${({ theme, selected }) => (selected ? theme.font.weight.semibold : theme.font.weight.regular)};
  font-size: ${({ theme }) => theme.font.size.small};
  cursor: pointer;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .material-icons {
    width: 100%;
    display: ${({ selected }) => (selected ? 'block' : 'none')};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.palette.primary.offwhite : theme.palette.primary.offwhite};
  }
`;

const StyledCategoriesContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(6)} `};
  flex-wrap: wrap;
  max-width: 400px;
  padding: ${({ theme }) => theme.sizing(2)};
`;

const StyledCategoryTitleContainer = styled.div`
  position: sticky;
  top: 32px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizing(2)};
  padding: ${({ theme }) => theme.sizing(4)};
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 10;
`;

const StyledIconsContainer = styled.div<{ iconsCount: number }>`
  width: ${({ iconsCount }) => `${iconsCount * 18 + 10}px`};
  display: flex;
`;

const StyledImage = styled.div<{ order: number; zIndex: number }>`
  position: relative;
  left: ${({ order }) => -((order + 1) * 6)}px;
  z-index: ${({ zIndex }) => zIndex};
`;

const StyledCategory = styled.div`
  height: 62px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.sizing(2.5)};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  cursor: pointer;
`;

const StyledValue = styled.div<{
  size: SelectSize;
  showSelect: boolean;
  hasError?: boolean;
  disabled?: boolean;
  bold?: boolean;
}>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  border: ${({ theme }) => `1px solid ${theme.palette.fieldGrey}`};
  background: ${({ theme }) => theme.palette.white};
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};

  ${({ size, showSelect, theme }) => {
    switch (size) {
      case 'tiny': {
        return css`
          height: 20px;
          padding: 0 ${theme.spacing(2)} ${showSelect ? '1px' : '0'} ${theme.spacing(2)};
          border-radius: ${theme.spacing(1)};
          & > .material-icons-round {
            right: 4px;
          }
        `;
      }
      case 'small': {
        return css`
          height: 28px;
          padding: 0 ${theme.spacing(2)} ${showSelect ? '1px' : '0'} ${theme.spacing(2)};
          border-radius: ${theme.spacing(1)};
          & > .material-icons-round {
            right: 4px;
          }
        `;
      }
      case 'medium': {
        return css`
          height: 36px;
          padding: 0 ${theme.spacing(3)} ${showSelect ? '1px' : '0'} ${theme.spacing(3)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 6px;
          }
        `;
      }
      case 'large': {
        return css`
          height: 40px;
          padding: 0 ${theme.spacing(3)} ${showSelect ? '1px' : '0'} ${theme.spacing(4)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 8px;
          }
        `;
      }
      case 'xlarge': {
        return css`
          height: 44px;
          padding: 0 ${theme.spacing(4)} ${showSelect ? '1px' : '0'} ${theme.spacing(5)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 10px;
          }
        `;
      }
      default:
        return '';
    }
  }};
  ${({ hasError, theme }) => {
    if (hasError) {
      return css`
        border: 2px solid ${theme.palette.danger.dark};
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
      `;
    }
  }}

  // removing the border and raduis from the bottom when the select is shown
  ${({ showSelect }) =>
    showSelect
      ? css`
          border-bottom: unset;
          border-bottom-left-radius: unset;
          border-bottom-right-radius: unset;
        `
      : ''}

  &:hover,
  &:focus {
    filter: brightness(85%);
  }
  &:active {
    filter: brightness(70%);
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

function ChartEditorAddRowSelect({
  data: pickerOptions,
  placement = 'bottomStart',
  size = 'small',
  width = '100%',
  selectWidth,
  value,
  includeFormula = true,
  includeFixedNumber = true,
  includeRows = false,
  includeBrackets = true,
  onChange,
  handleFormulaCategorySelected,
  zIndex = 'high',
}: PropsWithChildren<ChartEditorAddRowSelectProps>) {
  const selectHeight = 300;

  // filtering custom metrics that are not used
  const [showSelect, setShowSelect] = useState(false);
  const {
    containerCallbackRef,
    containerRect,
    elementRef: dropDownRef,
    elementCallbackRef: dropDownCallbackRef,
    elementDimensions: selectDimensions,
  } = usePortalPositioning(showSelect, setShowSelect);

  useOutsideAlerter(dropDownRef, () => setShowSelect(false));
  const financialConnections = [...new Set(useFinancialAccountsConnections())];
  const crmConnections = [...new Set(useCRMPipeLinesConnections())];
  let CATEGORIES: Category[] = [];

  const icons = (category: string) => {
    if (['financial', 'crm'].includes(category)) {
      const usedConnections = category === 'financial' ? financialConnections : crmConnections;
      return (
        <StyledIconsContainer iconsCount={usedConnections.length}>
          {usedConnections.map((connection, index) => {
            return (
              <StyledImage key={connection} order={index - 1} zIndex={usedConnections.length - index}>
                {iconLookup[connection]?.()}
              </StyledImage>
            );
          })}
        </StyledIconsContainer>
      );
    }

    return iconLookup[category]?.();
  };

  if (financialConnections.length) {
    CATEGORIES.push({
      label: 'Accounting',
      value: 'financial',
      icon: icons('financial'),
    });
  }
  if (crmConnections.length) {
    CATEGORIES.push({
      label: 'CRM',
      value: 'crm',
      icon: icons('crm'),
    });
  }

  CATEGORIES.push({
    label: 'ScaleXP',
    value: 'scalexp',
    icon: <img width={24} src={` /images/logos/scalexp-icon.svg`} alt="scalexp" />,
  });

  if (includeFormula) {
    CATEGORIES.push({
      label: 'Formula',
      value: 'custom',
      icon: <Icon size={6} name="calculate" color={theme.palette.granite} />,
    });
  }
  if (includeFixedNumber) {
    CATEGORIES.push({
      label: 'Fixed Number',
      value: 'constant',
      icon: <img width={24} src={` /images/logos/number.svg`} alt="number" />,
    });
  }
  if (includeRows) {
    CATEGORIES.push({
      label: 'Row',
      value: 'row',
      icon: <Icon size={6} name="toc" color={theme.palette.granite} />,
    });
  }
  if (includeBrackets) {
    CATEGORIES.push({
      label: 'Brackets',
      value: 'custom',
      icon: <Typography>()</Typography>,
    });
  }

  const menuSearchInputRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(() => {
    if (selectedCategory) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [selectedCategory]);
  const handleCategoryClick = (category: string) => {
    if ((category === 'constant' || category === 'custom') && handleFormulaCategorySelected) {
      setShowSelect(false);
      handleFormulaCategorySelected();
    } else {
      menuSearchInputRef.current?.focus();
      setSelectedCategory(category);
    }
  };

  const handleItemClick = (itemValue: string) => {
    onChange(itemValue);
    setShowSelect(false);
  };

  const handleSearchChange = (term: string) => {
    // clear category on search
    if (selectedCategory) {
      setSelectedCategory(null);
    }

    setSearch(term);
  };

  // filter by category
  const dataFilteredByCategory = useMemo(() => filterByCategory(pickerOptions, selectedCategory), [
    pickerOptions,
    selectedCategory,
  ]);
  // search values
  const searchedValues = dataFilteredByCategory.filter(item => item.label.toLowerCase().includes(search.toLowerCase()));
  // group values
  const grouped = searchedValues.reduce(
    (group, item) => {
      const category = item.group;
      if (!category) {
        return group;
      }

      group[category] = group[category] ?? [];
      group[category].push(item);
      return group;
    },
    {} as {
      [group: string]: FormulaPickerOption[];
    },
  );

  useEffect(() => {
    if (!showSelect) {
      setSelectedCategory(null);
      setSearch('');
    }
  }, [showSelect]);

  const iconSize = size === 'small' ? 4.5 : size === 'medium' ? 5 : size === 'large' ? 5.5 : size === 'xlarge' ? 6 : 4;
  const selectedOption = pickerOptions.find(option => option.value === value)!;
  const icon =
    selectedOption?.source_id && iconLookup[selectedOption?.source_id] ? iconLookup[selectedOption.source_id]() : null;

  return (
    <StyledContainer ref={containerCallbackRef} width={width}>
      <StyledValue size={size} onClick={() => setShowSelect(!showSelect)} showSelect={showSelect}>
        <Row vAlign="center" width="94%">
          {icon && icon}
          {selectedOption ? <span>{selectedOption.label}</span> : null}
        </Row>
        <Icon name="expand_more" size={iconSize} color={colors.blue[600]} className="expand-icon" />
      </StyledValue>

      {showSelect &&
        createPortal(
          <StyledSelect
            size={size}
            ref={dropDownCallbackRef}
            customWidth={selectedCategory ? 'maxOptionWidth' : selectWidth}
            height={selectHeight}
            placement={placement}
            container={containerRect}
            select={selectDimensions}
            containerWidth={width}
            zIndex={zIndex}
          >
            <StyledSearchInputContainer>
              <SearchInput
                autoFocus
                width="100%"
                ref={menuSearchInputRef}
                value={search}
                setValue={handleSearchChange}
                placeholder="Type to search"
                onClick={() => setShowSelect(true)}
                iconPosition="start"
              />
              <Icon name="expand_less" size={6} color={colors.blue[600]} className="expand-icon" />
            </StyledSearchInputContainer>
            {/* initial state no selected category and no search */}
            {!selectedCategory && !search && (
              <StyledCategoriesContainer>
                {CATEGORIES.map(category => (
                  <StyledCategory onClick={() => handleCategoryClick(category.value)}>
                    {category.icon}
                    <Typography color="secondary">{category.label}</Typography>
                  </StyledCategory>
                ))}
              </StyledCategoriesContainer>
            )}
            {/* selected category items */}
            {selectedCategory && (
              <StyledCategoryTitleContainer>
                <Icon
                  clickable
                  name="cancel"
                  size={4}
                  color={colors.granite}
                  rounded
                  onClick={() => handleCategoryClick('')}
                />
                <Row spacing="tiny">
                  {icons(selectedCategory)}
                  <Typography>{CATEGORIES.find(category => category.value === selectedCategory)?.label}</Typography>
                </Row>
              </StyledCategoryTitleContainer>
            )}
            {searchedValues.length === 0 && <StyledMenuItem>No results found</StyledMenuItem>}
            {(search || selectedCategory) && (
              <>
                {Object.entries(grouped).map(([groupName, items]) => (
                  <>
                    {/* an exception for row category */}
                    {!(selectedCategory === 'row' && groupName === 'Row') && (
                      <StyledGroup key={groupName}>{groupName.toUpperCase()}</StyledGroup>
                    )}
                    {items.map(item => {
                      return (
                        <StyledMenuItem
                          key={`${item.label}${item.value}`}
                          onClick={() => handleItemClick(item.value)}
                          selected={value === item.value}
                        >
                          <Row vAlign="center">
                            {item.source_id && icons(item.source_id)}
                            {item.label}
                          </Row>
                        </StyledMenuItem>
                      );
                    })}
                  </>
                ))}
              </>
            )}
          </StyledSelect>,
          document.body,
        )}
    </StyledContainer>
  );
}
export default ChartEditorAddRowSelect;
