import { combineReducers } from 'redux';

import accountGroups from './accountGroups/reducers';
import { OrganisationAccountGroups } from './accountGroups/types';
import accountRules from './accountRules/reducers';
import { OrganisationAccountRules } from './accountRules/types';
import accounts from './accounts/reducers';
import { OrganisationAccounts } from './accounts/types';
import actualsExchangeRateOverride from './actualsExchangeRates/reducers';
import { OrganisationActualsExchangeRateOverrides } from './actualsExchangeRates/types';
import budgetExchangeRates from './budgetExchangeRates/reducers';
import { OrganisationBudgetExchangeRates } from './budgetExchangeRates/types';
import budgets from './budgets/reducers';
import { OrganisationBudgets } from './budgets/types';
import budgetsets from './budgetsets/reducers';
import { OrganisationBudgetSets } from './budgetsets/types';
import charts from './charts/reducers';
import { OrganisationCharts } from './charts/types';
import columnsPresets from './columnsPresets/reducers';
import { OrganisationColumnsPresets } from './columnsPresets/types';
import consolidatedBudgetsets from './consolidatedBudgetsets/reducers';
import { OrganisationConsolidatedBudgetSets } from './consolidatedBudgetsets/types';
import customers from './customers/reducers';
import { OrganisationCustomers } from './customers/types';
import deferredAccountMapping from './deferredAccountMapping/reducers';
import { OrganisationDeferredAccountMappings } from './deferredAccountMapping/types';
import deferredBills from './deferredBills/reducers';
import { OrganisationDeferredBills } from './deferredBills/types';
import deferredBillsByAccount from './deferredBillsByAccount/reducers';
import { OrganisationDeferredBillsByAccount } from './deferredBillsByAccount/types';
import deferredBillsRemaining from './deferredBillsRemaining/reducers';
import { OrganisationDeferredBillsRemaining } from './deferredBillsRemaining/types';
import deferredBillsSummation from './deferredBillsSummation/reducers';
import { OrganisationDeferredBillsSummation } from './deferredBillsSummation/types';
import deferredInvoices from './deferredInvoices/reducers';
import { OrganisationDeferredInvoices } from './deferredInvoices/types';
import deferredInvoicesSummation from './deferredInvoicesSummation/reducers';
import { OrganisationDeferredInvoicesSummation } from './deferredInvoicesSummation/types';
import deferredSeriesByAccount from './deferredSeriesByAccount/reducers';
import { OrganisationDeferredSeriesByAccount } from './deferredSeriesByAccount/types';
import deferredSeriesRemaining from './deferredSeriesRemaining/reducers';
import { OrganisationDeferredSeriesRemaining } from './deferredSeriesRemaining/types';
import deferredSeriesRemainingByAccount from './deferredSeriesRemainingByAccount/reducers';
import { OrganisationDeferredSeriesRemainingByAccount } from './deferredSeriesRemainingByAccount/types';
import deferredSeriesRemainingByCustomer from './deferredSeriesRemainingByCustomer/reducers';
import { OrganisationDeferredSeriesRemainingByCustomer } from './deferredSeriesRemainingByCustomer/types';
import derivedMetrics from './derivedMetrics/reducers';
import { SystemDerivedMetrics } from './derivedMetrics/types';
import editedReport from './editedReport/reducers';
import { EditedReport } from './editedReport/types';
import exchangeRates from './exchangeRates/reducers';
import { SystemExchangeRates } from './exchangeRates/types';
import manualJournals from './manualJournals/reducers';
import { OrganisationManualJournals } from './manualJournals/types';
import nativeMetrics from './nativeMetrics/reducers';
import { OrganisationNativeMetrics } from './nativeMetrics/types';
import newdashboards from './newDashboards/reducers';
import { OrganisationDashboards as OrganisationNewDashboards } from './newDashboards/types';
import organisations from './organisations/reducers';
import { OrganisationOrganisations } from './organisations/types';
import pipelines from './pipelines/reducers';
import { OrganisationPipelines } from './pipelines/types';
import prepaymentsPresets from './prepaymentsPresets/reducers';
import { OrganisationPrepaymentsPresets } from './prepaymentsPresets/types';
import presentation from './presentation/reducers';
import { PresentationVS } from './presentation/types';
import presentationEditor from './presentationEditor/reducers';
import { PresentationEditorState } from './presentationEditor/types';
import reportGrouping from './reportGrouping/reducers';
import { ReportGrouping } from './reportGrouping/types';
import reports from './reports/reducers';
import { OrganisationReports } from './reports/types';
import trackingCategories from './trackingCategories/reducers';
import { OrganisationTrackingCategories } from './trackingCategories/types';

export interface Store {
  // Per tenant data
  account: OrganisationAccounts;
  accountGroups: OrganisationAccountGroups;
  charts: OrganisationCharts;
  deferredAccountMapping: OrganisationDeferredAccountMappings;
  deferredInvoices: OrganisationDeferredInvoices;
  deferredInvoicesSummation: OrganisationDeferredInvoicesSummation;
  deferredBills: OrganisationDeferredBills;
  deferredBillsByAccount: OrganisationDeferredBillsByAccount;
  deferredBillsSummation: OrganisationDeferredBillsSummation;
  prepaymentsPresets: OrganisationPrepaymentsPresets;
  deferredSeriesRemaining: OrganisationDeferredSeriesRemaining;
  deferredSeriesByAccount: OrganisationDeferredSeriesByAccount;
  deferredSeriesRemainingByAccount: OrganisationDeferredSeriesRemainingByAccount;
  deferredSeriesRemainingByCustomer: OrganisationDeferredSeriesRemainingByCustomer;
  deferredBillsRemaining: OrganisationDeferredBillsRemaining;
  customers: OrganisationCustomers;
  reports: OrganisationReports;
  columnsPresets: OrganisationColumnsPresets;
  trackingCategories: OrganisationTrackingCategories;
  nativeMetrics: OrganisationNativeMetrics;
  organisations: OrganisationOrganisations;
  budgetsets: OrganisationBudgetSets;
  consolidatedBudgetsets: OrganisationConsolidatedBudgetSets;
  exchangeRates: SystemExchangeRates;
  manualJournals: OrganisationManualJournals;
  newdashboards: OrganisationNewDashboards;
  budgets: OrganisationBudgets;
  actualsExchangeRateOverride: OrganisationActualsExchangeRateOverrides;
  budgetExchangeRates: OrganisationBudgetExchangeRates;
  pipelines: OrganisationPipelines;
  accountRules: OrganisationAccountRules;
  presentation: PresentationVS;
  presentationEditor: PresentationEditorState;
  // System wide data
  derivedMetrics: SystemDerivedMetrics;
  // local data
  editedReport: EditedReport;
  reportGrouping: ReportGrouping;
}

const rootReducer = combineReducers<Store>({
  account: accounts,
  accountGroups: accountGroups,
  actualsExchangeRateOverride,
  deferredAccountMapping,
  deferredInvoices,
  deferredInvoicesSummation,
  deferredBills,
  deferredBillsByAccount,
  deferredBillsSummation,
  deferredSeriesRemaining,
  deferredSeriesRemainingByAccount,
  deferredSeriesRemainingByCustomer,
  deferredBillsRemaining,
  prepaymentsPresets: prepaymentsPresets,
  deferredSeriesByAccount,
  customers,
  charts,
  reports,
  columnsPresets,
  trackingCategories,
  nativeMetrics,
  exchangeRates,
  derivedMetrics,
  organisations,
  manualJournals,
  newdashboards,
  budgetExchangeRates,
  budgetsets,
  consolidatedBudgetsets,
  budgets,
  pipelines,
  accountRules,
  editedReport,
  reportGrouping,
  presentation,
  presentationEditor,
});

export default rootReducer;
