import { useCallback } from 'react';
import { mutate } from 'swr';

import fetcher from '../../../utils/fetcher';

export type UpdateUserPayload = {
  first_name?: string;
  last_name?: string;
  job_title?: string;
  phone_number?: string;
  date_format?: 'DMY' | 'MDY' | 'YMD';
};

const useUpdateUserSettings = () => {
  return useCallback(async (settings: UpdateUserPayload) => {
    const response = await fetcher('/userconfig/settings/profile', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(settings),
    });

    mutate('/userconfig/settings/profile');

    return response;
  }, []);
};

export default useUpdateUserSettings;
