import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectAccountRules } from './selectors';
import { AccountRule, AccountRules } from './types';

export const ORGANISATIONS_ACCOUNT_RULES_LOAD = '[accountRules] LOAD';
export const ORGANISATIONS_ACCOUNT_RULE_CREATE_EMPTY = '[accountRules] CREATE EMPTY';
export const ORGANISATIONS_ACCOUNT_RULE_DELETE_EMPTY = '[accountRules] DELETE EMPTY';
export const ORGANISATIONS_ACCOUNT_RULE_UPDATE_EMPTY = '[accountRules] UPDATE EMPTY';
export const ORGANISATIONS_ACCOUNT_RULE_CREATE = '[accountRules] CREATE';
export const ORGANISATIONS_ACCOUNT_RULE_UPDATE = '[accountRules] UPDATE';
export const ORGANISATIONS_ACCOUNT_RULE_DELETE = '[accountRules] DELETE';

type AccountRulesResponse = {
  rules: AccountRules;
};
type CreateAccountRuleResponse =
  | {
      rule: AccountRule;
      job_id: string;
    }
  | {
      status: number;
      body: { message: string };
    };

type UpdateAccountRuleResponse =
  | {
      rule: AccountRule;
      job_id: string;
    }
  | {
      status: number;
      body: { message: string };
    };
type DeleteAccountRuleResponse = {
  rule: AccountRule;
  job_id: string;
};

type AccountRulesActionLoad = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_RULES_LOAD,
  AccountRules,
  { organisationId: number }
>;
type AccountRulesActionCreate = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_RULE_CREATE,
  CreateAccountRuleResponse,
  { organisationId: number; accountRule: AccountRule }
>;

type AccountRulesActionCreateEmpty = {
  type: typeof ORGANISATIONS_ACCOUNT_RULE_CREATE_EMPTY;
  payload: AccountRule;
  params: { organisationId: number };
};

type AccountRuleActionDeleteEmpty = {
  type: typeof ORGANISATIONS_ACCOUNT_RULE_DELETE_EMPTY;
  payload: AccountRule;
  params: { organisationId: number };
};

type AccountRuleActionUpdateEmpty = {
  type: typeof ORGANISATIONS_ACCOUNT_RULE_UPDATE_EMPTY;
  payload: AccountRule;
  params: { organisationId: number };
};

type AccountRuleActionUpdate = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_RULE_UPDATE,
  AccountRule,
  { organisationId: number; accountRule: AccountRule }
>;
type AccountRuleActionDelete = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_RULE_DELETE,
  AccountRule,
  { organisationId: number; accountRule: AccountRule }
>;
export type AccountRulesActions =
  | AccountRulesActionLoad
  | AccountRulesActionCreate
  | AccountRulesActionCreateEmpty
  | AccountRuleActionUpdate
  | AccountRuleActionUpdateEmpty
  | AccountRuleActionDelete
  | AccountRuleActionDeleteEmpty;

export type CreateAccountRuleActionResult =
  | {
      success: false;
      message?: string;
    }
  | {
      success: true;
      rule: AccountRule;
      job_id?: string;
    };

export type UpdateAccountRuleActionResult =
  | {
      success: false;
      message?: string;
    }
  | {
      success: true;
      rule: AccountRule;
      job_id?: string;
    };

export type DeleteAccountRuleActionResult =
  | {
      success: false;
    }
  | {
      success: true;
      rule: AccountRule;
      job_id?: string;
    };

export const loadAccountRulesAction = (organisationId: number): ThunkAction<AccountRulesActions> => async (
  dispatch: ThunkDispatch<AccountRulesActions>,
  getState,
) => {
  const state = getState();
  const accountRules = selectAccountRules(state, organisationId);

  if (accountRules?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_RULES_LOAD,
        () =>
          http<AccountRules | undefined, AccountRulesResponse>(
            `/api/v1/organisations/${organisationId}/invoices/deferred/rules`,
            {},
            response => response.rules,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const createAccountRuleAction = (
  organisationId: number,
  accountRule: AccountRule,
): ThunkAction<AccountRulesActionCreate, Promise<CreateAccountRuleActionResult>> => async (
  dispatch: ThunkDispatch<AccountRulesActions>,
  getState,
) => {
  const state = getState();
  const accountRules = selectAccountRules(state, organisationId);

  if (accountRules?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const fetchedAccountRule = await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_RULE_CREATE,
        async () =>
          http<CreateAccountRuleResponse, CreateAccountRuleResponse>(
            `/api/v1/organisations/${organisationId}/invoices/deferred/rules`,
            {
              method: 'POST',
              body: {
                account_id: accountRule.account_id,
                config: accountRule.config,
                revenue_type: accountRule.revenue_type,
                month_percentage: accountRule.month_percentage,
                use_issued_on_as_start_date: accountRule.use_issued_on_as_start_date,
              },
            },
            response => response,
          ),
        {
          organisationId,
          accountRule,
        },
      ),
    );

    // handling errors
    if ('status' in fetchedAccountRule && fetchedAccountRule?.status === 400) {
      return {
        success: false,
        message: fetchedAccountRule.body.message,
      };
    }

    // success
    if ('rule' in fetchedAccountRule) {
      return {
        success: true,
        rule: fetchedAccountRule.rule,
        job_id: fetchedAccountRule.job_id,
      };
    }

    // default
    return { success: false, message: `Rule creation failed` };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};

export const updateAccountRulesAction = (
  organisationId: number,
  accountRule: AccountRule,
): ThunkAction<AccountRuleActionUpdate, Promise<UpdateAccountRuleActionResult>> => async (
  dispatch: ThunkDispatch<AccountRulesActions>,
  getState,
) => {
  const state = getState();
  const accountRules = selectAccountRules(state, organisationId);

  if (accountRules?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const fetchedAccountRule = await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_RULE_UPDATE,
        async () =>
          http<UpdateAccountRuleResponse, UpdateAccountRuleResponse>(
            `/api/v1/organisations/${organisationId}/invoices/deferred/rules/${accountRule.id}`,
            {
              method: 'PATCH',
              body: {
                account_id: accountRule.account_id,
                config: accountRule.config,
                revenue_type: accountRule.revenue_type,
                month_percentage: accountRule.month_percentage,
                use_issued_on_as_start_date: accountRule.use_issued_on_as_start_date,
              },
            },
            response => response,
          ),
        {
          organisationId,
        },
      ),
    );

    // handling errors
    if ('status' in fetchedAccountRule && fetchedAccountRule?.status === 400) {
      return {
        success: false,
        message: fetchedAccountRule.body.message,
      };
    }

    // success
    if ('rule' in fetchedAccountRule) {
      return {
        success: true,
        rule: fetchedAccountRule.rule,
        job_id: fetchedAccountRule.job_id,
      };
    }

    // default
    return { success: false, message: `Rule creation failed` };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};

export const deleteAccountRulesAction = (
  organisationId: number,
  accountRule: AccountRule,
): ThunkAction<AccountRuleActionDelete, Promise<DeleteAccountRuleActionResult>> => async (
  dispatch: ThunkDispatch<AccountRulesActions>,
  getState,
) => {
  const state = getState();
  const accountRules = selectAccountRules(state, organisationId);

  if (accountRules?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const deletedAccountRule = await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_RULE_DELETE,
        async () =>
          http<DeleteAccountRuleResponse, DeleteAccountRuleResponse>(
            `/api/v1/organisations/${organisationId}/invoices/deferred/rules/${accountRule.id}`,
            {
              method: 'DELETE',
              body: {},
            },
          ),
        {
          organisationId,
          accountRule,
        },
      ),
    );
    return {
      success: true,
      rule: deletedAccountRule.rule,
      job_id: deletedAccountRule.job_id,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};
