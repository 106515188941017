import useSWR from 'swr';

import { Chart } from '../../../features/chart/ChartCard/types';
import fetcher from '../../../utils/fetcher';
import useActiveOrganisationId from '../../contexts/OrganisationContext/useActiveOrganisationId';

export const useChartData = (chartId?: string) => {
  const organisationId = useActiveOrganisationId();
  const endpoint = `/api/v1/organisations/${organisationId}/charts/${chartId}`;
  // @ts-ignore
  const { data: chartData, isValidating, mutate, error } = useSWR<Chart>([endpoint], key =>
    chartId ? fetcher(endpoint) : null,
  );
  return { chartData, isValidating, mutate, error };
};
