import produce from 'immer';

import {
  ORGANISATIONS_DEFERRED_ACCOUNT_MAPPINGS_LOAD,
  ORGANISATIONS_DEFERRED_ACCOUNT_MAPPING_UPDATE,
  DeferredAccountMappingsActions,
} from './actions';
import { OrganisationDeferredAccountMappings } from './types';

const deferredAccountMappings = (
  prevState: OrganisationDeferredAccountMappings = {},
  action: DeferredAccountMappingsActions,
): OrganisationDeferredAccountMappings => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_DEFERRED_ACCOUNT_MAPPINGS_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return state;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload,
            };
            return state;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return state;

          default:
            return state;
        }
      }
      case ORGANISATIONS_DEFERRED_ACCOUNT_MAPPING_UPDATE: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return state;

          case 'success':
            if (!action.payload.success) {
              state[organisationId] = {
                status: 'success',
                error: undefined,
                value: state[organisationId].value || {},
              };
              return state;
            }
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload.mappings,
            };
            return state;

          default:
            return state;
        }
      }
      default:
        return state;
    }
  });
};

export default deferredAccountMappings;
