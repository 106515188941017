import produce from 'immer';

import {
  ORGANISATIONS_DEFERRED_SERIES_REMAINING_BY_ACCOUNT_LOAD,
  DeferredSeriesRemainingByAccountActions,
} from './actions';
import { DeferredSeriesRemainingByAccount, OrganisationDeferredSeriesRemainingByAccount } from './types';

const deferredSeriesRemainingByAccount = (
  prevState: OrganisationDeferredSeriesRemainingByAccount = {},
  action: DeferredSeriesRemainingByAccountActions,
): OrganisationDeferredSeriesRemainingByAccount => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_DEFERRED_SERIES_REMAINING_BY_ACCOUNT_LOAD:
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: Object.entries(action.payload).reduce((acc, [customerId, data]) => {
                acc[customerId] = data;
                return acc;
              }, {} as DeferredSeriesRemainingByAccount),
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      default:
        return state;
    }
  });
};

export default deferredSeriesRemainingByAccount;
