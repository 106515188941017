import { arrayMove } from '@dnd-kit/sortable';
import { MetricSchema2DataOrNodesWithId, MetricSchema2NodesWithId } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import Typography from '../../../components/atoms/Typography';
import { Row } from '../../../store/state/reports/types';
import { useGetRowName } from '../../report-editor/ReportEditor/usePickerOptions';
import { DragHandle } from '../../report-editor/ReportEditorContent';

export const getItemAndParentByPath = (
  items: MetricSchema2DataOrNodesWithId[],
  path: string,
): {
  parent: MetricSchema2NodesWithId | null;
  item: MetricSchema2DataOrNodesWithId;
} | null => {
  const pathIdsArray = path.split(':');
  if (!pathIdsArray.length) {
    return null;
  }
  let searchedItem: MetricSchema2DataOrNodesWithId | undefined = items.find(item => item.id === pathIdsArray[0]);
  let parent: MetricSchema2DataOrNodesWithId | null = null;
  pathIdsArray.slice(1).forEach(id => {
    if (searchedItem && 'nodes' in searchedItem) {
      parent = searchedItem;
      searchedItem = searchedItem.nodes.find(node => node.id === id)!;
    }
  });

  if (!searchedItem) {
    return null;
  }

  return { parent, item: searchedItem };
};

export const moveNestedItemsWithSameParent = (
  items: MetricSchema2DataOrNodesWithId[],
  activeId: string,
  overId: string,
  overItem: MetricSchema2DataOrNodesWithId,
  activeParent: MetricSchema2NodesWithId | null,
  overParent: MetricSchema2NodesWithId | null,
): MetricSchema2DataOrNodesWithId[] | null => {
  // if they have the same parent we just return
  if (activeParent?.id === overParent?.id && !('nodes' in overItem)) {
    // they have parent
    if (activeParent) {
      const activeIndex = activeParent.nodes.findIndex(node => node.id === activeId);
      const overIndex = activeParent.nodes.findIndex(node => node.id === overId);
      activeParent.nodes = arrayMove(activeParent.nodes, activeIndex, overIndex);
      // make sure first node has an add operator
      activeParent.nodes[0] = { ...activeParent.nodes[0], operator: 'add' };
      return JSON.parse(JSON.stringify(items));
    }
    // item are in root
    const activeIndex = items.findIndex(node => node.id === activeId);
    const overIndex = items.findIndex(node => node.id === overId);
    items = arrayMove(items, activeIndex, overIndex);
    items[0] = { ...items[0], operator: 'add' };
    return JSON.parse(JSON.stringify(items));
  }

  return null;
};

export const moveNestedItemsWithDifferentParents = (
  items: MetricSchema2DataOrNodesWithId[],
  activeId: string,
  overId: string,
  activeItem: MetricSchema2DataOrNodesWithId,
  overItem: MetricSchema2DataOrNodesWithId,
  activeParent: MetricSchema2NodesWithId | null,
  overParent: MetricSchema2NodesWithId | null,
  // don't move to the over container, add it before or after it
  addBeforeOrAfter?: 'before' | 'after',
) => {
  // if they have the same parent we use move inside same parent
  if (activeParent?.id === overParent?.id && !('nodes' in overItem)) {
    return moveNestedItemsWithSameParent(items, activeId, overId, overItem, activeParent, overParent);
  }

  // diffrent parents
  // is dragged over multiple nodes
  if ('nodes' in overItem) {
    // delete old active item
    // item has parent
    if (activeParent) {
      activeParent.nodes = activeParent.nodes.filter(item => item.id !== activeId);
    }
    // item is in root
    else {
      items = items.filter(item => item.id !== activeId);
    }

    // if we need to put it before the container
    if (addBeforeOrAfter === 'before') {
      if (overParent) {
        const overIndex = overParent.nodes.findIndex(item => item.id === overId);
        overParent.nodes.splice(overIndex, 0, overIndex === 0 ? { ...activeItem, operator: 'add' } : activeItem);
      } else {
        const overIndex = items.findIndex(item => item.id === overId);
        items.splice(overIndex, 0, overIndex === 0 ? { ...activeItem, operator: 'add' } : activeItem);
      }
    } else if (addBeforeOrAfter === 'after') {
      if (overParent) {
        const overIndex = overParent.nodes.findIndex(item => item.id === overId);
        overParent.nodes.splice(overIndex + 1, 0, activeItem);
      } else {
        const overIndex = items.findIndex(item => item.id === overId);
        items.splice(overIndex + 1, 0, activeItem);
      }
    }
    // add it to the dragged over container
    else {
      overItem.nodes.unshift({ ...activeItem, operator: 'add' });
    }

    return JSON.parse(JSON.stringify(items));
  }

  // is dragged over single node
  // Deleting from old place
  if (activeParent) {
    activeParent.nodes = activeParent.nodes.filter(item => item.id !== activeId);
  }
  // item to delete is in root
  else {
    items = items.filter(item => item.id !== activeId);
  }

  // Adding to new place
  if (overParent) {
    overParent.nodes.unshift({ ...activeItem, operator: 'add' });
  }
  // item to add to is in root
  else {
    const overIndex = items.findIndex(item => item.id === overId);
    items.splice(overIndex, 0, overIndex === 0 ? { ...activeItem, operator: 'add' } : activeItem);
  }

  return JSON.parse(JSON.stringify(items));
};

export const addIdsToNestedNodes = (node: MetricSchema2DataOrNodesWithId): MetricSchema2DataOrNodesWithId => {
  node.id = uuidv4();

  if ('nodes' in node) {
    node.nodes.map(addIdsToNestedNodes);
  }

  return node;
};

export const isDraggingOverDescendants = (item: MetricSchema2DataOrNodesWithId, overId: string): boolean => {
  // if we found the item
  if (item.id === overId) {
    return true;
  }

  // if we are in a single node and it isn't the overId
  if (!('nodes' in item)) {
    return false;
  }

  // didn't find keep checking
  return item.nodes.some(node => isDraggingOverDescendants(node, overId));
};

export const getNestedItem = (
  item: MetricSchema2DataOrNodesWithId,
  id: string | null,
): MetricSchema2DataOrNodesWithId | null => {
  if (!id) {
    return null;
  }

  if (item.id === id) {
    return item;
  }

  if ('nodes' in item) {
    for (let i = 0; i < item.nodes.length; i++) {
      const foundNode = getNestedItem(item.nodes[i], id);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return null;
};

const StyledContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;

  span:nth-last-child(-n + 2) {
    text-align: end;
  }
`;

export const useGetDragOverlay = () => {
  const getRowName = useGetRowName();

  const getDragOverlay = (activeId: string | null, nodes: MetricSchema2DataOrNodesWithId[], rows: Row[]) => {
    const activeItem = getNestedItem({ id: '', operator: 'add', nodes }, activeId);
    if (!activeId || !activeItem) {
      return null;
    }

    let rowName: string | undefined = 'Brackets';

    if ('data' in activeItem) {
      rowName = getRowName(
        {
          id: uuidv4(),
          type: 'metric',
          bold: true,
          metric: {
            schemaType: 'simple',
            decimals: 0,
            nodes: [activeItem],
          },
        },
        rows,
      );
    }

    return (
      <StyledContainer>
        <DragHandle />
        <Typography color="secondary">{rowName}</Typography>
      </StyledContainer>
    );
  };

  return getDragOverlay;
};
