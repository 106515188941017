import dayjs from 'dayjs';
import { useEffect } from 'react';
import { ENV } from 'scalexp/config';
import fetcher from 'scalexp/utils/fetcher';

window.hsConversationsSettings = {
  loadImmediately: false,
};

const OnboardingChatSupport: React.FC = () => {
  const availabilityChatSupportTime =
    dayjs().tz('Europe/London').isAfter(dayjs().set('h', 7).set('m', 0)) &&
    dayjs().tz('Europe/London').isBefore(dayjs().set('h', 19).set('m', 0));

  const isChatAvailable = ENV === 'PROD' && availabilityChatSupportTime;

  const onConversationsAPIReady = () => {
    const status = window.HubSpotConversations.widget.status();
    if (status.loaded) {
      window.HubSpotConversations.widget.refresh();
    } else {
      window.HubSpotConversations.widget.load();
    }
    console.log(`HubSpot Conversations API: ${JSON.stringify(window.HubSpotConversations)}`);
  };
  useEffect(() => {
    if (isChatAvailable) {
      fetcher(`/api/v1/users/create_visitor_hubspot_identification_token`, { method: 'POST' }).then(res => {
        if (!res.email) {
          // We can not get the identification token, so no sense to load the script.
          return;
        }
        window.hsConversationsSettings.identificationEmail = res.email;
        window.hsConversationsSettings.identificationToken = res.hs_identification_token;

        const script = document.createElement('script');
        script.src = 'https://js.hs-scripts.com/8359851.js';
        script.async = true;
        script.id = 'hs-script-loader';
        script.defer = true;
        document.body.appendChild(script);

        if (window.HubSpotConversations) {
          onConversationsAPIReady();
        } else {
          window.hsConversationsOnReady = [onConversationsAPIReady];
        }
      });
    }
  }, [isChatAvailable]);
  return null;
};

export default OnboardingChatSupport;
