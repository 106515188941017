import { MetricSchema2 } from 'scalexp/utils/metrics/metricSchema2';

import { HSL } from '../../../../features/report/rows/ReportRowCell';

export type Row = EmptyRow | MetricRow;

export type EmptyRow = CommonRowProperties & {
  type: 'EMPTY';
};

export type MetricRow = CommonRowProperties & {
  type: 'metric';
  metric: MetricSchema2;
};

export type CommonRowProperties = {
  id: string;
  name?: string;
  show_groups?: boolean;
} & StyleRowProperties;

export type StyleRowProperties = {
  bold: boolean;
  color?: HSL;
  border_top?: BorderStyle;
  border_bottom?: BorderStyle;
};
export type BorderStyle = 'SOLID' | 'DOUBLE' | 'NONE';

export enum BorderStyleEnum {
  NONE = 'none',
  SOLID = 'solid',
  DOUBLE = 'double',
  SUBTLE = 'subtle',
}

export enum RowHeight {
  SMALL = 'small',
}
