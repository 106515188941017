import React from 'react';
import { MetricSchema2Window } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import Typography from '../../../../components/atoms/Typography';
import Row from '../../../../components/layout/Row';
import Select from '../../../../components/molecules/Select';
import { windowOperatorOptions, windowMonthsOptions, windowMonthsOptionsAfter } from './helpers';

interface ReportEditorUpdateWindowProps {
  window: MetricSchema2Window | undefined;
  handleUpdateWindow: (window: MetricSchema2Window | undefined) => void;
}

const StyledContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.sizing(2)};
  padding: ${({ theme }) => theme.sizing(2)};
`;

const ReportEditorUpdateWindow: React.FC<React.PropsWithChildren<ReportEditorUpdateWindowProps>> = ({
  window,
  handleUpdateWindow,
}) => {
  const before = window?.before ?? 0;
  const after = window?.after ?? 0;
  // value period === if we dont have a window or have a window and before === -after
  const isValuePeriod = before === -after || !window;
  const operator = isValuePeriod ? '' : window.operator;

  const handleOperatorChange = (updateOperator: 'add' | 'average' | 'change' | '') => {
    if (!updateOperator) {
      handleUpdateWindow(undefined);
      return;
    }

    handleUpdateWindow({
      operator: updateOperator,
      after,
      before: isValuePeriod ? before + 1 : before,
    });
  };

  const handleBeforeChange = (updatedBefore: number) => {
    const updatedAfter = after + updatedBefore < 0 ? -(updatedBefore - 1) : after;
    if (updatedBefore === 0 && updatedAfter === 0) {
      handleUpdateWindow(undefined);
      return;
    }

    handleUpdateWindow({
      ...window,
      operator: operator || 'add',
      before: updatedBefore,
      after: updatedAfter,
    });
  };

  const handleAfterChange = (updatedAfter: number) => {
    const updatedBefore = before;
    if (updatedBefore === 0 && updatedAfter === 0) {
      handleUpdateWindow(undefined);
      return;
    }

    handleUpdateWindow({
      ...window,
      operator: operator || 'add',
      before: updatedBefore,
      after: updatedAfter,
    });
  };

  return (
    <Row>
      <StyledContainer onMouseDown={e => e.stopPropagation()}>
        <Select
          width="100px"
          data={windowOperatorOptions}
          value={operator}
          onChange={handleOperatorChange}
          selectPlaceholder="Choose operator"
          zIndex="highest"
        />
        {operator ? (
          <>
            <Select
              width="150px"
              data={windowMonthsOptions}
              value={before}
              onChange={handleBeforeChange}
              zIndex="highest"
            />
            <Typography>to</Typography>
            <Select
              width="150px"
              data={windowMonthsOptionsAfter.filter(item => item.value + before > 0)}
              value={after}
              onChange={handleAfterChange}
              zIndex="highest"
            />
          </>
        ) : (
          <Select
            width="150px"
            data={windowMonthsOptionsAfter}
            value={after}
            onChange={value => {
              if (value === 0) {
                handleUpdateWindow(undefined);
                return;
              }

              handleUpdateWindow({
                operator: 'add',
                before: -value,
                after: value,
              });
            }}
            zIndex="highest"
          />
        )}
      </StyledContainer>
    </Row>
  );
};

export default ReportEditorUpdateWindow;
