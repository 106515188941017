import { Organisation } from 'scalexp/service';
import { getAccountDisplayName } from 'scalexp/store/state/accounts/hooks';
import { Account, AccountsVS } from 'scalexp/store/state/accounts/types';

export type AccountEntries = [accountId: string | number, accountInfo: any][];

export function sortAccountsEntries(entries: AccountEntries, accountsVS: AccountsVS, organisation: Organisation) {
  return entries.sort(([firstId], [secondId]) => {
    const firstAccount = accountsVS.value?.[Number(firstId)]!;
    const secondAccount = accountsVS.value?.[Number(secondId)]!;
    const firstAccountName = getAccountDisplayName(firstAccount, organisation);
    const secondAccountName = getAccountDisplayName(secondAccount, organisation);

    if (firstAccount.code && secondAccount.code) {
      return parseInt(firstAccount.code) - parseInt(secondAccount.code);
    } else if (firstAccount.code) {
      return -1;
    } else if (secondAccount.code) {
      return 1;
    }

    return firstAccountName.localeCompare(secondAccountName);
  });
}

export function sortAccounts(accounts: Account[], organisation: Organisation) {
  return accounts.sort((firstAccount, secondAccount) => {
    const firstAccountName = getAccountDisplayName(firstAccount, organisation);
    const secondAccountName = getAccountDisplayName(secondAccount, organisation);

    if (firstAccount.code && secondAccount.code) {
      return parseInt(firstAccount.code) - parseInt(secondAccount.code);
    } else if (firstAccount.code) {
      return -1;
    } else if (secondAccount.code) {
      return 1;
    }

    return firstAccountName.localeCompare(secondAccountName);
  });
}
