// NOTE: these styles were me just quickly turning the existing sidebar into
// something not insane and not relying on the various stylesheets
import styled from 'styled-components';

export const PageFilterTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #666666;
`;

export const PageName = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.tiny};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  line-height: ${({ theme }) => theme.font.lineheight.tiny};
  color: ${({ theme }) => theme.palette.midnight};
  text-transform: uppercase;
`;
export const SidebarSectionHeader = styled.div`
  color: #355570;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  text-transform: capitalize;
  font-size: 16px;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  height: calc(100vh - 60px);
  overflow-x: hidden;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

export const StyledRelativeContent = styled(Content)`
  position: relative;
`;
