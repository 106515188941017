import produce from 'immer';

import { ORGANISATIONS_CHARTS_LOAD, ChartsActions } from './actions';
import { OrganisationCharts, Charts } from './types';

const charts = (prevState: OrganisationCharts = {}, action: ChartsActions): OrganisationCharts => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_CHARTS_LOAD:
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload.reduce((acc, chart) => {
                acc[chart.chart_config_id] = chart;
                return acc;
              }, {} as Charts),
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      default:
        return state;
    }
  });
};

export default charts;
