import React from 'react';

import { Role } from '../../../service/sharing';

export interface Sector {
  id: number;
  name: string;
}
export interface User {
  user_id: number;
  username: string;
  email?: string;
  first_name?: string;
  job_title?: string;
  phone_number?: string;
  last_name?: string;
  current_organisation_id: number;
  current_organisation_sector?: Sector;
  current_organisation_logo?: string;
  current_organisation_auth0_id?: string;
  available_organisations?: Array<
    Array<{
      short: string | null;
      name: string;
      id: number;
      consolidated: boolean;
      child: boolean;
      organisation_id: number;
      auth0_id: string;
      consolidation_type: 'SINGLE' | 'SUBSIDIARY' | 'PARENT';
    }>
  >;
  // NOTE: used as a simple feature flag currently. Optional just so we don't
  // need to update user objects everywhere
  is_staff?: boolean;
  role?: Role;
  date_format?: 'DMY' | 'MDY' | 'YMD' | null;
}

const AnonymousUser: User = {
  user_id: -1,
  username: 'anonymous',
  current_organisation_id: -1,
  is_staff: false,
};

interface UserContext {
  user: User | null;
}

const userContext = React.createContext<UserContext>({ user: null }); // Create a context object

export { userContext, AnonymousUser };
