import { Organisation } from '../../store/state/organisations/types';
import { CalculationRequestEntry } from './newCalculations';

type Number = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
type Month = '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12';

type CalculationYear = `20${Number}${Number}`;
type CalculationQuarter = `${CalculationYear}-Q${'1' | '2' | '3' | '4'}`;
export type CalculationMonth = `${CalculationYear}-${Month}`;
type CalculationYearToDate = `${CalculationYear}-YTD`;
type CalculationYearRemaining = `${CalculationYear}-YR`;

export type DateKeys = (
  | CalculationMonth
  | CalculationQuarter
  | CalculationYear
  | CalculationYearToDate
  | CalculationYearRemaining
)[];

export const dateToRequest = (
  dateKeys: DateKeys,
  budgetIds?: number[],
  currencyCode: Organisation['default_currency_code'] = 'GBP',
): CalculationRequestEntry[] => {
  let result: any;

  result = [];

  for (const budgetId of [null, ...(budgetIds?.filter(budgetId => budgetId) || [])]) {
    for (const dateKey of dateKeys) {
      if (dateKey.length === 4) {
        result.push({
          source: budgetId ? 'budget' : 'actual',
          date: {
            type: 'year',
            year: Number(dateKey.slice(0, 4)),
          },
          currency: currencyCode,
          budget_id: budgetId,
        });
      } else if (dateKey.endsWith('-YTD')) {
        result.push({
          source: budgetId ? 'budget' : 'actual',
          date: {
            type: 'ytd',
            year: Number(dateKey.slice(0, 4)),
          },
          currency: currencyCode,
          budget_id: budgetId,
        });
      } else if (dateKey.endsWith('-YR')) {
        result.push({
          source: budgetId ? 'budget' : 'actual',
          date: {
            type: 'yr',
            year: Number(dateKey.slice(0, 4)),
          },
          currency: currencyCode,
          budget_id: budgetId,
        });
      } else if (dateKey.includes('-QTD')) {
        result.push({
          source: budgetId ? 'budget' : 'actual',
          date: {
            type: 'qtd',
            year: Number(dateKey.slice(0, 4)),
            quarter: Number(dateKey.slice(8, 9)),
          },
          currency: currencyCode,
          budget_id: budgetId,
        });
      } else if (dateKey.includes('-QR')) {
        result.push({
          source: budgetId ? 'budget' : 'actual',
          date: {
            type: 'qr',
            year: Number(dateKey.slice(0, 4)),
            quarter: Number(dateKey.slice(7, 8)),
          },
          currency: currencyCode,
          budget_id: budgetId,
        });
      } else if (dateKey.includes('-Q')) {
        result.push({
          source: budgetId ? 'budget' : 'actual',
          date: {
            type: 'quarter',
            year: Number(dateKey.slice(0, 4)),
            quarter: Number(dateKey.slice(6, 7)),
          },
          currency: currencyCode,
          budget_id: budgetId,
        });
      } else {
        result.push({
          source: budgetId ? 'budget' : 'actual',
          date: {
            type: 'month',
            year: Number(dateKey.slice(0, 4)),
            month: Number(dateKey.slice(5, 7)),
          },
          currency: currencyCode,
          budget_id: budgetId,
        });
      }
    }
  }

  return result;
};
