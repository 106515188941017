import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectDeferredAccountMappings } from './selectors';
import { DeferredAccountMappings } from './types';

export const ORGANISATIONS_DEFERRED_ACCOUNT_MAPPINGS_LOAD = '[deferredAccountMappings] LOAD';
export const ORGANISATIONS_DEFERRED_ACCOUNT_MAPPING_UPDATE = '[deferredAccountMappings] UPDATE';

type DeferredAccountMappingsResponse = {
  mappings: { id: number; from_account_id: number; to_account_id: number }[];
};
type UpdateDeferredAccountMappingResponse = {
  mappings: DeferredAccountMappings;
};

type DeferredAccountMappingsActionLoad = PromiseAction<
  typeof ORGANISATIONS_DEFERRED_ACCOUNT_MAPPINGS_LOAD,
  DeferredAccountMappings,
  { organisationId: number }
>;

type DeferredAccountMappingActionUpdate = PromiseAction<
  typeof ORGANISATIONS_DEFERRED_ACCOUNT_MAPPING_UPDATE,
  UpdateDeferredAccountMappingActionResult,
  { organisationId: number; mappings: DeferredAccountMappings }
>;

export type DeferredAccountMappingsActions = DeferredAccountMappingsActionLoad | DeferredAccountMappingActionUpdate;

export type UpdateDeferredAccountMappingActionResult =
  | {
      success: true;
      mappings: DeferredAccountMappings;
    }
  | {
      success: false;
      status: number;
      body?: { message?: string };
    };

export const loadDeferredAccountMappingsAction = (
  organisationId: number,
): ThunkAction<DeferredAccountMappingsActions> => async (
  dispatch: ThunkDispatch<DeferredAccountMappingsActions>,
  getState,
) => {
  const state = getState();
  const deferredAccountMappings = selectDeferredAccountMappings(state, organisationId);

  if (deferredAccountMappings?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_DEFERRED_ACCOUNT_MAPPINGS_LOAD,
        () =>
          http<DeferredAccountMappings | undefined, DeferredAccountMappingsResponse>(
            `/api/v1/organisations/${organisationId}/invoices/deferred/mappings`,
            {},
            response =>
              Object.fromEntries(response.mappings.map(mapping => [mapping.from_account_id, mapping.to_account_id])),
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const updateDeferredAccountMappingsAction = (
  organisationId: number,
  newMappings: DeferredAccountMappings,
): ThunkAction<
  DeferredAccountMappingActionUpdate,
  Promise<{ success: true; mappings: DeferredAccountMappings } | { success: false; message?: string }>
> => async (dispatch: ThunkDispatch<DeferredAccountMappingsActions>, getState) => {
  const state = getState();
  const deferredAccountMappings = selectDeferredAccountMappings(state, organisationId);

  if (deferredAccountMappings?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const res = await dispatch(
      promiseAction(
        ORGANISATIONS_DEFERRED_ACCOUNT_MAPPING_UPDATE,
        async () =>
          http<UpdateDeferredAccountMappingActionResult, UpdateDeferredAccountMappingResponse>(
            `/api/v1/organisations/${organisationId}/invoices/deferred/mappings`,
            {
              method: 'POST',
              body: newMappings,
            },
            response => ({
              success: true,
              mappings: Object.fromEntries(
                Object.entries(response.mappings).map(([key, value]) => [Number(key), Number(value)]),
              ),
            }),
          ),
        {
          organisationId,
        },
      ),
    );

    if (res.success) {
      return {
        success: true,
        mappings: res.mappings,
      };
    }

    return {
      success: false,
      message: res?.body?.message,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};
