export const formatConnectionName = (connectionName: string) => {
  if (!connectionName) {
    return '';
  }

  if (connectionName === 'quickbooks') {
    return 'QuickBooks';
  }

  return connectionName[0].toUpperCase() + connectionName.slice(1);
};
