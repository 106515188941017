import React, { useEffect } from 'react';

import Typography from '../../atoms/Typography';
import Select, { SelectSize } from '../Select';
import { CurrencyCode, getCurrencyName, isSupportedCurrency, SUPPORTED_CURRENCIES } from './currencySupport';
import useCurrencySelection from './useCurrencySelection';

export interface CurrencySelectProps {
  queryParamKey?: string;
  disabled?: boolean;
  replaceIncorrectValues?: boolean;
  fallbackCurrency?: CurrencyCode;
  width?: string;
  size?: SelectSize;
  alignment?: 'Start' | 'End';
}

/**
 * Currency code selector, tracks state in query parameters
 */
const CurrencySelect: React.FC<React.PropsWithChildren<CurrencySelectProps>> = ({
  queryParamKey = 'currencyCode',
  replaceIncorrectValues = true,
  fallbackCurrency = SUPPORTED_CURRENCIES[0],
  width = '100px',
  size = 'small',
  alignment,
  disabled = false,
}) => {
  const [currencyCode, setCurrencyCode] = useCurrencySelection(queryParamKey);

  useEffect(() => {
    if (!replaceIncorrectValues) {
      return;
    }

    // Ensure the currencyCode in the query params is valid
    if (!currencyCode || !isSupportedCurrency(currencyCode)) {
      if (!isSupportedCurrency(fallbackCurrency)) {
        console.log(
          `Warning! fallback currency "${fallbackCurrency}"is not supported, would cause infinite loop, so not updating`,
        );
        return;
      }
      setCurrencyCode(fallbackCurrency);
    }
  }, [currencyCode, fallbackCurrency, replaceIncorrectValues, setCurrencyCode]);

  return (
    <Select
      width={width}
      disabled={disabled}
      value={currencyCode as CurrencyCode}
      alignment={alignment}
      data-testid="currency-select"
      aria-label="Currency"
      aria-labelledby="currency"
      selectWidth={'maxOptionWidth'}
      size={size}
      onChange={value => {
        setCurrencyCode(value, 'pushIn');
      }}
      renderValue={(value, _) => <Typography>{value}</Typography>}
      data={Object.keys(SUPPORTED_CURRENCIES)
        .sort()
        .map(currencyCode => ({
          label: `${currencyCode} - ${getCurrencyName(currencyCode)?.replaceAll(`(${currencyCode})`, '')}`,
          value: currencyCode,
        }))}
    />
  );
};

export default CurrencySelect;
