import produce from 'immer';

import {
  ORGANISATIONS_COLUMNS_PRESETS_LOAD,
  ORGANISATIONS_COLUMNS_PRESETS_CREATE,
  ORGANISATIONS_COLUMNS_PRESETS_UPDATE,
  ORGANISATIONS_COLUMNS_PRESETS_DELETE,
  ColumnsPresetsActions,
} from './actions';
import { OrganisationColumnsPresets, ColumnsPresets } from './types';

const columnsPresets = (
  prevState: OrganisationColumnsPresets = {},
  action: ColumnsPresetsActions,
): OrganisationColumnsPresets => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_COLUMNS_PRESETS_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload.reduce((acc, columnsPreset) => {
                acc[columnsPreset.id] = columnsPreset;
                return acc;
              }, {} as ColumnsPresets),
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      case ORGANISATIONS_COLUMNS_PRESETS_CREATE:
      case ORGANISATIONS_COLUMNS_PRESETS_UPDATE: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: {
                ...(state[organisationId]?.value || {}),
                [action.payload.id]: action.payload,
              },
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      case ORGANISATIONS_COLUMNS_PRESETS_DELETE: {
        const { organisationId, columnsPreset } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            delete state[organisationId].value?.[columnsPreset.id];
            state[organisationId].status = 'success';
            state[organisationId].error = undefined;
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      default:
        return state;
    }
  });
};

export default columnsPresets;
