import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'rsuite';
import useSWR from 'swr';
import { useQueryParam } from 'use-query-params';

import TextButton from '../../components/atoms/TextButton';
import Typography from '../../components/atoms/Typography';
import Aligner from '../../components/layout/Aligner';
import Column from '../../components/layout/Column';
import Row from '../../components/layout/Row';
import Spacer from '../../components/layout/Spacer';
import Select from '../../components/molecules/Select';
import fetcher from '../../utils/fetcher';

const SelectSource = () => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const [loading, setLoading] = useState(false);
  const { data: sources, isValidating } = useSWR<{
    organisation_name: string;
    source_options: {
      source_id: string;
      source_name: string;
    }[];
  }>(`/api/v1/sources/${organisationId}`);

  const [selectedSource, setSelectedSource] = useState<string | null>(null);
  const [redirect_to] = useQueryParam<string>('redirect_to');

  const saveSource = async () => {
    try {
      if (selectedSource) {
        setLoading(true);
        await fetcher(`/api/v1/sources/${organisationId}/xero/${selectedSource}`, {
          method: 'post',
        });
        setLoading(false);
        window.location.href = redirect_to;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  if (isValidating) {
    return <Loader />;
  }

  return (
    <Column width={'460px'} style={{ marginLeft: 'auto', marginRight: 'auto', paddingLeft: '-55px' }}>
      <Spacer margin={{ top: 8 }} />
      <Typography size="h6" weight="bold">
        Select Xero Organisation
      </Typography>
      <Typography size="small" color="secondary">
        You have authorised us to access to multiple Xero organisations.
        <br />
        Please confirm which Xero organisation you would like to connect to
      </Typography>
      <Column>
        <Row>
          <Typography size="tiny" color="secondary" weight={'bold'}>
            {sources!.organisation_name}
          </Typography>
          <Select
            width="250px"
            data={
              sources!.source_options.map(source => ({
                label: source.source_name,
                value: source.source_id,
              })) || []
            }
            value={selectedSource}
            onChange={setSelectedSource}
          />
        </Row>
        <Aligner hAlign="flex-start">
          <TextButton loading={loading} onClick={saveSource}>
            Proceed
          </TextButton>
        </Aligner>
      </Column>
    </Column>
  );
};

export default SelectSource;
