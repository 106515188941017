import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useActiveOrganisation from '../../../components/contexts/OrganisationContext/useActiveOrganisation';
import useActiveOrganisationId from '../../../components/contexts/OrganisationContext/useActiveOrganisationId';
import { NativeMetricId } from '../../../service/types/metricSchema';
import { ValueState } from '../../values';
import { AccountGroup } from '../accountGroups/types';
import { Store } from '../index';
import { Organisation } from '../organisations/types';
import {
  loadAccountsAction,
  acknowledgeAccounts,
  eliminateAccount,
  uneliminateAccount,
  createManualAccount,
  updateManualAccount,
  updateManualAccountEntry,
  deleteManualAccount,
} from './actions';
import {
  AccountsByTag,
  selectAccountsByTags,
  selectAccounts,
  AccountsByGroup,
  selectAccountsByGroup,
} from './selectors';
import { AccountsVS, Account, Accounts } from './types';

export const useAccounts = (organisationId: number): AccountsVS => {
  const accounts: AccountsVS | undefined = useSelector<Store, ReturnType<typeof selectAccounts>>(state =>
    selectAccounts(state, organisationId),
  );
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadAccountsAction(organisationId));
  }, [organisationId]);

  if (accounts === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...accounts,
    reload,
  };
};

export const useAccountById = (accountId?: Account['account_id']): ValueState<Account | undefined> => {
  const organisationId = useActiveOrganisationId();
  const accountsVS = useAccounts(organisationId);

  if (accountsVS.status !== 'success') {
    return accountsVS as ValueState<Account | undefined>;
  }

  return {
    ...accountsVS,
    value: accountId ? accountsVS.value[accountId] : undefined,
  };
};
export const getAccountDisplayName = (account: Account, organisation: Organisation) => {
  let result = account.name;
  if (account.code) {
    result = account.code + ' - ' + result;
  }
  const accountOrganisation =
    organisation.children.find(org => org.organisation_id === account.organisation_id) || organisation;

  if (accountOrganisation?.name_short) {
    result = accountOrganisation?.name_short + ': ' + result;
  }

  return result;
};
export const useAccountDisplayNameById = (accountId: Account['account_id']): ValueState<string | undefined> => {
  const organisation = useActiveOrganisation();
  const accountVS = useAccountById(accountId);

  if (accountVS.status !== 'success' || !accountVS.value) {
    return accountVS as ValueState<string | undefined>;
  }

  const account = accountVS.value;

  return {
    ...accountVS,
    value: getAccountDisplayName(account, organisation),
  };
};

export const useAccountsByTag = (organisationId: number): ValueState<AccountsByTag> => {
  const accounts: ValueState<AccountsByTag> | undefined = useSelector<Store, ReturnType<typeof selectAccountsByTags>>(
    state => selectAccountsByTags(state, organisationId),
  );
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadAccountsAction(organisationId));
  }, [organisationId]);

  if (accounts === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...accounts,
    reload,
  };
};

export const useAccountsInTag = (tag: string): ValueState<Account[]> => {
  const organisationId = useActiveOrganisationId();
  const accountsByTagVS = useAccountsByTag(organisationId);

  if (accountsByTagVS.status !== 'success') {
    return accountsByTagVS as ValueState<Account[]>;
  }

  return {
    ...accountsByTagVS,
    value: accountsByTagVS.value[tag] || [],
  };
};

export const useAccountsByGroup = (): ValueState<AccountsByGroup> => {
  const organisationId = useActiveOrganisationId();
  const accounts: ValueState<AccountsByGroup> | undefined = useSelector<
    Store,
    ReturnType<typeof selectAccountsByGroup>
  >(state => selectAccountsByGroup(state, organisationId));
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadAccountsAction(organisationId));
  }, [organisationId]);

  if (accounts === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...accounts,
    reload,
  };
};

export const useAccountsInGroup = (groupId: AccountGroup['id']): ValueState<Account[]> => {
  const groups = useAccountsByGroup();

  if (groups.status !== 'success') {
    return groups as ValueState<Account[]>;
  }

  return {
    ...groups,
    value: (groups.value[groupId] || []).sort((a, b) => Number(a.code || '0') - Number(b.code || '0')),
  };
};

export const useAcknowledgeAccounts = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(
    async (accountIds: Account['account_id'][]) => {
      dispatch(acknowledgeAccounts(organisationId, accountIds));
    },
    [organisationId],
  );
};

export const useNotAcknowledgedAccounts = (organisationId: number): ValueState<Account[]> => {
  const accounts = useAccounts(organisationId);

  if (accounts.status !== 'success') {
    return accounts as ValueState<Account[]>;
  }

  return {
    ...accounts,
    value: Object.values(accounts.value).filter(account => account.acknowledged_at === null),
  };
};

export const useNotAcknowledgedAccountsCount = (organisationId: number): number => {
  const accounts = useAccounts(organisationId);

  if (accounts.status === 'error') {
    return -1;
  }
  if (accounts.status !== 'success') {
    return 0;
  }

  return Object.values(accounts.value).filter(account => account.acknowledged_at === null).length;
};

// eliminations
export const useEliminateAccount = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(
    async (accountId: Account['account_id']): Promise<void> => {
      await dispatch(eliminateAccount(organisationId, accountId));
    },
    [organisationId],
  );
};

export const useUneliminateAccount = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(
    async (accountId: Account['account_id']): Promise<void> => {
      await dispatch(uneliminateAccount(organisationId, accountId));
    },
    [organisationId],
  );
};

// Manual account actions
export const useManualAccountCreate = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(async (tagName: string, accountName: string): Promise<void> => {
    await dispatch(createManualAccount(organisationId, tagName, accountName));
  }, []);
};

export const useManualAccountUpdate = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(async (accountId: Account['account_id'], name: string): Promise<void> => {
    await dispatch(updateManualAccount(organisationId, accountId, name));
  }, []);
};

export const useManualAccountUpdateEntry = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(async (accountId: Account['account_id'], dateFor: string, value: number | null): Promise<void> => {
    await dispatch(updateManualAccountEntry(organisationId, accountId, dateFor, value));
  }, []);
};

export const useManualAccountDelete = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(async (accountId: Account['account_id']): Promise<void> => {
    await dispatch(deleteManualAccount(organisationId, accountId));
  }, []);
};

export const useManualAccountsByTag = (organisationId: number): { [tag: NativeMetricId]: Account[] } => {
  const accountsVS = useAccounts(organisationId);

  return useMemo(() => {
    if (accountsVS.status !== 'success') {
      return {};
    }
    const accounts: Accounts = accountsVS.value;

    const manualAccounts: Account[] = Object.values(accounts)
      .filter(account => account.source_name === 'manual')
      .map(cloneDeep);

    return manualAccounts.reduce((acc, account) => {
      const tag = account.tag;
      if (!acc[tag]) {
        acc[tag] = [];
      }
      acc[tag].push(account);
      return acc;
    }, {} as { [tag: NativeMetricId]: Account[] });
  }, [accountsVS]);
};
