import produce from 'immer';

import {
  ACTION_REPORTS_CREATE,
  ACTION_REPORTS_DELETE,
  ACTION_REPORTS_LOAD,
  ACTION_REPORTS_UPDATE,
  ReportsActions,
} from './actions';
import { OrganisationReports, Reports } from './types';

const reports = (prevState: OrganisationReports = {}, action: ReportsActions): OrganisationReports => {
  return produce(prevState, state => {
    switch (action.type) {
      case ACTION_REPORTS_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload.reduce((acc, report) => {
                acc[parseInt(report.id)] = report;
                return acc;
              }, {} as Reports),
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return;
        }
      }
      case ACTION_REPORTS_DELETE: {
        const { organisationId, reportId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            delete state[organisationId].value?.[reportId];
            state[organisationId].status = 'success';
            state[organisationId].error = undefined;
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }

      case ACTION_REPORTS_UPDATE:
      case ACTION_REPORTS_CREATE: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: {
                ...(state[organisationId]?.value || {}),
                [action.payload.id]: action.payload,
              },
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      default:
        return state;
    }
  });
};

export default reports;
