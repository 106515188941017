import React, { useRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import TextButton from '../../atoms/TextButton';

export interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  image_url?: string;
  onInputChange: (File: File) => void;
  name: string;
  icon?: string;
  variant?: 'primary' | 'secondary' | 'outlined' | 'danger';
  customSize?: 'tiny' | 'small' | 'medium';
  loading?: boolean;
}

const StyledInput = styled.input`
  display: none;
`;

const FileInput: React.FC<React.PropsWithChildren<FileInputProps>> = ({
  onInputChange,
  icon,
  children,
  variant = 'secondary',
  customSize = 'small',
  loading = false,
  accept,
  ...other
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      onInputChange(e.target.files?.[0]);
      e.target.value = '';
    }
  };
  return (
    <div>
      <TextButton
        variant={variant}
        size={customSize}
        loading={loading}
        loadingText="Uploading"
        onClick={() => {
          inputRef.current?.click();
        }}
        iconLeft={icon}
      >
        {children}
      </TextButton>
      <StyledInput
        ref={inputRef}
        onChange={handleChange}
        type="file"
        accept={accept ?? 'image/png, image/jpeg, image/svg+xml'}
        {...other}
      />
    </div>
  );
};

export default FileInput;
