const generateMonths = (numberOfMonths: number, negative: boolean, isAfter: boolean, includeZero: boolean) => {
  return new Array(numberOfMonths).fill(null).map((_, index) => {
    let number = includeZero ? index : index + 1;
    const labelBefore = number ? ` ${number} month${number === 1 ? '' : 's'} ago` : 'Current month';
    const labelAfter = number ? ` ${number} month${number === 1 ? '' : 's'} after` : 'Current month';
    return {
      value: negative ? -number : number,
      label: isAfter ? labelAfter : labelBefore,
    };
  });
};

export const windowMonthsOptions = [
  ...generateMonths(24, true, true, false).reverse(),
  ...generateMonths(25, false, false, true),
];

export const windowMonthsOptionsAfter = [
  ...generateMonths(24, false, true, true).reverse(),
  ...generateMonths(24, true, false, false),
];

export const windowOperatorOptions: { value: 'add' | 'average' | 'change' | ''; label: string }[] = [
  {
    value: '',
    label: 'Value',
  },
  {
    value: 'add',
    label: 'Add',
  },
  {
    value: 'average',
    label: 'Average',
  },
  {
    value: 'change',
    label: 'Change',
  },
];
