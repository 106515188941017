import { useEffect, useRef, MutableRefObject } from 'react';

const useOutsideAlerter = (ref: MutableRefObject<HTMLElement | null>, onClickOutside: VoidFunction): void => {
  useEffect(() => {
    function handleClickOutside(event: globalThis.MouseEvent) {
      if (!(event.target instanceof Element)) {
        return;
      }

      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, onClickOutside]);
};

const useFirstRender = (): boolean => {
  const isFirstRenderRef = useRef(true);

  useEffect(() => {
    // setting up isFirstRenderRef to false after first render
    isFirstRenderRef.current = false;
  }, []);

  return isFirstRenderRef.current;
};

export { useOutsideAlerter, useFirstRender };
