import produce from 'immer';

import { CustomersActions, ORGANISATIONS_CUSTOMERS_MERGE } from '../customers/actions';
import { ORGANISATIONS_DEFERRED_INVOICES_LOAD, DeferredInvoicesActions } from './actions';
import { DeferredInvoices, OrganisationDeferredInvoices } from './types';

const deferredInvoices = (
  prevState: OrganisationDeferredInvoices = {},
  action: DeferredInvoicesActions | CustomersActions,
): OrganisationDeferredInvoices => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_DEFERRED_INVOICES_LOAD:
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: Object.entries(action.payload).reduce((acc, [customerId, data]) => {
                acc[customerId] = data;
                return acc;
              }, {} as DeferredInvoices),
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      case ORGANISATIONS_CUSTOMERS_MERGE: {
        const { organisationId, customer } = action.params;
        switch (action.status) {
          case 'pending':
          case 'error':
          default:
            return;

          case 'success':
            if (!state?.[organisationId]?.value) {
              return;
            }
            delete state[organisationId].value![customer];

            return;
        }
      }
      default:
        return state;
    }
  });
};

export default deferredInvoices;
