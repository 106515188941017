import produce from 'immer';

import { ORGANISATIONS_TRACKING_CATEGORIES_LOAD, TrackingCategoriesActions } from './actions';
import { TrackingCategories, OrganisationTrackingCategories } from './types';

const trackingCategories = (
  prevState: OrganisationTrackingCategories = {},
  action: TrackingCategoriesActions,
): OrganisationTrackingCategories => {
  return produce(prevState, state => {
    const organisationId = action?.params?.organisationId;
    switch (action.type) {
      case ORGANISATIONS_TRACKING_CATEGORIES_LOAD:
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              value: action.payload.reduce((acc, trackingCategory) => {
                acc[trackingCategory.id] = trackingCategory;
                return acc;
              }, {} as TrackingCategories),
              error: undefined,
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              value: undefined,
              error: action.error,
            };
            return;

          default:
            return state;
        }
      default:
        return state;
    }
  });
};

export default trackingCategories;
