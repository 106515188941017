import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import { enableMapSet } from 'immer';
import React, { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'rsuite5/dist/rsuite.min.css';
import { SWRConfig } from 'swr';

import App from './App';
import ReduxStore from './ReduxStore';
import { PosthogProvider } from './components/contexts/PosthogContext';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_REDIRECT_URI, AUTH0_SCOPE } from './config';
import './index.css';
import fetcher from './utils/fetcher';

const history = createBrowserHistory();

enableMapSet();

// This is a temporary work around as we need to be able to set dedupingInterval in the testing suite
// So for now we don't use it in the App component
const ProviderStack: React.FC<React.PropsWithChildren<{ children: ReactNode }>> = ({ children }) => {
  return (
    <SWRConfig
      value={{
        fetcher,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }}
    >
      <ReduxStore>{children}</ReduxStore>
    </SWRConfig>
  );
};

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={AUTH0_REDIRECT_URI}
    cacheLocation={'localstorage'}
    useRefreshTokens={true}
    audience={AUTH0_AUDIENCE}
    advancedOptions={{
      defaultScope: AUTH0_SCOPE,
    }}
    onRedirectCallback={appState => {
      history.replace({
        pathname: appState?.redirectUri ? appState.redirectUri : window.location.pathname,
        search: '',
      });
    }}
  >
    <PosthogProvider>
      <BrowserRouter>
        <ProviderStack>
          <App />
        </ProviderStack>
      </BrowserRouter>
    </PosthogProvider>
  </Auth0Provider>,
);
