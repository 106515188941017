import React, { useState, useEffect, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { usePortalPositioning } from '../DropDown/usePortalPositioning';
import { useOutsideAlerter } from '../MultiSelect/hooks';

export type TooltipPlacement = 'top' | 'bottom' | 'left' | 'right' | 'leftStart' | 'leftEnd';

export interface TooltipProps {
  content: ReactNode | string;
  placement?: TooltipPlacement;
  trigger?: 'click' | 'hover';
  color?: 'black' | 'white';
  display?: 'flex' | 'inline-flex';
  noPadding?: boolean;
  closeTooltipOptions?: { closeTooltip: boolean; setCloseTooltip: (closeTooltip: boolean) => void };
}

const StyledContainer = styled.div<{ display: 'flex' | 'inline-flex' }>`
  position: relative;
  display: ${({ display }) => display};
  align-items: center;
`;

const StyledTooltip = styled.div<{
  placement: TooltipPlacement;
  container: DOMRect;
  tooltip: { width: number; height: number };
  color?: 'black' | 'white';
  noPadding?: boolean;
}>`
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: ${({ theme, noPadding }) => (noPadding ? 'unset' : `${theme.spacing(1)} ${theme.spacing(2)}`)};
  background-color: ${({ theme, color }) => (color === 'white' ? theme.palette.white : theme.palette.midnight)};
  color: ${({ theme, color }) => (color === 'white' ? theme.palette.midnight : theme.palette.white)};
  border: ${({ theme }) => `1px solid ${theme.palette.backgroundGrey}`};
  border-radius: ${({ theme }) => theme.spacing(1)};
  font-size: ${({ theme }) => theme.font.size.tiny};
  z-index: ${({ theme }) => theme.zIndex.highest};

  &::after {
    content: '';
    position: absolute;
    border-style: solid;
    color: ${({ theme, color }) => (color === 'white' ? theme.palette.white : theme.palette.midnight)};
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  ${({
    theme,
    placement,
    container: { top, left, height, width },
    tooltip: { width: tooltipWidth, height: tooltipHeight },
    color,
  }) => {
    switch (placement) {
      case 'top':
        return `
        top: calc(${top}px - ${tooltipHeight}px + ${window.scrollY}px - 7px);
        left: calc(${left}px - (${tooltipWidth}px / 2) + (${width}px / 2) + ${window.scrollX}px);

        &::after {
          bottom: -6px;
          left: calc(50% - 6px);
          border-top-color: ${color === 'white' ? theme.palette.white : theme.palette.midnight};
          border-width: 6px 6px 0;
        }
      `;
      case 'bottom':
        return `
        top: calc(${top}px + ${height}px + ${window.scrollY}px + 7px);
        left: calc(${left}px - (${tooltipWidth}px / 2) + (${width}px / 2) + ${window.scrollX}px);

        &::after {
          top: -6px;
          left: calc(50% - 6px);
          border-bottom-color: ${color === 'white' ? theme.palette.white : theme.palette.midnight};
          border-width: 0 6px 6px;
        }
      `;
      case 'left':
        return `
        top: calc(${top}px - (${tooltipHeight}px / 2) + (${height}px / 2) + ${window.scrollY}px);
        left: calc(${left}px - ${tooltipWidth}px + ${window.scrollX}px - 7px);

        &::after {
          right: -6px;
          top: calc(50% - 6px);
          border-left-color: ${color === 'white' ? theme.palette.white : theme.palette.midnight};
          border-width: 6px 0 6px 6px;
        }

      `;
      case 'right':
        return `
        top: calc(${top}px - (${tooltipHeight}px / 2) + (${height}px / 2) + ${window.scrollY}px);
        left: calc(${left}px + ${width}px + ${window.scrollX}px + 7px);

        &::after {
          left: -6px;
          top: calc(50% - 6px);
          border-right-color: ${color === 'white' ? theme.palette.white : theme.palette.midnight};
          border-width: 6px 6px 6px 0;
        }
      `;
      case 'leftStart':
        return `
        top: calc(${top}px - ${height / 2}px + ${window.scrollY}px);
        left: calc(${left}px - ${tooltipWidth}px + ${window.scrollX}px - 7px);

        &::after {
          top: 6px;
          right: -6px;
          border-left-color: ${color === 'white' ? theme.palette.white : theme.palette.midnight};
          border-width: 6px 0 6px 6px;
        }
      `;
      case 'leftEnd':
        return `
        top: calc(${top}px - ${tooltipHeight}px + ${height}px + ${window.scrollY}px + 3px);
        left: calc(${left}px - ${tooltipWidth}px + ${window.scrollX}px - 7px);

        &::after {
          right: -6px;
          bottom: 3px;
          border-left-color: ${color === 'white' ? theme.palette.white : theme.palette.midnight};
          border-width: 6px 0 6px 6px;
        }
      `;
    }
  }};
`;

const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  content,
  placement = 'top',
  trigger = 'hover',
  children,
  display = 'flex',
  noPadding = false,
  closeTooltipOptions,
  ...rest
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const {
    elementRef: tooltipRef,
    containerCallbackRef,
    containerRect,
    elementCallbackRef: tooltipCallbackRef,
    elementDimensions: tooltipDimensions,
  } = usePortalPositioning(showTooltip);

  useOutsideAlerter(tooltipRef, () => {
    setShowTooltip(false);
  });

  // a hack to close tooltip from outside
  useEffect(() => {
    if (closeTooltipOptions?.closeTooltip) {
      setShowTooltip(false);
      closeTooltipOptions.setCloseTooltip(false);
    }
  }, [closeTooltipOptions?.closeTooltip]);

  const handleContainerClick: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setShowTooltip(!showTooltip);
  };

  const handleHover = () => {
    setShowTooltip(true);
  };

  const handleBlur = () => {
    setShowTooltip(false);
  };

  return (
    <StyledContainer
      display={display}
      ref={containerCallbackRef}
      onClick={trigger === 'click' ? handleContainerClick : undefined}
      onMouseEnter={trigger === 'hover' ? handleHover : undefined}
      onMouseLeave={trigger === 'hover' ? handleBlur : undefined}
    >
      {showTooltip &&
        content &&
        createPortal(
          <StyledTooltip
            ref={tooltipCallbackRef}
            placement={placement}
            container={containerRect}
            tooltip={tooltipDimensions}
            onClick={e => e.stopPropagation()}
            noPadding={noPadding}
            {...rest}
          >
            {content}
          </StyledTooltip>,
          document.body,
        )}
      {children}
    </StyledContainer>
  );
};
export default Tooltip;
