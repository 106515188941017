import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFeatureFlag } from 'scalexp/service/feature-flags';

import Icon from '../../../components/atoms/Icon';
import Loader from '../../../components/atoms/Loader';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import { useOnboardingJourneys } from '../../../components/contexts/OnboardingJourneys';
import Row from '../../../components/layout/Row';
import { ProcessingJob } from '../../connections/sync-context/types';

type Props = {
  job: ProcessingJob;
  onHide: () => void;
  getTitles: (
    reloadingIn: number,
  ) => {
    runningTitle: string;
    completedTitle: string;
    errorTitle: string;
  };
};

const DataProcessingSyncStatusBar: React.FC<React.PropsWithChildren<Props>> = ({
  job: { name, status, task },
  getTitles,
  onHide,
}) => {
  const location = useLocation();
  const { journeys } = useOnboardingJourneys();

  const isDealsInProgressEnabled = useFeatureFlag('deals-in-progress');

  const [reloadingIn, setReloadingIn] = useState(10);

  const onBoarding = location.pathname.includes('onboarding');
  const crmIntegrationOnboarding = journeys?.crm_integration === true;
  const atConnectionConfigPage = location.pathname.includes(`oauth/${name}`);

  // we refresh automatically if the user is not in the onboarding
  // and (the crm integration onboarding is done or the user is not in the connection config page)
  const autoRefresh = !onBoarding && (crmIntegrationOnboarding || !atConnectionConfigPage);

  const handleFinish = () => {
    if (status === 'Completed') {
      onHide();

      if (
        isDealsInProgressEnabled &&
        ['PROCESSING_CRM_CUSTOM_MAPPING_DEAL_VALUES', 'PROCESSING_CRM_CLOSED_ON_DATE'].includes(task)
      ) {
        window.location.href = '/analysis/deals-in-progress';
        return;
      }

      if (autoRefresh) {
        window.location.reload();
      }
    }

    onHide();
  };

  useEffect(() => {
    if (status === 'Completed') {
      setInterval(() => setReloadingIn(prev => prev - 1), 1000);
    }
  }, [status]);

  useEffect(() => {
    if (reloadingIn < 1) {
      handleFinish();
    }
  }, [reloadingIn]);

  const { runningTitle, completedTitle, errorTitle } = getTitles(reloadingIn);

  return (
    <>
      <Row vAlign="center">
        {status === 'Running' && <Loader />}
        {status === 'Completed' && <Icon name="check_circle" outlined marginRight={0} />}
        {status === 'Error' && <Icon name="cancel" marginRight={0} />}
        <Typography size="tiny">
          {status === 'Running' ? runningTitle : status === 'Completed' ? completedTitle : errorTitle}
        </Typography>
      </Row>

      {autoRefresh && status !== 'Running' && (
        <TextButton variant="secondary" onClick={handleFinish}>
          {status === 'Completed' ? 'See new data' : 'Close'}
        </TextButton>
      )}
    </>
  );
};

export default DataProcessingSyncStatusBar;
