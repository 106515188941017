import dayjs from 'dayjs';
import React, { useEffect } from 'react';

import { MonthPicker } from '../MonthPicker';
import { DATE_FORMAT, isSupportedDate } from './dateSupport';
import useDateSelection from './useDateSelection';

export type DateSelectProps = {
  width?: string;
  queryParamKey?: string;
  fallbackDate?: string;
  start?: Date;
  end?: Date;
  disabled?: boolean;
  sortOrder?: 'ASCENDING' | 'DESCENDING';
  monthOnly?: boolean;
  onChange?: (value: string) => void;
  dateFormat?: string;
};

/**
 * Date selector, tracks state in query parameters
 */
const DateSelect: React.FC<React.PropsWithChildren<DateSelectProps>> = ({
  width = '105px',
  queryParamKey = 'date',
  dateFormat = DATE_FORMAT,
  fallbackDate = dayjs('2019-01-01').format(dateFormat),
  disabled = false,
  onChange,
  ...props
}) => {
  const [date, setDate] = useDateSelection(queryParamKey);

  useEffect(() => {
    // Ensure the date in the query params is valid
    if (!date || !isSupportedDate(date)) {
      if (!isSupportedDate(fallbackDate)) {
        console.log(
          `Warning! fallback date "${fallbackDate}"is not supported, would cause infinite loop, so not updating`,
        );
        return;
      }
      setDate(fallbackDate);
    }
  }, [date, fallbackDate, setDate]);

  const handleChange = (value: string) => {
    onChange?.(value);
    setDate(value, 'pushIn');
  };

  return (
    <MonthPicker
      width={width}
      value={date ?? fallbackDate}
      onChange={handleChange}
      disabled={disabled}
      data-testid="month-select"
      aria-label="Month selector"
      dateFormat={dateFormat}
      {...props}
    />
  );
};

export default DateSelect;
