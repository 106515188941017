import React from 'react';
import { MetricSchema2Decimals } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import Typography from '../../../../components/atoms/Typography';
import useActiveOrganisation from '../../../../components/contexts/OrganisationContext/useActiveOrganisation';
import { usePipelines } from '../../../../store/state/pipelines/hooks';
import { Pipeline, PipelineStage, PipelineStageMetricsTypes } from '../../../../store/state/pipelines/types';
import ReportEditorDisplayStageRow from '../ReportEditorDisplayStageRow';

interface ReportEditorDisplayAllStagesRowProps {
  pipeline: Pipeline;
  metric: PipelineStageMetricsTypes;
  result: PipelineStage['result'];
  decimals: MetricSchema2Decimals;
}

const StyledGroupsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing(1)} 0`};
  border-radius: ${({ theme }) => `0 0 ${theme.spacing(2)} ${theme.spacing(2)}`};
`;

const StyledNoPipelinesContainer = styled.div`
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: 20px 1fr 160px 160px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding-left: ${({ theme }) => theme.spacing(4)};
`;

const ReportEditorDisplayAllStagesRow: React.FC<React.PropsWithChildren<ReportEditorDisplayAllStagesRowProps>> = ({
  pipeline,
  metric,
  result,
  decimals,
}) => {
  const organisation = useActiveOrganisation();
  const pipelinesVS = usePipelines(organisation.organisation_id);

  if (pipelinesVS.status !== 'success') {
    return null;
  }

  const stages = [...pipeline.stages]
    .filter(stage => stage.result === result && !stage.is_excluded)
    .sort((a, b) => a.display_order - b.display_order);

  return (
    <StyledGroupsContainer>
      {stages.length === 0 && (
        <StyledNoPipelinesContainer>
          <div />
          <Typography>No included stages found for {pipeline.name}</Typography>
        </StyledNoPipelinesContainer>
      )}
      {stages.map(stage => (
        <ReportEditorDisplayStageRow pipelineId={pipeline.id} stage={stage} metric={metric} decimals={decimals} />
      ))}
    </StyledGroupsContainer>
  );
};

export default ReportEditorDisplayAllStagesRow;
