import { MetricSchema2DataOrNodes } from 'scalexp/utils/metrics/metricSchema2';

import { MetricRow, Row } from '../../../../store/state/reports/types';
import { theme } from '../../../../theme';

export const rowBorderStyles = {
  SOLID: '0.6px solid grey',
  DOUBLE: '3.2px double black',
  SUBTLE: `0.6px solid ${theme.palette.silver}`,
  NONE: 'none',
} as const;

const checkIsNodeReferenced = (rowId: string, node: MetricSchema2DataOrNodes): boolean => {
  if ('data' in node && node.data.operator === 'reference' && rowId === node.data.rowId) {
    return true;
  }

  if ('nodes' in node) {
    return !!node.nodes.find(n => checkIsNodeReferenced(rowId, n));
  }

  return false;
};

export const getReferencingRow = (rowId: string, rows: Row[]): MetricRow | undefined => {
  const referencingRow = rows.find(row => {
    if (row.type === 'EMPTY') {
      return false;
    }

    return row.metric.nodes.some(node => checkIsNodeReferenced(rowId, node));
  });

  return referencingRow as MetricRow;
};
