import { useCallback } from 'react';
import useSWR, { mutate } from 'swr';

import useActiveOrganisationId from '../../components/contexts/OrganisationContext/useActiveOrganisationId';
import fetcher from '../../utils/fetcher';
import { DeferredRevenueConfig, DeferredRevenueConfigs, DeferredRevenueConfigWithoutId } from './types';

export const useDeferredRevenueConfigs = () => {
  const organisationId = useActiveOrganisationId();
  return useSWR<DeferredRevenueConfigs>(`/api/v2/deferred/${organisationId}/deferred_revenue_config/`, fetcher);
};

export const useCreateDeferredRevenueConfig = () => {
  const organisationId = useActiveOrganisationId();
  return useCallback(
    async (config: DeferredRevenueConfigWithoutId) => {
      const response = await fetcher(`/api/v2/deferred/${organisationId}/deferred_revenue_config/`, {
        body: JSON.stringify(config),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      await mutate(`/api/v2/deferred/${organisationId}/deferred_revenue_config/`);
      return response;
    },
    [organisationId],
  );
};

export const useUpdateDeferredRevenueConfig = () => {
  const organisationId = useActiveOrganisationId();
  return useCallback(
    async (config: DeferredRevenueConfig) => {
      const { id: configId, ...configWithoutId } = config;
      const response = await fetcher(`/api/v2/deferred/${organisationId}/deferred_revenue_config/${configId}`, {
        body: JSON.stringify(configWithoutId),
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      await mutate(`/api/v2/deferred/${organisationId}/deferred_revenue_config/`);
      return response;
    },
    [organisationId],
  );
};

export const useDeleteDeferredRevenueConfig = () => {
  const organisationId = useActiveOrganisationId();
  return useCallback(
    async (configId: DeferredRevenueConfig['id']) => {
      const response = await fetcher(`/api/v2/deferred/${organisationId}/deferred_revenue_config/${configId}`, {
        method: 'DELETE',
      });
      await mutate(`/api/v2/deferred/${organisationId}/deferred_revenue_config/`);
      return response;
    },
    [organisationId],
  );
};
