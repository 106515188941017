import produce from 'immer';

import { ACTION_PRESENTATION_ADD_SLIDE } from '../presentation/actions';
import { ACTION_PRESENTATION_SET_ACTIVE_ELEMENT_KEY, ACTION_PRESENTATION_SET_ACTIVE_SLIDE } from './actions';
import { PresentationEditorActions, PresentationEditorState } from './types';

const presentationEditor = (
  prevState: PresentationEditorState = {
    activeElementKey: null,
    activeSlideId: null,
  },
  action: PresentationEditorActions,
): PresentationEditorState => {
  return produce(prevState, state => {
    switch (action.type) {
      case ACTION_PRESENTATION_SET_ACTIVE_SLIDE: {
        state.activeSlideId = action.payload;
        return state;
      }
      case ACTION_PRESENTATION_SET_ACTIVE_ELEMENT_KEY: {
        state.activeElementKey = action.payload;
        return state;
      }
      case ACTION_PRESENTATION_ADD_SLIDE: {
        if (action.status === 'success') {
          state.activeSlideId = action.payload.id;
          return state;
        }
        return state;
      }
      default:
        return state;
    }
  });
};

export default presentationEditor;
