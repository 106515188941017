import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import Icon from '../../../components/atoms/Icon';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import { OnboardingOrganisation, useOnboardingContext } from '../../../components/contexts/OnboardingContext';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import Spacer from '../../../components/layout/Spacer';
import { getCurrencyName, SUPPORTED_CURRENCIES } from '../../../components/molecules/CurrencySelect/currencySupport';
import Form from '../../../components/molecules/Form';
import FormFieldInput from '../../../components/molecules/FormFieldInput';
import FormFieldSelect from '../../../components/molecules/FormFieldSelect';
import OnboardingPageTitle from '../../../components/molecules/OnboardingPageTitle';
import fetcher from '../../../utils/fetcher';

declare module 'yup' {
  interface ArraySchema<T> {
    unique(propertyName: string, message?: any): ArraySchema<T>;
  }
}

interface ChildOrganisationInfo {
  name: string;
  shortId: string;
  currency: string;
}

yup.addMethod(yup.array, 'unique', function (propertyName: 'name' | 'currency', message) {
  return this.test('unique', message, function (value) {
    if (!value) {
      return true;
    }
    value.forEach((el: ChildOrganisationInfo, index: number) => {
      const elIndex = value.findIndex(
        (val: ChildOrganisationInfo, idx) =>
          val[propertyName] !== '' &&
          val[propertyName].toLowerCase() === el[propertyName].toLowerCase() &&
          index !== idx,
      );
      if (elIndex > 0) {
        throw this.createError({
          path: `${this.path}.${elIndex}.${propertyName}`,
        });
      }
    });

    return true;
  });
});

const OnboardingConsolidatedChildrenContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const CompanyRow = styled(Row)`
  position: relative;
`;

const RowIndicator = styled(Typography)`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.granite};
  position: relative;
  top: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
`;
const DeleteIcon = styled(Icon)`
  position: absolute;
  right: -46px;
  top: 75%;
  transform: translateY(-75%);
`;

const consolidatedChildrenSchema = yup.object().shape({
  children: yup
    .array()
    .of(
      yup.object({
        name: yup
          .string()
          .min(3, 'Organisation name must be at least 3 characters')
          .required('Organisation name is required'),
        default_currency_code: yup.string().required('Currency is required'),
        name_short: yup
          .string()
          .max(8, 'Subsidiary Short ID must be at most 8 characters')
          .required('Subsidiary Short ID is required'),
      }),
    )
    .unique('name', 'Organisation name must be unique')
    .unique('name_short', 'Subsidiary Short ID must be unique')
    .min(2)
    .required(),
});

type CreateChildOrganisationResponse = {
  consolidated_organisation_auth0_id: string;
};

const OnboardingConsolidatedChildren = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { onboardingConfig, setOnboardingConfig } = useOnboardingContext();
  const { control } = useForm();
  const { organisationId } = useParams<{ organisationId: string }>();
  const { loginWithRedirect } = useAuth0();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'children',
  });

  useEffect(() => {
    onboardingConfig.organisation.children.forEach((child, index) => {
      append({ id: index, ...child });
    });
  }, []);

  const handleSubmit = async (values: { children: OnboardingOrganisation[] }) => {
    setIsLoading(true);
    try {
      const createResponse: CreateChildOrganisationResponse = await fetcher(
        `/api/v2/organisation/${organisationId}/create_children`,
        {
          method: 'POST',
          body: JSON.stringify(
            values.children.map(child => ({
              name: child.name,
              reporting_currency: child.default_currency_code,
              name_short: child.name_short,
            })),
          ),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      setOnboardingConfig({
        ...onboardingConfig,
        organisation: {
          ...onboardingConfig.organisation,
          children: values.children,
        },
      });
      const redirectConfig = {
        organization: createResponse.consolidated_organisation_auth0_id,
        redirectUri: `${window.location.origin}/auth0-callback`,
      };

      loginWithRedirect(redirectConfig as RedirectLoginOptions);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <OnboardingConsolidatedChildrenContainer>
      <OnboardingPageTitle
        title="Set up your consolidated organisation"
        subtitle="Subsidiary, Subsidiary Short ID name need to be unique within this organisation."
        backLink="/onboarding/how-will-use"
      />
      <Spacer margin={{ top: 16 }}>
        <Form schema={consolidatedChildrenSchema} onSubmit={handleSubmit}>
          <Column spacing="xxlarge">
            {fields.map((field, index) => (
              <CompanyRow spacing="large" vAlign="flex-start" key={field.id}>
                <RowIndicator color="white" size="small" weight="medium">
                  {index + 1}
                </RowIndicator>
                <FormFieldInput
                  label="Organisation Name"
                  customSize="large"
                  placeholder="Enter subsidiary name"
                  name={`children.${index}.name`}
                  width="290px"
                  type="text"
                />
                <FormFieldSelect
                  name={`children.${index}.default_currency_code`}
                  label="Currency"
                  selectHeight={200}
                  searchable
                  customSize="xlarge"
                  selectWidth={290}
                  width="290px"
                  placeholder="Select from the list"
                  data={Object.keys(SUPPORTED_CURRENCIES)
                    .sort()
                    .map(currencyCode => ({ label: getCurrencyName(currencyCode), value: currencyCode }))}
                />
                <FormFieldInput
                  label="Subsidiary Short ID"
                  customSize="large"
                  placeholder="UK, USA, FR, CA"
                  name={`children.${index}.name_short`}
                  width="290px"
                  type="text"
                />

                {index > 1 ? (
                  <DeleteIcon
                    title="Delete"
                    color="#4B5D78"
                    name="delete"
                    size={6}
                    clickable
                    onClick={() => {
                      remove(index);
                    }}
                  />
                ) : null}
              </CompanyRow>
            ))}
          </Column>
          <Spacer margin={{ top: 13.75, left: 4 }}>
            <TextButton
              iconRight="add"
              variant="secondary"
              type="button"
              size="large"
              width="238px"
              onClick={() => append({ name: '', currency: '', shortId: '' })}
            >
              Add Subsidiary
            </TextButton>
          </Spacer>
          <Spacer margin={{ top: 12 }} padding={{ bottom: 10 }}>
            <Row width="950px" vAlign="center" hAlign="flex-end">
              <TextButton
                type="submit"
                width="130px"
                variant="primary"
                size="large"
                loading={isLoading}
                disabled={isLoading}
              >
                Save
              </TextButton>
            </Row>
          </Spacer>
        </Form>
      </Spacer>
    </OnboardingConsolidatedChildrenContainer>
  );
};

export default OnboardingConsolidatedChildren;
