import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';

import Icon from '../../../components/atoms/Icon';
import Input from '../../../components/atoms/Input';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import Row from '../../../components/layout/Row';
import Tooltip from '../../../components/molecules/Tooltip';
import { theme } from '../../../theme';

export interface ReportEditorCustomNameProps {
  defaultName?: string;
  customName?: string;
  handleRename: (newName?: string) => void;
}

const StyledContainer = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};
`;

const ReportEditorCustomName: React.FC<React.PropsWithChildren<ReportEditorCustomNameProps>> = ({
  defaultName,
  customName,
  handleRename,
}) => {
  const [closeTooltip, setCloseTooltip] = useState(false);
  const [newName, setNewName] = useState(customName || defaultName);

  useEffect(() => {
    setNewName(customName || defaultName);
  }, [customName, defaultName]);

  const handleSave = () => {
    handleRename(newName);
    setCloseTooltip(true);
  };

  const handleReset = () => {
    handleRename(undefined);
    setCloseTooltip(true);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      setNewName(customName || defaultName);
      setCloseTooltip(true);
    }
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <Tooltip
      content={
        <StyledContainer>
          <Row>
            <Typography color="secondary">Custom Name</Typography>
          </Row>
          <Row>
            <Input
              placeholder={defaultName}
              onChange={e => setNewName(e.target.value)}
              value={newName}
              width="200px"
              onKeyDown={handleKeyDown}
            />
            {defaultName && (
              <TextButton variant="secondary" onClick={handleReset}>
                Reset
              </TextButton>
            )}
            <TextButton width="100%" onClick={handleSave}>
              Save
            </TextButton>
          </Row>
        </StyledContainer>
      }
      placement="top"
      color="white"
      trigger="click"
      closeTooltipOptions={{ closeTooltip, setCloseTooltip }}
    >
      <Tooltip content="Set different name for row">
        <Icon
          name="title"
          color={customName ? theme.palette.primary.light : theme.palette.granite}
          size={6}
          clickable
        />
      </Tooltip>
    </Tooltip>
  );
};

export default ReportEditorCustomName;
