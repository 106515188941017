import produce from 'immer';

import {
  ORGANISATIONS_NATIVE_METRICS_LOAD,
  ORGANISATIONS_NATIVE_METRICS_UPDATE,
  NativeMetricsActions,
} from './actions';
import { OrganisationNativeMetrics } from './types';

const metrics = (
  prevState: OrganisationNativeMetrics = {},
  action: NativeMetricsActions,
): OrganisationNativeMetrics => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_NATIVE_METRICS_LOAD:
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload,
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      case ORGANISATIONS_NATIVE_METRICS_UPDATE: {
        const { organisationId, tagName, nativeMetric } = action.params;
        const nativeMetrics = state[organisationId].value;
        if (nativeMetrics) {
          nativeMetrics[tagName] = nativeMetric;
        }
        return;
      }
      default:
        return state;
    }
  });
};

export default metrics;
