import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectOrganisation } from './selectors';
import { Organisation, OrganisationVS } from './types';

export const ORGANISATIONS_ORGANISATION_LOAD = '[organisations] LOAD';
export const ORGANISATIONS_ORGANISATION_UPDATE = '[organisations] UPDATE';
export const ORGANISATIONS_ORGANISATION_LANDING_KPI_UPDATE = '[organisations] UPDATE_KPI';
export const ORGANISATIONS_ORGANISATION_LANDING_CHART_UPDATE = '[organisations] UPDATE_CHART';
export const ORGANISATIONS_ORGANISATION_LANDING_SHORTCUT_UPDATE = '[organisations] UPDATE_SHORTCUT';
export type OrganisationsActionLoad = PromiseAction<
  typeof ORGANISATIONS_ORGANISATION_LOAD,
  Organisation,
  { organisationId: number }
>;
type OrganisationsActionUpdate = PromiseAction<
  | typeof ORGANISATIONS_ORGANISATION_UPDATE
  | typeof ORGANISATIONS_ORGANISATION_LANDING_KPI_UPDATE
  | typeof ORGANISATIONS_ORGANISATION_LANDING_CHART_UPDATE
  | typeof ORGANISATIONS_ORGANISATION_LANDING_SHORTCUT_UPDATE,
  null,
  { organisationId: number; organisation: PartialUpdatableOrganisation }
>;
export type OrganisationsActions = OrganisationsActionLoad | OrganisationsActionUpdate;

type OrganisationResponse = Organisation;

export const loadOrganisationAction = (organisationId: number): ThunkAction<OrganisationsActions> => async (
  dispatch: ThunkDispatch<OrganisationsActions>,
  getState,
) => {
  const state = getState();
  const organisations = selectOrganisation(state, organisationId);

  if (organisations?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ORGANISATION_LOAD,
        () => http<Organisation[], OrganisationResponse>(`/api/v1/organisations/${organisationId}`),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export type PartialUpdatableOrganisation = Partial<
  Pick<
    Organisation,
    | 'landing_page'
    | 'financial_year_start'
    | 'show_impact_colours'
    | 'primary_brand_colour'
    | 'secondary_brand_colour'
    | 'exclude_zeroed_accounts'
    | 'default_budget_set_id'
    | 'default_consolidated_budget_set_id'
    | 'prepayment_accounts'
    | 'balance_sheet_account'
    | 'upsell_leeway_type'
    | 'upsell_leeway_value'
    | 'downgraded_leeway_type'
    | 'downgraded_leeway_value'
    | 'completed_tasks'
    | 'prepayment_threshold'
    | 'date_format'
    | 'default_deferred_revenue_config_id'
    | 'balance_sheet_accrual_account'
    | 'prepaid_bills_location'
    | 'is_mfa_enabled'
    | 'default_prepayment_account_id'
  >
>;

export const updateOrganisationAction = (
  organisationId: number,
  organisation: PartialUpdatableOrganisation,
): ThunkAction<OrganisationsActions> => async (dispatch: ThunkDispatch<OrganisationsActions>, getState) => {
  const state = getState();
  const organisationVS: OrganisationVS | undefined = selectOrganisation(state, organisationId);

  if (organisationVS?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ORGANISATION_UPDATE,
        () =>
          http<null>(`/api/v1/organisations/${organisationId}/settings`, {
            method: 'PATCH',
            body: organisation,
          }),
        {
          organisationId,
          organisation,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
    dispatch(loadOrganisationAction(organisationId));
    throw e;
  }
};

export const updateOrganisationLandingShortcutAction = (
  organisationId: number,
  organisation: PartialUpdatableOrganisation,
): ThunkAction<OrganisationsActions> => async (dispatch: ThunkDispatch<OrganisationsActions>, getState) => {
  const state = getState();
  const organisationVS: OrganisationVS | undefined = selectOrganisation(state, organisationId);

  if (organisationVS?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ORGANISATION_LANDING_SHORTCUT_UPDATE,
        () =>
          http<null>(`/api/v1/organisations/${organisationId}/settings`, {
            method: 'PATCH',
            body: organisation,
          }),
        {
          organisationId,
          organisation,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
export const updateOrganisationLandingKpiCardAction = (
  organisationId: number,
  organisation: PartialUpdatableOrganisation,
): ThunkAction<OrganisationsActions> => async (dispatch: ThunkDispatch<OrganisationsActions>, getState) => {
  const state = getState();
  const organisationVS: OrganisationVS | undefined = selectOrganisation(state, organisationId);

  if (organisationVS?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ORGANISATION_LANDING_KPI_UPDATE,
        () =>
          http<null>(`/api/v1/organisations/${organisationId}/settings`, {
            method: 'PATCH',
            body: organisation,
          }),
        {
          organisationId,
          organisation,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const updateOrganisationLandingChartAction = (
  organisationId: number,
  organisation: PartialUpdatableOrganisation,
): ThunkAction<OrganisationsActions> => async (dispatch: ThunkDispatch<OrganisationsActions>, getState) => {
  const state = getState();
  const organisationVS: OrganisationVS | undefined = selectOrganisation(state, organisationId);

  if (organisationVS?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ORGANISATION_LANDING_CHART_UPDATE,
        () =>
          http<null>(`/api/v1/organisations/${organisationId}/settings`, {
            method: 'PATCH',
            body: organisation,
          }),
        {
          organisationId,
          organisation,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
