import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '../../../components/atoms/Icon';
import Loader from '../../../components/atoms/Loader';
import Message from '../../../components/atoms/Message';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import FinancialYearCard from '../../../components/organisms/FinancialYearCard';
import OrganisationBrandSettingsCard from '../../../components/organisms/OrganisationBrandSettingsCard';
import useUserSettings from '../../../pages/settings/general/useUserSettings';
import { useSyncContext } from '../../connections/sync-context/SyncContext';
import DateFormat from '../../profile/DateFormat';

const SettingsPageContainer = styled(Row)`
  padding-top: 31px;
  padding-bottom: 31px;
  padding-right: 138px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
`;

const ImportingDataIndication = styled(Column)`
  min-width: 357px;
  max-width: 357px;
  gap: 0px;
  background-color: ${({ theme }) => theme.palette.primary.offwhite};
  padding: 21px 16px 23px;
  border-radius: 8px;
`;

const OnboardingOrganisationSettings = () => {
  const history = useHistory();
  const { jobs } = useSyncContext();
  const isSync = jobs.some(job => job.status !== 'Completed');

  const { data: user, isValidating, error } = useUserSettings();

  if (error) {
    return <Message type="error" title="Failed to fetch user" />;
  }

  if (isValidating) {
    return <Loader center vertical />;
  }

  return (
    <SettingsPageContainer width="100%" vAlign="flex-end">
      <Column width="100%">
        <FinancialYearCard />
        <DateFormat format={user!.date_format} />
        <OrganisationBrandSettingsCard />
      </Column>
      <Column height="95%" vAlign={isSync ? 'space-between' : 'end'} hAlign="end">
        {isSync && (
          <ImportingDataIndication>
            <Row spacing="tiny">
              <Icon name="sync" />
              <Typography size="medium">Importing Data</Typography>
            </Row>
            <Typography weight="regular" size="medium">
              Your data may take up to 15 minutes to import. Please specify your financial year and add your logo during
              this time.
            </Typography>
          </ImportingDataIndication>
        )}
        <TextButton
          width="130px"
          variant="primary"
          size="large"
          onClick={() => history.push('/connections/onboarding/holding-screen')}
        >
          Next
        </TextButton>
      </Column>
    </SettingsPageContainer>
  );
};

export default OnboardingOrganisationSettings;
