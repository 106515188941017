import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectBudgetExchangeRates } from './selectors';
import { BudgetExchangeRates, FXRateData } from './types';

export const ORGANISATIONS_BUDGET_EXCHANGE_RATES_LOAD = '[budgetExchangeRates] LOAD';
export const ORGANISATIONS_BUDGET_EXCHANGE_RATES_UPDATE = '[budgetExchangeRates] UPDATE';

export type BudgetExchangeRatesActions =
  | PromiseAction<
      typeof ORGANISATIONS_BUDGET_EXCHANGE_RATES_LOAD,
      BudgetExchangeRates,
      {
        organisationId: number;
      }
    >
  | UpdateBudgetCurrencyAction;

export type UpdateBudgetCurrencyAction = PromiseAction<
  typeof ORGANISATIONS_BUDGET_EXCHANGE_RATES_UPDATE,
  FXRateData,
  {
    organisationId: number;
    currencyCode: string;
    targetCurrencyCode: string;
    date: string;
    value: number;
  }
>;

export const loadBudgetExchangeRatesAction = (
  organisationId: number,
): ThunkAction<BudgetExchangeRatesActions> => async (dispatch: ThunkDispatch<BudgetExchangeRatesActions>, getState) => {
  const state = getState();
  const exchangeRates = selectBudgetExchangeRates(state, organisationId);

  if (exchangeRates?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_BUDGET_EXCHANGE_RATES_LOAD,
        () =>
          http<{ rates: BudgetExchangeRates }, BudgetExchangeRates>(
            `/api/v1/organisations/${organisationId}/budget_exchange_rates`,
            {},
            // @ts-ignore
            response => response.rates,
          ),
        { organisationId },
      ),
    );
  } catch (e) {
    console.warn('Error dispatching action', e);
  }
};

export const updateBudgetExchangeRate = (
  organisationId: number,
  currencyCode: string,
  targetCurrencyCode: string,
  date: string,
  value: number,
): ThunkAction<BudgetExchangeRatesActions> => async (dispatch: ThunkDispatch<BudgetExchangeRatesActions>, getState) => {
  const state = getState();
  const exchangeRates = selectBudgetExchangeRates(state, organisationId);

  if (exchangeRates?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_BUDGET_EXCHANGE_RATES_UPDATE,
        () =>
          http<FXRateData, FXRateData>(
            `/api/v1/organisations/${organisationId}/budget_exchange_rates`,
            {
              method: 'POST',
              body: {
                from_currency_code: currencyCode,
                to_currency_code: targetCurrencyCode,
                date,
                value,
              },
            },
            response => response,
          ),
        { organisationId, currencyCode, targetCurrencyCode, date, value },
      ),
    );
  } catch (e) {
    console.warn('Error dispatching action', e);
  }
};
