import produce from 'immer';

import { ORGANISATIONS_DEFERRED_BILLS_LOAD, DeferredBillsActions } from './actions';
import { DeferredBills, OrganisationDeferredBills } from './types';

const deferredBills = (
  prevState: OrganisationDeferredBills = {},
  action: DeferredBillsActions,
): OrganisationDeferredBills => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_DEFERRED_BILLS_LOAD:
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: Object.entries(action.payload).reduce((acc, [customerId, data]) => {
                acc[customerId] = data;
                return acc;
              }, {} as DeferredBills),
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      default:
        return state;
    }
  });
};

export default deferredBills;
