import { MetricSchema2DataSource } from 'scalexp/utils/metrics/metricSchema2';

import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import useActiveOrganisation from '../../../components/contexts/OrganisationContext/useActiveOrganisation';
import { ColorPalette } from '../../../components/organisms/MetricSchemaChartWrapper/generateColorPalette';
import { useAccountGroups, useAccountGroupsByTag } from '../../../store/state/accountGroups/hooks';
import {
  AccountGroup,
  AccountGroups,
  AccountGroupsByTag,
  AccountGroupsVS,
} from '../../../store/state/accountGroups/types';
import { useAccounts } from '../../../store/state/accounts/hooks';
import { Accounts } from '../../../store/state/accounts/types';
import { NativeMetricNames, useNativeMetricNames } from '../../../store/state/nativeMetrics/hooks';
import { usePipelines } from '../../../store/state/pipelines/hooks';
import { Pipeline, PipelineStage, PipelineStageMetricsTypes } from '../../../store/state/pipelines/types';
import { ValueState } from '../../../store/values';
import { TimeSeries } from '../TimeChart/types';
import { WaterfallSeries } from '../WaterfallChart/types';

const useExpandedSeries = (series: TimeSeries[] | WaterfallSeries[]): ValueState<(TimeSeries | WaterfallSeries)[]> => {
  const organisation = useActiveOrganisation();
  const { organisation_id: organisationId } = useOrganisationContext();
  const groupsByTagVS = useAccountGroupsByTag();
  const pipelinesVS = usePipelines(organisationId);
  const accountVS = useAccounts(organisationId);
  const nativeMetricNamesVS = useNativeMetricNames();
  const groupsVS: AccountGroupsVS = useAccountGroups();

  if (
    pipelinesVS.status === 'error' ||
    accountVS.status === 'error' ||
    groupsByTagVS.status === 'error' ||
    groupsVS.status === 'error' ||
    nativeMetricNamesVS.status === 'error'
  ) {
    return {
      status: 'error',
      error: pipelinesVS.error || accountVS.error || groupsByTagVS.error || nativeMetricNamesVS.error || groupsVS.error,
      value: undefined,
    } as ValueState<TimeSeries[]>;
  }

  if (
    pipelinesVS.status === 'pending' ||
    accountVS.status === 'pending' ||
    groupsByTagVS.status === 'pending' ||
    groupsVS.status === 'pending' ||
    nativeMetricNamesVS.status === 'pending'
  ) {
    return {
      status: 'pending',
    } as ValueState<TimeSeries[]>;
  }

  const groupsByTag: AccountGroupsByTag = groupsByTagVS.value;
  const accounts: Accounts = accountVS.value;
  const pipelines: Pipeline[] = pipelinesVS.value;
  const nativeMetricNames: NativeMetricNames = nativeMetricNamesVS.value;
  const groups: AccountGroups = groupsVS.value;

  return {
    status: 'success',
    error: undefined,
    value: series.flatMap((serie: TimeSeries | WaterfallSeries): (TimeSeries | WaterfallSeries)[] => {
      let metricSchema = serie.metric_schema;
      if (metricSchema.schemaType === 'complex') {
        // This is a complex metric schema, we can't expand it
        return [serie];
      }

      const isExpanded: boolean = 'expand' in serie && serie.expand;
      if (!isExpanded) {
        // The series is not expanded, return as is
        return [serie];
      }

      const dataNode: MetricSchema2DataSource = metricSchema.nodes[0].data;

      switch (dataNode.operator) {
        case 'native': {
          if (
            dataNode.operator === 'native' &&
            (dataNode.metricId.startsWith('CUSTOM_NATIVE_METRIC') ||
              ['UNITS', 'NUMBER_OF_EMPLOYEES'].includes(dataNode.metricId))
          ) {
            // Custom native metrics should be broken down into accounts
            const accountsInTag = Object.entries(accounts).filter(
              ([, account]) => account.tag && account.tag === dataNode.metricId,
            );

            // If there are no accounts in the tag, return the serie as is
            if (accountsInTag.length === 0) {
              return [serie];
            }
            const colors = ColorPalette.generate_tones(serie.color, accountsInTag.length);
            return accountsInTag.map(([, account], index) => {
              return {
                ...serie,
                name: account.name,
                color: colors[index],
                metric_schema: {
                  schemaType: 'simple',
                  decimals: 0,
                  nodes: [
                    {
                      data: {
                        operator: 'entity',
                        entity: 'account',
                        accountId: account.account_id,
                        isCashflow: dataNode.isCashflow,
                      },
                      operator: 'add',
                    },
                  ],
                },
              };
            });
          } else {
            // Native metrics should be broken down into groups
            const groups: AccountGroup[] = groupsByTag[dataNode.metricId] || [];

            // Filter out the sub groups
            const filteredGroups = groups.filter(group => !group.parent_group_id);

            // If there are no groups in the tag, return the serie as is
            if (filteredGroups.length === 0) {
              return [serie];
            }

            // Find any accounts not in any of the groups, and add them to a group of their own called "All Other"
            const hasUngroupedAccounts: boolean = Object.values(accounts).some(
              account => !account.group_id && account.tag && account.tag === dataNode.metricId,
            );

            const colors = ColorPalette.generate_tones(
              serie.color,
              hasUngroupedAccounts ? filteredGroups.length + 1 : filteredGroups.length,
            );
            const result: TimeSeries[] = filteredGroups.map((group, index) => {
              return {
                ...serie,
                name: group.name,
                color: colors[index],
                metric_schema: {
                  schemaType: 'simple',
                  decimals: 0,
                  nodes: [
                    {
                      operator: 'add',
                      data: {
                        operator: 'entity',
                        entity: 'group',
                        groupId: group.id,
                        isCashflow: dataNode.isCashflow,
                      },
                    },
                  ],
                },
              } as TimeSeries;
            });

            // If there are no ungrouped accounts, return as is
            if (!hasUngroupedAccounts) {
              return result;
            }

            // Add another series for the ungrouped accounts
            return [
              ...result,
              {
                ...serie,
                name: `All Other ${nativeMetricNames[dataNode.metricId]}`,
                color: colors[colors.length - 1],
                metric_schema: {
                  schemaType: 'simple',
                  decimals: 0,
                  nodes: [
                    {
                      operator: 'add',
                      data: {
                        operator: 'entity',
                        entity: 'ungrouped-accounts-in-native',
                        metricId: dataNode.metricId,
                        isCashflow: dataNode.isCashflow,
                      },
                    },
                  ],
                },
              } as TimeSeries,
            ];
          }
        }

        case 'sales': {
          const salesMetricStageType: 'IN_PROGRESS' | 'WON' | 'LOST' = dataNode.metricId.includes('IN_PROGRESS')
            ? 'IN_PROGRESS'
            : dataNode.metricId.includes('WON')
            ? 'WON'
            : 'LOST';
          const pipelineCategory: 'NEW_SALES' | 'RENEWALS' = dataNode.metricId.startsWith('RENEWALS')
            ? 'RENEWALS'
            : 'NEW_SALES';

          // Sales metrics should be broken down into stages
          const pipelinesInSalesMetric: Pipeline[] = pipelines.filter(
            (pipeline: Pipeline): boolean => pipeline.category === pipelineCategory,
          );

          // Filter out excluded stages
          // Leave IN_PROGRESS stages only when isInProgress otherwise use
          // WON or LOST
          const stageFilter = (stage: PipelineStage): boolean => {
            return stage.result === salesMetricStageType && !stage?.is_excluded;
          };

          // Get the count of all the stages of all the pipelines in this sales metric
          const stagesCount = pipelinesInSalesMetric
            .flatMap((pipeline, index) =>
              [...pipeline.stages].sort((stageA, stageB) => {
                return stageA.display_order - stageB.display_order;
              }),
            )
            .filter(stageFilter).length;

          // Generate a color per stage

          const colors = ColorPalette.generate_tones(serie.color, stagesCount);

          return pipelinesInSalesMetric.flatMap((pipeline, index) => {
            const stages: PipelineStage[] = [...pipeline.stages].filter(stageFilter).sort((stageA, stageB) => {
              return stageA.display_order - stageB.display_order;
            });
            let stageMetricId: string;
            if (dataNode.metricId.endsWith('_FORECASTED_WEIGHTED_VALUE')) {
              stageMetricId = 'metric_deals_forecasted_weighted_value';
            } else if (dataNode.metricId.endsWith('WEIGHTED_ACV')) {
              stageMetricId = 'metric_deals_weighted_acv';
            } else if (dataNode.metricId.endsWith('_ACV')) {
              stageMetricId = 'metric_deals_acv';
            } else {
              stageMetricId = 'metric_deals';
            }

            return stages.map((stage, stageIndex) => {
              return {
                ...serie,
                name: `${pipeline.name}:${stage.name}`,
                color: colors[index + stageIndex],
                metric_schema: {
                  schemaType: 'simple',
                  decimals: 0,
                  nodes: [
                    {
                      operator: 'add',
                      data: {
                        operator: 'entity',
                        entity: 'stage',
                        pipelineId: pipeline.id,
                        stageId: stage.id,
                        stageMetricId,
                      },
                    },
                  ],
                },
              } as TimeSeries;
            });
          });
        }

        case 'invoiced-revenue': {
          if (organisation.consolidation_type !== 'PARENT') {
            // Only consolidated level can be broken down into subsidiaries
            return [serie];
          }
          const colors = ColorPalette.generate_tones(serie.color, organisation.children.length);
          return Object.values(organisation.children).map((subsidiary, index) => {
            const metricSchemaDeepCopy = JSON.parse(JSON.stringify(serie.metric_schema));
            metricSchemaDeepCopy['nodes'][0]['data']['subsidiaryId'] = subsidiary.organisation_id;
            return {
              ...serie,
              name: subsidiary.name_short + ': ' + serie.name,
              color: colors[index],
              metric_schema: metricSchemaDeepCopy,
            };
          });
        }

        case 'entity': {
          switch (dataNode.entity) {
            case 'pipeline': {
              // Pipeline series should be broken down into stages
              const pipeline: Pipeline = pipelines.find(pipeline => pipeline.id === dataNode.pipelineId)!;

              if (pipeline) {
                // Only include IN_PROGRESS stages that are not excluded
                const stageFilter = (stage: PipelineStage): boolean => {
                  return stage.result === 'IN_PROGRESS' && !stage?.is_excluded;
                };

                const stages: PipelineStage[] = [...pipeline.stages].filter(stageFilter).sort((stageA, stageB) => {
                  return stageA.display_order - stageB.display_order;
                });
                const colors: string[] = ColorPalette.generate_tones(serie.color, stages.length);

                return stages.map((stage, stageIndex) => {
                  let stageMetricId: PipelineStageMetricsTypes;
                  if (dataNode.pipelineMetricId.endsWith('_forecasted_weighted_value')) {
                    stageMetricId = 'metric_deals_forecasted_weighted_value';
                  } else if (dataNode.pipelineMetricId.endsWith('weighted_acv')) {
                    stageMetricId = 'metric_deals_weighted_acv';
                  } else if (dataNode.pipelineMetricId.endsWith('_acv')) {
                    stageMetricId = 'metric_deals_acv';
                  } else {
                    stageMetricId = 'metric_deals';
                  }
                  return {
                    ...serie,
                    name: stage.name,
                    color: colors[stageIndex],
                    metric_schema: {
                      schemaType: 'simple',
                      decimals: 0,
                      nodes: [
                        {
                          operator: 'add',
                          data: {
                            operator: 'entity',
                            entity: 'stage',
                            pipelineId: pipeline.id,
                            stageId: stage.id,
                            stageMetricId,
                          },
                        },
                      ],
                    },
                  };
                });
              }
              return [];
            }

            case 'group': {
              // Groups should be broken down into subgroups
              const group: AccountGroup | undefined = groups[dataNode.groupId];

              // Group may have been deleted
              if (!group) {
                return [serie];
              }

              // subgroups can not be broken down further
              const isSubGroup = !!group.parent_group_id;
              if (isSubGroup) {
                return [serie];
              }

              // If there are no subgroups, then the group cannot be broken down further
              const subGroupIds: AccountGroup['id'][] = group.groups;
              if (subGroupIds.length === 0) {
                return [serie];
              }

              // Find any accounts not in any of the subgroups, but in the group itself, and add them to a series of their own called "All Other"
              const hasAccountsWithoutGroup = Object.values(accounts).some(
                account => account.group_id && account.group_id === dataNode.groupId,
              );

              const colors: string[] = ColorPalette.generate_tones(
                serie.color,
                hasAccountsWithoutGroup ? subGroupIds.length + 1 : subGroupIds.length,
              );

              const result: TimeSeries[] = subGroupIds.map((subGroupId, subGroupIndex) => {
                return {
                  ...serie,
                  name: groups[subGroupId].name,
                  color: colors[subGroupIndex],
                  metric_schema: {
                    schemaType: 'simple',
                    decimals: 0,
                    nodes: [
                      {
                        operator: 'add',
                        data: {
                          operator: 'entity',
                          entity: 'group',
                          groupId: subGroupId,
                        },
                      },
                    ],
                  },
                } as TimeSeries;
              });

              if (!hasAccountsWithoutGroup) {
                return result;
              } else {
                return [
                  ...result,
                  {
                    ...serie,
                    name: `All Other ${group.name}`,
                    color: colors[colors.length - 1],
                    metric_schema: {
                      schemaType: 'simple',
                      decimals: 0,
                      nodes: [
                        {
                          operator: 'add',
                          data: {
                            operator: 'entity',
                            entity: 'ungrouped-accounts-in-group',
                            groupId: dataNode.groupId,
                            isCashflow: dataNode.isCashflow,
                          },
                        },
                      ],
                    },
                  } as TimeSeries,
                ];
              }
            }

            default: {
              // Other entities return as is
              return [serie];
            }
          }
        }

        default: {
          // Other entities return as is
          return [serie];
        }
      }
    }),
  };
};

export default useExpandedSeries;
