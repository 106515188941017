import dayjs from 'dayjs';
import { useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

const useDateSelection = (queryParamKey: string = 'date') => {
  return useQueryParam(queryParamKey, StringParam);
};

export const useDateSelectionDate = (queryParamKey: string = 'date'): dayjs.Dayjs => {
  const [value] = useQueryParam<string | null | undefined>(queryParamKey, StringParam);
  return useMemo(() => dayjs(value ?? undefined), [value]);
};

export default useDateSelection;
