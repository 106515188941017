import React from 'react';
import useSWR from 'swr';

import useActiveOrganisation from '../../../components/contexts/OrganisationContext/useActiveOrganisation';
import { Connection } from '../../../components/organisms/OAuthConnectionsManager';
import fetcher from '../../../utils/fetcher';

export type ConnectionError = {
  status: 'error';
  error: any;
};

export type ConnectionNotConnected = {
  status: 'notconnected';
};

export type ConnectionSuccess = {
  status: 'connected';
} & Connection;

export type ConnectionNeedsRelink = {
  status: 'needs-relink';
};
interface ConsolidatedConnection {
  organisation_id: number;
  organisation_name: string;
  connections: Connection[];
}
interface ConsolidatedMappedConnection {
  organisation_id: number;
  organisation_name: string;
  connections: ConnectionStatuses;
}
export type ConnectionStatus = ConnectionError | ConnectionNotConnected | ConnectionNeedsRelink | ConnectionSuccess;

export type ConnectionStatuses = { [connectionId: string]: ConnectionStatus };

export interface ConnectionContextProps {}

export const ConnectionsContext = React.createContext<{
  connections: ConnectionStatuses;
  consolidatedConnections: ConsolidatedMappedConnection[];
  revalidate: VoidFunction;
  isLoaded: boolean;
}>(undefined!);

export const ConnectionsProvider: React.FC<React.PropsWithChildren<ConnectionContextProps>> = ({ children }) => {
  const organisation = useActiveOrganisation();
  const organisationId = organisation.organisation_id;
  let mappedConsolidatedConnections: ConsolidatedMappedConnection[] = [];

  const { data, mutate, error: connectionsError } = useSWR<ConsolidatedConnection[]>(
    `/api/v1/${organisationId}/connections`,
    (key: string) => fetcher(`/api/v1/${organisationId}/connections`).then(res => res),
  );
  mappedConsolidatedConnections = (data || []).map(org => {
    const mappedConnectionsChild: ConnectionStatuses = (org.connections || []).reduce(
      (acc, connection) => ({
        ...acc,
        [connection.source_name]: connectionsError
          ? { status: 'error', error: connectionsError }
          : connection.connected
          ? { status: 'connected', ...connection }
          : { status: 'needs-relink' },
      }),
      {
        xero: { status: 'notconnected' },
        quickbooks: { status: 'notconnected' },
        stripe: { status: 'notconnected' },
        sageone: { status: 'notconnected' },
        sage50: { status: 'notconnected' },
        zohobooks: { status: 'notconnected' },
        sage200cloud: { status: 'notconnected' },
        netvisor: { status: 'notconnected' },
        clearbooks: { status: 'notconnected' },
        quickbooksdesktop: { status: 'notconnected' },
        salesforce: { status: 'notconnected' },
        pipedrive: { status: 'notconnected' },
        hubspot: { status: 'notconnected' },
      },
    );
    return { ...org, connections: mappedConnectionsChild };
  });

  const currentOrg = Object.values(data?.find(org => org.organisation_id === organisationId)?.connections || {});

  const mappedConnections: ConnectionStatuses = ((currentOrg as Connection[]) || []).reduce(
    (acc, connection) => ({
      ...acc,
      [connection.source_name]: connectionsError
        ? { status: 'error', error: connectionsError }
        : connection.connected
        ? { status: 'connected', ...connection }
        : { status: 'needs-relink' },
    }),
    {
      xero: { status: 'notconnected' },
      quickbooks: { status: 'notconnected' },
      stripe: { status: 'notconnected' },
      sageone: { status: 'notconnected' },
      sage50: { status: 'notconnected' },
      zohobooks: { status: 'notconnected' },
      sage200cloud: { status: 'notconnected' },
      netvisor: { status: 'notconnected' },
      clearbooks: { status: 'notconnected' },
      quickbooksdesktop: { status: 'notconnected' },
      salesforce: { status: 'notconnected' },
      pipedrive: { status: 'notconnected' },
      hubspot: { status: 'notconnected' },
    },
  );

  if (connectionsError) {
    return <div>Failed to retrieve connections</div>;
  }

  return (
    <ConnectionsContext.Provider
      value={{
        consolidatedConnections: mappedConsolidatedConnections,
        connections: mappedConnections,
        revalidate: mutate,
        isLoaded: !!data,
      }}
    >
      {children}
    </ConnectionsContext.Provider>
  );
};

export const useConnectionsContext = () => {
  const value = React.useContext(ConnectionsContext);

  if (!value) {
    throw new Error('ConnectionsProvider not found!');
  }

  return value;
};
