import { useMemo } from 'react';

import { useConnectionsContext } from './index';

const useConnectionsNeedRelinkCount = (): number => {
  const { connections } = useConnectionsContext();
  return useMemo(
    () =>
      Object.values(connections).reduce(
        (total, connection) =>
          connection.status === 'needs-relink' || (connection.status === 'connected' && connection.needs_relinking)
            ? total + 1
            : total,
        0,
      ),
    [connections],
  );
};

export default useConnectionsNeedRelinkCount;
