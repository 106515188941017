import { useConnectionsContext } from './index';
import { CRM_CONNECTIONS } from './useGetAccountingAndSalesSystem';

const useHasCrmConnection = (): boolean => {
  const { connections } = useConnectionsContext();
  if (!connections) {
    throw new Error('No ConnectionProvider found');
  }

  return (
    Object.entries(connections).findIndex(
      ([key, obj]) => obj.status !== 'notconnected' && CRM_CONNECTIONS.includes(key),
    ) > -1
  );
};

export default useHasCrmConnection;
