import useCurrencySelection from 'scalexp/components/molecules/CurrencySelect/useCurrencySelection';
import { MetricSchema2, MetricSchema2Complex, MetricSchema2Data } from 'scalexp/utils/metrics/metricSchema2';

import { useOrganisationContext } from '../../components/contexts/OrganisationContext';
import { NativeMetricId } from '../../service/types/metricSchema';
import { useAccountGroupsByTag } from '../../store/state/accountGroups/hooks';
import { AccountGroup } from '../../store/state/accountGroups/types';
import { useAccounts } from '../../store/state/accounts/hooks';
import { useDerivedMetrics } from '../../store/state/derivedMetrics/hooks';
import { useNativeMetricNames } from '../../store/state/nativeMetrics/hooks';
import { ValueState } from '../../store/values';
import { CalculationResult } from './calculationRequestManager';
import { DateKeys, dateToRequest } from './calculations';
import { useMultipleMetricSchemaSeries } from './useMetricSchemaSeries';

const useMetricSchemaSeriesGroups = (
  metricSchema: MetricSchema2,
  dateKey: string,
  budgetId?: number,
): {
  seriesGroupsVS: ValueState<CalculationResult[]>;
  labels: string[];
} => {
  const { organisation_id: organisationId, default_currency_code } = useOrganisationContext();

  const groupsByTagVS = useAccountGroupsByTag();
  const nativeMetricNamesVS = useNativeMetricNames();
  const accountsVS = useAccounts(organisationId);
  const derivedMetricsVS = useDerivedMetrics(organisationId);
  const onlyMetricSchemaData: MetricSchema2Data | undefined =
    metricSchema?.schemaType === 'simple' ? metricSchema.nodes[0] : undefined;
  const nativeMetricId = onlyMetricSchemaData?.data.operator === 'native' && onlyMetricSchemaData?.data.metricId;
  const derivedMetricId = onlyMetricSchemaData?.data.operator === 'derived' && onlyMetricSchemaData?.data.metricId;
  const nativeMetricName = nativeMetricId ? nativeMetricNamesVS.value?.[nativeMetricId] : null;
  const derivedMetricName = derivedMetricId ? derivedMetricsVS.value?.[derivedMetricId].name : null;
  const metricName = nativeMetricName || derivedMetricName || 'Metric';
  const groupsInTag: AccountGroup[] | undefined = nativeMetricId ? groupsByTagVS.value?.[nativeMetricId] : undefined;
  const labels: string[] = [];
  const derivedMetricNodes: MetricSchema2Complex['nodes'] = derivedMetricId
    ? derivedMetricsVS.value?.[derivedMetricId].metricSchema.nodes || []
    : [];
  const [currencyCode] = useCurrencySelection();
  const currency = currencyCode || default_currency_code;

  const consistsOfOnlyNativeMetrics: boolean =
    derivedMetricNodes &&
    derivedMetricNodes.every(node => node.operator === 'add' && 'data' in node && node.data.operator === 'native');

  const nativeMetricIdsInDerivedMetric: string[] = (consistsOfOnlyNativeMetrics ? derivedMetricNodes : [])
    .map(node => 'data' in node && node.data.operator === 'native' && node.data.metricId)
    .filter(Boolean) as string[];

  const nativeMetricInDerivedMetric: { [tag: NativeMetricId]: AccountGroup[] } = Object.fromEntries(
    nativeMetricIdsInDerivedMetric.map(nativeMetricId => {
      // we only return parent groups, not subgroups
      const parentGroups = groupsByTagVS.value?.[nativeMetricId]?.filter(group => !group.parent_group_id) || [];

      return [nativeMetricId, parentGroups];
    }),
  );

  const hasAnyTag: boolean = !!(
    nativeMetricId ||
    Object.values(nativeMetricInDerivedMetric).length ||
    derivedMetricId === 'OPERATING_EXPENSES'
  );
  let groupings: MetricSchema2[];

  if (!accountsVS.value || !groupsByTagVS.value || !hasAnyTag) {
    groupings = [metricSchema];
  } else {
    const nativeMetricInDerivedMetricHasGroups = Object.values(nativeMetricInDerivedMetric).length > 0;

    let groupsSets: AccountGroup[][] = [];
    if (derivedMetricId === 'OPERATING_EXPENSES') {
      // This is a temporary work-around for the Operating Expenses derived metric
      // When we introduced subgroups and removed the existing 3 OPEX native metrics, we had to change the definition of the OPEX YTD pie chart
      // This caused a situation where we would not be able to break down total opex because it was OPEX + DA. And derived
      // metrics always result in a single series.
      // That is why this exception was added, which breaks OPEX down into the groups and adds DA as a separate series.
      // This can be removed when we introduce the ability to have multiple series in the same pie chart.
      // When this is added, we also need to make sure all pie chart defined with OPEX
      // are updated to be 2 separate series, DA and OPEX. instead of just TOTAL_OPEX.
      const opexGroups: AccountGroup[] = groupsByTagVS.value['OPERATING_EXPENSES'] || [];
      const opexParentGroups: AccountGroup[] = opexGroups.filter(group => !group.parent_group_id);
      groupsSets = [
        opexParentGroups.flatMap(parentGroup => {
          if (parentGroup.groups.length > 0) {
            return parentGroup.groups.map(groupId => opexGroups.find(group => group.id === groupId)) as AccountGroup[];
          } else {
            return [parentGroup] as AccountGroup[];
          }
        }),
      ];
    } else if (nativeMetricInDerivedMetricHasGroups) {
      groupsSets = Object.values(nativeMetricInDerivedMetric);
    } else {
      if (nativeMetricId && groupsInTag) {
        // we only return parent groups, not subgroups
        groupsSets = [groupsInTag.filter(group => !group.parent_group_id)];
      }
    }

    groupings = groupsSets.flatMap((groups: AccountGroup[]) => {
      const tagGroups = Object.values(groups);

      const result: MetricSchema2[] = tagGroups.map((group, _, allGroups) => {
        labels.push(group.name);
        return {
          schemaType: 'simple',
          decimals: 0,
          nodes: [
            {
              operator: 'add',
              data: {
                operator: 'entity',
                entity: 'group',
                groupId: group.id,
                isCashflow: false,
              },
            },
          ],
        };
      });

      // Get all accounts that are not grouped
      const hasUngroupedAccounts = Object.values(accountsVS.value!).some(
        account => account.tag === nativeMetricId && !account.group_id,
      );

      if (hasUngroupedAccounts) {
        labels.push('All Other');
        result.push({
          schemaType: 'simple',
          decimals: 0,
          nodes: [
            {
              operator: 'add',
              data: {
                operator: 'entity',
                entity: 'ungrouped-accounts-in-native',
                metricId: nativeMetricId as string,
                isCashflow: false,
              },
            },
          ],
        });
      }

      return result;
    });
    if (derivedMetricId === 'OPERATING_EXPENSES') {
      labels.push('Depreciation & Amortisation');
      groupings.push({
        schemaType: 'simple',
        decimals: 0,
        nodes: [
          {
            operator: 'add',
            data: {
              operator: 'native',
              metricId: 'DEPRECIATION_AMORTISATION',
              isCashflow: false,
            },
          },
        ],
      });

      // Get all accounts that are not grouped
      const hasUngroupedAccountsInOPEX = Object.values(accountsVS.value!).some(account => {
        if (account.tag !== 'OPERATING_EXPENSES') {
          return false;
        }
        if (!account.group_id) {
          return true;
        }
        // If an account is in a group, but that group has subgroups, then the account will not be included in any group
        // So we add it to the all other category here.
        const group: AccountGroup | undefined = groupsByTagVS.value?.[account.tag]?.find(
          group => group.id === account.group_id,
        );
        return group && group.groups.length > 0;
      });

      if (hasUngroupedAccountsInOPEX) {
        labels.push('All Other');
        groupings.push({
          schemaType: 'simple',
          decimals: 0,
          nodes: [
            {
              operator: 'add',
              data: {
                operator: 'entity',
                entity: 'ungrouped-accounts-in-native',
                metricId: 'OPERATING_EXPENSES',
                isCashflow: false,
              },
            },
          ],
        });
      }
    }
  }

  const seriesGroupsVS = useMultipleMetricSchemaSeries(
    groupings.length ? groupings : [metricSchema],
    dateToRequest([dateKey] as DateKeys, budgetId ? [budgetId] : undefined, currency),
  );

  if (
    accountsVS.status === 'pending' ||
    groupsByTagVS.status === 'pending' ||
    (groupings.length && groupings.length !== seriesGroupsVS.value?.length)
  ) {
    return {
      labels: [],
      seriesGroupsVS: {
        status: 'pending',
      },
    };
  }

  return {
    seriesGroupsVS,
    labels: groupings.length ? labels : [metricName],
  };
};

export default useMetricSchemaSeriesGroups;
