import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../index';
import { Report, Row } from '../reports/types';
import {
  addEditedReportRowAction,
  deleteEditedReportRowAction,
  setEditedReportAddedComputationRowIdAction,
  setEditedReportSelectedRowIdAction,
  updateEditedReportAction,
  updateEditedReportRowAction,
} from './actions';
import {
  selectAddedComputationRowId,
  selectEditedReport,
  selectIsEditedReportDirty,
  selectSelectedRowId,
} from './selectors';

export const useEditedReport = (): Report | null => {
  const editedReport: Report | null = useSelector<Store, ReturnType<typeof selectEditedReport>>(state =>
    selectEditedReport(state),
  );

  return editedReport;
};

export const useIsEditedReportDirty = (): boolean => {
  const isDirty: boolean = useSelector<Store, ReturnType<typeof selectIsEditedReportDirty>>(state =>
    selectIsEditedReportDirty(state),
  );

  return isDirty;
};

// report
export const useUpdateEditedReport = () => {
  const dispatch = useDispatch();

  return useCallback((report: Report | null, isDirty?: boolean) => {
    dispatch(updateEditedReportAction(report, isDirty));
  }, []);
};

// rows
export const useEditReportSelectedRowId = () => {
  const selectedRowId: string | null = useSelector<Store, ReturnType<typeof selectSelectedRowId>>(state =>
    selectSelectedRowId(state),
  );

  return selectedRowId;
};

export const useEditReportAddedComputationRowId = () => {
  const selectedRowId: string | null = useSelector<Store, ReturnType<typeof selectAddedComputationRowId>>(state =>
    selectAddedComputationRowId(state),
  );

  return selectedRowId;
};

export const useSetEditedReportSelectedRowId = () => {
  const dispatch = useDispatch();

  return useCallback((rowId: string | null) => {
    dispatch(setEditedReportSelectedRowIdAction(rowId));
  }, []);
};

export const useSetEditedReportAddedComputationRowId = () => {
  const dispatch = useDispatch();

  return useCallback((rowId: string | null) => {
    dispatch(setEditedReportAddedComputationRowIdAction(rowId));
  }, []);
};

export const useAddEditedReportRow = () => {
  const dispatch = useDispatch();

  return useCallback((row: Row) => {
    dispatch(addEditedReportRowAction(row));
  }, []);
};

export const useUpdateEditedReportRow = () => {
  const dispatch = useDispatch();

  return useCallback((row: Row) => {
    dispatch(updateEditedReportRowAction(row));
  }, []);
};

export const useDeleteEditedReportRow = () => {
  const dispatch = useDispatch();

  return useCallback((rowId: string) => {
    dispatch(deleteEditedReportRowAction(rowId));
  }, []);
};
