import { API_URL } from '../config';
import { authHeader } from './auth';

export const apiUrl = API_URL;

type FetcherError = Error & {
  info?: any;
  status?: number;
};

const fetcher = async (resource: Request | string, init?: RequestInit) => {
  let response: Response;

  // Add default required headers
  const newInit: RequestInit = {
    mode: 'cors',
    credentials: 'include',
    ...(init || {}),
    headers: {
      ...(init?.headers || {}),
      ...authHeader(),
    },
  };

  if (typeof resource == 'object') {
    // Handle Request resource parameter
    if (!resource.url.startsWith('/')) {
      response = await fetch(resource, newInit);
    } else {
      const newRequest: Request = new Request({
        ...resource,
        url: apiUrl + resource.url,
      });
      response = await fetch(newRequest, newInit);
    }
  } else {
    // Handle string resource parameter
    const newUrl = resource.startsWith('/') ? apiUrl + resource : resource;
    response = await fetch(newUrl, newInit);
  }

  // Redirect user to signin page on Unauthorized response
  if (response.status === 401) {
    // This is a temporary hack because the signin page currently tries to fetch the user through the header returning
    // a 401 as well. TODO: remove this check
    const publicRoutes = ['/signin', '/forgot-password'];

    if (
      publicRoutes.includes(window.location.pathname) ||
      window.location.pathname.startsWith('/invitations') ||
      window.location.pathname.startsWith('/reset-password') ||
      window.location.pathname.startsWith('/start-trial') ||
      window.location.pathname.startsWith('/login-with-xero')
    ) {
      return;
    }

    window.location.href = '/signin';
    return;
  }

  if (!response.ok) {
    const error: FetcherError = new Error('An error occurred while fetching the data.');
    error.info = await response.text();
    error.status = response.status;
    // TODO: redirect user to login route if code 401
    throw error;
  }

  const contentType = response.headers.get('content-type');
  if (response.redirected) {
    return 'redirected';
  }
  if (!contentType || contentType.indexOf('application/json') === -1) {
    console.log('Only JSON is supported at the moment, but no need to throw error');
    return null;
  }

  if (response.status === 204) {
    return null;
  }

  // used to get the error text if the json parsing failed.
  const responseClone = response.clone();

  try {
    const json = await response.json();
    return json;
  } catch (e) {
    const error: FetcherError = new Error('Failed to parse json in response body');
    error.info = `Response body text: '${await responseClone.text()}'`;
    error.status = response.status;
    throw error;
  }
};

export default fetcher;
export type { FetcherError };
