import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export interface RowProps extends HTMLAttributes<HTMLDivElement> {
  width?: string;
  height?: string;
  wrap?: boolean;
  spacing?: 'none' | 'tiny' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  hAlign?: 'flex-start' | 'start' | 'center' | 'end' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
  vAlign?: 'flex-start' | 'start' | 'center' | 'end' | 'flex-end' | 'stretch';
  flex?: number | string;
}

const StyledRow = styled.div<Omit<RowProps, 'wrap'> & { $wrap: RowProps['wrap'] }>`
  display: flex;
  flex-direction: row;
  min-width: 0;
  ${({ width }) => (width ? `width: ${width};` : '')};
  ${({ height }) => (height ? `height: ${height};` : '')};
  ${({ hAlign }) => (hAlign ? `justify-content: ${hAlign};` : '')};
  ${({ vAlign }) => (vAlign ? `align-items: ${vAlign};` : '')};
  ${({ flex }) => (flex ? `flex: ${flex};` : '')}
  ${({ $wrap }) => ($wrap ? 'flex-wrap: wrap;' : '')}
  gap: ${({ theme, spacing }) => {
    switch (spacing) {
      case 'none':
        return '0px';
      case 'tiny':
        return theme.spacing(1);
      case 'small':
        return theme.spacing(2);
      case 'medium':
        return theme.spacing(4);
      case 'large':
        return theme.spacing(6);
      case 'xlarge':
        return theme.spacing(8);
      case 'xxlarge':
        return theme.spacing(10);
    }
  }};
`;

const Row: React.FC<React.PropsWithChildren<RowProps>> = ({
  hAlign = 'start',
  vAlign = 'start',
  spacing = 'medium',
  wrap = false,
  flex,
  ...other
}) => {
  return <StyledRow hAlign={hAlign} vAlign={vAlign} spacing={spacing} $wrap={wrap} flex={flex} {...other} />;
};

export default Row;
