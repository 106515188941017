import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Organisation } from 'scalexp/service';
import { MetricSchema2 } from 'scalexp/utils/metrics/metricSchema2';
import { ArrayParam, useQueryParam } from 'use-query-params';

import useActiveOrganisation from '../../components/contexts/OrganisationContext/useActiveOrganisation';
import useCurrencySelection from '../../components/molecules/CurrencySelect/useCurrencySelection';
import useDateSelection from '../../components/molecules/DateSelect/useDateSelection';
import { TrackingCategoryOption } from '../../store/state/trackingCategories/types';
import { ValueState } from '../../store/values';
import { useDeepEqualMemo } from '../useDeepEqualMemo';
import { CalculationRequestManager, CalculationResult, Request } from './calculationRequestManager';
import { CalculationRequestEntry } from './newCalculations';

/**
 * Triggers a sentry error when a calculation fails
 * @param error Error object
 * @param pathname Pathname where the error occurred
 * @param organisation Organisation for which the calculation failed
 * @param metricSchema MetricSchema for which the calculation failed
 * @param calculationRequest entries for which the calculation failed
 */
const triggerSentryCalculationFailedError = (
  error: { info: string; status: number },
  pathname: string,
  organisation: Organisation,
  metricSchema: MetricSchema2 | MetricSchema2[],
  calculationRequest: CalculationRequestEntry[] | undefined,
) => {
  Sentry.captureException(new Error(error.info), scope => {
    scope.clear();
    scope.setTransactionName(`CalculationException ${error.status}`);
    scope.setExtra('location', pathname);
    scope.setExtra('organisationID', organisation.organisation_id);
    scope.setExtra('organisation', organisation.name);
    scope.setExtra('metricSchema', JSON.stringify(metricSchema));
    scope.setExtra('entires', JSON.stringify(calculationRequest));
    return scope;
  });
};

/**
 * Expects a single metric schema definition and returns a SimpleSeries containing both the actuals and targets data
 * derived from the provided schema
 * @param metricSchema MetricSchema for which to derive data
 * @param calculationRequest defines which values other than the values by month should be calculated
 */
const useMetricSchemaSeries = (
  metricSchema: MetricSchema2,
  calculationRequest?: CalculationRequestEntry[],
): ValueState<CalculationResult> => {
  const memoizedMetricSchema = useDeepEqualMemo(metricSchema);
  const memoizedCalculationRequest = useDeepEqualMemo(calculationRequest);
  const [result, setResult] = useState<any>(null);
  const location = useLocation();
  const organisation = useActiveOrganisation();
  const [selectedDate, setSelectedDate] = useDateSelection();
  const [currencyCode] = useCurrencySelection();
  const focalDate = selectedDate?.substr(0, 'YYYY-MM'.length);
  const [selectedTrackingCategoryIds] = useQueryParam<TrackingCategoryOption['id'][]>(
    'tracking_category',
    // @ts-ignore
    ArrayParam,
  );

  const currentRequest = useRef<any>(null);

  const newRequest: { metricSchema: MetricSchema2; request: Request } = {
    metricSchema: memoizedMetricSchema!,
    request: {
      organisationId: organisation.organisation_id,
      date: focalDate!,
      entries: memoizedCalculationRequest as CalculationRequestEntry[],
      // @ts-ignore
      trackingCategoryIds: selectedTrackingCategoryIds || null,
    },
  };

  const newMakeRequest = () => {
    currentRequest.current = newRequest;
    CalculationRequestManager.makeRequest(memoizedMetricSchema!, newRequest.request as Request)
      .then(response => {
        if (currentRequest.current === newRequest) {
          CalculationRequestManager.setIsRequestStored(newRequest);
          setResult(response);
        }
      })
      .catch((error: { info: string; status: number }) => {
        if (error.status >= 400) {
          triggerSentryCalculationFailedError(error, location.pathname, organisation, metricSchema, calculationRequest);
        }

        setResult(null);
      });
  };

  // Ignore old calculation requests
  useEffect(() => {
    if (!focalDate) {
      return;
    }

    newMakeRequest();
  }, [
    memoizedCalculationRequest,
    memoizedMetricSchema,
    focalDate,
    selectedTrackingCategoryIds,
    currencyCode,
    CalculationRequestManager.isRequestStored(newRequest),
  ]);

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(dayjs().subtract(1, 'month').format('YYYY-MM'));
    }
  }, [selectedDate]);

  if (!result) {
    return {
      status: 'pending',
    };
  }

  return {
    status: 'success',
    error: undefined,
    value: result,
    reload: newMakeRequest,
  };
};

export default useMetricSchemaSeries;

/**
 * Expects multiple metric schema definition and returns a SimpleSeries containing both the actuals and targets data
 * derived from the provided schema
 * @param metricSchemas MetricSchema[] for which to derive data
 * @param budgetIds Optional budget ids to use for the calculation
 * @param calculationRequest Optional calculation request to use for the calculation
 */
export const useMultipleMetricSchemaSeries = (
  metricSchemas: MetricSchema2[],
  calculationRequest?: CalculationRequestEntry[],
  metricSchemasLoaded: boolean = true,
): ValueState<CalculationResult[]> => {
  const memoizedMetricSchemas = useDeepEqualMemo(metricSchemas)!;
  const memoizedCalculationRequest = useDeepEqualMemo(calculationRequest);
  const [result, setResult] = useState<any>(null);
  const location = useLocation();
  const organisation = useActiveOrganisation();
  const [selectedDate = dayjs().format('YYYY-MM')] = useDateSelection();
  const focalDate = selectedDate?.substr(0, 'YYYY-MM'.length);
  const [selectedTrackingCategoryIds] = useQueryParam<TrackingCategoryOption['id'][]>(
    'tracking_category',
    // @ts-ignore
    ArrayParam,
  );

  const currentRequest = useRef<any>(null);

  const newRequest: { metricSchema: MetricSchema2; request: Request } = {
    metricSchemas: memoizedMetricSchemas,
    request: {
      organisationId: organisation.organisation_id,
      date: focalDate!,
      entries: memoizedCalculationRequest ?? [],
      // @ts-ignore
      trackingCategoryIds: selectedTrackingCategoryIds || null,
    },
  };

  const handleRequestMetricSchemas = async () => {
    const newResult: CalculationResult[] = [];
    currentRequest.current = newRequest;
    try {
      await Promise.all(
        memoizedMetricSchemas.map(async (metricSchema, index) => {
          try {
            const response = await CalculationRequestManager.makeRequest(metricSchema, newRequest.request);
            newResult[index] = response;
          } catch (err) {
            console.log(err);
          }
        }),
      );

      if (currentRequest.current === newRequest) {
        CalculationRequestManager.setIsRequestStored(newRequest);
        setResult(newResult);
      }
    } catch (err) {
      const error = err as { info: string; status: number };
      if (error.status >= 400) {
        triggerSentryCalculationFailedError(error, location.pathname, organisation, metricSchemas, calculationRequest);
      }
      setResult(null);
    }
  };

  // Ignore old calculation requests
  useEffect(() => {
    if (!metricSchemasLoaded) {
      return;
    }

    handleRequestMetricSchemas();
  }, [
    memoizedMetricSchemas,
    memoizedCalculationRequest,
    focalDate,
    selectedTrackingCategoryIds,
    metricSchemasLoaded,
    CalculationRequestManager.isRequestStored(newRequest),
  ]);

  if (!result) {
    return {
      status: 'pending',
    };
  }

  return {
    status: 'success',
    error: undefined,
    value: result,
    reload: handleRequestMetricSchemas,
  };
};
