import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { ValueState } from '../../values';
import { Store } from '../index';
import {
  ACTION_REPORTS_UPDATE,
  createReportAction,
  CreateReportActionResult,
  deleteReportAction,
  DeleteReportActionResult,
  loadReportsAction,
  updateReportAction,
  UpdateReportActionResult,
} from './actions';
import { selectReportIds, selectReports } from './selectors';
import { Report, ReportsVS, ReportVS } from './types';

export const useReports = (organisationId: number): ReportsVS => {
  const reports: ReportsVS | undefined = useSelector<Store, ReturnType<typeof selectReports>>(state =>
    selectReports(state, organisationId),
  );
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadReportsAction(organisationId));
  }, [organisationId]);

  if (reports === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
    };
  }

  return {
    ...reports,
    reload,
  };
};

export const useReportsIds = (organisationId: number): ValueState<number[]> => {
  const reports: ReportsVS = useReports(organisationId);
  const reportIds = useSelector<Store, ReturnType<typeof selectReportIds>>(state =>
    selectReportIds(state, organisationId),
  );

  if (reports.status === 'pending') {
    return {
      ...reports,
      status: 'pending',
      value: reportIds,
    };
  }

  if (reports.status === 'success') {
    return {
      ...reports,
      value: reportIds,
    };
  }

  return reports as ValueState<number[]>;
};

export const NEW_REPORT_TEMPLATE = ({
  id: 'new',
  name: 'New report',
  column_collection_id: null,
  columns: [],
  rows: [
    {
      id: uuidv4(),
    },
  ],
} as unknown) as Report;

export const useReport = (
  organisationId: number,
  reportId: number | 'new' | 'template',
  template?: Report,
): ReportVS => {
  const reports: ReportsVS = useReports(organisationId);
  if (reportId === 'template' && !template) {
    return {
      status: 'error',
      value: undefined,
      error: new Error('You should provide a template!'),
    };
  }

  if (reportId === 'template' && template) {
    return {
      status: 'success',
      value: template,
      error: undefined,
    };
  }

  if (reportId === 'new') {
    return {
      status: 'success',
      value: NEW_REPORT_TEMPLATE,
      error: undefined,
    };
  }

  if (typeof reportId !== 'number') {
    return {
      status: 'error',
      value: undefined,
      error: new Error('reportId must be a number!'),
    };
  }

  if (reports.status === 'pending') {
    return {
      reload: reports.reload,
      status: 'pending',
      value: reports.value?.[reportId],
    };
  }

  if (reports.status === 'success') {
    const report = reports.value[reportId];
    if (!report) {
      return {
        reload: reports.reload,
        status: 'error',
        error: new Error(`No report found with id "${reportId}"`),
        value: undefined,
      };
    }
    return {
      ...reports,
      value: report,
    };
  }

  return reports as ReportVS;
};

export const useDeleteReport = () => {
  const dispatch = useDispatch();

  return useCallback(async (organisationId: number, reportId: number): Promise<DeleteReportActionResult> => {
    const res = await dispatch(deleteReportAction(organisationId, reportId));
    return (res as unknown) as DeleteReportActionResult;
  }, []);
};

export const useUpdateReport = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (organisationId: number, reportId: number, reportConfig: Report): Promise<UpdateReportActionResult> => {
      const res = await dispatch(updateReportAction(organisationId, reportId, reportConfig));
      return (res as unknown) as UpdateReportActionResult;
    },
    [],
  );
};

export const useUpdateReportLocally = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (organisationId: number, reportId: number, reportConfig: Report): Promise<UpdateReportActionResult> => {
      const res = await dispatch({
        type: ACTION_REPORTS_UPDATE,
        payload: reportConfig,
        params: { organisationId, reportId, reportConfig },
        status: 'success',
      });
      return (res as unknown) as UpdateReportActionResult;
    },
    [],
  );
};

export const useCreateReport = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (organisationId: number, reportConfig: Omit<Report, 'id'>): Promise<CreateReportActionResult> => {
      const res = await dispatch(createReportAction(organisationId, reportConfig));
      return (res as unknown) as CreateReportActionResult;
    },
    [],
  );
};
