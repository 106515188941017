import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { object, string } from 'yup';

import Icon from '../../../components/atoms/Icon';
import Notification from '../../../components/atoms/Notification';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import { useOnboardingContext } from '../../../components/contexts/OnboardingContext';
import Row from '../../../components/layout/Row';
import Spacer from '../../../components/layout/Spacer';
import { getCurrencyName, SUPPORTED_CURRENCIES } from '../../../components/molecules/CurrencySelect/currencySupport';
import Form from '../../../components/molecules/Form';
import FormFieldInput from '../../../components/molecules/FormFieldInput';
import FormFieldSelect from '../../../components/molecules/FormFieldSelect';
import OnboardingPageTitle from '../../../components/molecules/OnboardingPageTitle';
import { Organisation } from '../../../service';
import { theme } from '../../../theme';
import { getDealTrackingSource } from '../../../utils/customTracking';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import fetcher, { FetcherError } from '../../../utils/fetcher';
import { generateErrorMessage } from '../../../utils/restAPI';

export const SECTORS_CHOICES = [
  { value: 'SAAS_TECH', label: 'SaaS, Tech' },
  { value: 'MEDIA_CREATIVE_AGENCIES', label: 'Media & Creative Agencies' },
  { value: 'EVENTS_ENTERTAINMENT', label: 'Events & Entertainment' },
  { value: 'MANUFACTURING', label: 'Manufacturing' },
  { value: 'FINANCE_INCLUDING_ASSET_MANAGEMENT', label: 'Finance including Asset Management' },
  { value: 'EDUCATION_TRAINING', label: 'Education & Training' },
  { value: 'PROFESSIONAL_SERVICES', label: 'Professional Services' },
  { value: 'OTHER_B2B', label: 'Other B2B' },
  { value: 'B2C', label: 'B2C' },
];

const OnboardingOrganisationInfoContainer = styled.div`
  height: 100%;
  width: 100%;
  & > div:first-child {
    margin-top: -30px;
  }
`;

const StyledRowInput = styled(Row)<{ isOnFocus: boolean }>`
  padding-left: 24px;
  & > *:last-child {
    display: none;
  }
  ${({ isOnFocus, theme }) => {
    if (isOnFocus) {
      return css`
        padding: 18px 24px;
        position: relative;
        background-color: ${theme.palette.backgroundGrey};
        border-radius: 16px;
        & > *:last-child {
          display: flex;
        }
      `;
    }
  }}
`;

const FormMessageInfoContainer = styled(Row)`
  padding: 18px;
  background-color: #d1defc;
  border-radius: 8px;
  max-width: 398px;
  & h1 {
    margin-top: -30px;
  }
`;

const organisationInfoSchema = object().shape({
  name: string()
    .max(50, "Organisation name can't have more than 50 characters.")
    .required('Organisation name is required!'),
  default_currency_code: string().required('default_currency_code is required!'),
  sector: string().required('Sector is required!'),
  consolidation_type: string().required('Organisation consolidation_type is required!'),
});

type OrganisationResponse = {
  id: number;
  name: string;
  consolidation_type: Organisation['consolidation_type'];
};

const FormInfoMessage: React.FC<React.PropsWithChildren> = ({ children }) => (
  <FormMessageInfoContainer vAlign="flex-start" spacing="small">
    <Icon name="info" color={theme.palette.granite} size={5} />
    {children}
  </FormMessageInfoContainer>
);

const OnboardingOrganisationInfo: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState<
    'organisation_name' | 'currency' | 'consolidation_type' | 'sector' | ''
  >('organisation_name');
  const history = useHistory();
  const { onboardingConfig, setOnboardingConfig } = useOnboardingContext();
  if (onboardingConfig.organisation.name) {
    history.push('/onboarding/how-will-use');
  }

  const displayGenericErrorMessage = () => {
    Notification.error({ title: 'Failed to create organisation!' });
  };

  const handleSubmit = async (values: {
    name: string;
    default_currency_code: string;
    consolidation_type: Organisation['consolidation_type'];
    sector: string;
  }) => {
    setIsLoading(true);
    try {
      const res: OrganisationResponse = await fetcher('/api/v2/organisation/create_organisation', {
        method: 'POST',
        body: JSON.stringify({
          name: values.name,
          reporting_currency: values.default_currency_code,
          consolidation_type: values.consolidation_type,
          custom_tracking: getDealTrackingSource() || null,
          company_sector: values.sector,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!res.id) {
        displayGenericErrorMessage();
        return;
      }
      const organisationId: number = res.id;
      let children = onboardingConfig.organisation.children;
      if (values.consolidation_type === 'PARENT') {
        if (onboardingConfig.organisation.children.length < 2) {
          children.push(
            { organisationId: 0, name: '', children: [], name_short: '', default_currency_code: '' },
            { organisationId: 0, name: '', children: [], name_short: '', default_currency_code: '' },
          );
        }
      } else {
        children = [];
      }
      setOnboardingConfig({
        ...onboardingConfig,
        organisation: {
          ...values,
          organisationId,
          name_short: '',
          children,
        },
      });
      setIsLoading(false);
      history.push(`/onboarding/${organisationId}/how-will-you-use`);
    } catch (e: FetcherError | any) {
      setIsLoading(false);
      if (e.status === 400) {
        const parsedError = JSON.parse(e.info);
        Notification.error({ title: `Failed to create organisation: ${generateErrorMessage(parsedError)}` });
        return;
      }
      displayGenericErrorMessage();
    }
  };

  return (
    <OnboardingOrganisationInfoContainer>
      <OnboardingPageTitle
        title="Tell us about your organisation"
        subtitle="You’re setting up a new organisation on ScaleXP. Expecting an existing organisation? Please ask the owner to
          invite you. If you’re the owner, please ensure you are logged in using the same email."
        backLink="/onboarding"
      />

      <Spacer margin={{ top: 10 }}>
        <Form schema={organisationInfoSchema} onSubmit={handleSubmit}>
          <StyledRowInput spacing="xxlarge" isOnFocus={focusedInput === 'organisation_name'}>
            <FormFieldInput
              label="Company Name"
              onFocus={() => setFocusedInput('organisation_name')}
              defaultValue={onboardingConfig.organisation.name}
              customSize="large"
              placeholder="Your organisation’s name"
              name={'name'}
              width="454px"
              type="text"
            />
            <FormInfoMessage>
              <Typography weight="regular" color="black" size="medium">
                For consolidated companies, enter the name of the “Parent” company.
              </Typography>
            </FormInfoMessage>
          </StyledRowInput>
          <Spacer margin={{ top: 4 }}>
            <StyledRowInput spacing="xxlarge" isOnFocus={focusedInput === 'currency'}>
              <FormFieldSelect
                onClick={() => setFocusedInput('currency')}
                name="default_currency_code"
                defaultValue={onboardingConfig.organisation.default_currency_code}
                searchable
                label="Currency"
                selectHeight={250}
                customSize="xlarge"
                selectWidth={454}
                width="454px"
                placeholder="Select from the list"
                data={Object.keys(SUPPORTED_CURRENCIES)
                  .sort()
                  .map(currencyCode => ({ label: getCurrencyName(currencyCode), value: currencyCode }))}
              />
              <FormInfoMessage>
                <Typography weight="regular" color="black" size="medium">
                  The currency must be the same as the one in the accounting system. For consolidation “parent”
                  entities, choose any currency.
                </Typography>
              </FormInfoMessage>
            </StyledRowInput>
          </Spacer>
          <Spacer margin={{ top: 4 }}>
            <StyledRowInput spacing="xxlarge" isOnFocus={focusedInput === 'sector'}>
              <FormFieldSelect
                onClick={() => setFocusedInput('sector')}
                name="sector"
                defaultValue={onboardingConfig.organisation.default_currency_code}
                searchable
                label="Sector"
                selectHeight={250}
                customSize="xlarge"
                selectWidth={454}
                width="454px"
                placeholder="Select from the list"
                data={SECTORS_CHOICES}
              />
              <FormInfoMessage>
                <Typography weight="regular" color="black" size="medium">
                  ScaleXP will provide a suite of metrics tailored to your sector.
                </Typography>
              </FormInfoMessage>
            </StyledRowInput>
          </Spacer>
          <Spacer margin={{ top: 4 }}>
            <StyledRowInput spacing="xxlarge" isOnFocus={focusedInput === 'consolidation_type'}>
              <FormFieldSelect
                onClick={() => setFocusedInput('consolidation_type')}
                name="consolidation_type"
                label="How many companies are you connecting?"
                customSize="xlarge"
                defaultValue={
                  onboardingConfig.organisation.default_currency_code === ''
                    ? ''
                    : onboardingConfig.organisation.children.length > 0
                    ? 'PARENT'
                    : 'SINGLE'
                }
                selectWidth={454}
                width="454px"
                placement="bottomStart"
                placeholder="Select from the list"
                data={[
                  { label: '1 company', value: 'SINGLE' },
                  { label: '2 or more companies', value: 'PARENT' },
                ]}
              />
              <FormInfoMessage>
                <Typography weight="regular" color="black" size="medium">
                  Select the number of companies to be connected, excluding the parent company.
                </Typography>
              </FormInfoMessage>
            </StyledRowInput>
          </Spacer>
          <Spacer margin={{ top: 10 }} padding={{ bottom: 10 }}>
            <Row width="454px" vAlign="center" hAlign="flex-end">
              <TextButton
                type="submit"
                width="130px"
                variant="primary"
                size="large"
                loading={isLoading}
                disabled={isLoading}
              >
                Save
              </TextButton>
            </Row>
          </Spacer>
        </Form>
      </Spacer>
    </OnboardingOrganisationInfoContainer>
  );
};

export default OnboardingOrganisationInfo;
