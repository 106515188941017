import React from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { InputProps } from 'scalexp/components/atoms/Input';
import TextButton, { TextButtonProps } from 'scalexp/components/atoms/TextButton';
import Column, { ColumnProps } from 'scalexp/components/layout/Column';
import Row from 'scalexp/components/layout/Row';
import Form from 'scalexp/components/molecules/Form';
import FormFieldInput from 'scalexp/components/molecules/FormFieldInput';
import FormFieldPhoneInput from 'scalexp/components/molecules/FormFieldPhoneInput';
import { object, string } from 'yup';

const userInfoSchema = object().shape({
  first_name: string().required('First name is required!').min(2, 'First name must be at least 2 characters.'),
  last_name: string().required('Surname is required!').min(2, 'Surname must be at least 2 characters.'),
  phone_number: string()
    .nullable()
    .required('Phone is required!')
    .test('phone_number', 'Phone number is not valid', (value?: string | null) => isValidPhoneNumber(value || '')),
  job_title: string().required('Job title is required!'),
});

type UserProfileProps<Data extends FieldValues> = {
  width?: string;
  showEmail?: boolean;
  inputSize?: InputProps['customSize'];
  inputSpacing?: ColumnProps['spacing'];
  submitSize?: TextButtonProps['size'];
  handleSubmit: SubmitHandler<Data>;
  defaultValues: Data;
  isLoading: boolean;
};

const UserProfileForm = <Data extends FieldValues>({
  width = '100%',
  showEmail = false,
  inputSize = 'small',
  inputSpacing = 'medium',
  submitSize = 'medium',
  handleSubmit,
  defaultValues,
  isLoading,
}: UserProfileProps<Data>) => {
  const commonProps = { customSize: inputSize, width: width, type: 'text' };
  return (
    <Form schema={userInfoSchema} onSubmit={handleSubmit} defaultValues={defaultValues}>
      <Column width="100%" spacing={inputSpacing}>
        {showEmail && <FormFieldInput label="Email" placeholder="Email" name="email" disabled {...commonProps} />}
        <FormFieldInput label="First Name" placeholder="First name" name="first_name" {...commonProps} />
        <FormFieldInput label="Surname" placeholder="Surname" name="last_name" {...commonProps} />
        <FormFieldPhoneInput label="Phone" name="phone_number" {...commonProps} />
        <FormFieldInput label="Job title" placeholder="Job Title" name="job_title" {...commonProps} />
        <Row width={width} vAlign="center" hAlign="flex-end">
          <TextButton
            type="submit"
            width="130px"
            variant="primary"
            size={submitSize}
            loading={isLoading}
            disabled={isLoading}
          >
            Save
          </TextButton>
        </Row>
      </Column>
    </Form>
  );
};

export default UserProfileForm;
