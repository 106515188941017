import { ThunkAction as TA, ThunkDispatch as TD } from 'redux-thunk';

import { Store } from './state';

interface Action {
  type: string;
}

export type ThunkDispatch<Actions extends Action> = TD<Store, unknown, Actions>;

export type ThunkAction<Actions extends Action, Response = Promise<void>> = TA<Response, Store, unknown, Actions>;

interface PromiseActionPending<Type extends string, Params = void> {
  type: Type;
  params: Params;
  status: 'pending';
}

const setPending = <Type extends string, Params extends object>(
  type: Type,
  params: Params = {} as Params,
): PromiseActionPending<Type, Params> => ({
  type,
  params,
  status: 'pending',
});

interface PromiseActionComplete<Type extends string, R extends unknown, Params extends object> {
  type: Type;
  status: 'success';
  params: Params;
  payload: R;
}

const setComplete = <Type extends string, R extends unknown, Params extends object>(
  type: Type,
  payload: R,
  params: Params = {} as Params,
): PromiseActionComplete<Type, R, Params> => ({
  type,
  params,
  payload,
  status: 'success',
});

interface PromiseActionError<Type extends string, Params extends object> {
  type: Type;
  status: 'error';
  params: Params;
  error: Error;
}

const setError = <Type extends string, Params extends object>(
  type: Type,
  error: Error,
  params: Params = {} as Params,
): PromiseActionError<Type, Params> => ({
  type,
  params,
  error,
  status: 'error',
});

export type PromiseAction<ActionType extends string, Response = void, ActionParams extends object = {}> =
  | PromiseActionPending<ActionType, ActionParams>
  | PromiseActionComplete<ActionType, Response, ActionParams>
  | PromiseActionError<ActionType, ActionParams>;

type DispatchPromiseAction<
  ActionType extends string,
  Response = void,
  ActionParams extends object = {}
> = ThunkDispatch<PromiseAction<ActionType, Response, ActionParams>>;

type ThunkPromiseAction<ActionType extends string, Response = void, ActionParams extends object = {}> = ThunkAction<
  PromiseAction<ActionType, Response, ActionParams>,
  Promise<Response>
>;

export interface PayloadAction<Type extends string, Params extends object, Payload = undefined> {
  type: Type;
  payload?: Payload;
  params: Params;
}

export function promiseAction<Response = void>(
  type: string,
  asyncFn: () => Promise<Response>,
  params: object = {},
): ThunkPromiseAction<typeof type, Response, typeof params> {
  return async (dispatch: DispatchPromiseAction<typeof type, Response, typeof params>) => {
    dispatch(setPending(type, params));
    try {
      const response = await asyncFn();
      dispatch(setComplete(type, response, params));
      return response;
    } catch (error) {
      dispatch(setError(type, error as Error, params));
      throw error;
    }
  };
}
