import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export interface ColumnProps extends HTMLAttributes<HTMLDivElement> {
  width?: string;
  height?: string;
  spacing?: 'none' | 'tiny' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  vAlign?: 'flex-start' | 'start' | 'center' | 'end' | 'space-between' | 'space-around' | 'space-evenly';
  hAlign?: 'flex-start' | 'start' | 'center' | 'end' | 'stretch';
}

export const StyledColumn = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  min-height: 0;

  ${({ width }) => (width ? `width: ${width}` : '')};
  ${({ height }) => (height ? `height: ${height}` : '')};
  ${({ vAlign }) => (vAlign ? `justify-content: ${vAlign}` : '')};
  ${({ hAlign }) => (hAlign ? ` align-items: ${hAlign}` : '')};

  gap: ${({ theme, spacing }) => {
    switch (spacing) {
      case 'none':
        return '0px';
      case 'tiny':
        return theme.spacing(1);
      case 'small':
        return theme.spacing(2);
      case 'medium':
        return theme.spacing(4);
      case 'large':
        return theme.spacing(6);
      case 'xlarge':
        return theme.spacing(8);
      case 'xxlarge':
        return theme.spacing(10);
    }
  }};
`;

const Column: React.FC<React.PropsWithChildren<ColumnProps>> = ({
  vAlign = 'start',
  hAlign = 'start',
  spacing = 'medium',
  ...other
}) => {
  return <StyledColumn vAlign={vAlign} hAlign={hAlign} spacing={spacing} {...other} />;
};

export default Column;
