import { useAuth0 } from '@auth0/auth0-react';
import posthog from 'posthog-js';
import { Link, useHistory, useLocation } from 'react-router-dom';

import useHasCrmConnection from '../../../features/connections/ConnectionsContext/useHasCrmConnection';
import { useSelf } from '../../../service';
import { useHasReadAllPermission, useHasWriteAllPermission } from '../../../service/permissions';
import { clearSession } from '../../../utils/auth';
import fetcher from '../../../utils/fetcher';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import Typography from '../../atoms/Typography';
import { useUpdateCurrentOrganisationContext } from '../../contexts/OrganisationContext';
import useActiveOrganisation from '../../contexts/OrganisationContext/useActiveOrganisation';
import Aligner from '../../layout/Aligner';
import Row from '../../layout/Row';
import AuthHeader from '../../molecules/AuthHeader';
import DropDown from '../../molecules/DropDown';
import Select from '../../molecules/Select';
import {
  HeaderNavBar,
  HelpContainer,
  LogoContainer,
  NavItem as StyledNavItem,
  ProfileDetailsContainer,
  ProfilePicture,
  SwitchOrgContainer,
} from './Header.styles';

const NavItem = ({ icon, ...props }: { to: string; icon: string; children?: string; isActive?: boolean }) => {
  return (
    <StyledNavItem to={props.to} $isActive={props.isActive}>
      <Aligner direction="vertical" hAlign="center" vAlign="center" gap="4px">
        <Icon name={icon} color="#fff" />
        <Typography color="white" size="tiny" weight="regular">
          {props.children}
        </Typography>
      </Aligner>
    </StyledNavItem>
  );
};
export const Logo = () => (
  <Link to="/">
    <img alt="ScaleXP Logo" className="logo" />
  </Link>
);

const Header = () => {
  const pathname = useLocation()?.pathname;
  const history = useHistory();
  const { data: user } = useSelf();
  const { logout, user: auth0User } = useAuth0();
  const updateCurrentOrganisation = useUpdateCurrentOrganisationContext();
  const { enable_consolidated_customers_page, consolidation_type, prepaid_bills_location } = useActiveOrganisation();
  const allowConsolidatedDeferredRevenue = !!enable_consolidated_customers_page;
  const hasCrmConnection = useHasCrmConnection();
  const hasWriteAllPermission = useHasWriteAllPermission();
  const hasReadAllPermission = useHasReadAllPermission();
  const activeOrganisation = useActiveOrganisation();
  const availableOrganisations =
    user?.available_organisations
      ?.sort((o1, o2) => o1[0].name.localeCompare(o2[0].name))
      ?.flatMap(orgList => {
        return orgList.map((org, index) => {
          const consolidatedChild = index !== 0 && orgList.length > 1;
          const isFirstAndConsolidated = index === 0 && orgList.length > 1;
          return {
            ...org,
            consolidated: isFirstAndConsolidated,
            child: consolidatedChild,
          };
        });
      }) ?? [];

  const isLoadData = pathname?.startsWith('/oauth') || pathname?.startsWith('/metrics');
  const switchOrganisation = async (id: number) => {
    if (consolidation_type !== 'SINGLE') {
      posthog.capture('Onboarding - Consolidation - Milestone 1');
    }
    const org = availableOrganisations.find(org => org.id === id);
    updateCurrentOrganisation(org);

    // @ts-ignore
    window.initializeUserGuiding();
    // @ts-ignore
    window.userGuiding.identify(user?.user_id, {
      Organisation_selection: org?.consolidation_type,
    });

    window.location.replace('/');
  };
  const isAuthPage =
    pathname?.startsWith('/signin') ||
    pathname?.startsWith('/signup') ||
    pathname?.startsWith('/reset-password') ||
    pathname?.startsWith('/forgot-password');

  const isPrintPage = pathname.endsWith('/print');

  if (!user) {
    return null;
  }

  if (isAuthPage) {
    return <AuthHeader />;
  }

  if (isPrintPage) {
    return null;
  }

  const logoutAction = async () => {
    await fetcher('/logout/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    clearSession();
    await logout();
  };

  const goToSettings = () => {
    if (!isLoadData) {
      history.push('/settings/');
    } else {
      window.location.replace('/settings/');
    }
  };

  const handleOpenHelpCenter = () => {
    //@ts-ignore
    userGuiding.expandResourceCenter(3603);
  };

  const locationPath = prepaid_bills_location === 'p_and_l' ? '' : '/allocate';

  return (
    <HeaderNavBar>
      <LogoContainer>
        <Logo />
      </LogoContainer>

      <Divider direction="vertical" height="20px" />
      <NavItem icon="text_snippet" isActive={pathname?.startsWith('/reports')} to="/reports">
        Reports
      </NavItem>

      <NavItem icon="pie_chart" isActive={pathname?.startsWith('/dashboards')} to="/dashboards">
        Dashboards
      </NavItem>

      <NavItem icon="present_to_all" isActive={pathname?.startsWith('/presentations')} to={'/presentations'}>
        Presentations
      </NavItem>

      {hasReadAllPermission &&
        (allowConsolidatedDeferredRevenue ||
          activeOrganisation.consolidation_type !== 'PARENT' ||
          hasCrmConnection) && (
          <NavItem icon="supervised_user_circle" isActive={pathname?.startsWith('/analysis')} to="/analysis">
            Customers
          </NavItem>
        )}
      {hasWriteAllPermission &&
        activeOrganisation.consolidation_type !== 'PARENT' &&
        activeOrganisation.enable_prepayments && (
          <NavItem
            icon="supervised_user_circle"
            isActive={pathname?.startsWith('/suppliers')}
            to={`/suppliers${locationPath}`}
          >
            Cost
          </NavItem>
        )}

      <Divider direction="vertical" height="20px" />
      {hasWriteAllPermission && (
        <NavItem icon="upload" to={'/oauth/'} isActive={isLoadData}>
          Load Data
        </NavItem>
      )}
      <NavItem icon="settings" isActive={pathname?.startsWith('/settings')} to="/settings">
        Settings
      </NavItem>

      {user && (
        <>
          <SwitchOrgContainer>
            <Select
              data={availableOrganisations.map(org => ({ ...org, label: org.name, value: org.id }))}
              value={activeOrganisation?.organisation_id}
              onChange={switchOrganisation}
              selectWidth={'maxOptionWidth'}
              selectPlaceholder="Select Organisation"
              searchable={availableOrganisations.length > 10}
              renderSelectItem={(_, org) => (
                <Row spacing="small" vAlign="center">
                  {org.child && <Icon name="chevron_right" marginRight={0} />}
                  <Typography weight="regular">{org.name}</Typography>
                </Row>
              )}
            />
          </SwitchOrgContainer>
          <Divider direction="vertical" height="20px" />
          <div style={{ width: '6px' }} />
          <HelpContainer onClick={() => handleOpenHelpCenter()}>
            <Icon name="help" color="white" marginRight={0} />
            <Typography weight="semibold" color="white">
              Help
            </Typography>
          </HelpContainer>
          <DropDown placement="bottomEnd" customButton={<ProfilePicture src={auth0User?.picture} />}>
            <ProfileDetailsContainer>
              <Typography>
                {auth0User?.given_name ?? ''} {auth0User?.family_name ?? ''}
              </Typography>
              <Typography size="tiny">{user?.email ?? ''}</Typography>
            </ProfileDetailsContainer>
            <Divider />
            <DropDown.Item onClick={goToSettings} icon="manage_accounts">
              Settings
            </DropDown.Item>
            <DropDown.Item onClick={logoutAction} icon="logout">
              Logout
            </DropDown.Item>
          </DropDown>
        </>
      )}
    </HeaderNavBar>
  );
};

export default Header;
