import { useAuth0 } from '@auth0/auth0-react';

import Loader from '../../components/atoms/Loader';

const XeroSignIn = () => {
  const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <Loader center vertical />;
  }

  if (isAuthenticated) {
    window.location.href = '/';
    return null;
  }

  loginWithRedirect({
    connection: 'Xero',
  });

  return null;
};

export default XeroSignIn;
