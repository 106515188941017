import React, { HTMLAttributes, useCallback, useLayoutEffect, useState } from 'react';
import ReactGridLayout, { Layout } from 'react-grid-layout';
import styled from 'styled-components';

import { useDashboardEditorContext } from '../../../components/contexts/DashboardEditorContext';
import ChartCard from '../../chart/ChartCard';

export interface DashboardEditorContentProps {}
export interface StyledDashboardEditorContentProps extends HTMLAttributes<HTMLDivElement> {}
export const StyledDashboardEditorContent = styled.div<StyledDashboardEditorContentProps>`
  ${({ theme }) =>
    `
    min-height: 100vh;
    width: 100%;
    background-color: ${theme.palette.backgroundGrey};
    & .react-resizable-handle {
      opacity: 0;
    }
  `}
`;

const COLS_NUMBER = 4;
const rowHeight = 156;

const StyledCard = styled.div`
  height: 100%;
  weight: 100%;
`;

const DashboardEditorContent: React.FC<DashboardEditorContentProps> = () => {
  const { charts, primaryColour, secondaryColour, targetsColour } = useDashboardEditorContext();
  const [width, setWidth] = useState(1180);
  const containerRef = React.useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    let isMounted = true;
    const updateWidth = () => {
      if (!isMounted) {
        return;
      }

      const node = containerRef.current;
      if (node instanceof HTMLElement && node.offsetWidth) {
        setWidth(node.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      isMounted = false;
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const generateDOM = useCallback(() => {
    return charts
      .filter(chart => chart.enabled)
      .map(chart => {
        return (
          <StyledCard key={chart.id} data-grid={chart}>
            {chart.chart_data && (
              <ChartCard
                key={`${chart.id}-${primaryColour}-${secondaryColour}-${targetsColour}`}
                chartData={chart.chart_data}
              />
            )}
          </StyledCard>
        );
      });
  }, [charts]);

  const mapChartsToLayouts = (charts: any): Layout[] => {
    return charts.map((c: any) => ({
      i: c.id,
      x: c.x,
      y: c.y,
      w: c.w,
      h: c.h,
    }));
  };
  return (
    <>
      <StyledDashboardEditorContent ref={containerRef}>
        <ReactGridLayout
          width={width}
          rowHeight={rowHeight}
          layout={mapChartsToLayouts(charts)}
          cols={COLS_NUMBER}
          margin={[20, 20]}
          isDraggable={false}
          isResizable={false}
          isDroppable={false}
          compactType={'vertical'}
          isBounded
        >
          {generateDOM()}
        </ReactGridLayout>
      </StyledDashboardEditorContent>
    </>
  );
};
export default DashboardEditorContent;
