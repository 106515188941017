import React from 'react';
import TextButton from 'scalexp/components/atoms/TextButton';
import Typography from 'scalexp/components/atoms/Typography';
import Column from 'scalexp/components/layout/Column';
import Row from 'scalexp/components/layout/Row';
import BlurredModal from 'scalexp/components/molecules/BlurredModal';
import styled from 'styled-components';

interface UpgradePlanNotificationModalPropsType {
  show: boolean;
  onHide: () => void;
}

const UpgradePlanNotificationContainer = styled(Row)`
  padding: ${({ theme }) => theme.sizing(10)};

  & a {
    text-decoration: underline;
  }
`;
const UpgradePlanNotificationModal: React.FC<UpgradePlanNotificationModalPropsType> = ({ onHide, show }) => {
  return (
    <BlurredModal showModal={show} isBlurred={false} showCloseButton={false} width="800px" onHide={onHide}>
      <UpgradePlanNotificationContainer>
        <img width={311} src="/images/onboarding/onboarding_revenue.png" alt="upgrade plan scalexp" />
        <Column spacing="xlarge" hAlign="end">
          <Column>
            <Typography size="h6" weight="regular">
              Your upgrade request has been captured.{' '}
            </Typography>
            <Typography size="medium" weight="regular">
              A member of our team will reach out to help you get set up on the right plan. <br />
              <br />
              You can view our plans and pricing{' '}
              <a target="_blank" href="https://www.scalexp.com/pricing/" rel="noreferrer">
                here.
              </a>
            </Typography>
          </Column>
          <TextButton width="120px" onClick={onHide}>
            Done
          </TextButton>
        </Column>
      </UpgradePlanNotificationContainer>
    </BlurredModal>
  );
};

export default UpgradePlanNotificationModal;
