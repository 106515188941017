import { UserProfile } from './useUserSettings';

const mapDateFormatToDayjsFormat = (date_format?: UserProfile['date_format']) => {
  switch (date_format) {
    case 'MDY':
      return 'MM-DD-YYYY';
    case 'YMD':
      return 'YYYY-MM-DD';
    default:
      return 'DD-MM-YYYY';
  }
};

export default mapDateFormatToDayjsFormat;
