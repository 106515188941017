import colors from './colors';

/**
 * Before adding new colors here, discuss this with the team.
 * By design we should try to keep our palette minimal, consider using a color already available in the palette if possible
 */
export const theme = {
  palette: {
    colors: colors,
    midnight: colors.midnight,
    white: colors.white,
    granite: colors.granite,
    silver: colors.silver,
    backgroundGrey: colors.backgroundGrey,
    sky: colors.sky,
    cyan: colors.cyan,
    fieldGrey: colors.fieldGrey,
    midnightGradient: colors.midnightGradient,
    accountsType: {
      revenue: '#FFFBD6',
      expense: '#DFE2FF',
      liability: '#E2E7E5',
      assets: '#B2C9EA',
      equity: '#ABB6B6',
    },
    // Primary brand color
    primary: {
      offwhite: colors.blue[50],
      lighter: colors.blue[200],
      light: colors.blue[400],
      main: colors.blue[600],
      dark: colors.blue[800],
    },
    // Informational colors
    success: {
      offwhite: colors.green[50],
      lighter: colors.green[200],
      light: colors.green[400],
      main: colors.green[600],
      dark: colors.green[800],
    },
    warning: {
      offwhite: colors.yellow[50],
      lighter: colors.yellow[200],
      light: colors.yellow[400],
      main: colors.yellow[600],
      dark: colors.yellow[800],
    },
    danger: {
      offwhite: colors.red[50],
      lighter: colors.red[200],
      light: colors.red[400],
      main: colors.red[600],
      dark: colors.red[800],
    },
  },
  rounding: {
    small: '0.125em',
    normal: '0.25em',
  },
  sizing: (increments: number = 1) => `${increments * 4}px`,
  spacing: (increments: number = 1) => `${increments * 4}px`,
  shadow: {
    normal: '0px 1px 1px rgba(0, 0, 0, 0.1)',
    medium: '0px 3px 7px rgba(0, 0, 0, 0.15)',
    card: '0px 1px 4px 2px rgba(0, 0, 0, 0.05)',
  },
  font: {
    size: {
      tiny: '0.8125em	',
      small: '0.875rem',
      medium: '1rem',
      h6: '1.25rem',
      h5: '1.375rem',
      h4: '1.625rem',
      h3: '2rem',
      h2: '2.25rem',
      h1: '2.625rem',
    },
    weight: {
      regular: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
    },
    lineheight: {
      tiny: '1.125rem',
      small: '1.375rem',
      medium: '1.75rem',
      h6: '2rem',
      h5: '2.25rem',
      h4: '2.375rem',
      h3: '2.75rem',
      h2: '3rem',
      h1: '3rem',
    },
  },
  zIndex: {
    low: -1,
    regular: 1,
    medium: 10,
    high: 100,
    highest: 10000,
  },
} as const;
