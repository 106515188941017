import produce from 'immer';

import {
  ORGANISATIONS_CUSTOMERS_LOAD,
  CustomersActions,
  ORGANISATIONS_CUSTOMERS_MERGE,
  ORGANISATIONS_CUSTOMERS_UNMERGE,
  ORGANISATIONS_CUSTOMERS_CREATE_MANUAL_CUSTOMER,
  ORGANISATIONS_CUSTOMERS_UPDATE_CUSTOMER,
} from './actions';
import { OrganisationCustomers, Customers } from './types';

const customers = (prevState: OrganisationCustomers = {}, action: CustomersActions): OrganisationCustomers => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_CUSTOMERS_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            if (state[organisationId].value) {
              state[organisationId] = {
                status: 'success',
                error: undefined,
                value: {
                  ...state[organisationId].value,
                  ...action.payload.reduce((acc, customer) => {
                    acc[customer.id] = customer;
                    return acc;
                  }, {} as Customers),
                },
              };
            } else {
              state[organisationId] = {
                status: 'success',
                error: undefined,
                value: action.payload.reduce((acc, customer) => {
                  acc[customer.id] = customer;
                  return acc;
                }, {} as Customers),
              };
            }

            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }

      case ORGANISATIONS_CUSTOMERS_MERGE: {
        const { organisationId, customer, onto } = action.params;
        switch (action.status) {
          case 'pending':
          case 'error':
          default:
            return;

          case 'success':
            if (!state[organisationId].value) {
              return;
            }
            delete state[organisationId].value![customer];
            state[organisationId].value![onto] = action.payload;
            return;
        }
      }
      case ORGANISATIONS_CUSTOMERS_UPDATE_CUSTOMER: {
        const { organisationId, customerId, name, is_excluded } = action.params;
        switch (action.status) {
          case 'pending':
          case 'error':
          default:
            return;

          case 'success':
            if (!state[organisationId].value) {
              return;
            }

            if (name !== undefined) {
              state[organisationId].value![customerId].name = name;
            }
            if (is_excluded !== undefined) {
              state[organisationId].value![customerId].is_excluded = is_excluded;
            }
            return;
        }
      }
      case ORGANISATIONS_CUSTOMERS_UNMERGE: {
        const { organisationId, customer, SXPCustomer, source } = action.params;
        switch (action.status) {
          case 'pending':
            if (!state[organisationId].value) {
              return;
            }
            const customers = state[organisationId].value![SXPCustomer][source];
            state[organisationId].value![SXPCustomer][source] = customers.filter(
              source_customer => source_customer.id !== customer,
            );
            return;
          case 'error':
          default:
            return;
          case 'success':
            if (!state[organisationId].value) {
              return;
            }

            state[organisationId].value![action.payload.id] = action.payload;
            return;
        }
      }

      case ORGANISATIONS_CUSTOMERS_CREATE_MANUAL_CUSTOMER: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
          case 'error':
          default:
            return;

          case 'success':
            if (!state[organisationId]?.value) {
              state[organisationId] = {
                status: 'success',
                error: undefined,
                value: {},
              };
              return;
            }
            // @ts-ignore
            state[organisationId].value![action.payload.id] = action.payload;
            return;
        }
      }

      default:
        return state;
    }
  });
};

export default customers;
