import type { RedirectLoginOptions } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useEffect } from 'react';
import { Organisation } from 'scalexp/service';
import { useOrganisation } from 'scalexp/store/state/organisations/hooks';
import { useAccessTokenListener } from 'scalexp/utils/accessToken';
import { clearSession } from 'scalexp/utils/auth';
import { logUndefinedOrgFunc } from 'scalexp/utils/logger';
import { setCurrentOrganisation } from 'scalexp/utils/organisation';

import Loader from '../../atoms/Loader';
import Message from '../../atoms/Loader';
import useActiveOrganisationAuth0Id from './useActiveOrganisationAuth0Id';
import useActiveOrganisationId from './useActiveOrganisationId';

export interface OrganisationContextProps {}

export const OrganisationContext = React.createContext<Organisation>(undefined!);
export const UpdateCurrentOrganisationContext = React.createContext<Function>(undefined!);

export const OrganisationProvider: React.FC<React.PropsWithChildren<OrganisationContextProps>> = ({ children }) => {
  const {
    loginWithRedirect,
    user: auth0User,
    isLoading: isLoadingAuth0,
    isAuthenticated: isAuthenticatedAuth0,
    logout,
  } = useAuth0();
  const organisationId = useActiveOrganisationId();
  const organisationAuth0Id = useActiveOrganisationAuth0Id();
  const organisation = useOrganisation(organisationId);
  const switchOrganisation = useCallback((organisation: Organisation) => {
    // logic to update the active organisation
    setCurrentOrganisation(organisation);
    organisation === undefined && logUndefinedOrgFunc('switchOrganisation');
  }, []);

  useAccessTokenListener();

  useEffect(() => {
    if (!isLoadingAuth0 && !isAuthenticatedAuth0) {
      clearSession();
      logout();
    }
  }, [isAuthenticatedAuth0, isAuthenticatedAuth0]);

  useEffect(() => {
    const redirectConfig: RedirectLoginOptions = {
      organization: organisationAuth0Id,
    };

    // users auth0 obj is not linked in with the organisation, requires logging-in again with the organisation param
    if (!auth0User?.org_id || auth0User?.org_id !== organisationAuth0Id) {
      loginWithRedirect(redirectConfig);
    }
  }, [organisationAuth0Id, auth0User]);

  useEffect(() => {
    if (organisation.value?.is_onboarding_paused) {
      window.location.href = '/onboarding/rejection';
    }
  }, [organisation]);

  if (isLoadingAuth0 || !organisation.value) {
    return <Loader vertical backdrop />;
  }

  if (organisation.status === 'error') {
    return <Message type="error" description="Failed to load Organisation" />;
  }

  return (
    <OrganisationContext.Provider value={organisation.value}>
      <UpdateCurrentOrganisationContext.Provider value={switchOrganisation}>
        {children}
      </UpdateCurrentOrganisationContext.Provider>
    </OrganisationContext.Provider>
  );
};

export const useOrganisationContext = () => {
  const value = React.useContext(OrganisationContext);

  if (!value) {
    throw new Error('OrganisationProvider not found!');
  }

  return value;
};

export const useUpdateCurrentOrganisationContext = () => {
  const value = React.useContext(UpdateCurrentOrganisationContext);

  if (!value) {
    throw new Error('OrganisationProvider not found!');
  }

  return value;
};
