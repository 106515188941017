import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import Icon from '../../atoms/Icon';
import Loader from '../../atoms/Loader';
import Aligner from '../../layout/Aligner';

export interface MessageProps extends HTMLAttributes<HTMLDivElement> {
  // TODO: finish other variants
  variant?: 'info' | 'danger' | 'warning' | 'success';
  size?: 'medium' | 'large';
  width?: string;
  rounded?: boolean;
  withIcon?: boolean;
  loading?: boolean;
  customHeight?: string;
  onHide?: () => void;
}

const StyledMessage = styled.div<MessageProps>`
  min-height: 45px;
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.05);

  ${({ theme, rounded }) => css`
    border-radius: ${rounded ? theme.rounding.normal : '0px'};
    background-color: ${theme.palette.white};
    color: ${theme.palette.danger.dark};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size.tiny};
    line-height: ${theme.font.lineheight.small};
  `}

  ${({ theme, variant }) => {
    if (variant === 'info') {
      return css`
        border: 1px solid ${theme.palette.primary.main};
        color: ${theme.palette.primary.main};
      `;
    }
    if (variant === 'success') {
      return css`
        border: 1px solid ${theme.palette.success.lighter};
        color: ${theme.palette.success.main};
      `;
    }
    if (variant === 'warning') {
      return css`
        border: 1px solid #bd5b00;
        color: #bd5b00;
      `;
    }
    if (variant === 'danger') {
      return css`
        border: 1px solid ${theme.palette.danger.light};
        color: ${theme.palette.danger.dark};
      `;
    }
    return ``;
  }}

  ${({ theme, size, customHeight }) => {
    if (size === 'medium') {
      return css`
        height: ${customHeight ? customHeight : '45px'};
        padding: 0 ${theme.spacing(4)} 0 ${theme.spacing(1)};
      `;
    }
    if (size === 'large') {
      return css`
        height: ${customHeight ? customHeight : '64px'};
        padding: 0 ${theme.spacing(3)} 0 ${theme.spacing(3)};
      `;
    }
    return ``;
  }}

  & > span {
    cursor: pointer;
  }
`;

const StyledIconContainer = styled.div<MessageProps>`
  height: 35px;
  min-width: 35px;
  max-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.sizing(1)};

  ${({ theme, variant }) => {
    if (variant === 'info') {
      return css`
        background-color: ${theme.palette.primary.offwhite};
        color: ${theme.palette.primary.main};

        & .rs-loader-spin::after {
          border-color: ${theme.palette.primary.main} transparent transparent;
        }
      `;
    }
    if (variant === 'success') {
      return css`
        background-color: ${theme.palette.success.offwhite};
        color: ${theme.palette.success.lighter};

        & .rs-loader-spin::after {
          border-color: ${theme.palette.success.lighter} transparent transparent;
        }
      `;
    }
    if (variant === 'warning') {
      return css`
        background-color: #ffefdf;
        color: #bd5b00;

        & .rs-loader-spin::after {
          border-color: #bd5b00 transparent transparent;
        }
      `;
    }
    if (variant === 'danger') {
      return css`
        background-color: ${theme.palette.danger.offwhite};
        color: ${theme.palette.danger.light};

        & .rs-loader-spin::after {
          border-color: ${theme.palette.danger.light} transparent transparent;
        }
      `;
    }
    return ``;
  }}

  & .rs-loader-spin::after {
    border-width: 2px;
  }

  & .material-icons {
    color: inherit;
  }
`;

const Message: React.FC<React.PropsWithChildren<MessageProps>> = ({
  rounded = true,
  variant = 'danger',
  width = '100%',
  size = 'large',
  withIcon = false,
  customHeight = undefined,
  children,
  loading = false,
  onHide,
  ...other
}) => {
  const iconName =
    variant === 'success'
      ? 'check_circle_outline'
      : variant === 'warning'
      ? 'warning_amber'
      : variant === 'danger'
      ? 'warning'
      : variant === 'info'
      ? 'info'
      : '';
  return (
    <StyledMessage variant={variant} size={size} width={width} customHeight={customHeight} rounded={rounded} {...other}>
      <Aligner width="100%" hAlign="flex-start">
        {withIcon && (
          <StyledIconContainer variant={variant} size={size}>
            {loading ? <Loader /> : <Icon name={iconName} marginRight={0} />}
          </StyledIconContainer>
        )}
        <Aligner width="100%">{children} </Aligner>
      </Aligner>
      {onHide && <Icon name="close" color="inherit" marginRight={0} onClick={() => onHide()} />}
    </StyledMessage>
  );
};

export default Message;
