import React, { useMemo } from 'react';
import {
  MetricSchema2DataSourceEntityAccount,
  MetricSchema2DataType,
  MetricSchema2Decimals,
  MetricSchema2Simple,
} from 'scalexp/utils/metrics/metricSchema2';
import { getSimpleMetricSchemaFromNode } from 'scalexp/utils/metrics/ms2utils';
import styled from 'styled-components';

import Typography from '../../../../components/atoms/Typography';
import useActiveOrganisation from '../../../../components/contexts/OrganisationContext/useActiveOrganisation';
import useCurrencySelection from '../../../../components/molecules/CurrencySelect/useCurrencySelection';
import { useDateSelectionDate } from '../../../../components/molecules/DateSelect/useDateSelection';
import { useAccounts } from '../../../../store/state/accounts/hooks';
import { Account } from '../../../../store/state/accounts/types';
import { Column } from '../../../../store/state/columnsPresets/types';
import useMetricSchemaSeries from '../../../../utils/metrics/useMetricSchemaSeries';
import { formatValue } from '../../ReportEditor/helpers';
import { reportEditorcalculationRequest } from '../ReportEditorCustomRow';

interface ReportEditorDisplayAccountRowProps {
  accountId: Account['account_id'];
  decimals: MetricSchema2Decimals;
}

const StyledAccountContainer = styled.div`
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: 20px 1fr 160px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};

  span:last-child {
    text-align: end;
  }
`;

const COLUMNS_12_MONTHS: Column[] = [...new Array(12)].map((_, index) => ({
  id: `${index}`,
  type: 'AGGREGATION',
  name: `${index} month`,
  aggregate_over: { value_basis: 'ACTUAL', period: 'MONTH', relative_month_offset: -index },
}));

const ReportEditorDisplayAccountRow: React.FC<React.PropsWithChildren<ReportEditorDisplayAccountRowProps>> = ({
  accountId,
  decimals,
}) => {
  const date = useDateSelectionDate();
  const [currencyCode] = useCurrencySelection();

  const metricSchema: MetricSchema2Simple<MetricSchema2DataSourceEntityAccount> = getSimpleMetricSchemaFromNode({
    operator: 'add',
    data: {
      operator: 'entity',
      entity: 'account',
      accountId: accountId,
      isCashflow: false,
    },
  });
  const node = metricSchema.nodes[0];

  const organisation = useActiveOrganisation();
  const { organisation_id, default_currency_code, financial_year_start } = organisation;
  const seriesVS = useMetricSchemaSeries(
    metricSchema,
    reportEditorcalculationRequest(date, currencyCode || default_currency_code),
  );
  const accountsVS = useAccounts(organisation_id);
  const account = accountsVS.value?.[node.data.accountId];

  const values: (number | null)[] = useMemo(() => {
    const series = seriesVS.value;
    if (!series) {
      return [];
    }

    return series.map(serie => (serie ? Number(serie.value) : null));
  }, [seriesVS.value, COLUMNS_12_MONTHS, financial_year_start]);

  const hasValuesLastYear = useMemo(() => values.some(value => value), [values]);

  if (!hasValuesLastYear) {
    return null;
  }

  if (!account) {
    console.log(`Warning! No account found with id ${node.data.accountId}`);
    return null;
  }
  const accountOrganisation =
    organisation.children.find(org => org.organisation_id === account.organisation_id) || organisation;
  let title = account.name;
  if (account.code) {
    title = account.code + ' - ' + title;
  }
  if (accountOrganisation?.name_short) {
    title = accountOrganisation?.name_short + ': ' + title;
  }

  const dataType: MetricSchema2DataType =
    (seriesVS.value?.[0]?.data_type.toLowerCase() as MetricSchema2DataType) || 'monetary';

  return (
    <StyledAccountContainer>
      <div />
      <Typography color="secondary">{title}</Typography>
      <Typography color="secondary">
        {formatValue(values[0], currencyCode || default_currency_code, dataType, decimals)}
      </Typography>
    </StyledAccountContainer>
  );
};

export default ReportEditorDisplayAccountRow;
