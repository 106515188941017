import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useUpdateOrganisation } from '../../../../store/state/organisations/hooks';
import Notification from '../../../atoms/Notification';
import Typography from '../../../atoms/Typography';
import useActiveOrganisation from '../../../contexts/OrganisationContext/useActiveOrganisation';
import Row from '../../../layout/Row';
import ColourPicker from '../../../molecules/ColourPicker';

export interface OrganisationBrandColorsCardProps {}

const StyledOrganisationBrandColorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} 0 ${theme.spacing(5)} ${theme.spacing(6)}`};

  &:first-of-type {
    padding-top: ${({ theme }) => theme.spacing(5)};
  }
`;

const OrganisationBrandColorsCard: React.FC<React.PropsWithChildren<OrganisationBrandColorsCardProps>> = () => {
  const organisation = useActiveOrganisation();
  const organisationId = organisation.organisation_id;
  const primary_color = organisation.primary_brand_colour;
  const secondary_color = organisation.secondary_brand_colour;
  const updateOrganisation = useUpdateOrganisation();
  const location = useLocation();
  const isOnboarding = location.pathname.includes('onboarding');

  const handleUpdatePrimaryBrandColor = async (value: string) => {
    const success: boolean = await updateOrganisation(organisationId, {
      primary_brand_colour: value,
    });
    if (success) {
      Notification.success({
        title: 'Successfully updated brand colours!',
        placement: 'bottomEnd',
      });
    } else {
      Notification.error({ title: 'Failed to update primary colour', placement: 'bottomEnd' });
    }
  };

  const handleUpdateSecondaryBrandColor = async (value: string) => {
    const success: boolean = await updateOrganisation(organisationId, {
      secondary_brand_colour: value,
    });
    if (success) {
      Notification.success({
        title: 'Successfully updated brand colours!',
        placement: 'bottomEnd',
      });
    } else {
      Notification.error({ title: 'Failed to update secondary colour', placement: 'bottomEnd' });
    }
  };

  return (
    <StyledOrganisationBrandColorsContainer>
      <Typography size="medium" weight="semibold">
        {isOnboarding ? 'Add Your Brand Colours' : 'Brand colours'}
      </Typography>
      <Row vAlign="center">
        <ColourPicker
          key={primary_color}
          showBrandColor={false}
          placement="auto"
          color={primary_color || '#000000'}
          handleColor={handleUpdatePrimaryBrandColor}
        />
        <Typography weight="regular">Primary Colour</Typography>
      </Row>
      <Row vAlign="center">
        <ColourPicker
          key={secondary_color}
          showBrandColor={false}
          placement="auto"
          color={secondary_color || '#000000'}
          handleColor={handleUpdateSecondaryBrandColor}
        />
        <Typography weight="regular">Secondary Colour</Typography>
      </Row>
    </StyledOrganisationBrandColorsContainer>
  );
};
export default OrganisationBrandColorsCard;
