import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { OnboardingProvider } from '../../components/contexts/OnboardingContext';
import OnboardingConsolidatedChildren from '../../features/onboarding/OnboardingConsolidatedChildren';
import OnboardingHeader from '../../features/onboarding/OnboardingHeader';
import OnboardingHowWillUse from '../../features/onboarding/OnboardingHowWillUse';
import OnboardingOrganisationInfo from '../../features/onboarding/OnboardingOrganisationInfo';
import OnboardingUserInfo from '../../features/onboarding/OnboardingUserInfo';
import OnboaringRejection from '../../features/onboarding/OnboaringRejection';

const OnboardingContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 60px);
  padding-left: 207px;
  padding-top: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.palette.white};
`;

const OnboardingPage = () => {
  return (
    <OnboardingProvider>
      <div>
        <OnboardingHeader />
        <OnboardingContainer>
          <Switch>
            <Route path="/onboarding/rejection">
              <OnboaringRejection />
            </Route>
            <Route exact path="/onboarding/:organisationId/consolidated-organisation">
              <OnboardingConsolidatedChildren />
            </Route>
            <Route exact path="/onboarding/:organisationId/how-will-you-use">
              <OnboardingHowWillUse />
            </Route>
            <Route exact path="/onboarding/organisation-info">
              <OnboardingOrganisationInfo />
            </Route>
            <Route path="/">
              <OnboardingUserInfo />
            </Route>
          </Switch>
        </OnboardingContainer>
      </div>
    </OnboardingProvider>
  );
};

export default OnboardingPage;
