import { InvoicedRevenueMetricId } from '../../../service/types/metricSchema';
import { PromiseAction } from '../../actions';
import { ValueState } from '../../values';
import { Account } from '../accounts/types';
import { Organisation } from '../organisations/types';
import { OrganisationData } from '../types';

export type ConnectionType = 'manual' | 'xero' | 'quickbooks' | 'salesforce' | 'hubspot';

export type OrganisationBudgetSets = OrganisationData<BudgetSetDetailsVS>;

export type BudgetSetDetailsVS = ValueState<BudgetSetDetails[]>;

export interface BudgetSetDetails {
  id: number;
  organisation_id: Organisation['organisation_id'];
  name: string;
  connection_name: ConnectionType;
  created_at: string;
  start_month: string;
  end_month: string;
  updated_at: string;
  status: string;
  modified_by: string;
  account_ids: Account['account_id'][];
  invoiced_revenue_metric_ids: InvoicedRevenueMetricId[];
}

export interface ListBudgetSetsResponse {
  budget_sets: BudgetSetDetails[];
}

export interface PatchBudgetSetBody {
  name: string;
}

export const ORGANISATIONS_BUDGETSETS_LOAD = '[BudgetSet] LOAD';
export const ORGANISATIONS_BUDGETSETS_DELETE = '[BudgetSet] DELETE';
export const ORGANISATIONS_BUDGETSETS_RENAME = '[BudgetSet] RENAME';

export type budgetSetsActions = loadBudgetSetsAction | deleteBudgetSetAction | renameBudgetSetAction;

export type loadBudgetSetsAction = PromiseAction<
  typeof ORGANISATIONS_BUDGETSETS_LOAD,
  BudgetSetDetails[],
  { organisationId: number }
>;

export type deleteBudgetSetAction = PromiseAction<
  typeof ORGANISATIONS_BUDGETSETS_DELETE,
  undefined,
  { organisationId: number; budgetsetId: number }
>;

export type renameBudgetSetAction = PromiseAction<
  typeof ORGANISATIONS_BUDGETSETS_RENAME,
  undefined,
  { organisationId: number; budgetsetId: number; budgetsetName: string }
>;
