import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export interface BoxProps extends HTMLAttributes<HTMLDivElement> {
  withBorder?: boolean;
  rounded?: boolean;
  width?: number;
}

const StyledBox = styled.div<BoxProps>`
  ${({ theme, withBorder }) => `
    padding: ${theme.spacing(10)} ${theme.spacing(15)};
    background: ${theme.palette.white};
  `}
  ${({ theme, rounded }) => `border-radius: ${rounded ? theme.rounding.normal : 'none'};`}
  ${({ theme, withBorder }) => (withBorder ? `border: 1px solid ${theme.rounding.normal};` : ``)}
  ${({ width }) => `width: ${width}px;`}
`;

const Box: React.FC<React.PropsWithChildren<BoxProps>> = ({
  rounded = true,
  withBorder = true,
  children,
  ...other
}) => {
  return (
    <StyledBox rounded={rounded} withBorder={withBorder} {...other}>
      {children}
    </StyledBox>
  );
};

export default Box;
