// Supported dates, for now only monthly increments at first day of the month
// Expects ISO8601 Date
export const DATE_FORMAT = 'YYYY-MM-DD';
export const NEW_DATE_FORMAT = 'YYYY-MM';
export const DATE_FORMAT_DISPLAY = 'MMM YYYY';

// TODO: improve regex to handle cases like 2021-99-99
export const DATE_FORMAT_REGEX = new RegExp('^20[0-9]{2}-[0-9]{2}-[0-9]{2}$');

export const isSupportedDate = (date: string | null | undefined) => {
  return !!date && DATE_FORMAT_REGEX.test(date);
};
