import posthog from 'posthog-js';
import React from 'react';

import { POSTHOG_TOKEN } from '../../../config';

export interface PosthogContextProps {}

type Posthog = typeof posthog;

export const PosthogContext = React.createContext<Partial<Posthog> | undefined>(undefined!);

export const PosthogProvider: React.FC<React.PropsWithChildren<PosthogContextProps>> = ({ children }) => {
  React.useEffect(() => {
    posthog.init(POSTHOG_TOKEN, {
      api_host: 'https://app.posthog.com',
    });
  }, []);

  return <PosthogContext.Provider value={posthog}>{children}</PosthogContext.Provider>;
};

export const usePosthogContext = () => {
  const value = React.useContext(PosthogContext);

  if (!value) {
    throw new Error('PosthogProvider not found!');
  }

  return value;
};
