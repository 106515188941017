import Notification from '../../../components/atoms/Notification';
import { promiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectConsolidatedBudgetSets } from './selectors';
import {
  ConsolidatedBudgetSet,
  consolidatedBudgetSetsActions,
  ListConsolidatedBudgetSetsResponse,
  ORGANISATIONS_CONSOLIDATED_BUDGETSETS_CREATE,
  ORGANISATIONS_CONSOLIDATED_BUDGETSETS_DELETE,
  ORGANISATIONS_CONSOLIDATED_BUDGETSETS_LOAD,
  ORGANISATIONS_CONSOLIDATED_BUDGETSETS_UPDATE,
} from './types';

export const loadBudgetSets = (organisationId: number): ThunkAction<consolidatedBudgetSetsActions> => async (
  dispatch: ThunkDispatch<consolidatedBudgetSetsActions>,
  getState,
) => {
  const state = getState();
  const budgetSets = selectConsolidatedBudgetSets(state, organisationId);

  if (budgetSets?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CONSOLIDATED_BUDGETSETS_LOAD,
        () =>
          http<ConsolidatedBudgetSet[], ListConsolidatedBudgetSetsResponse>(
            `/api/v1/organisations/${organisationId}/consolidated_budget_sets`,
            undefined,
            response => response.budgets,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const createBudget = (
  organisationId: number,
  budgetSet: Omit<ConsolidatedBudgetSet, 'id'>,
): ThunkAction<consolidatedBudgetSetsActions> => async (
  dispatch: ThunkDispatch<consolidatedBudgetSetsActions>,
  getState,
) => {
  const state = getState();
  const budgetSets = selectConsolidatedBudgetSets(state, organisationId);

  if (budgetSets?.status === 'pending') {
    return;
  }

  try {
    const response = await http<ConsolidatedBudgetSet, ConsolidatedBudgetSet>(
      `/api/v1/organisations/${organisationId}/consolidated_budget_sets`,
      {
        method: 'POST',
        body: budgetSet,
      },
    );

    dispatch({
      type: ORGANISATIONS_CONSOLIDATED_BUDGETSETS_CREATE,
      params: { organisationId },
      payload: response,
    });
  } catch (e) {
    console.log('Error dispatching action', e);
    Notification.error({ title: 'Create consolidated budget failed', placement: 'bottomEnd' });
  }
};

export const updateBudget = (
  organisationId: number,
  budgetSetId: number,
  budgetSet: ConsolidatedBudgetSet,
): ThunkAction<consolidatedBudgetSetsActions> => async (
  dispatch: ThunkDispatch<consolidatedBudgetSetsActions>,
  getState,
) => {
  const state = getState();
  const budgetSets = selectConsolidatedBudgetSets(state, organisationId);

  if (budgetSets?.status === 'pending') {
    return;
  }

  const oldBudgetSet = budgetSets.value?.find?.(bset => bset.id === budgetSetId);

  try {
    dispatch({
      type: ORGANISATIONS_CONSOLIDATED_BUDGETSETS_UPDATE,
      params: { organisationId, budgetSetId },
      payload: budgetSet,
    });

    http<ConsolidatedBudgetSet[], ConsolidatedBudgetSet>(
      `/api/v1/organisations/${organisationId}/consolidated_budget_sets/${budgetSetId}`,
      { method: 'PUT', body: budgetSet },
    );
  } catch (e) {
    console.log('Error dispatching action', e);
    Notification.error({ title: 'Update consolidated budget failed', placement: 'bottomEnd' });

    dispatch({
      type: ORGANISATIONS_CONSOLIDATED_BUDGETSETS_UPDATE,
      params: { organisationId, budgetSetId },
      payload: oldBudgetSet,
    });
  }
};

export const deleteBudget = (
  organisationId: number,
  budgetSetId: number,
): ThunkAction<consolidatedBudgetSetsActions> => async (
  dispatch: ThunkDispatch<consolidatedBudgetSetsActions>,
  getState,
) => {
  const state = getState();
  const budgetSets = selectConsolidatedBudgetSets(state, organisationId);

  if (budgetSets?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CONSOLIDATED_BUDGETSETS_DELETE,
        () =>
          http<ConsolidatedBudgetSet[], ConsolidatedBudgetSet>(
            `/api/v1/organisations/${organisationId}/consolidated_budget_sets/${budgetSetId}`,
            { method: 'DELETE' },
          ),
        {
          organisationId,
          budgetsetId: budgetSetId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
