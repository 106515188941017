import { NativeMetricId } from '../../../service/types/metricSchema';
import { PayloadAction, promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { setAccountGroupId } from '../accounts/actions';
import { Account } from '../accounts/types';
import { Organisation } from '../organisations/types';
import { selectAccountGroups } from './selectors';
import { AccountGroup } from './types';

export const ORGANISATIONS_ACCOUNT_GROUPS_LOAD = '[accountGroups] LOAD';
export const ORGANISATIONS_ACCOUNT_GROUPS_RENAME_NATIVE_METRIC = '[accountGroups] RENAME_NATIVE_METRIC';
export const ORGANISATIONS_ACCOUNT_GROUPS_MOVE_ACCOUNTS_TO_NATIVE_METRIC =
  '[accountGroups] MOVE_ACCOUNTS_TO_NATIVE_METRIC';
export const ORGANISATIONS_ACCOUNT_GROUPS_CREATE_GROUP = '[accountGroups] CREATE_GROUP';
export const ORGANISATIONS_ACCOUNT_GROUPS_RENAME_GROUP = '[accountGroups] RENAME_GROUP';
export const ORGANISATIONS_ACCOUNT_GROUPS_REORDER_GROUP = '[accountGroups] REORDER_GROUP';
export const ORGANISATIONS_ACCOUNT_GROUPS_DELETE_GROUP = '[accountGroups] DELETE_GROUP';
export const ORGANISATIONS_ACCOUNT_GROUPS_MOVE_ACCOUNTS_TO_GROUP = '[accountGroups] MOVE_ACCOUNTS_TO_GROUP';
export const ORGANISATIONS_ACCOUNT_GROUPS_CREATE_SUBGROUP = '[accountGroups] CREATE_SUBGROUP';
export const ORGANISATIONS_ACCOUNT_GROUPS_RENAME_SUBGROUP = '[accountGroups] RENAME_SUBGROUP';
export const ORGANISATIONS_ACCOUNT_GROUPS_REORDER_SUBGROUP = '[accountGroups] REORDER_SUBGROUP';
export const ORGANISATIONS_ACCOUNT_GROUPS_DELETE_SUBGROUP = '[accountGroups] DELETE_SUBGROUP';
export const ORGANISATIONS_ACCOUNT_GROUPS_MOVE_ACCOUNTS_TO_SUBGROUP = '[accountGroups] MOVE_ACCOUNTS_TO_SUBGROUP';
export const ORGANISATIONS_ACCOUNT_GROUPS_CASCADE_GROUPS = '[accountGroups] CASCADE_GROUPS';

export type AccountGroupsActions =
  | LoadAccountGroupsAction
  | RenameNativeMetricAction
  | MoveAccountsToNativeMetricAction
  | CreateGroupAction
  | RenameGroupAction
  | ReorderGroupAction
  | DeleteGroupAction
  | MoveAccountsToGroupAction
  | CreateSubGroupAction
  | RenameSubGroupAction
  | ReorderSubGroupAction
  | DeleteSubGroupAction
  | MoveAccountsToSubGroupAction
  | CascadeGroupsAction;

export type LoadAccountGroupsAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_LOAD,
  AccountGroup[],
  { organisationId: Organisation['organisation_id'] }
>;

export type RenameNativeMetricAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_RENAME_NATIVE_METRIC,
  { organisationId: Organisation['organisation_id']; tag: NativeMetricId; name: string },
  { organisationId: Organisation['organisation_id']; tag: NativeMetricId; name: string }
>;
export type MoveAccountsToNativeMetricAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_MOVE_ACCOUNTS_TO_NATIVE_METRIC,
  { organisationId: Organisation['organisation_id']; tag: NativeMetricId; accountIds: Account['account_id'][] },
  { organisationId: Organisation['organisation_id']; tag: NativeMetricId; accountIds: Account['account_id'][] }
>;
export type CreateGroupAction = PayloadAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_CREATE_GROUP,
  {
    organisationId: Organisation['organisation_id'];
    tag: NativeMetricId;
    name: string;
    accountIds: Account['account_id'][];
  },
  AccountGroup
>;
export type RenameGroupAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_RENAME_GROUP,
  { organisationId: Organisation['organisation_id']; groupId: AccountGroup['id']; name: string },
  { organisationId: Organisation['organisation_id']; groupId: AccountGroup['id']; name: string }
>;
export type ReorderGroupAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_REORDER_GROUP,
  { organisationId: Organisation['organisation_id']; tag: NativeMetricId; orderedGroupIds: AccountGroup['id'][] },
  { organisationId: Organisation['organisation_id']; tag: NativeMetricId; orderedGroupIds: AccountGroup['id'][] }
>;
export type DeleteGroupAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_DELETE_GROUP,
  { organisationId: Organisation['organisation_id']; groupId: AccountGroup['id'] },
  { organisationId: Organisation['organisation_id']; groupId: AccountGroup['id'] }
>;
export type MoveAccountsToGroupAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_MOVE_ACCOUNTS_TO_GROUP,
  { organisationId: Organisation['organisation_id']; groupId: AccountGroup['id']; accountIds: Account['account_id'][] },
  { organisationId: Organisation['organisation_id']; groupId: AccountGroup['id']; accountIds: Account['account_id'][] }
>;
export type CreateSubGroupAction = PayloadAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_CREATE_SUBGROUP,
  {
    organisationId: Organisation['organisation_id'];
    groupId: AccountGroup['id'];
    name: string;
    accountIds: Account['account_id'][];
  },
  AccountGroup
>;
export type RenameSubGroupAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_RENAME_SUBGROUP,
  { organisationId: Organisation['organisation_id']; subGroupId: AccountGroup['id']; name: string },
  { organisationId: Organisation['organisation_id']; subGroupId: AccountGroup['id']; name: string }
>;
export type ReorderSubGroupAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_REORDER_SUBGROUP,
  {
    organisationId: Organisation['organisation_id'];
    groupId: AccountGroup['id'];
    orderedSubGroupIds: AccountGroup['id'][];
  },
  {
    organisationId: Organisation['organisation_id'];
    groupId: AccountGroup['id'];
    orderedSubGroupIds: AccountGroup['id'][];
  }
>;
export type DeleteSubGroupAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_DELETE_SUBGROUP,
  { organisationId: Organisation['organisation_id']; subGroupId: AccountGroup['id'] },
  { organisationId: Organisation['organisation_id']; subGroupId: AccountGroup['id'] }
>;
export type MoveAccountsToSubGroupAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_MOVE_ACCOUNTS_TO_SUBGROUP,
  {
    organisationId: Organisation['organisation_id'];
    subGroupId: AccountGroup['id'];
    accountIds: Account['account_id'][];
  },
  {
    organisationId: Organisation['organisation_id'];
    subGroupId: AccountGroup['id'];
    accountIds: Account['account_id'][];
  }
>;
export type CascadeGroupsAction = PromiseAction<
  typeof ORGANISATIONS_ACCOUNT_GROUPS_CASCADE_GROUPS,
  { organisationId: Organisation['organisation_id'] },
  { organisationId: Organisation['organisation_id'] }
>;

type AccountGroupsResponse = {
  groups: AccountGroup[];
};

export const loadAccountGroupsAction = (
  organisationId: Organisation['organisation_id'],
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_LOAD,
        () =>
          http<AccountGroup[], AccountGroupsResponse>(
            `/api/v1/organisations/${organisationId}/accounts/groups`,
            {},
            response => response.groups,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const renameNativeMetricAction = (
  organisationId: Organisation['organisation_id'],
  tag: NativeMetricId,
  name: string,
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_RENAME_NATIVE_METRIC,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/accounts/native_metric/${tag}`, {
            method: 'PATCH',
            body: { name },
          }),
        {
          organisationId,
          tag,
          name,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const moveAccountsToNativeMetricAction = (
  organisationId: Organisation['organisation_id'],
  tag: NativeMetricId,
  accountIds: Account['account_id'][],
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_MOVE_ACCOUNTS_TO_NATIVE_METRIC,
        () =>
          http<AccountGroup, AccountGroup>(`/api/v1/organisations/${organisationId}/accounts/native_metric/${tag}`, {
            method: 'PUT',
            body: {
              account_ids: accountIds,
            },
          }),
        {
          organisationId,
          tag,
          accountIds,
        },
      ),
    );
    await dispatch(setAccountGroupId(organisationId, accountIds, null, tag));
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const createGroupAction = (
  organisationId: Organisation['organisation_id'],
  tag: NativeMetricId,
  name: string,
  accountIds: Account['account_id'][],
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    const response: AccountGroup = await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_CREATE_GROUP,
        () =>
          http<AccountGroup>(`/api/v1/organisations/${organisationId}/accounts/groups`, {
            method: 'POST',
            body: {
              tag: tag,
              account_ids: accountIds,
              name: name,
            },
          }),
        {
          organisationId,
          name,
          accountIds,
        },
      ),
    );
    await dispatch(setAccountGroupId(organisationId, accountIds, response.id, tag));
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const renameGroupAction = (
  organisationId: Organisation['organisation_id'],
  groupId: AccountGroup['id'],
  name: string,
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_RENAME_GROUP,
        () =>
          http<AccountGroup, AccountGroup>(`/api/v1/organisations/${organisationId}/accounts/groups/${groupId}`, {
            method: 'PATCH',
            body: {
              name: name,
            },
          }),
        {
          organisationId,
          groupId,
          name,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const reorderGroupAction = (
  organisationId: Organisation['organisation_id'],
  tag: NativeMetricId,
  orderedGroupIds: AccountGroup['id'][],
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_REORDER_GROUP,
        () =>
          http<AccountGroup, AccountGroup>(`/api/v1/organisations/${organisationId}/accounts/native_metric/${tag}`, {
            method: 'PATCH',
            body: {
              ordered_group_ids: orderedGroupIds,
            },
          }),
        {
          organisationId,
          tag,
          orderedGroupIds,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const deleteGroupAction = (
  organisationId: Organisation['organisation_id'],
  groupId: AccountGroup['id'],
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    const accountIds = await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_DELETE_GROUP,
        () =>
          http<Account['account_id'][], { account_ids: Account['account_id'][] }>(
            `/api/v1/organisations/${organisationId}/accounts/groups/${groupId}`,
            {
              method: 'DELETE',
            },
            response => response.account_ids,
          ),
        {
          organisationId,
          groupId,
        },
      ),
    );
    await dispatch(setAccountGroupId(organisationId, accountIds, null, null));
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const moveAccountsToGroupAction = (
  organisationId: Organisation['organisation_id'],
  groupId: AccountGroup['id'],
  accountIds: Account['account_id'][],
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    const response = await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_MOVE_ACCOUNTS_TO_GROUP,
        () =>
          http<AccountGroup, AccountGroup>(`/api/v1/organisations/${organisationId}/accounts/groups/${groupId}`, {
            method: 'PUT',
            body: {
              account_ids: accountIds,
            },
          }),
        {
          organisationId,
          groupId,
          accountIds,
        },
      ),
    );
    await dispatch(setAccountGroupId(organisationId, accountIds, response.id, response.tag));
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const createSubGroupAction = (
  organisationId: Organisation['organisation_id'],
  groupId: AccountGroup['id'],
  name: string,
  accountIds: Account['account_id'][],
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    const response = await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_CREATE_SUBGROUP,
        () =>
          http<AccountGroup, AccountGroup>(`/api/v1/organisations/${organisationId}/accounts/sub_groups`, {
            method: 'POST',
            body: {
              parent_group_id: groupId,
              name: name,
              account_ids: accountIds,
            },
          }),
        {
          organisationId,
          groupId,
          name,
          accountIds,
        },
      ),
    );
    await dispatch(setAccountGroupId(organisationId, accountIds, response.id, response.tag));
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const renameSubGroupAction = (
  organisationId: Organisation['organisation_id'],
  subGroupId: AccountGroup['id'],
  name: string,
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_RENAME_SUBGROUP,
        () =>
          http<AccountGroup, AccountGroup>(
            `/api/v1/organisations/${organisationId}/accounts/sub_groups/${subGroupId}`,
            {
              method: 'PATCH',
              body: {
                name: name,
              },
            },
          ),
        {
          organisationId,
          subGroupId,
          name,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const reorderSubGroupAction = (
  organisationId: Organisation['organisation_id'],
  groupId: AccountGroup['id'],
  orderedSubGroupIds: AccountGroup['id'][],
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_REORDER_SUBGROUP,
        () =>
          http<AccountGroup, AccountGroup>(`/api/v1/organisations/${organisationId}/accounts/groups/${groupId}`, {
            method: 'PATCH',
            body: {
              ordered_sub_group_ids: orderedSubGroupIds,
            },
          }),
        {
          organisationId,
          groupId,
          orderedSubGroupIds,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const deleteSubGroupAction = (
  organisationId: Organisation['organisation_id'],
  subGroupId: AccountGroup['id'],
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    const accountIds = await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_DELETE_SUBGROUP,
        () =>
          http<Account['account_id'][], { account_ids: Account['account_id'][] }>(
            `/api/v1/organisations/${organisationId}/accounts/sub_groups/${subGroupId}`,
            {
              method: 'DELETE',
            },
            response => response.account_ids,
          ),
        {
          organisationId,
          subGroupId,
        },
      ),
    );
    await dispatch(setAccountGroupId(organisationId, accountIds, null, null));
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const moveAccountsToSubGroupAction = (
  organisationId: Organisation['organisation_id'],
  subGroupId: AccountGroup['id'],
  accountIds: Account['account_id'][],
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    const response = await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_MOVE_ACCOUNTS_TO_SUBGROUP,
        () =>
          http<AccountGroup, AccountGroup>(
            `/api/v1/organisations/${organisationId}/accounts/sub_groups/${subGroupId}`,
            {
              method: 'PUT',
              body: {
                account_ids: accountIds,
              },
            },
          ),
        {
          organisationId,
          subGroupId,
          accountIds,
        },
      ),
    );
    await dispatch(setAccountGroupId(organisationId, accountIds, response.id, response.tag));
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const cascadeAccountGroupsAction = (
  organisationId: Organisation['organisation_id'],
): ThunkAction<AccountGroupsActions> => async (dispatch: ThunkDispatch<AccountGroupsActions>, getState) => {
  const state = getState();
  const accountGroups = selectAccountGroups(state, organisationId);

  if (accountGroups?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACCOUNT_GROUPS_CASCADE_GROUPS,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/cascade_groupings`, {
            method: 'POST',
          }),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
