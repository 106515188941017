import { useEffect } from 'react';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import { Connection } from 'scalexp/components/organisms/OAuthConnectionsManager';
import { useConnectionsContext } from 'scalexp/features/connections/ConnectionsContext';
import { ImportJob, Job } from 'scalexp/features/connections/sync-context/types';
import fetcher from 'scalexp/utils/fetcher';

const useGetRunningJobs = async (jobs: Job[], setJobs: (jobs: Job[]) => void) => {
  const { consolidatedConnections } = useConnectionsContext();

  const { organisation_id } = useActiveOrganisation();

  const getRunningJobs = async () => {
    const importJobsByConnection: ImportJob[][] = await Promise.all(
      // consolidatedConnections has the current organisation and its subsidaries,
      // if the organisation is a subsidary it will have only its own connection
      consolidatedConnections.map(consolidatedConnection =>
        Promise.all(
          Object.entries(consolidatedConnection.connections)
            .filter(
              ([_, connection]) => connection.status === 'connected' && connection.last_import?.status === 'running',
            )
            .map(async value => {
              const connection = value[1] as Connection;
              const res = await fetcher(
                `/api/v1/${organisation_id}/connections/import/${connection.last_import?.import_job_id}`,
              );

              return {
                ...res,
                name: connection.source_name,
                organisationName: consolidatedConnection.organisation_name,
                completedSteps: 0,
              };
            }),
        ),
      ),
    );

    const allImportJobs = await Promise.all(importJobsByConnection.flatMap(improtJobs => improtJobs));

    const allJobs = [...jobs, ...allImportJobs];
    // making sure not to add duplicate jobs
    const uniqueJobs = Array.from(new Map(allJobs.map(job => [job.id, job])).values());

    setJobs(uniqueJobs);
  };

  useEffect(() => {
    getRunningJobs();
  }, [consolidatedConnections]);
};

export default useGetRunningJobs;
