import React, { HTMLAttributes, MouseEvent } from 'react';
import styled from 'styled-components';

import colors from '../../../colors';
import Icon from '../Icon';

export interface DropDownItemProps extends HTMLAttributes<HTMLDivElement> {
  icon?: string;
  color?: string;
  iconSize?: number;
  active?: boolean;
  disabled?: boolean;
}

const StyledDropDownItem = styled.div<DropDownItemProps>`
  min-height: ${({ theme }) => theme.spacing(9)};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  background-color: ${({ theme, active }) => (active ? theme.palette.primary.offwhite : 'unset')};
  color: ${({ color }) => color};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.offwhite};
  }
`;

const DropDownItem: React.FC<React.PropsWithChildren<DropDownItemProps>> = ({
  onClick,
  children,
  icon,
  iconSize,
  color = colors.granite,
  disabled,
  ...rest
}) => {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  return (
    <StyledDropDownItem onClick={handleClick} disabled={disabled} color={color} {...rest}>
      {icon && <Icon name={icon} size={iconSize} color="inherit" rounded marginRight={3} />}
      {children}
    </StyledDropDownItem>
  );
};
export default DropDownItem;
