import React from 'react';
import styled from 'styled-components';

import Typography from '../../../components/atoms/Typography';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import BlurredModal from '../../../components/molecules/BlurredModal';

const newChartTypes = ['bar', 'stacked', 'pie', 'waterfall', 'line', 'dual'];
const StyledCreateNewChartContainer = styled(Column)`
  padding: ${({ theme }) => theme.spacing(8)};
`;

interface NewChartModalPropsType {
  showModal: boolean;
  onHide: () => void;
  createChartFromScratch: (type: string) => void;
}
const StyledChartChoice = styled(Column)`
  cursor: pointer;
  text-transform: capitalize;
  background: #f8f9fc;
  border-radius: 4px;
  border: 1.3px solid transparent;
  transition: 0.3s;
  &:hover {
    border: 1.3px solid #acbae0;
    box-shadow: ${({ theme }) => theme.shadow.normal};
  }

  & > div {
    position: relative;
    width: 98.8px;
    height: 57.2px;
  }
  & > div > img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    width: 80%;
    height: 80%;
  }
`;
const NewChartModal: React.FC<NewChartModalPropsType> = ({ showModal, onHide, createChartFromScratch }) => {
  return (
    <BlurredModal isBlurred={false} showCloseButton={false} width="570px" showModal={showModal} onHide={onHide}>
      <StyledCreateNewChartContainer spacing="large">
        <Typography size="h5" weight="regular">
          Select Chart type
        </Typography>
        <Row spacing="xlarge" wrap width="100%">
          {newChartTypes.map(type => (
            <StyledChartChoice onClick={() => createChartFromScratch(type)} key={type} hAlign="center" spacing="tiny">
              <div>
                <img alt={`chart-icon-${type}`} src={`/images/charts/${type}.svg`} />
              </div>
              <Typography size="medium" weight="medium">
                {type}
              </Typography>
            </StyledChartChoice>
          ))}
        </Row>
      </StyledCreateNewChartContainer>
    </BlurredModal>
  );
};

export default NewChartModal;
