import React from 'react';
import styled from 'styled-components';

import Typography from '../../atoms/Typography';

interface SidebarVideoGuideProps {
  link: string;
  image: string;
}

const StyledContainer = styled.a`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(4.5)}`};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const StyledImage = styled.img`
  width: 174px;
  height: 100px;
  border-radius: 4px;
`;

let SidebarVideoGuide: React.FC<React.PropsWithChildren<SidebarVideoGuideProps>> = ({ link, image }) => {
  return (
    <StyledContainer href={link} target="_blank">
      <Typography color="secondary">Video Guide</Typography>
      <StyledImage width={174} src={image} alt="video guide" />
    </StyledContainer>
  );
};

export default SidebarVideoGuide;
