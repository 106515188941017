import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export interface SubHeaderProps extends HTMLAttributes<HTMLDivElement> {
  sticky?: boolean;
  height?: string;
  topBorder?: boolean;
}
export const StyledSubHeader = styled.div<SubHeaderProps>`
  ${({ height, theme, topBorder }) =>
    css`
      width: 100%;
      height: ${height};
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${theme.spacing(4)} ${theme.spacing(6)};
      border-bottom: 1px solid ${theme.palette.silver};
      ${topBorder ? `border-top: 1px solid ${theme.palette.silver};` : ''}
      background-color: ${theme.palette.white};
    `}
  ${({ sticky, theme }) =>
    sticky
      ? css`
          position: sticky;
          top: 0;
          z-index: ${theme.zIndex.medium};
        `
      : ''}
`;
const SubHeader: React.FC<React.PropsWithChildren<SubHeaderProps>> = ({ height = '60px', children, ...other }) => {
  return (
    <StyledSubHeader height={height} {...other}>
      {children}
    </StyledSubHeader>
  );
};
export default SubHeader;
