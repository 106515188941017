import React, { HTMLAttributes, useRef } from 'react';
import styled from 'styled-components';

import ChartCard from '../../../features/chart/ChartCard';
import { useDataProvenanceMetricDetails } from '../../../features/chart/ChartCard/hooks';
import { DashboardChartCard } from '../../../store/state/newDashboards/types';
import ShadowedChartCardContainer from '../../atoms/ShadowedChartCardContainer';
import { useDashboardContext } from '../../contexts/NewDashboardContext';
import DashboardChartOptions from '../../molecules/DashboardChartOptions';
import DashboardChartPlaceholder from '../../molecules/DashboardChartPlaceholder';
import HeaderConfig from '../../molecules/HeaderConfig';
import { useChartData } from './useChartData';

export interface DashboardChartProps {
  chartCard: DashboardChartCard;
}
export interface StyledDashboardChartProps extends HTMLAttributes<HTMLDivElement> {}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const HiddenContainer = styled.div`
  display: none;
`;

const StyledResizeImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const StyledDeleteContainer = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  left: -6px;
  top: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.midnight};
  border-radius: 50%;
  opacity: 0.6;
  cursor: pointer;
`;

const DashboardChart: React.FC<DashboardChartProps> = ({ chartCard }) => {
  const { chartData } = useChartData(chartCard.i);
  const chartRef = useRef(null);
  const { isEditMode } = useDashboardContext();
  const DPMetrics = useDataProvenanceMetricDetails(chartData);

  if (!chartData?.chart_data && !isEditMode) {
    return <HiddenContainer />;
  }
  if (!chartData?.chart_data) {
    return <DashboardChartPlaceholder card={chartCard} />;
  }

  const period = chartData.chart_type === 'time_chart' ? chartData.chart_data.period : 'MONTH';

  const headerConfig = chartData.header_config?.metricSchema && (
    <HeaderConfig period={period} metricSchema={chartData?.header_config?.metricSchema} />
  );

  const chartOptions = (
    <DashboardChartOptions
      metrics={DPMetrics}
      chartId={chartCard.i}
      chartCardRef={chartRef}
      isKPIChart={chartCard.chart_type === 'kpi_chart'}
    />
  );

  return (
    <ShadowedChartCardContainer>
      <StyledContainer ref={chartRef}>
        <ChartCard chartData={chartData} headerConfig={headerConfig} chartOptions={chartOptions} />
      </StyledContainer>
      {isEditMode && <StyledResizeImage src={`/images/icons/angled_arrow.png`} alt="drag-indicator" />}
    </ShadowedChartCardContainer>
  );
};

export default DashboardChart;
