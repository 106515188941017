import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';

const ACCESS_TOKEN_KEY = 'jwt_token';

const setAccessToken = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

const getAccessToken = (): string => {
  return localStorage.getItem(ACCESS_TOKEN_KEY) || '';
};

const addAccessTokenToCookies = () => {
  const accessToken = getAccessToken();
  // retrieve the domain name
  const locationParts = window.location.hostname.split('.');
  locationParts.shift();
  const domain = locationParts.join('.');
  document.cookie = `jwt_token=${accessToken};path=/;domain=${domain};secure;`;
};

const getAccessTokenPermissions = (): string[] => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return [];
  }
  const decodedAccessToken = jwt_decode<any>(accessToken);
  if (!decodedAccessToken?.permissions) {
    return [];
  }
  return decodedAccessToken.permissions;
};

const getAccessTokenResourceAccess = (): {
  dashboards: string[];
  presentations: string[];
  reports: string[];
} | null => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }
  const decodedAccessToken = jwt_decode<any>(accessToken);
  const dashboardsUrl = `https://scalexp.com/dashboards`;
  const presentationsUrl = `https://scalexp.com/presentations`;
  const reportsUrl = `https://scalexp.com/reports`;
  // Check the urls are keys of decodedAccessToken
  const resourcesList = [dashboardsUrl, presentationsUrl, reportsUrl];
  const tokenHasResources = resourcesList.every(key => key in decodedAccessToken);
  if (!tokenHasResources) {
    return null;
  }
  return {
    dashboards: decodedAccessToken[dashboardsUrl],
    presentations: decodedAccessToken[presentationsUrl],
    reports: decodedAccessToken[reportsUrl],
  };
};

const handleAccessTokenChange = (e: StorageEvent): void => {
  if (e.storageArea !== localStorage || e.key !== ACCESS_TOKEN_KEY) {
    return;
  }
  window.location.reload();
};

const useAccessTokenListener = (): void => {
  useEffect(() => {
    window.addEventListener('storage', handleAccessTokenChange);
    return () => {
      window.removeEventListener('storage', handleAccessTokenChange);
    };
  }, []);
};

export {
  setAccessToken,
  getAccessToken,
  addAccessTokenToCookies,
  getAccessTokenPermissions,
  getAccessTokenResourceAccess,
  useAccessTokenListener,
};
