import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Store } from 'redux';

import { setupStore } from './store';

const store = setupStore();

const ReduxStore: React.FC<React.PropsWithChildren<{ optionalStore?: Store }>> = ({ children, optionalStore }) => (
  <ReduxProvider store={optionalStore || store}>{children}</ReduxProvider>
);

export default ReduxStore;
