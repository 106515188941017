import React from 'react';

export interface ValidationContextProps {
  hasError: boolean;
}

export const ValidationContext = React.createContext<ValidationContextProps>(undefined!);

export const ValidationContextProvider: React.FC<React.PropsWithChildren<ValidationContextProps>> = ({
  children,
  hasError = false,
}) => {
  return (
    <ValidationContext.Provider
      value={{
        hasError,
      }}
    >
      {children}
    </ValidationContext.Provider>
  );
};

export const useValidationContext = () => {
  const value = React.useContext(ValidationContext);
  return value;
};
