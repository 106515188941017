import {
  MetricSchema2,
  MetricSchema2Data,
  MetricSchema2DataSourceReferenceRow,
  MetricSchema2Nodes,
} from 'scalexp/utils/metrics/metricSchema2';

import Notification from '../../../components/atoms/Notification';
import { Row } from '../../../store/state/reports/types';

export const replaceReferences = (row: Row, rows: Array<Row>): Row => {
  if (row.type !== 'metric') {
    return row;
  }

  const metricSchema: MetricSchema2 = row.metric;

  if (metricSchema.schemaType === 'simple') {
    return row;
  }

  return {
    ...row,
    metric: {
      ...metricSchema,
      nodes: metricSchema.nodes.map(node => replaceNodeReferences(node, rows, 50)),
    },
  };
};

export const replaceNodeReferences = (
  nodeOrNodes: MetricSchema2Data | MetricSchema2Nodes,
  rows: Array<Row>,
  maxCalls: number,
): MetricSchema2Data | MetricSchema2Nodes => {
  // Prevent cyclic recursive calls from going on forever
  if (!maxCalls) {
    Notification.error({
      title: 'Could not calculate row values, Make sure you are not creating a cyclic row reference.',
      duration: 10000,
    });
    console.error('Could not calculate row values, Make sure you are not creating a cyclic row reference.');
    throw RangeError('Could not calculate row values, Make sure you are not creating a cyclic row reference.');
  }

  if ('nodes' in nodeOrNodes) {
    const nodes: MetricSchema2Nodes = nodeOrNodes;
    return {
      ...nodes,
      nodes: nodes.nodes.map(node => replaceNodeReferences(node, rows, maxCalls - 1)),
    };
  }

  const dataNode: MetricSchema2Data = nodeOrNodes as MetricSchema2Data;
  if (dataNode.data.operator !== 'reference') {
    return nodeOrNodes;
  }
  const dataNodeReference = dataNode as MetricSchema2Data<MetricSchema2DataSourceReferenceRow>;

  const referencedRow = rows.find(r => r.id === dataNodeReference.data.rowId);
  if (!referencedRow) {
    Notification.error({
      title: `Row is referencing a deleted row`,
      duration: 10000,
    });
    return {
      operator: 'add',
      data: {
        operator: 'constant',
        value: 0,
      },
    };
  }
  if (referencedRow.type !== 'metric') {
    throw new Error('Referenced row was not of new metric type!');
  }

  return {
    operator: dataNodeReference.operator,
    window: dataNodeReference.window,
    nodes: referencedRow.metric.nodes.map(node => replaceNodeReferences(node, rows, maxCalls - 1)),
  };
};
