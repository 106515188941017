import produce from 'immer';

import { ORGANISATIONS_DEFERRED_BILLS_REMAINING_LOAD, deferredBillsRemainingActions } from './actions';
import { OrganisationDeferredBillsRemaining } from './types';

const deferredBillsRemaining = (
  prevState: OrganisationDeferredBillsRemaining = {},
  action: deferredBillsRemainingActions,
): OrganisationDeferredBillsRemaining => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_DEFERRED_BILLS_REMAINING_LOAD:
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload,
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      default:
        return state;
    }
  });
};

export default deferredBillsRemaining;
