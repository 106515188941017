import React from 'react';

import { theme } from '../../../theme';
import Icon from '../../atoms/Icon';
import Typography from '../../atoms/Typography';
import Tooltip from '../Tooltip';

export interface WindowingIndicatorProps {}

const WindowingIndicator: React.FC<React.PropsWithChildren<WindowingIndicatorProps>> = () => {
  return (
    <Tooltip
      content={
        <Typography size="small" weight="semibold" color={'white'}>
          This row is being calculated over more than 1 month. <br /> Click the row and the config button to view or
          change the calculation period
        </Typography>
      }
    >
      <Icon name="access_time" color={theme.palette.primary.main} outlined rounded />
    </Tooltip>
  );
};

export default WindowingIndicator;
