import React from 'react';
import styled, { css } from 'styled-components';

import Typography from '../../atoms/Typography';
import Column from '../../layout/Column';
import Row from '../../layout/Row';
import Card from '../Card';

const Container = styled(Column)`
  ${({ theme }) => css`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: ${theme.palette.backgroundGrey};
  `}
`;

const BlockedCard = styled(Card)`
  ${({ theme }) => css`
    width: ${theme.sizing(250)};
    max-height: ${theme.sizing(145)};
    padding: ${theme.sizing(8)};
    display: flex;
    flex-direction: column;
    border-radius: ${theme.sizing(4)};
    gap: ${theme.sizing(12.75)};
    box-shadow: 0px 1px 4px 2px rgba(172, 186, 224, 0.2);
  `}
`;

const IndicatorMessage = styled(Typography)`
  ${({ theme }) => css`
    display: block;
    width: 430px;
    height: ${theme.sizing(22)};
    border-radius: 16px;
    background-color: ${theme.palette.backgroundGrey};
    padding: ${theme.sizing(4)};
  `}
`;

const StyledLink = styled.a<{ variant: 'primary' | 'secondary' }>`
  ${({ theme, variant }) => css`
    height: 48px;
    width: 188px;
    display: flex;
    font-weight: 500;
    font-size: 16px;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    border-radius: 8px;
    border: 1.2px solid ${variant === 'secondary' ? theme.palette.midnight : theme.palette.primary.main};
    background-color: ${variant === 'secondary' ? theme.palette.white : theme.palette.primary.main};
    color: ${variant === 'primary' ? theme.palette.white : theme.palette.midnight};

    &:hover {
      text-decoration: none;
      color: ${variant === 'primary' ? theme.palette.white : theme.palette.midnight};
    }
  `}
`;

const BlockUserMessage: React.FC<React.PropsWithChildren> = () => {
  return (
    <Container hAlign="center" vAlign="center">
      <BlockedCard>
        <Row>
          <img src="/images/logos/scalexp_black.svg" alt="scalexp logo" height={39.2} />
        </Row>
        <Row vAlign="center" hAlign="space-between" width="100%">
          <Column spacing="large" width="436px">
            <Typography size="h4" weight="regular">
              Your ScaleXP Trial has ended
            </Typography>
            <Typography size="medium" color="secondary" weight="regular">
              To continue using the system, please Upgrade using the button below. <br />
              Still have questions or just want to talk with a member of the team? Just drop us an email -
              support@ScaleXP.com - or use the Contact button below.
            </Typography>
            <IndicatorMessage size="medium" color="secondary" weight="regular">
              If you do not upgrade, your data will be permanently removed from the system within 30 days.
            </IndicatorMessage>
          </Column>
          <img width={460} src="/images/illustrations/account_blocked.png" alt="scalex account blocked" />
        </Row>
        <Row spacing="large">
          <StyledLink variant="secondary" href="https://meetings.hubspot.com/scalexp/45-min-product-demo-clone">
            Contact
          </StyledLink>
          <StyledLink
            variant="primary"
            href="mailto:support@scalexp.com?subject=Upgrade ScaleXP&body=I would like to upgrade my ScaleXP plan"
          >
            Upgrade
          </StyledLink>
        </Row>
      </BlockedCard>
    </Container>
  );
};

export default BlockUserMessage;
