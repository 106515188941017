import React, { useMemo } from 'react';
import { MetricSchema2Simple } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import Typography from '../../../components/atoms/Typography';
import {
  useAddEditedReportRow,
  useEditedReport,
  useSetEditedReportAddedComputationRowId,
  useSetEditedReportSelectedRowId,
} from '../../../store/state/editedReport/hooks';
import usePickerOptions from '../ReportEditor/usePickerOptions';
import ReportEditorAddRowSelect from '../ReportEditorAddRowSelect';

interface ReportEditorAddRowProps {
  showDropDown: boolean;
  setShowDropDown: (showDropDown: boolean) => void;
  scrollToBottom: () => void;
}

const StyledOverlayContainer = styled.div`
  width: 80%;
  height: 122px;
  position: sticky;
  bottom: 0;
  margin: auto;
  background: ${({ theme }) => theme.palette.backgroundGrey};
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 54px;
  position: relative;
  top: 0;
  bottom: ${({ theme }) => theme.spacing(17)};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
`;

const SearchContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.white};
`;

const DividerContainer = styled.div`
  width: 210px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.white};
  cursor: pointer;
`;

const ReportEditorAddRow: React.FC<React.PropsWithChildren<ReportEditorAddRowProps>> = ({
  showDropDown,
  setShowDropDown,
  scrollToBottom,
}) => {
  const editedReport = useEditedReport();
  const SetAddedComputationRowId = useSetEditedReportAddedComputationRowId();
  const setEditedReportSelectedRowId = useSetEditedReportSelectedRowId();
  const addEditedReportRow = useAddEditedReportRow();

  const pickerOptions = usePickerOptions(editedReport || undefined);
  const normalizedPickerOptions = useMemo(
    () => [
      ...pickerOptions
        .filter(option => option.id !== 'number' && !option.id.startsWith('row:'))
        .map(option => ({ ...option, label: option.name, value: option.id, group: option.role })),
    ],
    [pickerOptions],
  );

  const handleAddRows = (values: string[]) => {
    values.forEach(value => {
      const [type, newId, newIdPartTwo] = value.split(':');

      const newRowId = uuidv4();
      setEditedReportSelectedRowId(newRowId);

      if (value === 'custom') {
        addEditedReportRow({
          id: newRowId,
          bold: true,
          type: 'metric',
          name: 'Total',
          metric: {
            schemaType: 'complex',
            nodes: [],
            impact: 'neutral',
            aggregation: 'sum',
            dataType: 'monetary',
            decimals: 0,
          },
        });
        SetAddedComputationRowId(newRowId);
        return;
      }
      if (value === 'constant') {
        const metric: MetricSchema2Simple = {
          schemaType: 'simple',
          decimals: 0,
          nodes: [
            {
              operator: 'add',
              data: {
                operator: 'constant',
                value: 0,
              },
            },
          ],
        };
        addEditedReportRow({
          id: newRowId,
          bold: true,
          type: 'metric',
          metric: metric,
        });
        return;
      }

      switch (type) {
        case 'nm': {
          const metric: MetricSchema2Simple = {
            schemaType: 'simple',
            decimals: 0,
            nodes: [
              {
                operator: 'add',
                data: {
                  operator: 'native',
                  metricId: newId,
                  isCashflow: false,
                },
              },
            ],
          };
          addEditedReportRow({
            id: newRowId,
            bold: true,
            type: 'metric',
            metric: metric,
          });
          break;
        }
        case 'dm': {
          const metric: MetricSchema2Simple = {
            schemaType: 'simple',
            decimals: 0,
            nodes: [
              {
                operator: 'add',
                data: {
                  operator: 'derived',
                  metricId: newId,
                },
              },
            ],
          };
          addEditedReportRow({
            id: newRowId,
            bold: true,
            type: 'metric',
            metric: metric,
          });
          break;
        }
        case 'account': {
          const metric: MetricSchema2Simple = {
            schemaType: 'simple',
            decimals: 0,
            nodes: [
              {
                operator: 'add',
                data: {
                  operator: 'entity',
                  entity: 'account',
                  accountId: parseInt(newId),
                  isCashflow: false,
                },
              },
            ],
          };
          addEditedReportRow({
            id: newRowId,
            bold: true,
            type: 'metric',
            metric: metric,
          });
          break;
        }
        case 'group': {
          const metric: MetricSchema2Simple = {
            schemaType: 'simple',
            decimals: 0,
            nodes: [
              {
                operator: 'add',
                data: {
                  operator: 'entity',
                  entity: 'group',
                  groupId: parseInt(newId),
                  isCashflow: false,
                },
              },
            ],
          };
          addEditedReportRow({
            id: newRowId,
            bold: true,
            type: 'metric',
            metric: metric,
          });
          break;
        }
        case 'sm': {
          const metric: MetricSchema2Simple = {
            schemaType: 'simple',
            decimals: 0,
            nodes: [
              {
                operator: 'add',
                data: {
                  operator: 'sales',
                  metricId: newId,
                },
              },
            ],
          };
          addEditedReportRow({
            id: newRowId,
            bold: true,
            type: 'metric',
            metric: metric,
          });
          break;
        }
        case 'ir': {
          const metric: MetricSchema2Simple = {
            schemaType: 'simple',
            decimals: 0,
            nodes: [
              {
                operator: 'add',
                data: {
                  operator: 'invoiced-revenue',
                  metricId: newId,
                },
              },
            ],
          };
          addEditedReportRow({
            id: newRowId,
            bold: true,
            type: 'metric',
            metric: metric,
          });
          break;
        }
        case 'pm': {
          const metric: MetricSchema2Simple = {
            schemaType: 'simple',
            decimals: 0,
            nodes: [
              {
                operator: 'add',
                data: {
                  operator: 'entity',
                  entity: 'pipeline',
                  pipelineMetricId: 'metric_deals_in_progress_acv',
                  pipelineId: newId,
                },
              },
            ],
          };
          addEditedReportRow({
            id: newRowId,
            bold: true,
            type: 'metric',
            metric: metric,
          });
          break;
        }
        case 'ps': {
          const metric: MetricSchema2Simple = {
            schemaType: 'simple',
            decimals: 0,
            nodes: [
              {
                operator: 'add',
                data: {
                  operator: 'entity',
                  entity: 'stage',
                  stageMetricId: 'metric_deals_acv',
                  pipelineId: newIdPartTwo,
                  stageId: newId,
                },
              },
            ],
          };
          addEditedReportRow({
            id: newRowId,
            bold: true,
            type: 'metric',
            metric: metric,
          });
          break;
        }
        case 'row': {
          const metric: MetricSchema2Simple = {
            schemaType: 'simple',
            decimals: 0,
            nodes: [
              {
                operator: 'add',
                data: {
                  operator: 'reference',
                  reference: 'row',
                  rowId: newId,
                },
              },
            ],
          };
          addEditedReportRow({
            id: newRowId,
            bold: true,
            type: 'metric',
            metric: metric,
          });
          break;
        }
        default: {
          break;
        }
      }
    });
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const handleAddDivider = () => {
    addEditedReportRow({ id: uuidv4(), type: 'EMPTY', bold: true });
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  return (
    <StyledOverlayContainer>
      <StyledContainer>
        <SearchContainer>
          <ReportEditorAddRowSelect
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            data={normalizedPickerOptions}
            handleAddRows={handleAddRows}
            includeFixedNumber={false}
            includeBrackets={false}
          />
        </SearchContainer>
        <DividerContainer onClick={handleAddDivider}>
          <Typography color="secondary">+ Add Divider</Typography>
        </DividerContainer>
      </StyledContainer>
    </StyledOverlayContainer>
  );
};

export default ReportEditorAddRow;
