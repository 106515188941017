import React from 'react';
import styled from 'styled-components';

export const StyledBackground = styled.div`
  background: rgb(20, 49, 148);
  background: linear-gradient(315deg, rgba(11, 24, 85, 1) 23%, rgb(20 49 148 / 6%) 13%),
    linear-gradient(210deg, rgb(17 24 61 / 3%) 23%, rgba(20, 49, 148, 1) 13%),
    linear-gradient(256deg, rgb(11 19 63 / 96%) 23%, #162d7f 13%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const Background: React.FC<React.PropsWithChildren<unknown>> = ({ ...other }) => {
  return <StyledBackground {...other} />;
};

export default Background;
