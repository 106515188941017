import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useMemo } from 'react';
import {
  MetricSchema2DataOrNodesWithId,
  MetricSchema2DataWithId,
  MetricSchema2Decimals,
  MetricSchema2NodesWithId,
  MetricSchema2Operator,
} from 'scalexp/utils/metrics/metricSchema2';
import { recursiveRemoveIds } from 'scalexp/utils/metrics/ms2utils';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import Typography from '../../../components/atoms/Typography';
import useActiveOrganisation from '../../../components/contexts/OrganisationContext/useActiveOrganisation';
import Row from '../../../components/layout/Row';
import useCurrencySelection from '../../../components/molecules/CurrencySelect/useCurrencySelection';
import { useDateSelectionDate } from '../../../components/molecules/DateSelect/useDateSelection';
import { MetricRow, Report } from '../../../store/state/reports/types';
import useMetricSchemaSeries from '../../../utils/metrics/useMetricSchemaSeries';
import { DISPLAY_COLUMNS, formatValue } from '../../report-editor/ReportEditor/helpers';
import { FormulaPickerOption } from '../../report-editor/ReportEditorAddRowSelect';
import { DragHandle } from '../../report-editor/ReportEditorContent';
import ReportEditorDelete from '../../report-editor/ReportEditorDelete';
import { reportEditorcalculationRequest } from '../../report-editor/rows/ReportEditorCustomRow';
import { replaceReferences } from '../../report/report-wrapper/helpers';
import FormulaEditorAddRowAndBrackets from '../FormulaEditorAddRowAndBrackets';
import FormulaEditorOperator from '../FormulaEditorOperator';
import FormulaEditorRows from '../FormulaEditorRows';

interface FormulaEditorCustomRowProps {
  pickerOptions: FormulaPickerOption[];
  id: string;
  nodes: MetricSchema2DataOrNodesWithId[];
  updateNodes: (nodes: MetricSchema2DataOrNodesWithId[]) => void;
  isSelected: boolean;
  handleIsSelectedChange: (isSelected: boolean) => void;
  handleDelete: () => void;
  operator: MetricSchema2Operator;
  handleOperatorChange?: (operator: MetricSchema2Operator) => void;
  selectedNodeId: string | null;
  setSelectedNodeId: (selectedNodeId: string | null) => void;
  addedNodeId: string | null;
  setAddedNodeId: (addedNodeId: string | null) => void;
  parentPath?: string;
  report: Report | null;
  includeRows?: boolean;
  decimals: MetricSchema2Decimals;
}

const StyledContainer = styled.div<{ isSelected: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ isSelected }) => (isSelected ? '#0044F2' : '#f6c155')};
  border-radius: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
`;

const StyledOperatorContainer = styled.div`
  position: absolute;
  top: -12.4px;
  padding-left: 14px;
  display: flex;
  gap: ${({ theme }) => theme.spacing(5)};
  cursor: pointer;
`;

const StyledRowsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(5)}`};
`;

const StyledSubTotalContainer = styled.div<{ isSelected: boolean }>`
  height: 40px;
  display: grid;
  align-items: center;
  grid-template-columns: ${({ isSelected }) => `54px ${isSelected ? '20px' : ''} 1fr 160px`};
  gap: ${({ theme }) => theme.spacing(5)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(8)};
  border-radius: ${({ theme }) => `0 0 ${theme.spacing(2)} ${theme.spacing(2)}`};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.palette.silver : '#fff6e3')};
  text-align: end;
  cursor: pointer;

  & > div:first-child > span {
    color: ${({ theme, isSelected }) => (isSelected ? theme.palette.primary.main : '#f6c155')};
  }
`;

const FormulaEditorCustomRow: React.FC<React.PropsWithChildren<FormulaEditorCustomRowProps>> = ({
  id,
  nodes,
  updateNodes,
  isSelected,
  handleIsSelectedChange,
  pickerOptions,
  handleDelete,
  operator,
  handleOperatorChange,
  selectedNodeId,
  setSelectedNodeId,
  addedNodeId,
  setAddedNodeId,
  parentPath,
  report,
  includeRows,
  decimals,
}) => {
  const path = parentPath ? `${parentPath}:${id}` : id;
  const depth = path.split(':').length;

  const date = useDateSelectionDate();
  const [currencyCode] = useCurrencySelection();

  const organisation = useActiveOrganisation();
  const { financial_year_start, default_currency_code } = organisation;
  const rowWithoutReferences = useMemo(
    () =>
      replaceReferences(
        {
          id: uuidv4(),
          type: 'metric',
          metric: {
            schemaType: 'complex',
            nodes,
            decimals: 0,
            dataType: 'monetary',
            impact: 'neutral',
            aggregation: 'sum',
          },
          bold: true,
        },
        report?.rows || [],
      ) as MetricRow,
    [nodes, report?.rows],
  );

  const metricSchemaWithoutIds = recursiveRemoveIds(rowWithoutReferences.metric);
  const seriesVS = useMetricSchemaSeries(
    metricSchemaWithoutIds,
    reportEditorcalculationRequest(date, currencyCode || default_currency_code),
  );

  // select brackets when the added bracket is this one
  useEffect(() => {
    if (addedNodeId === id) {
      handleIsSelectedChange(!isSelected);
      setAddedNodeId(null);
    }
  }, [addedNodeId]);

  const values: (number | null)[] = useMemo(() => {
    const series = seriesVS.value;
    if (!series) {
      return [];
    }

    return series.map(serie => (serie ? Number(serie.value) : null));
  }, [seriesVS.value, DISPLAY_COLUMNS, financial_year_start]);

  const handleAddRows = (values: string[]) => {
    let updatedNodes = [...nodes];

    values.forEach(value => {
      const [type, newId, newIdPartTwo] = value.split(':');
      const nodeId = uuidv4();
      setAddedNodeId(nodeId);

      if (value === 'custom') {
        const newNode: MetricSchema2NodesWithId = {
          id: nodeId,
          operator: 'add',
          nodes: [],
        };
        updatedNodes.push(newNode);
        return;
      }
      if (value === 'constant') {
        const newNode: MetricSchema2DataWithId = {
          id: nodeId,
          operator: 'add',
          data: {
            operator: 'constant',
            value: 0,
          },
        };
        updatedNodes.push(newNode);
        return;
      }

      let node: MetricSchema2DataWithId;
      switch (type) {
        case 'nm': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'native',
              metricId: newId,
              isCashflow: false,
            },
          };
          break;
        }
        case 'dm': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'derived',
              metricId: newId,
            },
          };
          break;
        }
        case 'ir': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'invoiced-revenue',
              metricId: newId,
            },
          };
          break;
        }
        case 'account': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'entity',
              entity: 'account',
              accountId: parseInt(newId),
              isCashflow: false,
            },
          };
          break;
        }
        case 'group': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'entity',
              entity: 'group',
              groupId: parseInt(newId),
              isCashflow: false,
            },
          };
          break;
        }
        case 'sm': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'sales',
              metricId: newId,
            },
          };
          break;
        }
        case 'pm': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'entity',
              entity: 'pipeline',
              pipelineMetricId: 'metric_deals_in_progress_acv',
              pipelineId: newId,
            },
          };
          break;
        }
        case 'ps': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'entity',
              entity: 'stage',
              stageMetricId: 'metric_deals_acv',
              pipelineId: newIdPartTwo,
              stageId: newId,
            },
          };
          break;
        }
        case 'row': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'reference',
              reference: 'row',
              rowId: newId,
            },
          };
          break;
        }
        default: {
          throw new Error(`Unknown case '${type}' from '${value}'`);
        }
      }

      updatedNodes.push(node);
    });
    updateNodes(updatedNodes);
  };

  const handleAddBrackets = () => {
    const newNode: MetricSchema2NodesWithId = {
      id: uuidv4(),
      operator: 'add',
      nodes: [],
    };
    let updatedNodes = [...nodes];
    updatedNodes.push(newNode);
    setAddedNodeId(newNode.id);
    updateNodes(updatedNodes);
  };

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, data: { path } });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const isSelectedOrChildSelected = isSelected || nodes.some(node => !('nodes' in node) && node.id === selectedNodeId);

  return (
    <StyledContainer ref={setNodeRef} style={style} isSelected={isSelectedOrChildSelected}>
      <StyledOperatorContainer>
        {operator && handleOperatorChange && (
          <FormulaEditorOperator
            operator={operator}
            handleOperatorChange={handleOperatorChange}
            isSelected={isSelectedOrChildSelected}
          />
        )}
      </StyledOperatorContainer>

      <StyledRowsContainer>
        <FormulaEditorRows
          pickerOptions={pickerOptions}
          id={id}
          nodes={nodes as any}
          updateNodes={updateNodes}
          selectedNodeId={selectedNodeId}
          setSelectedNodeId={setSelectedNodeId}
          addedNodeId={addedNodeId}
          setAddedNodeId={setAddedNodeId}
          parentPath={parentPath}
          report={report}
          includeRows={includeRows}
          decimals={decimals}
        />
        {isSelectedOrChildSelected && (
          <FormulaEditorAddRowAndBrackets
            pickerOptions={pickerOptions}
            handleAddRows={handleAddRows}
            handleAddBrackets={depth < 2 ? handleAddBrackets : undefined}
            includeRows={includeRows}
            includeBrackets={depth < 2}
          />
        )}
      </StyledRowsContainer>
      <StyledSubTotalContainer
        isSelected={isSelectedOrChildSelected}
        onClick={() => handleIsSelectedChange(!isSelectedOrChildSelected)}
      >
        <Row>
          <DragHandle {...attributes} {...listeners} />
          <Typography size="medium">( )</Typography>
        </Row>
        {isSelectedOrChildSelected && <ReportEditorDelete handleDelete={handleDelete} />}
        <Typography color="secondary">Sub Total:</Typography>
        <Typography color="secondary">
          {formatValue(values[0], currencyCode || default_currency_code, 'numerical')}
        </Typography>
      </StyledSubTotalContainer>
    </StyledContainer>
  );
};

export default FormulaEditorCustomRow;
