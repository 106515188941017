import { createSelector } from 'reselect';

import { ValueState } from '../../values';
import { Store } from '../index';
import { AccountGroupsByTag, AccountGroupsVS } from './types';

export const selectAccountGroups = (state: Store, organisationId: number): AccountGroupsVS | undefined => {
  return state.accountGroups[organisationId];
};

export const selectAccountGroupsByTags = createSelector([selectAccountGroups], accountGroupsState => {
  if (accountGroupsState === undefined || accountGroupsState?.status !== 'success') {
    return accountGroupsState as ValueState<AccountGroupsByTag>;
  }

  // Group the accounts by tags
  const accountGroups = Object.values(accountGroupsState.value);
  const accountGroupssByTag = accountGroups.reduce((acc, account) => {
    if (account.tag) {
      acc[account.tag] = [...(acc[account.tag] || []), account];
    }
    return acc;
  }, {} as AccountGroupsByTag);

  return {
    ...accountGroupsState,
    value: accountGroupssByTag,
  } as ValueState<AccountGroupsByTag>;
});
