import qs from 'query-string';
import React from 'react';
import { Link } from 'react-router-dom';
import { object, string } from 'yup';

import Background from '../../components/atoms/Background';
import Box from '../../components/atoms/Box';
import Divider from '../../components/atoms/Divider';
import TextButton from '../../components/atoms/TextButton';
import Typography from '../../components/atoms/Typography';
import Spacer from '../../components/layout/Spacer';
import Form from '../../components/molecules/Form';
import FormFieldInput from '../../components/molecules/FormFieldInput';
import Message from '../../components/molecules/Message';
import WidePage from '../../components/templates/WidePage';
import fetcher from '../../utils/fetcher';

const SignUpSchema = object().shape({
  email: string().required('Email is required'),
});

const SignUpPage = () => {
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');
  const handlePasswordReset = async (values: { email: string }) => {
    try {
      await fetcher('/forgot-password/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: qs.stringify({
          email: values.email,
        }),
      });
      setMessage(
        'We have sent you an e-mail with a link to reset your password. Please contact us if you do not receive it within a few minutes.',
      );
    } catch (e) {
      setError("Email doesn't exist.");
    }
  };
  return (
    <WidePage>
      <Background>
        <Box width={480}>
          <Spacer margin={{ bottom: 8 }}>
            <Typography size="h5" weight="bold" display="block">
              Forgot password?
            </Typography>
            <Spacer margin={{ top: 1 }} />

            <Typography display="block" color="secondary">
              Enter your e-mail address below. We'll send you an e-mail with a link to reset it.
            </Typography>
          </Spacer>
          {error && (
            <Spacer margin={{ bottom: 6 }}>
              <Message> {error}</Message>
            </Spacer>
          )}
          {message && (
            <Spacer margin={{ bottom: 6 }}>
              <Message variant="info"> {message}</Message>
            </Spacer>
          )}

          <Form
            onSubmit={handlePasswordReset}
            schema={SignUpSchema}
            actions={<TextButton type="submit">Reset my password</TextButton>}
          >
            <FormFieldInput width="100%" name="email" label="Email Address" type="email" />
          </Form>

          <Spacer margin={{ top: 6, bottom: 6 }}>
            <Typography display="block" color="secondary">
              Please contact us if you have any trouble resetting your password.
            </Typography>
          </Spacer>
          <Divider />
          <Spacer margin={{ top: 6 }} />
          <Link to="/signin">
            <Typography display="block" color="primary">
              Back to Login
            </Typography>
          </Link>
        </Box>
      </Background>
    </WidePage>
  );
};
export default SignUpPage;
