import { StringParam, UrlUpdateType, useQueryParam } from 'use-query-params';

import useActiveOrganisation from '../../contexts/OrganisationContext/useActiveOrganisation';
import { CurrencyCode } from './currencySupport';

/**
 * Tracks the selected currency as a query parameter
 * @param queryParamKey optional string, the query parameter key used for this selection, defaults to 'currencyCode'
 */
const useCurrencySelection = (
  queryParamKey: string = 'currencyCode',
): [CurrencyCode, (newValue: CurrencyCode, updateType?: UrlUpdateType | undefined) => void] => {
  const organisation = useActiveOrganisation();
  const [currencyCode, setCurrencyCode] = useQueryParam(queryParamKey, StringParam);
  return [(currencyCode || organisation.default_currency_code || 'GBP') as CurrencyCode, setCurrencyCode];
};

export default useCurrencySelection;
