import React from 'react';
import styled, { css } from 'styled-components';

import SidebarSection from '../../../components/atoms/SidebarSection';
import SidebarWrapper from '../../../components/atoms/SidebarWrapper';
import Toggle from '../../../components/atoms/Toggle';
import Typography, { StyledBodyText } from '../../../components/atoms/Typography';
import { PreviewChartConfig, useDashboardEditorContext } from '../../../components/contexts/DashboardEditorContext';
import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import ColourPicker from '../../../components/molecules/ColourPicker';

export interface DashboardEditorSidebarProps {}

const StyledSideBarWrapper = styled(SidebarWrapper)`
  background-color: #ffffff;
  box-shadow: inset -1px 0px 0px #e2e2ea;
  border: unset;
  height: 100%;
`;
const StyledRow = styled(Row)`
  text-align: center;
`;
const CheckboxToggle = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & > ${StyledBodyText} {
    width: 200px;
  }
`;

export const ClickableTextStyling = styled.span`
  ${({ theme }) => css`
    text-decoration: underline;
    &:hover span {
      cursor: pointer;
      color: ${theme.palette.primary.main};
    }
  `}
`;

const ChartCategory = styled(Column)`
  margin-bottom: 24px;
`;

const DashboardEditorSidebar: React.FC<DashboardEditorSidebarProps> = () => {
  let {
    charts: chartsData,
    primaryColour,
    secondaryColour,
    targetsColour,
    toggleEnabled,
    setPrimaryColour,
    setSecondaryColour,
    setTargetsColour,
  } = useDashboardEditorContext();
  const organisation = useOrganisationContext();
  const applyBrandColors = () => {
    if (organisation.primary_brand_colour) {
      setPrimaryColour(organisation.primary_brand_colour);
    }
    if (organisation.secondary_brand_colour) {
      setSecondaryColour(organisation.secondary_brand_colour);
    }
  };
  const groups = chartsData
    // @ts-ignore
    .map(c => ({ ...c, category: c.category || 'Other' }))
    .reduce((groups: { [key: string]: PreviewChartConfig[] }, item) => {
      const group = groups[item.category] || [];
      group.push(item);
      groups[item.category] = group;
      return groups;
    }, {});

  return (
    <StyledSideBarWrapper>
      <SidebarSection>
        <StyledRow width="100%" hAlign="flex-start" vAlign="center">
          <Typography color="secondary">Smart Dashboard</Typography>
        </StyledRow>
      </SidebarSection>
      <SidebarSection>
        {Object.entries(groups).map(([category, charts]) => (
          <ChartCategory width="100%" key={category}>
            <Typography color="secondary" weight="bold">
              {category}
            </Typography>
            {charts.map((chart, index) => (
              <Row width="100%" key={chart.name}>
                <CheckboxToggle>
                  <Typography>{chart.name}</Typography>
                  <Toggle size="small" checked={chart.enabled} onChange={() => toggleEnabled(chart.id!)} />
                </CheckboxToggle>
              </Row>
            ))}
          </ChartCategory>
        ))}
      </SidebarSection>
      <SidebarSection>
        <Column width="100%">
          <Row width="100%" hAlign="space-between">
            <ColourPicker
              key={primaryColour}
              color={primaryColour}
              handleColor={setPrimaryColour}
              placement={'bottomStart'}
              showBrandColor={false}
            />
            <Typography color="secondary">Primary color </Typography>
          </Row>
          <Row width="100%" hAlign="space-between">
            <ColourPicker
              key={secondaryColour}
              color={secondaryColour}
              handleColor={setSecondaryColour}
              placement={'bottomStart'}
              showBrandColor={false}
            />
            <Typography color="secondary">Secondary color</Typography>
          </Row>
          <Row width="100%" hAlign="space-between">
            <ColourPicker
              key={targetsColour}
              color={targetsColour}
              handleColor={setTargetsColour}
              placement={'bottomStart'}
              showBrandColor={false}
            />
            <Typography color="secondary">Targets color</Typography>
          </Row>
          <Row width="100%" hAlign="space-between">
            <span />
            <ClickableTextStyling onClick={applyBrandColors}>
              <Typography color="secondary" weight="bold">
                Apply brand colours
              </Typography>
            </ClickableTextStyling>
          </Row>
        </Column>
      </SidebarSection>
    </StyledSideBarWrapper>
  );
};

export default DashboardEditorSidebar;
