import React, { useMemo } from 'react';
import { MetricSchema2DataType, MetricSchema2Decimals } from 'scalexp/utils/metrics/metricSchema2';
import { getSimpleMetricSchemaFromNode } from 'scalexp/utils/metrics/ms2utils';
import styled from 'styled-components';

import Typography from '../../../../components/atoms/Typography';
import useActiveOrganisation from '../../../../components/contexts/OrganisationContext/useActiveOrganisation';
import useCurrencySelection from '../../../../components/molecules/CurrencySelect/useCurrencySelection';
import { useDateSelectionDate } from '../../../../components/molecules/DateSelect/useDateSelection';
import { Pipeline, PipelineStage, PipelineStageMetricsTypes } from '../../../../store/state/pipelines/types';
import useMetricSchemaSeries from '../../../../utils/metrics/useMetricSchemaSeries';
import { DISPLAY_COLUMNS, formatValue } from '../../ReportEditor/helpers';
import { reportEditorcalculationRequest } from '../ReportEditorCustomRow';

interface ReportEditorDisplayPipelineRowProps {
  pipelineId: Pipeline['id'];
  stage: PipelineStage;
  metric: PipelineStageMetricsTypes;
  decimals: MetricSchema2Decimals;
}

const StyledGroupContainer = styled.div`
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: 20px 1fr 160px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};

  span:last-child {
    text-align: end;
  }
`;

const ReportEditorDisplayStageRow: React.FC<React.PropsWithChildren<ReportEditorDisplayPipelineRowProps>> = ({
  pipelineId,
  stage,
  metric,
  decimals,
}) => {
  const date = useDateSelectionDate();
  const [currencyCode] = useCurrencySelection();

  const organisation = useActiveOrganisation();
  const { default_currency_code, financial_year_start } = organisation;
  const seriesVS = useMetricSchemaSeries(
    getSimpleMetricSchemaFromNode({
      data: {
        operator: 'entity',
        entity: 'stage',
        pipelineId: pipelineId,
        stageId: stage.id,
        stageMetricId: metric,
      },
      operator: 'add',
    }),
    reportEditorcalculationRequest(date, currencyCode || default_currency_code),
  );

  const values: (number | null)[] = useMemo(() => {
    const series = seriesVS.value;
    if (!series) {
      return [];
    }

    return series.map(serie => (serie ? Number(serie.value) : null));
  }, [seriesVS.value, DISPLAY_COLUMNS, financial_year_start]);

  const dataType: MetricSchema2DataType =
    (seriesVS.value?.[0]?.data_type.toLowerCase() as MetricSchema2DataType) || 'monetary';

  return (
    <StyledGroupContainer>
      <div />
      <Typography color="secondary">{stage.name}</Typography>
      <Typography color="secondary">
        {formatValue(values[0], currencyCode || default_currency_code, dataType, decimals)}
      </Typography>
    </StyledGroupContainer>
  );
};

export default ReportEditorDisplayStageRow;
