export const stageMetricOptions = [
  { label: 'Deals, Number of Deals', value: 'metric_deals' },
  { label: 'Deals, Deal value', value: 'metric_deals_acv' },
  { label: 'Deals, Probability Weighted Deal Value', value: 'metric_deals_weighted_acv' },
  { label: 'Deals, Forecasted Weighted Value', value: 'metric_deals_forecasted_weighted_value' },
];

export const pipelineMetricOptions = [
  { label: 'Deals lost, Number of Deals', value: 'metric_deals_lost' },
  { label: 'Deals lost, Deal Value', value: 'metric_deals_lost_acv' },
  { label: 'Deals won, Number of Deals', value: 'metric_deals_won' },
  { label: 'Deals won, Deal Value', value: 'metric_deals_won_acv' },
  { label: 'Deals in progress, Number of Deals', value: 'metric_deals_in_progress' },
  { label: 'Deals in progress, Deal Value', value: 'metric_deals_in_progress_acv' },
  { label: 'Deals in progress, Probability Weighted Deal Value', value: 'metric_deals_in_progress_weighted_acv' },
  {
    label: 'Deals in progress, Forecasted Weighted Value',
    value: 'metric_deals_in_progress_forecasted_weighted_value',
  },
];

export const pipelineTypeOptions = [
  {
    value: 'IN_PROGRESS',
    label: 'In Progress, Number of Deals',
  },
  {
    value: 'IN_PROGRESS_ACV',
    label: 'In Progress, Deal Value',
  },
  {
    value: 'IN_PROGRESS_WEIGHTED_ACV',
    label: 'In Progress, Probability Weighted Deal Value',
  },
  {
    value: 'IN_PROGRESS_FORECASTED_WEIGHTED_VALUE',
    label: 'In Progress, Forecasted Weighted Deal Value',
  },
  {
    value: 'WON',
    label: 'Won, Number of Deals',
  },
  {
    value: 'WON_ACV',
    label: 'Won, Deal Value',
  },
  {
    value: 'LOST',
    label: 'Lost, Number of Deals',
  },
  {
    value: 'LOST_ACV',
    label: 'Lost, Deal value',
  },
];
