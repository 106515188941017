import produce from 'immer';

import {
  EditedReportActions,
  ACTION_EDITED_REPORT_UPDATE,
  ACTION_EDITED_REPORT_ADD_ROW,
  ACTION_EDITED_REPORT_UPDATE_ROW,
  ACTION_EDITED_REPORT_DELETE_ROW,
  ACTION_EDITED_REPORT_SET_SELECTED_ROWID,
  ACTION_EDITED_REPORT_SET_ADDED_COMPUTATION_ROWID,
} from './actions';
import { EditedReport } from './types';

const editedReport = (
  prevState: EditedReport = { report: null, selectedRowId: null, addedComputationRowId: null, isDirty: false },
  action: EditedReportActions,
): EditedReport => {
  return produce(prevState, state => {
    switch (action.type) {
      // report
      case ACTION_EDITED_REPORT_UPDATE: {
        const { report, isDirty } = action.params;
        state.report = report;
        state.isDirty = isDirty;
        break;
      }
      // row
      case ACTION_EDITED_REPORT_SET_SELECTED_ROWID: {
        const { rowId } = action.params;
        state.selectedRowId = rowId;
        break;
      }
      case ACTION_EDITED_REPORT_SET_ADDED_COMPUTATION_ROWID: {
        const { rowId } = action.params;
        state.addedComputationRowId = rowId;
        break;
      }
      case ACTION_EDITED_REPORT_ADD_ROW: {
        const { row: newRow } = action.params;
        state.report?.rows.push(newRow);
        state.isDirty = true;
        break;
      }
      case ACTION_EDITED_REPORT_UPDATE_ROW: {
        const { row: updatedRow } = action.params;
        const index = state.report?.rows.findIndex(row => row.id === updatedRow.id);
        if (index === undefined || index === -1) {
          return state;
        }

        state.report?.rows.splice(index, 1, updatedRow);
        state.isDirty = true;
        break;
      }
      case ACTION_EDITED_REPORT_DELETE_ROW: {
        if (!state.report) {
          return state;
        }

        const { rowId } = action.params;
        state.report.rows = state.report?.rows.filter(row => row.id !== rowId);
        state.isDirty = true;
        break;
      }
      default:
        return state;
    }
    return state;
  });
};

export default editedReport;
