import { WritableJournal } from '../../../features/deferred-revenue-journal/WriteJournalContext';
import { promiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectManualJournals } from './selectors';
import { ManualJournalsResponse, ManualJournalActions as ManualJournalsActions, ManualJournal } from './types';

export const ORGANISATIONS_MANUAL_JOURNALS_LOAD = 'ORGANISATIONS_MANUAL_JOURNALS_LOAD';
export const ORGANISATIONS_MANUAL_JOURNAL_POST = 'ORGANISATIONS_MANUAL_JOURNAL_POST';

export const loadManualJournalsAction = (organisationId: number): ThunkAction<ManualJournalsActions> => async (
  dispatch: ThunkDispatch<ManualJournalsActions>,
  getState,
) => {
  const state = getState();
  const manualJournals = selectManualJournals(state, organisationId);

  if (manualJournals?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_MANUAL_JOURNALS_LOAD,
        () =>
          http<ManualJournalsResponse, ManualJournalsResponse>(
            `/api/v1/organisations/${organisationId}/accounting/manual_journal`,
            {},
            response => response,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export type PostManualJournalResult =
  | {
      success: false;
    }
  | {
      success: true;
      journal: ManualJournal;
    };

export const postManualJournalAction = (
  organisationId: number,
  journal: WritableJournal,
): ThunkAction<ManualJournalsActions, Promise<PostManualJournalResult>> => async (
  dispatch: ThunkDispatch<ManualJournalsActions>,
  getState,
) => {
  const state = getState();
  const manualJournals = selectManualJournals(state, organisationId);
  if (manualJournals?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const res = await dispatch(
      promiseAction(
        ORGANISATIONS_MANUAL_JOURNAL_POST,
        () =>
          http<ManualJournal, { journal: ManualJournal }>(
            `/api/v1/organisations/${organisationId}/accounting/manual_journal`,
            {
              body: {
                month: journal.month,
                lines: journal.lines.map(line => ({
                  account_id: line.account_id,
                  line_amount: line.line_amount.toFixed(2),
                })),
              },
              method: 'POST',
            },
            response => response.journal,
          ),
        {
          organisationId,
          journal,
        },
      ),
    );
    return {
      success: true,
      journal: res,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};
