import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface SidebarSectionProps extends HTMLAttributes<HTMLDivElement> {}

const StyledSidebarSection = styled.div<SidebarSectionProps>`
  ${({ theme }) => `
    width: 100%;
    padding: ${theme.spacing(3)}  ${theme.spacing(2.5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};

    :not(&:first-of-type) {
      border-top: 1px solid ${theme.palette.silver};
    }
  `}
`;

const SidebarSection: React.FC<React.PropsWithChildren<SidebarSectionProps>> = ({ children, ...other }) => {
  return <StyledSidebarSection {...other}>{children}</StyledSidebarSection>;
};

export default SidebarSection;
