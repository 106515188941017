import useActiveOrganisationId from '../../../components/contexts/OrganisationContext/useActiveOrganisationId';
import { usePipelines } from '../../../store/state/pipelines/hooks';

const useCRMPipeLinesConnections = (): string[] => {
  const organisationId = useActiveOrganisationId();
  const pipelinesVS = usePipelines(organisationId);
  const crmConnections = [...new Set(Object.values(pipelinesVS.value || {}).map(pipeline => pipeline.source_name))];

  return crmConnections;
};

export default useCRMPipeLinesConnections;
