import produce from 'immer';

import { budgetSetsActions, OrganisationBudgets, ORGANISATIONS_BUDGET_LOAD } from './types';

const budgets = (prevState: OrganisationBudgets = {}, action: budgetSetsActions): OrganisationBudgets => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_BUDGET_LOAD:
        const { organisationId, budgetsetId } = action.params;
        if (!state[organisationId]) {
          state[organisationId] = {};
        }
        switch (action.status) {
          case 'pending': {
            state[organisationId][budgetsetId] = {
              status: 'pending',
              error: undefined,
              value: {},
            };
            return;
          }
          case 'success': {
            state[organisationId][budgetsetId] = {
              status: 'success',
              error: undefined,
              value: action.payload,
            };
            return;
          }
          case 'error': {
            state[organisationId][budgetsetId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;
          }
          default:
            return state;
        }

      default:
        return state;
    }
  });
};

export default budgets;
