import { MetricSchema2DataType } from 'scalexp/utils/metrics/metricSchema2';

import { PipelineMetricsTypes } from '../../store/state/pipelines/types';

const NATIVE_METRIC_COLOR: { [key: string]: string } = {
  REVENUE: '#FFFBD6',
  EXPENSE: '#DFE2FF',
  ASSET: '#B2C9EA',
  LIABILITY: '#E2E7E5',
  EQUITY: '#ABB6B6',
};

export const getNativeMetricColor = (metricId: string) => {
  const type = NATIVE_METRICS_TYPES_MAP[metricId];
  if (!type) {
    return 'white';
  }
  return NATIVE_METRIC_COLOR[type];
};

export const NATIVE_METRICS_TYPES_MAP: { [key: string]: string } = {
  REVENUE_TOTAL: 'REVENUE',
  NON_OPERATING_INCOME: 'REVENUE',
  INTER_COMPANY_REVENUE: 'REVENUE',
  COST_OF_SALES: 'EXPENSE',
  OPERATING_EXPENSES: 'EXPENSE',
  DEPRECIATION_AMORTISATION: 'EXPENSE',
  NON_OPERATING_EXPENSES: 'EXPENSE',
  INTER_COMPANY_COSTS: 'EXPENSE',
  CASH_IN_BANK: 'ASSET',
  ACCOUNTS_RECEIVABLE: 'ASSET',
  CURRENT_ASSETS: 'ASSET',
  FIXED_ASSETS: 'ASSET',
  NON_CURRENT_ASSETS: 'ASSET',
  INTER_COMPANY_ASSETS: 'ASSET',
  OTHER_CURRENT_LIABILITIES: 'LIABILITY',
  NON_CURRENT_LIABILITIES: 'LIABILITY',
  INTER_COMPANY_LIABILITIES: 'LIABILITY',
  DEBT: 'LIABILITY',
  ACCOUNTS_PAYABLE: 'LIABILITY',
  RETAINED_EARNINGS: 'EQUITY',
  EQUITY: 'EQUITY',
};

export const UNASSIGNED_PNL = 'UNASSIGNED_PNL';
export const UNASSIGNED_BS = 'UNASSIGNED_BS';
export const NATIVE_METRICS_PROFIT_AND_LOSS = [
  'REVENUE_TOTAL',
  'COST_OF_SALES',
  'OPERATING_EXPENSES',
  'DEPRECIATION_AMORTISATION',
  'NON_OPERATING_INCOME',
  'NON_OPERATING_EXPENSES',
  'INTER_COMPANY_REVENUE',
  'INTER_COMPANY_COSTS',
  UNASSIGNED_PNL,
];

export const NATIVE_METRICS_BALANCE_SHEET = [
  'CASH_IN_BANK',
  'ACCOUNTS_RECEIVABLE',
  'OTHER_CURRENT_ASSETS',
  'FIXED_ASSETS',
  'NON_CURRENT_ASSETS',
  'ACCOUNTS_PAYABLE',
  'OTHER_CURRENT_LIABILITIES',
  'DEBT',
  'NON_CURRENT_LIABILITIES',
  'INTER_COMPANY_ASSETS',
  'INTER_COMPANY_LIABILITIES',
  'RETAINED_EARNINGS',
  'EQUITY',
  UNASSIGNED_BS,
];

export const NATIVE_METRICS_CUSTOM: string[] = [
  'CUSTOM_NATIVE_METRIC_INTEGER_ONE',
  'CUSTOM_NATIVE_METRIC_INTEGER_TWO',
  'CUSTOM_NATIVE_METRIC_INTEGER_THREE',
  'CUSTOM_NATIVE_METRIC_DECIMAL_ONE',
  'CUSTOM_NATIVE_METRIC_DECIMAL_TWO',
  'CUSTOM_NATIVE_METRIC_DECIMAL_THREE',
  'CUSTOM_NATIVE_METRIC_PERCENTAGE_ONE',
  'CUSTOM_NATIVE_METRIC_PERCENTAGE_TWO',
  'CUSTOM_NATIVE_METRIC_PERCENTAGE_THREE',
  'CUSTOM_NATIVE_METRIC_MONETARY_ONE',
  'CUSTOM_NATIVE_METRIC_MONETARY_TWO',
  'CUSTOM_NATIVE_METRIC_MONETARY_THREE',
  // Specific custom native metrics
  'NUMBER_OF_EMPLOYEES',
  'UNITS',
  'ORDERS',
  'MONTHS_OF_CASH',
];
export const NATIVE_METRICS_OTHER: string[] = [
  // Eliminations
  'ELIMINATED_REVENUE',
  'ELIMINATED_COSTS',
  'ELIMINATED_ASSETS',
  'ELIMINATED_LIABILITIES',
  // Custom native metrics
  ...NATIVE_METRICS_CUSTOM,
];

export const NATIVE_METRIC_IDS: string[] = [
  ...NATIVE_METRICS_PROFIT_AND_LOSS,
  ...NATIVE_METRICS_BALANCE_SHEET,
  ...NATIVE_METRICS_OTHER,
];
export type NativeMetricId = typeof NATIVE_METRIC_IDS[number];

// Hard coded dataTypes for native metrics
export const NATIVE_METRIC_DATA_TYPE: { [nativeMetricId: NativeMetricId]: MetricSchema2DataType } = {
  ACCOUNTS_PAYABLE: 'monetary',
  ACCOUNTS_RECEIVABLE: 'monetary',
  CASH_IN_BANK: 'monetary',
  COST_OF_SALES: 'monetary',
  OTHER_CURRENT_ASSETS: 'monetary',
  OTHER_CURRENT_LIABILITIES: 'monetary',
  DEBT: 'monetary',
  DEPRECIATION_AMORTISATION: 'monetary',
  EQUITY: 'monetary',
  FIXED_ASSETS: 'monetary',
  INTER_COMPANY_REVENUE: 'monetary',
  INTER_COMPANY_COSTS: 'monetary',
  INTER_COMPANY_ASSETS: 'monetary',
  INTER_COMPANY_LIABILITIES: 'monetary',
  ELIMINATED_REVENUE: 'monetary',
  ELIMINATED_COSTS: 'monetary',
  ELIMINATED_ASSETS: 'monetary',
  ELIMINATED_LIABILITIES: 'monetary',
  NON_CURRENT_ASSETS: 'monetary',
  NON_CURRENT_LIABILITIES: 'monetary',
  NON_OPERATING_INCOME: 'monetary',
  NON_OPERATING_EXPENSES: 'monetary',
  OPERATING_EXPENSES: 'monetary',
  RETAINED_EARNINGS: 'monetary',
  REVENUE_TOTAL: 'monetary',
  REVENUE: 'monetary',
  NUMBER_OF_EMPLOYEES: 'numerical',
  CUSTOM_NATIVE_METRIC_INTEGER_ONE: 'numerical',
  CUSTOM_NATIVE_METRIC_INTEGER_TWO: 'numerical',
  CUSTOM_NATIVE_METRIC_INTEGER_THREE: 'numerical',
  CUSTOM_NATIVE_METRIC_DECIMAL_ONE: 'numerical',
  CUSTOM_NATIVE_METRIC_DECIMAL_TWO: 'numerical',
  CUSTOM_NATIVE_METRIC_DECIMAL_THREE: 'numerical',
  CUSTOM_NATIVE_METRIC_PERCENTAGE_ONE: 'percentage',
  CUSTOM_NATIVE_METRIC_PERCENTAGE_TWO: 'percentage',
  CUSTOM_NATIVE_METRIC_PERCENTAGE_THREE: 'percentage',
  CUSTOM_NATIVE_METRIC_MONETARY_ONE: 'monetary',
  CUSTOM_NATIVE_METRIC_MONETARY_TWO: 'monetary',
  CUSTOM_NATIVE_METRIC_MONETARY_THREE: 'monetary',
  UNITS: 'numerical',
  ORDERS: 'numerical',
  MONTHS_OF_CASH: 'numerical',
  UNASSIGNED_PNL: 'monetary',
  UNASSIGNED_BS: 'monetary',
};

export const PIPELINE_METRIC_DATA_TYPE: { [key: string]: MetricSchema2DataType } = {
  metric_deals_lost: 'numerical',
  metric_deals_lost_acv: 'monetary',
  metric_deals_won: 'numerical',
  metric_deals_won_acv: 'monetary',
  metric_deals_in_progress: 'numerical',
  metric_deals_in_progress_acv: 'monetary',
  metric_deals_in_progress_weighted_acv: 'monetary',
  metric_deals_in_progress_forecasted_weighted_value: 'monetary',
};
export const PIPELINE_STAGE_METRIC_DATA_TYPE: { [key: string]: MetricSchema2DataType } = {
  metric_deals: 'numerical',
  metric_deals_acv: 'monetary',
  metric_deals_weighted_acv: 'monetary',
  metric_deals_forecasted_weighted_value: 'monetary',
};

export const SALES_METRIC_DATA_TYPE: { [key: string]: MetricSchema2DataType } = {
  RENEWALS_IN_PROGRESS: 'numerical',
  RENEWALS_IN_PROGRESS_ACV: 'monetary',
  RENEWALS_IN_PROGRESS_WEIGHTED_ACV: 'monetary',
  RENEWALS_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE: 'monetary',
  RENEWALS_WON: 'numerical',
  RENEWALS_WON_ACV: 'monetary',
  RENEWALS_LOST: 'numerical',
  RENEWALS_LOST_ACV: 'monetary',
  NEW_SALES_IN_PROGRESS: 'numerical',
  NEW_SALES_IN_PROGRESS_ACV: 'monetary',
  NEW_SALES_IN_PROGRESS_WEIGHTED_ACV: 'monetary',
  NEW_SALES_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE: 'monetary',
  NEW_SALES_WON: 'numerical',
  NEW_SALES_WON_ACV: 'monetary',
  NEW_SALES_LOST: 'numerical',
  NEW_SALES_LOST_ACV: 'monetary',
};

export const SALES_METRIC_PIPELINE_TYPE: { [key: string]: PipelineMetricsTypes } = {
  RENEWALS_IN_PROGRESS: 'metric_deals_in_progress',
  RENEWALS_IN_PROGRESS_ACV: 'metric_deals_in_progress_acv',
  RENEWALS_IN_PROGRESS_WEIGHTED_ACV: 'metric_deals_in_progress_weighted_acv',
  RENEWALS_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE: 'metric_deals_in_progress_forecasted_weighted_value',
  RENEWALS_WON: 'metric_deals_won',
  RENEWALS_WON_ACV: 'metric_deals_won_acv',
  RENEWALS_LOST: 'metric_deals_lost',
  RENEWALS_LOST_ACV: 'metric_deals_lost_acv',
  NEW_SALES_IN_PROGRESS: 'metric_deals_in_progress',
  NEW_SALES_IN_PROGRESS_ACV: 'metric_deals_in_progress_acv',
  NEW_SALES_IN_PROGRESS_WEIGHTED_ACV: 'metric_deals_in_progress_weighted_acv',
  NEW_SALES_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE: 'metric_deals_in_progress_forecasted_weighted_value',
  NEW_SALES_WON: 'metric_deals_won',
  NEW_SALES_WON_ACV: 'metric_deals_won_acv',
  NEW_SALES_LOST: 'metric_deals_lost',
  NEW_SALES_LOST_ACV: 'metric_deals_lost_acv',
};

export const SALES_METRIC_DISPLAY_NAMES: { [key: string]: string } = {
  RENEWALS_IN_PROGRESS: 'Renewals, In Progress, Number of Deals',
  RENEWALS_IN_PROGRESS_ACV: 'Renewals, In Progress, Deal Value',
  RENEWALS_IN_PROGRESS_WEIGHTED_ACV: 'Renewals, In Progress, Probability Weighted Deal Value',
  RENEWALS_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE: 'Renewals, In Progress, Forecasted Weighted Value',
  RENEWALS_WON: 'Renewals Won, Number of Deals',
  RENEWALS_WON_ACV: 'Renewals Won, Deal Value',
  RENEWALS_LOST: 'Renewals Lost, Number of Deals',
  RENEWALS_LOST_ACV: 'Renewals Lost, Deal value',
  NEW_SALES_IN_PROGRESS: 'New Sales, In Progress, Number of Deals',
  NEW_SALES_IN_PROGRESS_ACV: 'New Sales, In Progress, Deal Value',
  NEW_SALES_IN_PROGRESS_WEIGHTED_ACV: 'New Sales, In Progress, Probability Weighted Deal Value',
  NEW_SALES_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE: 'New Sales, In Progress, Forecasted Weighted Value',
  NEW_SALES_WON: 'New Sales Won, Number of Deals',
  NEW_SALES_WON_ACV: 'New Sales Won, Deal Value',
  NEW_SALES_LOST: 'New Sales Lost, Number of Deals',
  NEW_SALES_LOST_ACV: 'New Sales Lost, Deal Value',
};

// Hard coded names for native metrics
export const NATIVE_METRIC_DISPLAY_NAMES: { [nativeMetricId: NativeMetricId]: string } = {
  ACCOUNTS_PAYABLE: 'Accounts Payable',
  ACCOUNTS_RECEIVABLE: 'Accounts Receivable',
  CASH_IN_BANK: 'Cash in Bank',
  COST_OF_SALES: 'Cost of Sales',
  OTHER_CURRENT_ASSETS: 'Other Current Assets',
  OTHER_CURRENT_LIABILITIES: 'Other Current Liabilities',
  DEBT: 'Debt',
  DEPRECIATION_AMORTISATION: 'Depreciation and Amortisation',
  EQUITY: 'Equity',
  FIXED_ASSETS: 'Fixed Assets',
  INTER_COMPANY_REVENUE: 'Intercompany Revenue',
  INTER_COMPANY_COSTS: 'Intercompany Costs',
  INTER_COMPANY_ASSETS: 'Intercompany Assets',
  INTER_COMPANY_LIABILITIES: 'Intercompany Liabilities',
  ELIMINATED_REVENUE: 'Eliminated Revenue',
  ELIMINATED_COSTS: 'Eliminated Costs',
  ELIMINATED_ASSETS: 'Eliminated Assets',
  ELIMINATED_LIABILITIES: 'Eliminated Liabilities',
  NON_CURRENT_ASSETS: 'Non Current Assets',
  NON_CURRENT_LIABILITIES: 'Non Current Liabilities',
  NON_OPERATING_INCOME: 'Non Operating Income',
  NON_OPERATING_EXPENSES: 'Non Operating Expenses',
  OPERATING_EXPENSES: 'Operating Expenses',
  RETAINED_EARNINGS: 'Retained Earnings',
  REVENUE_TOTAL: 'Total Revenue',
  NUMBER_OF_EMPLOYEES: 'Number of Employees',
  CUSTOM_NATIVE_METRIC_DECIMAL_ONE: 'Custom Metric #.# No.1',
  CUSTOM_NATIVE_METRIC_DECIMAL_TWO: 'Custom Metric #.# No.2',
  CUSTOM_NATIVE_METRIC_DECIMAL_THREE: 'Custom Metric #.# No.3',
  CUSTOM_NATIVE_METRIC_INTEGER_ONE: 'Custom Metric # No.1',
  CUSTOM_NATIVE_METRIC_INTEGER_TWO: 'Custom Metric # No.2',
  CUSTOM_NATIVE_METRIC_INTEGER_THREE: 'Custom Metric # No.3',
  CUSTOM_NATIVE_METRIC_PERCENTAGE_ONE: 'Custom Metric % No.1',
  CUSTOM_NATIVE_METRIC_PERCENTAGE_TWO: 'Custom Metric % No.2',
  CUSTOM_NATIVE_METRIC_PERCENTAGE_THREE: 'Custom Metric % No.3',
  CUSTOM_NATIVE_METRIC_MONETARY_ONE: 'Custom Metric £ No.1',
  CUSTOM_NATIVE_METRIC_MONETARY_TWO: 'Custom Metric £ No.2',
  CUSTOM_NATIVE_METRIC_MONETARY_THREE: 'Custom Metric £ No.3',
  UNITS: 'Units',
  ORDERS: 'Orders',
  MONTHS_OF_CASH: 'Estimated Months of Cash Remaining',
  UNASSIGNED_PNL: 'Unassigned PNL accounts',
  UNASSIGNED_BS: 'Unassigned BS accounts',
};

export const SALES_METRIC_IDS: string[] = [
  'NEW_SALES_IN_PROGRESS',
  'NEW_SALES_IN_PROGRESS_ACV',
  'NEW_SALES_IN_PROGRESS_WEIGHTED_ACV',
  'NEW_SALES_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE',
  'NEW_SALES_WON',
  'NEW_SALES_WON_ACV',
  'NEW_SALES_LOST',
  'NEW_SALES_LOST_ACV',
  'RENEWALS_IN_PROGRESS',
  'RENEWALS_IN_PROGRESS_ACV',
  'RENEWALS_IN_PROGRESS_WEIGHTED_ACV',
  'RENEWALS_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE',
  'RENEWALS_WON',
  'RENEWALS_WON_ACV',
  'RENEWALS_LOST',
  'RENEWALS_LOST_ACV',
];
export type SalesMetricIds = typeof SALES_METRIC_IDS[number];

export const INVOICED_REVENUE_METRIC_IDS: string[] = [
  'MRR',
  'MRR_NEW',
  'MRR_LOST',
  'MRR_UPGRADED',
  'MRR_DOWNGRADED',
  'MRR_REACTIVATED',
  'ACTIVE_CUSTOMERS',
  'CUSTOMERS_NEW',
  'CUSTOMERS_LOST_OR_CHURNED',
  'CUSTOMERS_UPGRADES',
  'CUSTOMERS_DOWNGRADES',
  'CUSTOMERS_REACTIVATED',
  'MRR_CUMULATIVE',
];
export type InvoicedRevenueMetricId = typeof INVOICED_REVENUE_METRIC_IDS[number];

// Hard coded names for native metrics
export const INVOICED_REVENUE_METRIC_DISPLAY_NAMES: { [revenueMetricId: InvoicedRevenueMetricId]: string } = {
  ACTIVE_CUSTOMERS: 'Active Customers',
  CUSTOMERS_DOWNGRADES: 'Downgraded Customers',
  CUSTOMERS_LOST_OR_CHURNED: 'Lost Customers',
  CUSTOMERS_UPGRADES: 'Upsold Customers',
  CUSTOMERS_NEW: 'New Customers',
  CUSTOMERS_REACTIVATED: 'Reactivated Customers',
  MRR_DOWNGRADED: 'MRR Downgraded',
  MRR_LOST: 'MRR Lost',
  MRR_NEW: 'MRR New',
  MRR: 'MRR',
  MRR_UPGRADED: 'MRR Upsell',
  MRR_REACTIVATED: 'MRR Reactivated',
  MRR_CUMULATIVE: 'MRR (cumulative)',
};

export const REVENUE_METRIC_DATA_TYPE: { [revenueMetricId: string]: MetricSchema2DataType } = {
  ACTIVE_CUSTOMERS: 'numerical',
  CUSTOMERS_DOWNGRADES: 'numerical',
  CUSTOMERS_LOST_OR_CHURNED: 'numerical',
  CUSTOMERS_UPGRADES: 'numerical',
  CUSTOMERS_NEW: 'numerical',
  CUSTOMERS_REACTIVATED: 'numerical',
  MRR_DOWNGRADED: 'monetary',
  MRR_LOST: 'monetary',
  MRR_NEW: 'monetary',
  MRR: 'monetary',
  MRR_UPGRADED: 'monetary',
  MRR_REACTIVATED: 'monetary',
  MRR_CUMULATIVE: 'monetary',
};

export const NON_EDITABLE_DERIVED_METRICS_IDS = [
  'TOTAL_CURRENT_LIABILITIES',
  'NET_PROFIT',
  'TOTAL_EQUITY',
  'OPERATING_PROFIT',
  'TOTAL_CURRENT_ASSETS',
  'BS_FX_CONSOLIDATION_RESERVES',
  'PNL_FX_CONSOLIDATION_RESERVES',
];

export const INVOICED_REVENUE_METRIC_DEFINITION: { [nativeMetricId: NativeMetricId]: string } = {
  MRR:
    'MRR, or Monthly Recurring Revenue, is monthly revenue from customers invoiced.  This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',
  MRR_NEW:
    'New MRR is MRR from customers invoiced for the first time.  This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',
  MRR_LOST:
    'Lost MRR is MRR for customers whose invoiced service period has ended .  This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',
  MRR_UPGRADED:
    'Upsold MRR is the amount of increase in MRR from customers with higher revenue than in the prior month.   This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',
  MRR_DOWNGRADED:
    'Downgraded MRR is the amount of decrease in MRR from customers with lower revenue than the prior month.  This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',
  MRR_REACTIVATED:
    'Reactivated MRR is MRR from customers invoiced for the first time after being lost.   This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',

  ACTIVE_CUSTOMERS:
    'Active Customers is the number of customers with MRR in the month.  This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',
  CUSTOMERS_NEW:
    'New Customers is the number of customers invoiced for the first time.  This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',
  CUSTOMERS_LOST_OR_CHURNED:
    'Lost Customers is the number of customers whose invoiced service period has ended .  This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',
  CUSTOMERS_UPGRADES:
    'Upsold Customers is the number of customers with higher revenue than in the prior month.   This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',
  CUSTOMERS_DOWNGRADES:
    'Downgraded Customers is the number of customers with lower revenue than the prior month.  This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',
  CUSTOMERS_REACTIVATED:
    'Reactivated Customers is the number of customers invoiced for the first time, after being lost.  This data is calculated automatically by ScaleXP.  Full details are provided on the Customers page of the system, in the ‘global’ report.',
};
