import {
  CommonSlideProperties,
  Presentation,
  SlideElement,
  SlideElementKey,
} from '../../../features/presentations/types';
import { promiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { PresentationsActions } from './types';

export const ACTION_PRESENTATION_LOAD = '[presentation] LOAD';
export const ACTION_PRESENTATION_UPDATE = '[presentation] UPDATE';
export const ACTION_PRESENTATION_OPTIMISTIC_UPDATE = '[presentation] OPTIMISTIC_UPDATE';
export const ACTION_PRESENTATION_ADD_SLIDE = '[presentation] ADD';
export const ACTION_PRESENTATION_UPDATE_SLIDE = '[presentation] UPDATE_SLIDE';
export const ACTION_PRESENTATION_DELETE_SLIDE = '[presentation] DELETE_SLIDE';
export const ACTION_PRESENTATION_DUPLICATE_SLIDE = '[presentation] DUPLICATE_SLIDE';
export const ACTION_PRESENTATION_UPDATE_SLIDE_ELEMENT = '[presentation] UPDATE_SLIDE_ELEMENT';
export const ACTION_PRESENTATION_DELETE_SLIDE_ELEMENT = '[presentation] DELETE_SLIDE_ELEMENT';
export const ACTION_PRESENTATION_REORDER_SLIDE = '[presentation] REORDER_SLIDE';
export const UPDATE_PRESENTATION_SLIDE_COMPUTED_PROPERTIES = '[presentation] UPDATE_SLIDE_COMPUTED_PROPERTIES';

export const loadPresentationsAction = (
  organisationId: number,
  presentationId: string,
): ThunkAction<PresentationsActions> => async (dispatch: ThunkDispatch<PresentationsActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_PRESENTATION_LOAD,
        () =>
          http<Presentation, Presentation>(
            `/api/v1/organisations/${organisationId}/presentations/${presentationId}`,
            {},
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const updatePresentation = (
  organisationId: number,
  presentation: Partial<Omit<Presentation, 'slides'>>,
): ThunkAction<PresentationsActions> => async (dispatch: ThunkDispatch<PresentationsActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_PRESENTATION_UPDATE,
        () =>
          http<void, Presentation>(
            `/api/v1/organisations/${organisationId}/presentations/${presentation.id}`,
            {
              method: 'PATCH',
              body: presentation,
            },
            res => res,
          ),
        {
          organisationId,
          presentation,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const updatePresentationOptimistic = (
  organisationId: number,
  presentation: Partial<Omit<Presentation, 'slides'>>,
): ThunkAction<PresentationsActions> => async (dispatch: ThunkDispatch<PresentationsActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_PRESENTATION_OPTIMISTIC_UPDATE,
        () =>
          http<void, Presentation>(
            `/api/v1/organisations/${organisationId}/presentations/${presentation.id}`,
            {
              method: 'PATCH',
              body: presentation,
            },
            res => res,
          ),
        {
          organisationId,
          presentation,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const newPresentationSlide = (
  organisationId: number,
  presentationId: string,
  slideLayout: string,
): ThunkAction<PresentationsActions> => async (dispatch: ThunkDispatch<PresentationsActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_PRESENTATION_ADD_SLIDE,
        () =>
          http<void, Presentation>(
            `/api/v1/organisations/${organisationId}/presentations/${presentationId}/slides`,
            {
              method: 'POST',
              body: {
                layout: slideLayout,
              },
            },
            res => res,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const updatePresentationSlide = (
  organisationId: number,
  presentationId: string,
  slideId: number,
  data: Partial<CommonSlideProperties>,
): ThunkAction<PresentationsActions> => async (dispatch: ThunkDispatch<PresentationsActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_PRESENTATION_UPDATE_SLIDE,
        () =>
          http<void, Presentation>(
            `/api/v1/organisations/${organisationId}/presentations/${presentationId}/slides/${slideId}`,
            {
              method: 'PATCH',
              body: data,
            },
            res => res,
          ),
        {
          organisationId,
          slideId,
          data,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const updatePresentationSlidePosition = (
  organisationId: number,
  presentationId: string,
  slideId: number,
  position: number,
): ThunkAction<PresentationsActions> => async (dispatch: ThunkDispatch<PresentationsActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_PRESENTATION_REORDER_SLIDE,
        () =>
          http<void, Presentation>(
            `/api/v1/organisations/${organisationId}/presentations/${presentationId}/slides/${slideId}`,
            {
              method: 'PATCH',
              body: {
                position: position,
              },
            },
            res => res,
          ),
        {
          organisationId,
          slideId,
          position,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const deletePresentationSlide = (
  organisationId: number,
  presentationId: string,
  slideId: number,
): ThunkAction<PresentationsActions> => async (dispatch: ThunkDispatch<PresentationsActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_PRESENTATION_DELETE_SLIDE,
        () =>
          http<void, Presentation>(
            `/api/v1/organisations/${organisationId}/presentations/${presentationId}/slides/${slideId}`,
            {
              method: 'DELETE',
            },
            res => res,
          ),
        {
          organisationId,
          slideId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const duplicatePresentationSlide = (
  organisationId: number,
  presentationId: string,
  slideId: number,
): ThunkAction<PresentationsActions> => async (dispatch: ThunkDispatch<PresentationsActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_PRESENTATION_DUPLICATE_SLIDE,
        () =>
          http<void, Presentation>(
            `/api/v1/organisations/${organisationId}/presentations/${presentationId}/slides/${slideId}`,
            {
              method: 'POST',
            },
            res => res,
          ),
        {
          organisationId,
          slideId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const updatePresentationSlideElement = (
  organisationId: number,
  presentationId: string,
  slideId: number,
  elementKey: SlideElementKey,
  data: SlideElement,
): ThunkAction<PresentationsActions> => async (dispatch: ThunkDispatch<PresentationsActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_PRESENTATION_UPDATE_SLIDE_ELEMENT,
        () =>
          http<void, Presentation>(
            `/api/v1/organisations/${organisationId}/presentations/${presentationId}/slides/${slideId}/${elementKey}`,
            {
              method: 'POST',
              body: data,
            },
            res => res,
          ),
        {
          organisationId,
          slideId,
          elementKey,
          savingType: data.element_type,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const deletePresentationSlideElement = (
  organisationId: number,
  presentationId: string,
  slideId: number,
  elementKey: SlideElementKey,
): ThunkAction<PresentationsActions> => async (dispatch: ThunkDispatch<PresentationsActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_PRESENTATION_DELETE_SLIDE_ELEMENT,
        () =>
          http<void, Presentation>(
            `/api/v1/organisations/${organisationId}/presentations/${presentationId}/slides/${slideId}/${elementKey}`,
            {
              method: 'POST',
              body: { element_type: 'none' },
            },
            res => res,
          ),
        {
          organisationId,
          slideId,
          elementKey,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
