import React, { useState } from 'react';
import styled from 'styled-components';

export interface ToggleProps {
  size?: 'small' | 'large';
  checked?: boolean;
  initChecked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

const StyledContainer = styled.div<ToggleProps>`
  position: relative;
  background-color: ${({ checked, theme }) => (checked ? theme.palette.primary.main : theme.palette.silver)};
  border-radius: 100px;
  cursor: pointer;

  ${({ disabled }) => {
    if (disabled) {
      return `
        cursor: not-allowed;
        opacity: 0.5;
      `;
    } else {
      return '';
    }
  }}
  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
          width: 40px;
          height: 24px;
          `;
      case 'large':
        return `
          width: 60px;
          height: 32px;
          `;
      default:
        return '';
    }
  }}
`;

const StyledToggle = styled.div<ToggleProps>`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.white};
  position: absolute;
  top: 2px;
  cursor: pointer;
  left: ${({ checked }) => (checked ? 'calc(50% - 2px)' : '2px')};

  ${({ disabled }) => {
    if (disabled) {
      return `
        cursor: not-allowed;
        opacity: 0.5;
      `;
    } else {
      return '';
    }
  }}

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
        width: 20px;
        height: 20px;
          `;
      case 'large':
        return `
          width: 28px;
          height: 28px;
          `;
      default:
        return '';
    }
  }}

  transition: left 0.25s;
`;

const Toggle: React.FC<React.PropsWithChildren<ToggleProps>> = ({
  size = 'small',
  checked,
  onChange,
  initChecked,
  disabled = false,
}) => {
  const [innerChecked, setInnerChecked] = useState(initChecked ?? false);

  const handleChange = () => {
    if (disabled) {
      return;
    }
    const newValue = !(checked ?? innerChecked);
    onChange && onChange(newValue);
    setInnerChecked(newValue);
  };

  const usedChecked = checked ?? innerChecked;
  return (
    <StyledContainer size={size} checked={usedChecked} disabled={disabled} onClick={handleChange}>
      <StyledToggle size={size} checked={usedChecked} disabled={disabled} />
    </StyledContainer>
  );
};
export default Toggle;
