import produce from 'immer';

import {
  ExchangeRatesActions,
  ORGANISATIONS_ACTUALS_EXCHANGE_RATE_OVERRIDES_LOAD,
  ORGANISATIONS_ACTUALS_EXCHANGE_RATE_OVERRIDES_UPDATE,
} from './actions';
import { OrganisationActualsExchangeRateOverrides } from './types';

const actualsExchangeRateOverride = (
  prevState: OrganisationActualsExchangeRateOverrides = {},
  action: ExchangeRatesActions,
): OrganisationActualsExchangeRateOverrides => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_ACTUALS_EXCHANGE_RATE_OVERRIDES_LOAD:
        switch (action.status) {
          case 'pending': {
            const { organisationId } = action.params;
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: undefined,
            };
            return;
          }
          case 'success': {
            const { organisationId } = action.params;
            state[organisationId] = {
              status: 'success',
              value: action.payload,
              error: undefined,
            };
            return;
          }
          case 'error': {
            const { organisationId } = action.params;
            state[organisationId] = {
              status: 'error',
              value: undefined,
              error: action.error,
            };
            return;
          }
          default:
            return state;
        }
      case ORGANISATIONS_ACTUALS_EXCHANGE_RATE_OVERRIDES_UPDATE:
        switch (action.status) {
          case 'success':
            const { organisationId, rate_type, from_currency, to_currency, date_for, value } = action.params;
            // Ensure the path exists, if not, create empty objects until it does
            if (!state[organisationId].value) {
              state[organisationId].value = {};
            }
            if (!state[organisationId].value![from_currency]) {
              state[organisationId].value![from_currency] = {};
            }
            if (!state[organisationId].value![from_currency][to_currency]) {
              state[organisationId].value![from_currency][to_currency] = {
                AVERAGE: {},
                LAST_DAY: {},
              };
            }
            if (value) {
              state[organisationId].value![from_currency][to_currency][rate_type][date_for] = value;
            } else {
              delete state[organisationId].value![from_currency][to_currency][rate_type][date_for];
            }
            return;
          default:
            return state;
        }
      default:
        return state;
    }
  });
};

export default actualsExchangeRateOverride;
