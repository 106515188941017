import React, { FC, ImgHTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import TextButton, { TextButtonProps } from 'scalexp/components/atoms/TextButton';
import Typography from 'scalexp/components/atoms/Typography';
import Column from 'scalexp/components/layout/Column';
import Row from 'scalexp/components/layout/Row';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.backgroundGrey};

  & .imgBox {
    position: relative;
    width: 460px;
    height: 296px;
  }
  & .imgBox > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StyledPanel = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.white};
    box-shadow: ${theme.shadow.card};
    border-radius: ${theme.sizing(4)};
    padding: ${theme.spacing(8)};
    display: flex;
    flex-direction: column;
    gap: 55px;
    position: relative;
  `}
`;

type PanelPageProps = {
  title: ReactNode;
  text: ReactNode;
  action: TextButtonProps;
  image: ImgHTMLAttributes<HTMLImageElement>;
};

const PanelPage: FC<PropsWithChildren<PanelPageProps>> = ({ title, text, action, image, children }) => {
  return (
    <StyledContainer>
      <StyledPanel>
        <Row vAlign="center" spacing="small">
          <img src="/images/logos/scalexp_black.svg" alt="scalexp logo" />
        </Row>
        <Row width="100%" vAlign="center">
          <Column width="436px" spacing="large">
            <Typography size="h4" weight="regular">
              {title}
            </Typography>
            <Typography color="secondary" weight="regular">
              {text}
            </Typography>
            <TextButton width="246px" size="xlarge" {...action}>
              {action.children}
            </TextButton>
            {children}
          </Column>
          <div className="imgBox">
            <img alt={image.alt} {...image} />
          </div>
        </Row>
        <Row />
        <Row />
      </StyledPanel>
    </StyledContainer>
  );
};

export default PanelPage;
