import React, { HTMLAttributes, useMemo } from 'react';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import { useDeferredRevenueConfigs } from 'scalexp/features/deferred-revenue/deferredRevenueConfigHooks';
import { DeferredRevenueConfig } from 'scalexp/features/deferred-revenue/types';
import {
  MetricSchema2,
  MetricSchema2Complex,
  MetricSchema2Data,
  MetricSchema2DataSource,
  MetricSchema2DataSourceDerivedMetric,
  MetricSchema2DataSourceEntityAccount,
  MetricSchema2DataSourceNativeMetric,
  MetricSchema2DataSourceSalesMetric,
  MetricSchema2Simple,
} from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import Divider from '../../../components/atoms/Divider';
import Icon from '../../../components/atoms/Icon';
import Typography from '../../../components/atoms/Typography';
import { useEditChartContext } from '../../../components/contexts/NewEditChartContext';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import ColourPicker from '../../../components/molecules/ColourPicker';
import Select from '../../../components/molecules/Select';
import FormulaEditorModal from '../../../components/organisms/FormulaEditorModal';
import { SalesMetricIds } from '../../../service/types/metricSchema';
import { theme } from '../../../theme';
import { Period } from '../../chart/ChartCard/types';
import { PieChart } from '../../chart/PieChart/types';
import usePickerOptions from '../../report-editor/ReportEditor/usePickerOptions';
import ChartEditorAddRowSelect from '../ChartEditorAddRowSelect';
import { PERIODS_OPTIONS, SeriesOptionContainer, StyledSidebarColorPicker } from '../NewChartsSidebar';
import { pipelineMetricOptions, pipelineTypeOptions, stageMetricOptions } from '../TimeChartSeries/options';

export interface PieChartOptionsProps {
  chart: PieChart;
  budgetOptions: ({ label: string; value: number } | { label: string; value: null })[];
  consolidatedBudgetOptions: ({ label: string; value: number } | { label: string; value: null })[];
}
export interface StyledPieChartOptionsProps extends HTMLAttributes<HTMLDivElement> {}
const StyledPieOptionWrapper = styled(Column)`
  padding: 19px 16px;
`;
export const StyledPieChartOptions = styled.div<StyledPieChartOptionsProps>`
  ${({ theme }) =>
    `
  `}
`;
const PieChartOptions: React.FC<PieChartOptionsProps> = ({ chart }) => {
  const { setConfig } = useEditChartContext();
  const period = chart.chart_data.period;
  const color = chart.chart_data.color;
  const metricSchema: MetricSchema2 = chart.chart_data.metric_schema;
  const organisation = useActiveOrganisation();
  const [showModal, setShowModal] = React.useState(false);
  const { data: deferredRevenueConfigs } = useDeferredRevenueConfigs();
  const [configureDetails, setConfigureDetails] = React.useState<null | {
    metricSchema: MetricSchema2Complex;
    updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => void;
  }>(null);
  const pickerOptions = usePickerOptions(undefined, true);
  const firstNode: MetricSchema2Data | null = metricSchema.schemaType === 'simple' ? metricSchema.nodes[0] : null;

  const isComplexCase: boolean = metricSchema.schemaType === 'complex';
  const isPipeline = firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline';
  const isStage = firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage';
  const isSales = firstNode?.data?.operator === 'sales';
  const isInvoicedRevenue = firstNode?.data?.operator === 'invoiced-revenue';
  const configOptions: {
    value: DeferredRevenueConfig['id'] | null;
    label: string;
  }[] = [
    ...Object.values(deferredRevenueConfigs || {}).map(config => ({
      value: config.id,
      label: config.name,
    })),
    {
      value: null,
      label: organisation.default_deferred_revenue_config_id
        ? `Default (${deferredRevenueConfigs?.[organisation.default_deferred_revenue_config_id].name})`
        : 'Default',
    },
  ].sort((a, b) => a.label.localeCompare(b.label));
  const normalizedPickerOptions = useMemo(() => {
    const options = [];
    pickerOptions.forEach(option => {
      if (['dm', 'nm', 'account', 'sm', 'pm', 'ps', 'pt', 'ir'].includes(option.id.split(':')[0])) {
        options.push({
          ...option,
          label: option.name,
          value: option.id,
          group: option.role,
        });
      }
    });
    if (isComplexCase) {
      options.push({
        id: '',
        group: '',
        source_id: '',
        role: '',
        name: 'Custom Formula',
        label: 'Custom Formula',
        value: '',
      });
    }
    return options;
  }, [pickerOptions, metricSchema]);

  const getSelectedValue = () => {
    // complex formula
    if (metricSchema.schemaType === 'complex') {
      return '';
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline') {
      return `pm:${firstNode.data.pipelineId}`;
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage') {
      return `ps:${firstNode.data.stageId}:${firstNode.data.pipelineId}`;
    } else if (firstNode?.data?.operator === 'sales' && firstNode?.data?.metricId) {
      const isRenewal = firstNode.data.metricId.startsWith('RENEWALS');
      return `pt:${isRenewal ? 'RENEWALS' : 'NEW_SALES'}`;
    }
    // get value from the choices
    else {
      return normalizedPickerOptions.find(option =>
        [
          (firstNode!.data as MetricSchema2DataSourceNativeMetric | MetricSchema2DataSourceDerivedMetric).metricId,
          (firstNode!.data as MetricSchema2DataSourceEntityAccount).accountId?.toString(),
        ].includes(option.id.split(':')[1]),
      )?.id;
    }
  };
  const getSelectedPipelineMetricValue = () => {
    if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline') {
      return firstNode.data.pipelineMetricId;
    }
  };

  const getSelectedPipelineStageMetricValue = () => {
    if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage') {
      return firstNode.data.stageMetricId;
    }
  };

  const getSelectedSalesMetricValue = () => {
    if (firstNode?.data?.operator === 'sales' && firstNode?.data?.metricId) {
      return firstNode.data.metricId;
    }
  };
  const getInvoicedRevenueValue = () => {
    if (firstNode?.data?.operator === 'invoiced-revenue' && firstNode?.data.configId) {
      return firstNode.data.configId;
    }
  };

  const handleAccountSelection = (newValue: string | null) => {
    if (!newValue) {
      return;
    }
    const [type, newId, secondId] = newValue.split(':');
    let newSimpleMetricSchemaDataSource: MetricSchema2DataSource;
    if (type === 'pt') {
      newSimpleMetricSchemaDataSource = {
        operator: 'sales',
        metricId: `${newId}_IN_PROGRESS_ACV` as SalesMetricIds,
      };
    } else if (type === 'nm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'native',
        metricId: newId,
        isCashflow: false,
      };
    } else if (type === 'dm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'derived',
        metricId: newId,
      };
    } else if (type === 'ir') {
      newSimpleMetricSchemaDataSource = {
        operator: 'invoiced-revenue',
        metricId: newId,
      };
    } else if (type === 'sm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'sales',
        metricId: newId as SalesMetricIds,
      };
    } else if (type === 'pm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'pipeline',
        pipelineId: newId,
        pipelineMetricId: 'metric_deals_won',
      };
    } else if (type === 'ps' && secondId) {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'stage',
        pipelineId: secondId,
        stageId: newId,
        stageMetricId: 'metric_deals',
      };
    } else if (type === 'account') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'account',
        accountId: parseInt(newId),
        isCashflow: false,
      };
    } else if (type === 'group') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'group',
        groupId: parseInt(newId),
        isCashflow: false,
      };
    } else {
      throw new Error('Unknown type');
    }

    const newSimpleMetricSchema: MetricSchema2Simple = {
      schemaType: 'simple',
      nodes: [
        {
          operator: 'add',
          data: newSimpleMetricSchemaDataSource,
        },
      ],
      decimals: 0,
    };

    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        metric_schema: newSimpleMetricSchema,
      },
    });
  };

  const handleColorChange = (color: string) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        color,
      },
    });
  };
  const handleSeriesAggregationPeriodChange = (period: Period) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        period,
      },
    });
  };
  const handlePipelineMetricSelection = (newValue: string | null) => {
    if (!newValue || !firstNode) {
      return;
    }
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        metric_schema: {
          ...chart.chart_data.metric_schema,
          nodes: [
            {
              ...chart.chart_data.metric_schema.nodes[0],
              data: {
                // @ts-ignore
                ...chart.chart_data.metric_schema.nodes[0].data,
                pipelineMetricId: newValue,
              },
            },
          ],
        },
      },
    });
  };
  const handleStageMetricSelection = (newValue: string | null) => {
    if (!newValue || !firstNode) {
      return;
    }
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        metric_schema: {
          ...chart.chart_data.metric_schema,
          nodes: [
            {
              ...chart.chart_data.metric_schema.nodes[0],
              data: {
                // @ts-ignore
                ...chart.chart_data.metric_schema.nodes[0].data,
                stageMetricId: newValue,
              },
            },
          ],
        },
      },
    });
  };
  const handleUpdateInvoicedRevenueValue = (newValue: string | null) => {
    if (!newValue || !firstNode) {
      return;
    }
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        metric_schema: {
          ...chart.chart_data.metric_schema,
          nodes: [
            {
              ...chart.chart_data.metric_schema.nodes[0],
              data: {
                // @ts-ignore
                ...chart.chart_data.metric_schema.nodes[0].data,
                configId: newValue,
              },
            },
          ],
        },
      },
    });
  };

  const handleSalesMetricSelection = (salesMetricId: string | null, isRenewal: boolean) => {
    if (!salesMetricId || !firstNode) {
      return;
    }
    const prefix = isRenewal ? 'RENEWALS' : 'NEW_SALES';
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        metric_schema: {
          ...chart.chart_data.metric_schema,
          nodes: [
            {
              operator: chart.chart_data.metric_schema.nodes[0].operator,
              data: {
                ...firstNode.data,
                metricId: `${prefix}_${salesMetricId}` as SalesMetricIds,
              } as MetricSchema2DataSourceSalesMetric,
            },
          ],
        },
      },
    });
  };

  const pickerOptionValue = getSelectedValue();
  const salesMetricValue = getSelectedSalesMetricValue()?.replace('RENEWALS_', '').replace('NEW_SALES_', '');

  return (
    <StyledPieOptionWrapper width="100%">
      <SeriesOptionContainer role="section">
        <Row hAlign="space-between" vAlign="start">
          <Column width="100%">
            <Typography display="block">Select period:</Typography>
            <Select
              size="medium"
              value={period}
              width="100%"
              onChange={value => handleSeriesAggregationPeriodChange(value as Period)}
              data={PERIODS_OPTIONS}
            />
          </Column>
          <StyledSidebarColorPicker>
            <ColourPicker handleColor={color => handleColorChange(color)} color={color} />
          </StyledSidebarColorPicker>
        </Row>
        <Divider />
        <Row hAlign="space-between" vAlign="center">
          <ChartEditorAddRowSelect
            includeFixedNumber={false}
            includeBrackets={false}
            selectWidth={360}
            width={metricSchema.schemaType === 'complex' ? '247px' : '300px'}
            size="medium"
            onChange={handleAccountSelection}
            data={normalizedPickerOptions}
            value={pickerOptionValue!}
            handleFormulaCategorySelected={() => {
              setConfigureDetails({
                metricSchema: {
                  schemaType: 'complex',
                  nodes: [],
                  decimals: 0,
                  dataType: 'monetary',
                  impact: 'neutral',
                  aggregation: 'sum',
                },
                updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => {
                  setConfig({
                    ...chart,
                    chart_data: {
                      ...chart.chart_data,
                      metric_schema: updatedMetricSchema,
                    },
                  });
                },
              });
              setShowModal(true);
            }}
          />
          {metricSchema.schemaType === 'complex' && (
            <Icon
              clickable
              name="settings"
              size={6}
              color={theme.palette.granite}
              onClick={() => {
                setConfigureDetails({
                  metricSchema,
                  updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => {
                    setConfig({
                      ...chart,
                      chart_data: {
                        ...chart.chart_data,
                        metric_schema: updatedMetricSchema,
                      },
                    });
                  },
                });
                setShowModal(true);
              }}
            />
          )}
        </Row>

        {isSales && !isComplexCase && (
          <Row hAlign="space-between" vAlign="center">
            <Select
              size="medium"
              width="100%"
              placeholder={'Select a sales metric'}
              selectHeight={300}
              selectWidth={338}
              data={pipelineTypeOptions}
              onChange={value =>
                handleSalesMetricSelection(value, !!getSelectedSalesMetricValue()?.startsWith('RENEWALS_'))
              }
              value={salesMetricValue}
            />
          </Row>
        )}

        {isPipeline && !isComplexCase && (
          <Row width="100%" hAlign="space-between" vAlign="center">
            <Select
              size="medium"
              width="100%"
              placeholder={'Select a pipeline metric'}
              selectHeight={300}
              selectWidth={338}
              data={pipelineMetricOptions}
              onChange={handlePipelineMetricSelection}
              value={getSelectedPipelineMetricValue()}
            />
          </Row>
        )}

        {isStage && !isComplexCase && (
          <Row width="100%" hAlign="space-between" vAlign="center">
            <Select
              size="medium"
              width="100%"
              placeholder={'Select a stage metric'}
              selectHeight={300}
              selectWidth={338}
              data={stageMetricOptions}
              onChange={handleStageMetricSelection}
              value={getSelectedPipelineStageMetricValue()}
            />
          </Row>
        )}
        {isInvoicedRevenue && !isComplexCase && (
          <Row hAlign="space-between" vAlign="center" width="100%">
            <Select<DeferredRevenueConfig['id'] | null>
              width="100%"
              size="medium"
              selectWidth={300}
              data={configOptions}
              value={getInvoicedRevenueValue()}
              onChange={value => {
                handleUpdateInvoicedRevenueValue?.(value);
              }}
              placeholder={'Config'}
              onClick={e => e.stopPropagation()}
            />
          </Row>
        )}
      </SeriesOptionContainer>
      {showModal && configureDetails != null && (
        <FormulaEditorModal configureDetails={configureDetails} onHide={() => setShowModal(false)} />
      )}
    </StyledPieOptionWrapper>
  );
};
export default PieChartOptions;
