import dayjs from 'dayjs';
import React from 'react';
import { MetricSchema2, MetricSchema2DataType } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import { getDateKey } from '../../../features/chart/ChartCard/helpers';
import { Period } from '../../../features/chart/ChartCard/types';
import { THRESHOLD_FOR_SMALL_NUMBERS } from '../../../features/chart/TimeChart/useTimeChartConfig';
import { formatValue } from '../../../features/data-provenance/metric-Schema-viewer/utils';
import { getFiscalQuarter } from '../../../utils/dates';
import { DateKeys, dateToRequest } from '../../../utils/metrics/calculations';
import useMetricSchemaSeries from '../../../utils/metrics/useMetricSchemaSeries';
import ChangeText from '../../atoms/ChangeText';
import Typography from '../../atoms/Typography';
import { useOrganisationContext } from '../../contexts/OrganisationContext';
import Row from '../../layout/Row';
import useCurrencySelection from '../CurrencySelect/useCurrencySelection';
import useDateSelection from '../DateSelect/useDateSelection';

const YEAR_COUNT = 12;
const QUARTER_COUNT = 3;

export interface HeaderConfigProps {
  metricSchema: MetricSchema2;
  period: Period;
}

const StyledValue = styled(Typography)`
  font-size: 24px;
  white-space: nowrap;
`;

const HeaderConfig: React.FC<HeaderConfigProps> = ({ metricSchema, period }) => {
  const [currencyCode] = useCurrencySelection();
  const [date] = useDateSelection('date');
  const { financial_year_start: financialYearStart } = useOrganisationContext();

  const { default_currency_code } = useOrganisationContext();
  const currency = currencyCode || default_currency_code;

  const yearsToRequest = [
    dayjs(date as string).format('YYYY'),
    dayjs(date as string)
      .subtract(1, 'year')
      .format('YYYY'),
  ];
  const previousQuarter = dayjs(date as string).subtract(1, 'quarter');
  const quartersToRequest = [
    `${dayjs(date as string).format('YYYY')}-Q${dayjs(date as string).quarter()}`,
    `${previousQuarter.format('YYYY')}-Q${previousQuarter.quarter()}`,
  ];
  const yearToDateRequest = [
    `${dayjs(date as string).format('YYYY')}-YTD`,
    `${dayjs(date as string)
      .subtract(1, 'year')
      .format('YYYY')}-YTD`,
  ];
  const qtdToRequest = [
    `${dayjs(date as string).format('YYYY')}-QTD${dayjs(date as string).quarter()}`,
    `${previousQuarter.format('YYYY')}-QTD${previousQuarter.quarter()}`,
  ];
  const dateInstance = date ? dayjs(date) : dayjs().subtract(1, 'month');
  const previousPeriodCount =
    period === 'YEAR' ? YEAR_COUNT : period === 'QUARTER' || period === 'QUARTER_TO_DATE' ? QUARTER_COUNT : 1;
  const previousPeriodDateInstance = dateInstance.subtract(previousPeriodCount, 'month');
  const { fiscalYear, fiscalQuarter } = getFiscalQuarter(dateInstance, 0, financialYearStart);
  const { fiscalYear: previousPeriodFiscalYear, fiscalQuarter: previousPeriodFiscalQuarter } = getFiscalQuarter(
    previousPeriodDateInstance,
    0,
    financialYearStart,
  );
  const currentPeriodDateKey = getDateKey(period, fiscalYear, fiscalQuarter, dateInstance);
  const previousPeriodDateKey = getDateKey(
    period,
    previousPeriodFiscalYear,
    previousPeriodFiscalQuarter,
    previousPeriodDateInstance,
  );

  const metricSchemaSeriesVS = useMetricSchemaSeries(
    metricSchema,
    dateToRequest(
      (period === 'MONTH'
        ? [currentPeriodDateKey, previousPeriodDateKey]
        : period === 'YEAR'
        ? yearsToRequest
        : period === 'YEAR_TO_DATE'
        ? yearToDateRequest
        : period === 'QUARTER_TO_DATE'
        ? qtdToRequest
        : quartersToRequest) as DateKeys,
      undefined,
      currency,
    ),
  );

  if (!metricSchemaSeriesVS.value) {
    return null;
  }
  const metricSchemaSeries = metricSchemaSeriesVS.value;

  let currentValue = 0;
  let previousValue = 0;
  let dataType: MetricSchema2DataType = metricSchemaSeries[0]?.data_type || 'monetary';
  let impact = metricSchemaSeries[0]?.impact || 'neutral';

  if (metricSchemaSeries[0]) {
    currentValue = Number(metricSchemaSeries[0].value);
  }
  if (metricSchemaSeries[1]) {
    previousValue = Number(metricSchemaSeries[1].value);
  }

  const denominatorValue = previousValue && currentValue ? currentValue - previousValue : 0;
  const changePercentage = (currentValue ? denominatorValue / Math.abs(previousValue) : 0) * 100 || 0;

  const formattedValue = formatValue(
    currentValue,
    dataType,
    currency,
    THRESHOLD_FOR_SMALL_NUMBERS > Math.abs(currentValue) && currentValue !== 0 ? 2 : 0,
  );

  return (
    <Row vAlign="center" spacing="large">
      <StyledValue size="h5"> {formattedValue}</StyledValue>
      <ChangeText
        period={period === 'QUARTER' ? 'previous quarter' : period === 'YEAR' ? 'previous year' : 'previous month'}
        formattedValue={`${changePercentage.toFixed(0)}%`}
        value={changePercentage}
        impact={impact}
      />
    </Row>
  );
};

export default HeaderConfig;
