import React from 'react';
import styled from 'styled-components';

import Icon from '../../../components/atoms/Icon';
import Tooltip from '../../../components/molecules/Tooltip';
import { theme } from '../../../theme';

export interface ReportEditorDeleteProps {
  handleDelete: () => void;
}

const DeleteIcon = styled(Icon)`
  transition: 0.3s;
  padding: 5px;
  max-width: 30px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: 8px;
  }
`;

const ReportEditorDelete: React.FC<React.PropsWithChildren<ReportEditorDeleteProps>> = ({ handleDelete }) => {
  const handleDeleteClick: React.MouseEventHandler<HTMLSpanElement> = e => {
    e.stopPropagation();
    handleDelete();
  };

  return (
    <Tooltip content="Delete this row">
      <DeleteIcon name="delete" color={theme.palette.granite} size={6} onClick={handleDeleteClick} clickable />
    </Tooltip>
  );
};

export default ReportEditorDelete;
