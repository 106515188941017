import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Dayjs } from 'dayjs';
import React, { useState, useEffect, useMemo } from 'react';
import Divider from 'scalexp/components/atoms/Divider';
import { theme } from 'scalexp/theme';
import { MetricSchema2Complex, MetricSchema2DataType } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import Typography from '../../../../components/atoms/Typography';
import useActiveOrganisation from '../../../../components/contexts/OrganisationContext/useActiveOrganisation';
import Row from '../../../../components/layout/Row';
import useCurrencySelection from '../../../../components/molecules/CurrencySelect/useCurrencySelection';
import { useDateSelectionDate } from '../../../../components/molecules/DateSelect/useDateSelection';
import { Organisation } from '../../../../service';
import {
  useEditReportAddedComputationRowId,
  useSetEditedReportAddedComputationRowId,
} from '../../../../store/state/editedReport/hooks';
import { MetricRow, Report, Row as RowType, StyleRowProperties } from '../../../../store/state/reports/types';
import { DateKeys, dateToRequest } from '../../../../utils/metrics/calculations';
import { CalculationRequestEntry } from '../../../../utils/metrics/newCalculations';
import useMetricSchemaSeries from '../../../../utils/metrics/useMetricSchemaSeries';
import { replaceReferences } from '../../../report/report-wrapper/helpers';
import { DISPLAY_COLUMNS, formatValue } from '../../ReportEditor/helpers';
import { FormulaPickerOption } from '../../ReportEditorAddRowSelect';
import { DragHandle } from '../../ReportEditorContent';
import ReportEditorCustomName from '../../ReportEditorCustomName';
import ReportEditorDelete from '../../ReportEditorDelete';
import ReportEditorSimpleSelect from '../../ReportEditorSimpleSelect';
import ReportEditorCustomRowComputation from '../ReportEditorCustomRowComputation';
import ReportEditorCustomRowStyles from '../ReportEditorCustomRowStyles';
import { rowBorderStyles } from '../ReportEditorRow/helpers';

interface ReportEditorCustomRowProps {
  row: MetricRow;
  report: Report | null;
  isSelected: boolean;
  pickerOptions: FormulaPickerOption[];
  handleIsSelectedChange: (isSelected: boolean) => void;
  handleChange: (value: string) => void;
  handleDelete: () => void;
  styles?: StyleRowProperties;
  handleUpdateRowStyles?: (styles: Partial<StyleRowProperties>) => void;
  handleUpdateRow: (row: RowType) => void;
  customName?: string;
  handleRename: (newName?: string) => void;
}

const StyledContainer = styled.div<{ isSelected: boolean; styles: Omit<StyleRowProperties, 'bold'> }>`
  width: 100%;
  min-height: 40px;
  display: grid;
  grid-template-columns: 20px 1fr 160px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};
  background-color: ${({ theme, isSelected, styles: { color } }) =>
    color ? `hsl(${color.h}, ${color.s}%, ${color.l}%)` : isSelected ? theme.palette.silver : theme.palette.white};
  border-top: ${({ styles: { border_top } }) => rowBorderStyles[border_top || 'NONE']};
  border-bottom: ${({ styles: { border_bottom } }) => rowBorderStyles[border_bottom || 'NONE']};
  border-radius: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;

  span:nth-last-child(-n + 2) {
    text-align: end;
  }
`;

const StyledActions = styled.div`
  overflow-x: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 500px 1fr;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
  margin-left: -9px;

  span:first-child {
    max-width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
  }
`;

export const reportEditorcalculationRequest = (date: Dayjs, currency: Organisation['default_currency_code']) =>
  (dateToRequest([date.format('YYYY-MM')] as DateKeys, undefined, currency) as unknown) as CalculationRequestEntry[];

const ReportEditorCustomRow: React.FC<React.PropsWithChildren<ReportEditorCustomRowProps>> = ({
  row,
  report,
  isSelected,
  pickerOptions,
  handleChange,
  handleIsSelectedChange,
  handleDelete,
  styles,
  handleUpdateRowStyles,
  handleUpdateRow,
  customName,
  handleRename,
}) => {
  const date = useDateSelectionDate();
  const [currencyCode] = useCurrencySelection();

  const organisation = useActiveOrganisation();
  const { financial_year_start, default_currency_code } = organisation;
  const rowWithoutReferences = replaceReferences(row, report?.rows || []) as MetricRow;
  const seriesVS = useMetricSchemaSeries(
    rowWithoutReferences.metric,
    reportEditorcalculationRequest(date, currencyCode || default_currency_code),
  );

  const addedComputationRowId = useEditReportAddedComputationRowId();
  const SetAddedComputationRowId = useSetEditedReportAddedComputationRowId();

  const [showComputationModal, setShowComputationModal] = useState(false);

  const values: (number | null)[] = useMemo(() => {
    const series = seriesVS.value;
    if (!series) {
      return [];
    }

    return series.map(serie => (serie ? Number(serie.value) : null));
  }, [seriesVS.value, DISPLAY_COLUMNS, financial_year_start]);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: row.id });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const handleSelectedRowIdChange = () => {
    handleIsSelectedChange(!isSelected);
  };

  const handleChangeMetricSchema = (metricSchema: MetricSchema2Complex) => {
    handleUpdateRow({ ...row, metric: metricSchema });
  };

  const handleNameChange = (name: string) => {
    handleUpdateRow({ ...row, name });
  };

  let rowColor: StyleRowProperties['color'];
  if (styles?.color) {
    rowColor = { ...styles.color };
    rowColor.l += Math.floor((100 - rowColor.l) / 1.5);
    rowColor.l += Math.floor((100 - rowColor.l) / 1.5);

    // ignoring white color
    if (rowColor?.h === 0 && rowColor?.s === 0) {
      rowColor = undefined;
    }
  }

  const dataType: MetricSchema2DataType =
    (seriesVS.value?.[0]?.data_type.toLowerCase() as MetricSchema2DataType) || 'monetary';

  // open computation modal when the added row is this custom row
  useEffect(() => {
    if (addedComputationRowId === row.id) {
      setShowComputationModal(true);
      SetAddedComputationRowId(null);
    }
  }, [addedComputationRowId]);

  const hasStyles = styles && handleUpdateRowStyles;

  return (
    <StyledContainer
      ref={setNodeRef}
      style={style}
      isSelected={isSelected}
      onClick={handleSelectedRowIdChange}
      styles={{ ...styles, color: rowColor }}
    >
      <DragHandle {...attributes} {...listeners} />
      <StyledActions>
        {!isSelected && (
          <Typography color="secondary" weight={row.bold ? 'semibold' : 'regular'}>
            {row.name}
          </Typography>
        )}
        {isSelected && (
          <Row flex={1} vAlign="center">
            <ReportEditorSimpleSelect
              pickerOptions={[
                ...pickerOptions,
                { value: `custom:${row.id}`, label: `${row.name} (Custom)`, group: 'Custom' },
              ]}
              value={`custom:${row.id}`}
              handleChange={handleChange}
              bold={styles?.bold}
            />
            <ReportEditorCustomRowComputation
              rowId={row.id}
              showComputationModal={showComputationModal}
              setShowComputationModal={setShowComputationModal}
              metricSchema={row.metric as MetricSchema2Complex}
              handleChangeMetricSchema={handleChangeMetricSchema}
              name={row.name}
              handleNameChange={handleNameChange}
              report={report}
            />

            <Divider direction="vertical" height="20px" noMargin color={theme.palette.fieldGrey} />
            <ReportEditorCustomName handleRename={handleRename} customName={customName} />
            {hasStyles && <ReportEditorCustomRowStyles styles={styles} handleUpdateRowStyles={handleUpdateRowStyles} />}

            <Divider direction="vertical" height="20px" noMargin color={theme.palette.fieldGrey} />
            <ReportEditorDelete handleDelete={handleDelete} />
          </Row>
        )}
      </StyledActions>
      <Typography color="secondary" weight={row.bold ? 'semibold' : 'regular'}>
        {formatValue(values[0], currencyCode || default_currency_code, dataType, row.metric.decimals)}
      </Typography>
    </StyledContainer>
  );
};

export default ReportEditorCustomRow;
