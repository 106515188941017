import React, { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import Typography from 'scalexp/components/atoms/Typography';
import styled, { css } from 'styled-components';

interface FormFieldPhoneInputPropsType extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  customSize?: 'small' | 'medium' | 'large';
  label?: string;
  noBorder?: boolean;
  defaultValue?: string;
}

type StyledPhoneInputWithCountryPropsType = FormFieldPhoneInputPropsType & { hasError: boolean | undefined };

export const StyledInputContainer = styled.div<{ disabled: boolean | undefined; width: string | number | undefined }>`
  ${({ width }) => (width ? `width: ${width};` : '')};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2.5)};

  ${({ disabled, theme }) => {
    if (disabled) {
      return css`
        & input {
          opacity: 0.4;
          background-color: #f8f9fc;
        }
      `;
    }
  }};
`;

export const StyledPhoneInputWithCountry = styled(PhoneInputWithCountry)<StyledPhoneInputWithCountryPropsType>`
  & input {
    border: none;
    outline: none;
  }
  ${({ theme, width = '100%' }) => css`
    width: ${width};
    padding: ${theme.spacing(1.7)} ${theme.spacing(3)}};
    border-width: 1px;
    border-style: solid;
    border-color: ${theme.palette.fieldGrey};
    background-color: ${theme.palette.white};
    color: ${theme.palette.midnight};
    &:hover {
      border-color: ${theme.palette.midnight} ;
      outline: 0;
    }
    &:active,
    &:focus {
      border-color: ${theme.palette.primary.main} ;
      outline: 0;
    }

    &:invalid {
      border-color: ${theme.palette.danger.main};
      border-width: ${theme.sizing(0.5)};
    }

  `}
  ${({ noBorder }) => {
    if (noBorder) {
      return css`
        border: none;
        outline: none;
      `;
    }
  }}
  ${({ customSize, theme }) => {
    switch (customSize) {
      case 'small': {
        return css`
          height: ${theme.sizing(7)};
          font-size: ${theme.font.size.small};
          line-height: ${theme.font.lineheight.small};
          border-radius: ${theme.sizing(1)};
        `;
      }
      case 'medium': {
        return css`
          height: ${theme.sizing(9)};
          font-size: ${theme.font.size.medium};
          line-height: ${theme.font.lineheight.medium};
          border-radius: ${theme.sizing(2)};
        `;
      }
      case 'large': {
        return css`
          height: ${theme.sizing(11)};
          font-size: ${theme.font.size.medium};
          line-height: ${theme.font.lineheight.medium};
          border-radius: ${theme.sizing(2)};
        `;
      }
      default:
        return '';
    }
  }};
  ${({ theme, hasError }) => {
    if (hasError) {
      return css`
        border-color: ${theme.palette.danger.main};
        border-width: 2px;
      `;
    }
    return '';
  }}
`;

const FormFieldPhoneInput: React.FC<FormFieldPhoneInputPropsType> = ({
  name,
  width,
  customSize = 'small',
  label,
  disabled,
  ...other
}) => {
  const { control, formState } = useFormContext();
  const error = formState.errors[name];
  const hasError = !!formState.errors[name];

  return (
    <StyledInputContainer width={width} disabled={disabled}>
      {label && <Typography weight="regular">{label}</Typography>}
      <StyledPhoneInputWithCountry
        name={name}
        control={control}
        countrySelectProps={{}}
        hasError={hasError}
        width={width}
        customSize={customSize}
        rules={{ required: true, validate: isPossiblePhoneNumber }}
        {...other}
      />
      {error && (
        <Typography size="small" color="danger">
          {error?.message as string}
        </Typography>
      )}
    </StyledInputContainer>
  );
};

export default FormFieldPhoneInput;
