import produce from 'immer';

import {
  consolidatedBudgetSetsActions,
  OrganisationConsolidatedBudgetSets,
  ORGANISATIONS_CONSOLIDATED_BUDGETSETS_LOAD,
  ORGANISATIONS_CONSOLIDATED_BUDGETSETS_UPDATE,
  ORGANISATIONS_CONSOLIDATED_BUDGETSETS_DELETE,
  ORGANISATIONS_CONSOLIDATED_BUDGETSETS_CREATE,
} from './types';

const budgetsets = (
  prevState: OrganisationConsolidatedBudgetSets = {},
  action: consolidatedBudgetSetsActions,
): OrganisationConsolidatedBudgetSets => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_CONSOLIDATED_BUDGETSETS_LOAD:
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload,
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }

      case ORGANISATIONS_CONSOLIDATED_BUDGETSETS_CREATE: {
        const { organisationId } = action.params;
        const consolidatedBudgetset = action.payload!;
        if (!state[organisationId].value) {
          return;
        }
        state[organisationId].value?.push(consolidatedBudgetset);

        return;
      }

      case ORGANISATIONS_CONSOLIDATED_BUDGETSETS_UPDATE: {
        const { organisationId, budgetSetId } = action.params;
        const consolidatedBudgetset = action.payload;
        state[organisationId].value = state[organisationId]?.value?.map(budget => {
          if (budget.id === budgetSetId) {
            return {
              ...budget,
              ...consolidatedBudgetset,
            };
          }
          return budget;
        });
        return;
      }

      case ORGANISATIONS_CONSOLIDATED_BUDGETSETS_DELETE: {
        const { organisationId, budgetsetId } = action.params;
        switch (action.status) {
          case 'pending':
            if (state[organisationId].value) {
              state[organisationId].value = state[organisationId]?.value?.filter(budget => budget.id !== budgetsetId);
            }
            return;
          default:
            return state;
        }
      }
      default:
        return state;
    }
  });
};

export default budgetsets;
