import dayjs from 'dayjs';

import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { InvoiceFilter } from '../deferredInvoices/types';
import { selectDeferredInvoicesSummation } from './selectors';
import { DeferredInvoicesSummation } from './types';

export const ORGANISATIONS_DEFERRED_INVOICES_SUMMATION_LOAD = '[deferredInvoicesSummation] LOAD';

export type DeferredInvoicesSummationActions = PromiseAction<
  typeof ORGANISATIONS_DEFERRED_INVOICES_SUMMATION_LOAD,
  DeferredInvoicesSummation,
  { organisationId: number }
>;

type DeferredDataResponse = DeferredInvoicesSummation;

export const loadDeferredInvoicesSummationAction = (
  organisationId: number,
  fromDate: Date,
  toDate: Date,
  lostAfter: number,
  newAfter: number,
  accountsFilter: string[],
  invoiceFilter?: InvoiceFilter | null,
  fullMonth?: boolean,
): ThunkAction<DeferredInvoicesSummationActions> => async (
  dispatch: ThunkDispatch<DeferredInvoicesSummationActions>,
  getState,
) => {
  const state = getState();
  const deferredInvoicesSummation = selectDeferredInvoicesSummation(state, organisationId);

  if (deferredInvoicesSummation?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_DEFERRED_INVOICES_SUMMATION_LOAD,
        () =>
          http<DeferredInvoicesSummation, DeferredDataResponse>(
            `/api/v1/organisations/${organisationId}/invoices/deferred/summation`,
            {
              body: {
                start: dayjs(fromDate).format('YYYY-MM'),
                end: dayjs(toDate).format('YYYY-MM'),
                lost_after: `${lostAfter}`,
                new_after: `${newAfter}`,
                account_ids: accountsFilter,
                invoice_filter: invoiceFilter,
                full_month: !!fullMonth,
                include_issued: true,
                include_pending: false,
                include_renewals: false,
              },
            },
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
