import { Store } from '../index';
import { Report } from '../reports/types';

export const selectEditedReport = (state: Store): Report | null => {
  return state.editedReport.report;
};

export const selectIsEditedReportDirty = (state: Store): boolean => {
  return state.editedReport.isDirty;
};

export const selectSelectedRowId = (state: Store): string | null => {
  return state.editedReport.selectedRowId;
};

export const selectAddedComputationRowId = (state: Store): string | null => {
  return state.editedReport.addedComputationRowId;
};
