import React from 'react';
import styled from 'styled-components';

import Icon from '../../../../components/atoms/Icon';
import Typography from '../../../../components/atoms/Typography';
import Column from '../../../../components/layout/Column';
import Row from '../../../../components/layout/Row';
import { COLOR_OPTIONS } from '../../../../components/molecules/ColorPicker';
import Tooltip from '../../../../components/molecules/Tooltip';
import { BorderStyle, StyleRowProperties } from '../../../../store/state/reports/types';
import { theme } from '../../../../theme';
import SelectBorder from '../../SelectBorder';
import SelectColor from '../../SelectColor';

interface ReportEditorCustomRowStylesProps {
  styles: StyleRowProperties;
  handleUpdateRowStyles: (styles: Partial<StyleRowProperties>) => void;
}

const StyledContainer = styled.div`
  width: 187px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr;
`;

const StyledToggleButton = styled.div<{ isSelected: boolean }>`
  width: 60px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(2)}`};
  border-radius: ${({ theme }) => theme.spacing(2.5)};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  font-weight: ${({ theme, isSelected }) => (isSelected ? theme.font.weight.bold : theme.font.weight.medium)};
  cursor: pointer;
  ${({ theme, isSelected }) => isSelected && `border: 1px solid ${theme.palette.granite}`};
`;

const ReportEditorCustomRowStyles: React.FC<React.PropsWithChildren<ReportEditorCustomRowStylesProps>> = ({
  styles,
  handleUpdateRowStyles,
}) => {
  const handleColorChange = (option: string) => {
    const color = COLOR_OPTIONS[option];

    handleUpdateRowStyles({
      color,
    });
  };

  const handleBoldToggle = () => {
    handleUpdateRowStyles({
      bold: !styles.bold,
    });
  };

  const handleBorderChange = (value: { borderTop?: BorderStyle; borderBottom?: BorderStyle }) => {
    handleUpdateRowStyles({
      border_top: value.borderTop,
      border_bottom: value.borderBottom,
    });
  };

  return (
    <Tooltip
      content={
        <StyledContainer>
          <Row>
            <Typography color="secondary">Style</Typography>
          </Row>
          <Column spacing="small">
            <StyledRow>
              <Typography color="secondary" weight="regular">
                Colour
              </Typography>
              <SelectColor value={styles.color} onSelect={handleColorChange} />
            </StyledRow>
            <StyledRow>
              <Typography color="secondary" weight="regular">
                Borders
              </Typography>
              <SelectBorder
                borders={{ borderTop: styles.border_top, borderBottom: styles.border_bottom }}
                onChange={handleBorderChange}
              />
            </StyledRow>
          </Column>
          <Row>
            <StyledToggleButton isSelected={styles.bold} onClick={handleBoldToggle}>
              Bold
            </StyledToggleButton>
          </Row>
        </StyledContainer>
      }
      placement="top"
      color="white"
      trigger="click"
    >
      <Tooltip content="Style row">
        <Icon name="brush" color={theme.palette.granite} size={6} clickable />
      </Tooltip>
    </Tooltip>
  );
};

export default ReportEditorCustomRowStyles;
