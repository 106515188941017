import React from 'react';

import { OrganisationContext } from '.';
import { Organisation } from '../../../store/state/organisations/types';

const useActiveOrganisation = (): Organisation => {
  const organisation = React.useContext(OrganisationContext);
  if (!organisation) {
    throw new Error('OrganisationContext not found');
  }
  return organisation;
};

export default useActiveOrganisation;
