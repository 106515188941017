import { API_URL } from '../config';
import { authHeader } from '../utils/auth';

interface RequestInitWithQuery extends Omit<RequestInit, 'body'> {
  query?: { [key: string]: string };
  body?: object;
}

const isRelativeUrl = (url: string) => !new RegExp('^(?:[a-z]+:)?//', 'i').test(url);

const buildUrl = (url: string, query?: RequestInitWithQuery['query']) => {
  if (query) {
    url += '?' + new URLSearchParams(query).toString();
  }
  if (isRelativeUrl(url)) {
    url = API_URL + url;
  }
  return url;
};

const http = async <ProcessedResponse = void, Response = void>(
  input: Request | string,
  init: RequestInitWithQuery = {},
  postProcess?: (response: Response) => ProcessedResponse,
): Promise<ProcessedResponse> => {
  const jsonType = 'application/json';
  const { query, headers = {}, body, method, ...requestInit } = init;

  let requestInput: Request | string = input;
  if (requestInput instanceof Request) {
    requestInput = {
      ...requestInput,
      url: buildUrl(requestInput.url, query),
    };
  } else {
    requestInput = buildUrl(requestInput, query);
  }

  const reqObj = {
    mode: 'cors',
    credentials: 'include',
    method: method || (body ? 'POST' : 'GET'),
    headers: {
      'Content-Type': jsonType,
      ...headers,
      ...authHeader(),
    },
    body: body ? JSON.stringify(body) : undefined,
    ...requestInit,
  };

  // @ts-ignore
  const res = await fetch(requestInput, reqObj);
  let resp;

  if (res.status === 400) {
    let json = await res.json();
    if (json) {
      return ({ status: 400, body: json } as unknown) as ProcessedResponse;
    }
  }
  if (!res.ok) {
    console.error(`${res.status} Error calling api '${res.url}': `, JSON.stringify(resp));
    resp = await res.json();
    throw resp?.error || resp;
  }
  if (reqObj.headers['Content-Type'] === jsonType) {
    if (res.status === 204) {
      // no content;
      return (void 0 as unknown) as ProcessedResponse;
    }
    const json = await res.json();
    if (postProcess) {
      return postProcess(json);
    }
    return json;
  }
  resp = await res.blob();
  return (resp as unknown) as ProcessedResponse;
};

export default http;
