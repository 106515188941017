import React from 'react';
import { MetricSchema2Decimals } from 'scalexp/utils/metrics/metricSchema2';
import { getSimpleMetricSchemaFromNode } from 'scalexp/utils/metrics/ms2utils';
import styled from 'styled-components';

import Typography from '../../../../components/atoms/Typography';
import { NativeMetricId } from '../../../../service/types/metricSchema';
import { useAccountGroupsInTag } from '../../../../store/state/accountGroups/hooks';
import { AccountGroup } from '../../../../store/state/accountGroups/types';
import { useAccountsInTag } from '../../../../store/state/accounts/hooks';
import { Account } from '../../../../store/state/accounts/types';
import { useNativeMetricName } from '../../../../store/state/nativeMetrics/hooks';
import ReportEditorDisplayAccountRow from '../ReportEditorDisplayAccountRow';
import ReportEditorDisplayGroupRow from '../ReportEditorDisplayGroupRow';

interface ReportEditorDisplayAllGroupsRowProps {
  tag: NativeMetricId;
  decimals: MetricSchema2Decimals;
}

const StyledGroupsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing(1)} 0`};
  border-radius: ${({ theme }) => `0 0 ${theme.spacing(2)} ${theme.spacing(2)}`};
`;

const StyledNoData = styled.div`
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: 20px 1fr 160px 160px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding-left: ${({ theme }) => theme.spacing(4)};
`;

const ReportEditorDisplayAllGroupsRow: React.FC<React.PropsWithChildren<ReportEditorDisplayAllGroupsRowProps>> = ({
  tag,
  decimals,
}) => {
  const nativeMetricNameVS = useNativeMetricName(tag);
  const accountsInTagVS = useAccountsInTag(tag);
  const groupsInTagVS = useAccountGroupsInTag(tag);

  if (
    nativeMetricNameVS.status !== 'success' ||
    accountsInTagVS.status !== 'success' ||
    groupsInTagVS.status !== 'success'
  ) {
    return null;
  }

  const nativeMetricName: string = nativeMetricNameVS.value;
  const accountsInTag: Account[] = accountsInTagVS.value;
  const groupsInTag: AccountGroup[] = groupsInTagVS.value;
  const hasNoData: boolean = accountsInTag.length === 0 && groupsInTag.length === 0;
  const ungroupedAccountsInTag: Account[] = accountsInTag.filter(account => !account.group_id);

  return (
    <StyledGroupsContainer>
      {hasNoData ? (
        <StyledNoData>
          <div />
          <Typography>There are no accounts configured for {nativeMetricName}</Typography>
        </StyledNoData>
      ) : groupsInTag.length > 0 ? (
        <>
          {groupsInTag
            ?.sort((a, b) => a.order - b.order)
            .map(group => {
              let groupName: string = group.name;
              const metricSchema = getSimpleMetricSchemaFromNode({
                operator: 'add',
                data: {
                  operator: 'entity',
                  entity: 'group',
                  groupId: group.id,
                  isCashflow: false,
                },
              });

              return <ReportEditorDisplayGroupRow name={groupName} metricSchema={metricSchema} decimals={decimals} />;
            })}
          {ungroupedAccountsInTag.length > 0 && (
            <ReportEditorDisplayGroupRow
              name={`${nativeMetricName}: All Other`}
              metricSchema={getSimpleMetricSchemaFromNode({
                operator: 'add',
                data: {
                  operator: 'entity',
                  entity: 'ungrouped-accounts-in-native',
                  metricId: tag,
                  isCashflow: false,
                },
              })}
              decimals={decimals}
            />
          )}
        </>
      ) : (
        accountsInTag.map(account => (
          <ReportEditorDisplayAccountRow accountId={account.account_id} decimals={decimals} />
        ))
      )}
    </StyledGroupsContainer>
  );
};

export default ReportEditorDisplayAllGroupsRow;
