import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Loader from '../../components/atoms/Loader';
import Message from '../../components/atoms/Message';
import { User } from '../../components/contexts/DeprecatedUserContext';
import WidePage from '../../components/templates/WidePage';
import { useSelf } from '../../service';
import { acceptInvitation, login, useInvitation } from '../../service/sharing';
import { signup } from '../../service/sharing';

type SignupData = {
  email: string;
  password: string;
};

export const AcceptInvitationPage: React.FC<React.PropsWithChildren<{}>> = () => {
  const { invitationId } = useParams<{ invitationId: string }>();

  const { data: user, isValidating } = useSelf();

  const { data: invitation, error: invitationError } = useInvitation(invitationId);

  if (invitationError) {
    return (
      <WidePage>
        <Message type="error" description="Failed to find invitation" />
      </WidePage>
    );
  }

  if (!invitation || isValidating) {
    return (
      <WidePage>
        <Loader backdrop vertical />
      </WidePage>
    );
  }

  return (
    <Container>
      <LeftHandSide>
        {user?.username === invitation.email ? (
          <AcceptInvitation invitationId={invitationId} />
        ) : invitation.registration_status === 'NOT_REGISTERED' ? (
          <SignupForm user={user || null} invitationId={invitationId} />
        ) : (
          <LoginForm user={user || null} invitationId={invitationId} />
        )}
      </LeftHandSide>
      <RightHandleSide>
        <BackgroundPattern />
      </RightHandleSide>
    </Container>
  );
};

const AcceptInvitation: React.FC<React.PropsWithChildren<{ invitationId: string }>> = ({ invitationId }) => {
  const [outcome, setOutcome] = React.useState<{
    error: boolean;
    reason: string;
  } | null>(null);

  const { data: invitation, error: invitationError } = useInvitation(invitationId);

  React.useEffect(() => {
    acceptInvitation(invitationId)
      .then(resp => {
        window.location.assign(invitation?.from_url || '/');
      })
      .catch(() => {
        setOutcome({ error: true, reason: 'Failed to accept invitation' });
      });
  }, [invitation?.from_url, invitationId]);

  if (invitationError) {
    return <Message type="error" description="Failed to find invitation" />;
  }

  if (!invitation) {
    return <Loader backdrop vertical />;
  }

  if (outcome?.error) {
    return (
      <FormArea>
        <Message type="error" title="Signup failure" description={outcome.reason} />
      </FormArea>
    );
  }

  return <Loader />;
};

const SignupForm: React.FC<React.PropsWithChildren<{ invitationId: string; user: User | null }>> = ({
  invitationId,
  user,
}) => {
  const { register, handleSubmit, reset, formState } = useForm<SignupData>();

  const { data: invitation, error: invitationError } = useInvitation(invitationId);

  const [signupOutcome, setSignupOutcome] = React.useState<{
    error: boolean;
    reason: string;
  } | null>(null);

  React.useEffect(() => {
    if (invitation) {
      reset({ email: invitation.email, password: '' });
    }
  }, [invitation, reset]);

  if (invitationError) {
    return <Message type="error" description="Failed to find invitation" />;
  }

  if (!invitation) {
    return <Loader backdrop vertical />;
  }

  const handleSignup = async (signupData: SignupData) => {
    try {
      await signup(invitationId, signupData.password);
      window.location.assign(invitation?.from_url || '/');
    } catch (error) {
      setSignupOutcome({ error: true, reason: 'Failed to sign up user' });
    }
  };

  return (
    <FormArea>
      <Logo />
      <h1>Welcome to ScaleXP</h1>
      <h2>Sign up to accept your invitation</h2>

      <form onSubmit={handleSubmit(handleSignup)}>
        <label>Email address</label>
        <input {...register('email', { required: true })} type="email" disabled autoComplete="email" />
        <label>Password</label>
        <input
          {...register('password', { required: true })}
          type="password"
          name="password"
          autoComplete="new-password"
        />
        <Footer>
          <SubmitButton type="submit">Create your account</SubmitButton>
        </Footer>
      </form>

      {signupOutcome?.error ? <Message type="error" title="Signup failure" description={signupOutcome.reason} /> : null}

      {user ? (
        <Message
          type="warning"
          description={`You are currently logged in as ${user.username} and will be logged out`}
        />
      ) : null}

      {formState.isSubmitting ? <Loader backdrop vertical /> : null}
    </FormArea>
  );
};

const LoginForm: React.FC<React.PropsWithChildren<{ invitationId: string; user: User | null }>> = ({
  invitationId,
  user,
}) => {
  const { register, handleSubmit, reset, formState } = useForm<SignupData>();

  const { data: invitation, error: invitationError } = useInvitation(invitationId);

  const [loginOutcome, setLoginOutcome] = React.useState<{
    error: boolean;
    reason: string;
  } | null>(null);

  React.useEffect(() => {
    if (invitation) {
      reset({ email: invitation.email, password: '' });
    }
  }, [invitation, reset]);

  if (invitationError) {
    return <Message type="error" description="Failed to find invitation" />;
  }

  if (!invitation) {
    return <Loader backdrop vertical />;
  }

  const handleLogin = async (signupData: SignupData) => {
    try {
      await login(invitationId, signupData.password);
      window.location.assign(invitation?.from_url || '/');
    } catch (error) {
      setLoginOutcome({ error: true, reason: 'Failed to log in as user' });
    }
  };

  return (
    <FormArea>
      <Logo />
      <h1>Welcome to ScaleXP</h1>
      <h2>Login in to accept your invitation</h2>

      <form onSubmit={handleSubmit(handleLogin)}>
        <label>Email address</label>
        <input {...register('email', { required: true })} type="email" disabled name="email" autoComplete="email" />
        <label>Password</label>
        <input
          {...register('password', { required: true })}
          type="password"
          name="password"
          autoComplete="new-password"
        />
        <Footer>
          <SubmitButton type="submit">Log in</SubmitButton>
        </Footer>
      </form>

      {loginOutcome?.error ? <Message type="error" title="Signup failure" description={loginOutcome.reason} /> : null}

      {user ? (
        <Message
          type="warning"
          description={`You are currently logged in as ${user.username} and will be logged out`}
        />
      ) : null}

      {formState.isSubmitting ? <Loader backdrop vertical /> : null}
    </FormArea>
  );
};

const Footer = styled.div`
  text-align: center;
`;

const Logo = styled.img.attrs({
  src: '/images/logos/scalexp-banner.svg',
})`
  position: absolute;
  left: 3em;
  top: 3em;
`;

const LeftHandSide = styled.div`
  width: 50%;
  background: #ebeced;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const RightHandleSide = styled.div`
  width: 50%;
  background-color: #173a58;
  position: relative;
`;

const BackgroundPattern = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-image: ${'/images/logos/scalexp-background.png'};
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
`;

const FormArea = styled.div`
  font-family: 'Inter', sans-serif;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  width: 600px;

  padding: 6em;
  position: relative;

  & h1 {
    margin-top: 2em;
    margin-bottom: 0.1em;
    padding: 0;

    color: #355570;
    font-size: 40px;
    letter-spacing: 0px;
    line-height: 48px;
    text-align: left;
  }

  & h2 {
    padding: 0;
    margin-top: 0.3em;
    margin-bottom: 3em;

    color: #6c6d6e;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 17px;
    text-align: left;
  }

  & form {
    margin-top: 2em;
  }

  & form label {
    font-weight: bold;
    display: block;

    color: #6c6d6e;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    text-transform: uppercase;
  }

  & form input {
    display: block;
    width: 80%;
    margin-bottom: 2em;
    padding: 0.8em;
    border-radius: 0.5em;
    border-width: 1px;
    outline: transparent dashed 1px;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));

    box-shadow: rgb(213 220 227) 0px 0px 0px 1px inset;

    background-color: #ffffff;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
  }
`;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  background: rgb(100, 0, 0);
`;

const SubmitButton = styled.button`
  margin: 0 0 2em 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  outline: 0 !important;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  transition: color 0.2s linear, background-color 0.3s linear;
  border: 1px solid #184269;
  cursor: pointer;
  height: 36px;
  text-decoration: none;
  border-radius: 6px;
  background: rgb(57, 73, 172);
  border-color: white;
  color: black;

  background-color: #a5cd1f;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: bold;
`;
