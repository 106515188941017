import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectReports } from './selectors';
import { Report } from './types';

export const ACTION_REPORTS_LOAD = '[reports] LOAD';
export const ACTION_REPORTS_UPDATE = '[reports] UPDATE';
export const ACTION_REPORTS_DELETE = '[reports] DELETE';
export const ACTION_REPORTS_CREATE = '[reports] CREATE';

type ReportsActionSet = PromiseAction<typeof ACTION_REPORTS_LOAD, Report[], { organisationId: number }>;
type ReportsActionDelete = PromiseAction<
  typeof ACTION_REPORTS_DELETE,
  DeleteReportActionResult,
  { organisationId: number; reportId: number }
>;
type ReportsActionUpdate = PromiseAction<
  typeof ACTION_REPORTS_UPDATE,
  Report,
  { organisationId: number; reportId: number; reportConfig: Report }
>;
type ReportsActionCreate = PromiseAction<
  typeof ACTION_REPORTS_CREATE,
  Report,
  { organisationId: number; reportConfig: Report }
>;
export type ReportsActions = ReportsActionSet | ReportsActionUpdate | ReportsActionDelete | ReportsActionCreate;

type ReportsResponse = {
  reports: Report[];
};

type DeleteReportResponse = unknown;
type UpdateReportResponse = Report;
type CreateReportResponse = Report;

export const loadReportsAction = (organisationId: number): ThunkAction<ReportsActionSet> => async (
  dispatch: ThunkDispatch<ReportsActions>,
  getState,
) => {
  const state = getState();
  const reports = selectReports(state, organisationId);

  if (reports?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ACTION_REPORTS_LOAD,
        () =>
          http<Report[], ReportsResponse>(
            `/api/v1/organisations/${organisationId}/reports`,
            {},
            response => response.reports,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export type DeleteReportActionResult =
  | {
      success: false;
    }
  | {
      success: true;
      report: Report;
    };

export type UpdateReportActionResult =
  | {
      success: false;
    }
  | {
      success: true;
      report: Report;
    };

export type CreateReportActionResult =
  | {
      success: false;
    }
  | {
      success: true;
      report: Report;
    };

export const deleteReportAction = (
  organisationId: number,
  reportId: number,
): ThunkAction<ReportsActionDelete, Promise<DeleteReportActionResult>> => async (
  dispatch: ThunkDispatch<ReportsActions>,
  getState,
) => {
  const state = getState();
  const reports = selectReports(state, organisationId);

  if (reports?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const report = await dispatch(
      promiseAction(
        ACTION_REPORTS_DELETE,
        async () =>
          http<Report, DeleteReportResponse>(`/api/v1/organisations/${organisationId}/reports/${reportId}`, {
            method: 'DELETE',
          }),
        {
          organisationId,
          reportId,
        },
      ),
    );
    return {
      success: true,
      report,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};

export const updateReportAction = (
  organisationId: number,
  reportId: number,
  reportConfig: Report,
): ThunkAction<ReportsActionUpdate, Promise<UpdateReportActionResult>> => async (
  dispatch: ThunkDispatch<ReportsActions>,
  getState,
) => {
  const state = getState();
  const reports = selectReports(state, organisationId);

  if (reports?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const report = await dispatch(
      promiseAction(
        ACTION_REPORTS_UPDATE,
        async () =>
          http<Report, UpdateReportResponse>(`/api/v1/organisations/${organisationId}/reports/${reportId}`, {
            method: 'PUT',
            body: reportConfig,
          }),
        {
          organisationId,
          reportId,
          reportConfig,
        },
      ),
    );
    return {
      success: true,
      report,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};

export const createReportAction = (
  organisationId: number,
  reportConfig: Omit<Report, 'id'>,
): ThunkAction<ReportsActionCreate, Promise<CreateReportActionResult>> => async (
  dispatch: ThunkDispatch<ReportsActions>,
  getState,
) => {
  const state = getState();
  const reports = selectReports(state, organisationId);

  if (reports?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const report = await dispatch(
      promiseAction(
        ACTION_REPORTS_CREATE,
        async () =>
          http<Report, CreateReportResponse>(`/api/v1/organisations/${organisationId}/reports/`, {
            method: 'POST',
            body: reportConfig,
          }),
        {
          organisationId,
          reportConfig,
        },
      ),
    );
    return {
      success: true,
      report,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};
