import React, { useMemo } from 'react';
import { Notification } from 'rsuite';
import {
  MetricSchema2,
  MetricSchema2Data,
  MetricSchema2DataSource,
  MetricSchema2DataSourceConstant,
  MetricSchema2DataSourceDerivedMetric,
  MetricSchema2DataSourceEntityAccount,
  MetricSchema2DataSourceEntityGroup,
  MetricSchema2DataSourceEntityPipeline,
  MetricSchema2DataSourceEntityPipelineStage,
  MetricSchema2DataSourceInvoicedRevenue,
  MetricSchema2DataSourceNativeMetric,
  MetricSchema2DataSourceReferenceRow,
  MetricSchema2DataSourceSalesMetric,
  MetricSchema2Decimals,
  MetricSchema2Simple,
  MetricSchema2Window,
} from 'scalexp/utils/metrics/metricSchema2';

import {
  useDeleteEditedReportRow,
  useEditedReport,
  useUpdateEditedReportRow,
} from '../../../../store/state/editedReport/hooks';
import { PipelineMetricsTypes, PipelineStageMetricsTypes } from '../../../../store/state/pipelines/types';
import { Row, StyleRowProperties } from '../../../../store/state/reports/types';
import { DeferredRevenueConfig } from '../../../deferred-revenue/types';
import usePickerOptions, { useGetRowName } from '../../ReportEditor/usePickerOptions';
import { FormulaPickerOption } from '../../ReportEditorAddRowSelect';
import ReportEditorGroupRow from '../ReportEditorAccountGroupRow';
import ReportEditorAccountRow from '../ReportEditorAccountRow';
import ReportEditorConstantRow from '../ReportEditorConstantRow';
import ReportEditorCustomRow from '../ReportEditorCustomRow';
import ReportEditorDerivedRow from '../ReportEditorDerivedRow';
import DividerRow from '../ReportEditorDividerRow';
import ReportEditorInvoicedRevenueRow from '../ReportEditorInvoicedRevenueRow';
import ReportEditorNativeMetricRow from '../ReportEditorNativeMetricRow';
import ReportEditorPipelineRow from '../ReportEditorPipelineRow';
import ReportEditorPipelineStageRow from '../ReportEditorPipelineStageRow';
import ReportEditorReferenceRow from '../ReportEditorReferenceRow';
import ReportEditorSalesMetricRow from '../ReportEditorSalesMetricRow';
import { getReferencingRow } from './helpers';

interface ReportEditorRowProps {
  row: Row;
  isSelected: boolean;
  handleIsSelectedChange: (isSelected: boolean) => void;
}

const ReportEditorRow: React.FC<ReportEditorRowProps> = ({ row, isSelected, handleIsSelectedChange }) => {
  const editedReport = useEditedReport();
  const updateEditedReportRow = useUpdateEditedReportRow();
  const deleteEditedReportRow = useDeleteEditedReportRow();

  const getRowName = useGetRowName();

  const pickerOptions = usePickerOptions();
  const normalizedPickerOptions: FormulaPickerOption[] = useMemo(() => {
    return [
      {
        label: 'Divider',
        value: 'divider',
        group: 'Divider',
      },
      {
        label: 'Fixed Number',
        value: 'constant',
        group: 'Constant',
      },
      ...pickerOptions
        .filter(option => option.id !== 'number' && option.id !== `row:${row.id}`)
        .map(option => ({ ...option, label: option.name, value: option.id, group: option.role })),
    ];
  }, [pickerOptions]);

  const handleChange = (value: string) => {
    if (value === 'divider') {
      updateEditedReportRow({ id: row.id, bold: true, name: undefined, type: 'EMPTY' });
      return;
    }

    if (value === 'constant') {
      const metric: MetricSchema2Simple = {
        schemaType: 'simple',
        decimals: 0,
        nodes: [
          {
            operator: 'add',
            data: {
              operator: 'constant',
              value: 0,
            },
          },
        ],
      };
      updateEditedReportRow({
        ...row,
        name: undefined,
        type: 'metric',
        metric: metric,
      });
      return;
    }

    let node: MetricSchema2DataSource;

    const [type, newId, newIdPartTwo] = value.split(':');
    switch (type) {
      case 'nm': {
        node = {
          operator: 'native',
          metricId: newId,
          isCashflow: false,
        };
        break;
      }
      case 'dm': {
        node = {
          operator: 'derived',
          metricId: newId,
        };
        break;
      }
      case 'sm': {
        node = {
          operator: 'sales',
          metricId: newId,
        };
        break;
      }
      case 'ir': {
        node = {
          operator: 'invoiced-revenue',
          metricId: newId,
        };
        break;
      }
      case 'pm': {
        node = {
          operator: 'entity',
          entity: 'pipeline',
          pipelineMetricId: 'metric_deals_in_progress_acv',
          pipelineId: newId,
        };
        break;
      }
      case 'ps': {
        node = {
          operator: 'entity',
          entity: 'stage',
          stageMetricId: 'metric_deals_acv',
          pipelineId: newIdPartTwo,
          stageId: newId,
        };
        break;
      }
      case 'account': {
        node = {
          operator: 'entity',
          entity: 'account',
          accountId: parseInt(newId),
          isCashflow: false,
        };
        break;
      }
      case 'group': {
        node = {
          operator: 'entity',
          entity: 'group',
          groupId: parseInt(newId),
          isCashflow: false,
        };
        break;
      }
      case 'row': {
        node = {
          operator: 'reference',
          reference: 'row',
          rowId: newId,
        };
        break;
      }
      case 'custom': {
        return;
      }
      default: {
        throw new Error(`Unknown case '${type}' from '${value}'`);
      }
    }

    const metric: MetricSchema2Simple = {
      schemaType: 'simple',
      decimals: 0,
      nodes: [
        {
          operator: 'add',
          data: node,
        },
      ],
    };

    updateEditedReportRow({
      ...row,
      name: undefined,
      type: 'metric',
      metric: metric,
    });
  };

  const handleDelete = () => {
    const referencingRow = getReferencingRow(row.id, editedReport!.rows);
    if (referencingRow) {
      Notification.warning({
        title: `Can't delete row because is referenced in row: ${getRowName(referencingRow, editedReport?.rows)}`,
        placement: 'bottomEnd',
      });
      return;
    }

    deleteEditedReportRow(row.id);
  };

  const styles: StyleRowProperties = {
    bold: row.bold,
    color: row.color,
    border_top: row.border_top,
    border_bottom: row.border_bottom,
  };

  const handleUpdateStyles = (updatedStyles: Partial<StyleRowProperties>) => {
    updateEditedReportRow({
      ...row,
      ...updatedStyles,
    });
  };

  const handleRename = (newName?: string) => {
    const { name, ...rest } = row;
    if (newName) {
      updateEditedReportRow({
        ...rest,
        name: newName,
      });
    } else {
      updateEditedReportRow({
        ...rest,
      });
    }
  };

  if (row.type === 'EMPTY') {
    return (
      <DividerRow
        name={row.name}
        styles={styles}
        id={row.id}
        isSelected={isSelected}
        handleIsSelectedChange={handleIsSelectedChange}
        handleUpdateRowStyles={handleUpdateStyles}
        handleRename={handleRename}
        handleDelete={handleDelete}
      />
    );
  }

  const handleChangePipelineMetric = (pipelineMetricId: PipelineMetricsTypes) => {
    const newRow = JSON.parse(JSON.stringify(row));
    newRow.metric.nodes[0].data.pipelineMetricId = pipelineMetricId;
    updateEditedReportRow(newRow);
  };

  const handleRevenueTypeChange = (isCashflow: boolean) => {
    const newRow = JSON.parse(JSON.stringify(row));
    newRow.metric.nodes[0].data.isCashflow = isCashflow;
    // reset tracking category options, because we can't have tracking categories and cashflow together
    delete newRow.metric.nodes[0].data.trackingCategoryOptions;
    updateEditedReportRow(newRow);
  };

  const handleTrackingCategoryOptionsChange = (value?: string[]) => {
    const newRow = JSON.parse(JSON.stringify(row));
    newRow.metric.nodes[0].data.trackingCategoryOptions = value;
    // reset cash flow, because we can't have tracking categories and cashflow together
    newRow.metric.nodes[0].data.isCashflow = false;
    updateEditedReportRow(newRow);
  };

  const handleChangePipelineStageMetric = (stageMetricId: PipelineStageMetricsTypes) => {
    const newRow = JSON.parse(JSON.stringify(row));
    newRow.metric.nodes[0].data.stageMetricId = stageMetricId;
    updateEditedReportRow(newRow);
  };

  // Custom Metric Schema, so there will be any number of nodes present
  if (row.metric.schemaType === 'complex') {
    return (
      <ReportEditorCustomRow
        row={row}
        report={editedReport}
        isSelected={isSelected}
        handleIsSelectedChange={handleIsSelectedChange}
        pickerOptions={normalizedPickerOptions}
        handleChange={handleChange}
        handleDelete={handleDelete}
        styles={styles}
        handleUpdateRowStyles={handleUpdateStyles}
        handleUpdateRow={updateEditedReportRow}
        customName={row.name}
        handleRename={handleRename}
      />
    );
  }

  // Simple Metric Schema, so there will only be exactly one data node present
  const node: MetricSchema2Data = row.metric.nodes[0];

  const handleUpdateWindow = (window: MetricSchema2Window | undefined) => {
    updateEditedReportRow({
      ...row,
      metric: {
        ...row.metric,
        nodes: [{ ...node, window }],
      },
    });
  };

  const handleUpdateDecimals = (decimals: MetricSchema2Decimals) => {
    updateEditedReportRow({
      ...row,
      metric: {
        ...row.metric,
        decimals,
      },
    });
  };

  switch (node.data.operator) {
    case 'derived': {
      const handleReplaceWithComplexMetricSchema = (name: string, metricSchema: MetricSchema2) => {
        updateEditedReportRow({
          ...row,
          name,
          metric: metricSchema,
        });
      };

      return (
        <ReportEditorDerivedRow
          id={row.id}
          node={node as MetricSchema2Data<MetricSchema2DataSourceDerivedMetric>}
          handleReplaceWithComplexMetricSchema={handleReplaceWithComplexMetricSchema}
          isSelected={isSelected}
          handleIsSelectedChange={handleIsSelectedChange}
          pickerOptions={normalizedPickerOptions}
          handleChange={handleChange}
          handleDelete={handleDelete}
          styles={styles}
          handleUpdateRowStyles={handleUpdateStyles}
          showComputation
          report={editedReport}
          window={node.window}
          handleUpdateWindow={handleUpdateWindow}
          decimals={row.metric.decimals}
          handleUpdateDecimals={handleUpdateDecimals}
        />
      );
    }
    case 'native': {
      const handleToggleShowGroups = () => {
        updateEditedReportRow({
          ...row,
          metric: {
            ...row.metric,
          },
          show_groups: !row.show_groups,
        });
      };

      return (
        <ReportEditorNativeMetricRow
          id={row.id}
          node={node as MetricSchema2Data<MetricSchema2DataSourceNativeMetric>}
          isSelected={isSelected}
          handleIsSelectedChange={handleIsSelectedChange}
          handleRevenueTypeChange={(isCashflow: boolean) => handleRevenueTypeChange(isCashflow)}
          pickerOptions={normalizedPickerOptions}
          handleChange={handleChange}
          handleDelete={handleDelete}
          showGroups={row.show_groups}
          handleToggleShowGroups={handleToggleShowGroups}
          styles={styles}
          handleUpdateRowStyles={handleUpdateStyles}
          customName={row.name}
          handleRename={handleRename}
          window={node.window}
          handleUpdateWindow={handleUpdateWindow}
          decimals={row.metric.decimals}
          handleUpdateDecimals={handleUpdateDecimals}
          handleTrackingCategoryOptionsChange={handleTrackingCategoryOptionsChange}
        />
      );
    }
    case 'sales': {
      const handleToggleShowGroups = () => {
        updateEditedReportRow({
          ...row,
          metric: {
            ...row.metric,
          },
          show_groups: !row.show_groups,
        });
      };

      return (
        <ReportEditorSalesMetricRow
          id={row.id}
          node={node as MetricSchema2Data<MetricSchema2DataSourceSalesMetric>}
          isSelected={isSelected}
          handleIsSelectedChange={handleIsSelectedChange}
          pickerOptions={normalizedPickerOptions}
          handleChange={handleChange}
          handleDelete={handleDelete}
          showGroups={row.show_groups}
          handleToggleShowGroups={handleToggleShowGroups}
          styles={styles}
          handleUpdateRowStyles={handleUpdateStyles}
          customName={row.name}
          handleRename={handleRename}
          window={node.window}
          handleUpdateWindow={handleUpdateWindow}
          decimals={row.metric.decimals}
          handleUpdateDecimals={handleUpdateDecimals}
        />
      );
    }
    case 'invoiced-revenue': {
      const handleToggleShowGroups = () => {
        updateEditedReportRow({
          ...row,
          metric: {
            ...row.metric,
          },
          show_groups: !row.show_groups,
        });
      };
      const handleChangeRevenueSubsidiary = (subsidiaryId?: number) => {
        const rowCopy = JSON.parse(JSON.stringify(row));
        if (subsidiaryId) {
          rowCopy.metric.nodes[0].data.subsidiaryId = subsidiaryId;
          rowCopy.show_groups = false;
        } else {
          delete rowCopy.metric.nodes[0].data.subsidiaryId;
        }
        updateEditedReportRow(rowCopy);
      };
      const handleChangeConfigId = (configId?: DeferredRevenueConfig['id']) => {
        const rowCopy = JSON.parse(JSON.stringify(row));
        if (configId) {
          rowCopy.metric.nodes[0].data.configId = configId;
          rowCopy.show_groups = false;
        } else {
          delete rowCopy.metric.nodes[0].data.configId;
        }
        updateEditedReportRow(rowCopy);
      };

      return (
        <ReportEditorInvoicedRevenueRow
          id={row.id}
          node={node as MetricSchema2Data<MetricSchema2DataSourceInvoicedRevenue>}
          isSelected={isSelected}
          handleIsSelectedChange={handleIsSelectedChange}
          pickerOptions={normalizedPickerOptions}
          handleChange={handleChange}
          handleDelete={handleDelete}
          showGroups={row.show_groups}
          handleToggleShowGroups={handleToggleShowGroups}
          styles={styles}
          handleUpdateRowStyles={handleUpdateStyles}
          customName={row.name}
          handleRename={handleRename}
          handleChangeRevenueSubsidiary={handleChangeRevenueSubsidiary}
          handleChangeConfigId={handleChangeConfigId}
          window={node.window}
          handleUpdateWindow={handleUpdateWindow}
          decimals={row.metric.decimals}
          handleUpdateDecimals={handleUpdateDecimals}
        />
      );
    }
    case 'entity': {
      switch (node.data.entity) {
        case 'account': {
          return (
            <ReportEditorAccountRow
              id={row.id}
              node={node as MetricSchema2Data<MetricSchema2DataSourceEntityAccount>}
              isSelected={isSelected}
              handleIsSelectedChange={handleIsSelectedChange}
              handleRevenueTypeChange={(isCashflow: boolean) => handleRevenueTypeChange(isCashflow)}
              pickerOptions={normalizedPickerOptions}
              handleChange={handleChange}
              handleDelete={handleDelete}
              styles={styles}
              handleUpdateRowStyles={handleUpdateStyles}
              customName={row.name}
              handleRename={handleRename}
              window={node.window}
              handleUpdateWindow={handleUpdateWindow}
              decimals={row.metric.decimals}
              handleUpdateDecimals={handleUpdateDecimals}
              handleTrackingCategoryOptionsChange={handleTrackingCategoryOptionsChange}
            />
          );
        }
        case 'group': {
          const handleToggleShowGroups = () => {
            updateEditedReportRow({
              ...row,
              metric: {
                ...row.metric,
              },
              show_groups: !row.show_groups,
            });
          };

          return (
            <ReportEditorGroupRow
              id={row.id}
              node={node as MetricSchema2Data<MetricSchema2DataSourceEntityGroup>}
              isSelected={isSelected}
              pickerOptions={normalizedPickerOptions}
              handleIsSelectedChange={handleIsSelectedChange}
              handleChange={handleChange}
              handleRevenueTypeChange={(isCashflow: boolean) => handleRevenueTypeChange(isCashflow)}
              handleDelete={handleDelete}
              styles={styles}
              handleUpdateRowStyles={handleUpdateStyles}
              customName={row.name}
              handleRename={handleRename}
              handleToggleShowGroups={handleToggleShowGroups}
              showGroups={!!row.show_groups}
              window={node.window}
              handleUpdateWindow={handleUpdateWindow}
              decimals={row.metric.decimals}
              handleUpdateDecimals={handleUpdateDecimals}
              handleTrackingCategoryOptionsChange={handleTrackingCategoryOptionsChange}
            />
          );
        }
        case 'pipeline': {
          const handleToggleShowGroups = () => {
            updateEditedReportRow({
              ...row,
              metric: {
                ...row.metric,
              },
              show_groups: !row.show_groups,
            });
          };

          return (
            <ReportEditorPipelineRow
              id={row.id}
              node={node as MetricSchema2Data<MetricSchema2DataSourceEntityPipeline>}
              isSelected={isSelected}
              handleIsSelectedChange={handleIsSelectedChange}
              pickerOptions={normalizedPickerOptions}
              handleChange={handleChange}
              handleChangePipelineMetric={handleChangePipelineMetric}
              handleDelete={handleDelete}
              showGroups={row.show_groups}
              handleToggleShowGroups={handleToggleShowGroups}
              styles={styles}
              handleUpdateRowStyles={handleUpdateStyles}
              customName={row.name}
              handleRename={handleRename}
              window={node.window}
              handleUpdateWindow={handleUpdateWindow}
              decimals={row.metric.decimals}
              handleUpdateDecimals={handleUpdateDecimals}
            />
          );
        }
        case 'stage': {
          return (
            <ReportEditorPipelineStageRow
              id={row.id}
              node={node as MetricSchema2Data<MetricSchema2DataSourceEntityPipelineStage>}
              isSelected={isSelected}
              handleIsSelectedChange={handleIsSelectedChange}
              pickerOptions={normalizedPickerOptions}
              handleChange={handleChange}
              handleChangePipelineStageMetric={handleChangePipelineStageMetric}
              handleDelete={handleDelete}
              styles={styles}
              handleUpdateRowStyles={handleUpdateStyles}
              customName={row.name}
              handleRename={handleRename}
              window={node.window}
              handleUpdateWindow={handleUpdateWindow}
              decimals={row.metric.decimals}
              handleUpdateDecimals={handleUpdateDecimals}
            />
          );
        }
        default: {
          return null;
        }
      }
    }
    case 'constant': {
      const handleNumberChange = (value: number) => {
        const constantDataNode: MetricSchema2Data<MetricSchema2DataSourceConstant> = {
          ...node,
          data: { ...(node.data as MetricSchema2DataSourceConstant), value },
        };
        updateEditedReportRow({
          ...row,
          metric: {
            ...row.metric,
            nodes: [constantDataNode],
          },
        });
      };

      return (
        <ReportEditorConstantRow
          id={row.id}
          node={node as MetricSchema2Data<MetricSchema2DataSourceConstant>}
          isSelected={isSelected}
          handleIsSelectedChange={handleIsSelectedChange}
          pickerOptions={normalizedPickerOptions}
          handleChange={handleChange}
          handleDelete={handleDelete}
          handleNumberChange={handleNumberChange}
          styles={styles}
          handleUpdateRowStyles={handleUpdateStyles}
          customName={row.name}
          handleRename={handleRename}
          decimals={row.metric.decimals}
          handleUpdateDecimals={handleUpdateDecimals}
        />
      );
    }
    case 'reference': {
      return (
        <ReportEditorReferenceRow
          id={row.id}
          node={node as MetricSchema2Data<MetricSchema2DataSourceReferenceRow>}
          isSelected={isSelected}
          handleIsSelectedChange={handleIsSelectedChange}
          pickerOptions={normalizedPickerOptions}
          handleChange={handleChange}
          handleDelete={handleDelete}
          report={editedReport}
          styles={styles}
          handleUpdateRowStyles={handleUpdateStyles}
          window={node.window}
          handleUpdateWindow={handleUpdateWindow}
          decimals={row.metric.decimals}
          handleUpdateDecimals={handleUpdateDecimals}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default ReportEditorRow;
