import dayjs from 'dayjs';
import React, { useState, useEffect, useCallback } from 'react';
import { useQueryParam } from 'use-query-params';

import Divider from '../../../components/atoms/Divider';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import Aligner from '../../../components/layout/Aligner';
import Spacer from '../../../components/layout/Spacer';
import { useUpdateOrganisation } from '../../../store/state/organisations/hooks';
import Notification from '../../atoms/Notification';
import useActiveOrganisation from '../../contexts/OrganisationContext/useActiveOrganisation';
import Card from '../../molecules/Card';
import DateSelect from '../../molecules/DateSelect';
import { DATE_FORMAT } from '../../molecules/DateSelect/dateSupport';
import LabeledTag from '../../molecules/LabeledTag';

interface FinancialYearCardProps {}

const FinancialYearCard: React.FC<React.PropsWithChildren<FinancialYearCardProps>> = () => {
  const [isEdit, setEdit] = useState(false);
  const organisation = useActiveOrganisation();
  const organisationId = organisation.organisation_id;
  const year_start_date = organisation.financial_year_start ?? 0;
  const updateOrganisation = useUpdateOrganisation();
  const [selectedDate, setSelectedDate] = useQueryParam<string>('date');
  useEffect(() => {
    setSelectedDate(dayjs().month(year_start_date).startOf('month').format(DATE_FORMAT));
  }, [year_start_date]);
  const startMonth = dayjs(selectedDate).month();
  const start_month = dayjs().month(startMonth).format('MMMM');
  const end_month = dayjs()
    .month(startMonth - 1)
    .format('MMMM');
  const getQuarter = useCallback(
    (quarter = 1) => {
      const multipler = (quarter - 1) * 3;
      const quarter_start = multipler + 0 + startMonth;
      return [
        dayjs().month(quarter_start).format('MMM'),
        dayjs().month(quarter_start).add(2, 'month').format('MMM'),
      ].join('-');
    },
    [startMonth],
  );

  const handleCancel = () => {
    setEdit(false);
    setSelectedDate(dayjs().month(year_start_date).startOf('month').format(DATE_FORMAT));
  };

  const handleSave = async () => {
    const success: boolean = await updateOrganisation(organisationId, {
      financial_year_start: startMonth,
    });
    if (success) {
      Notification.success({ title: 'Financial year was updated successfully!', placement: 'bottomEnd' });
      setEdit(false);
    } else {
      Notification.error({ title: "Couldn't update financial year.", placement: 'bottomEnd' });
    }
  };

  return (
    <Card width="760px">
      <Spacer margin={{ left: 6, right: 6, top: 5, bottom: 5 }}>
        <Aligner width="100%" hAlign="space-between">
          <Aligner hAlign="flex-start" direction="vertical">
            <Typography size="medium" weight="semibold">
              Define Your Financial Year
            </Typography>
          </Aligner>
          <Aligner>
            {!isEdit && (
              <TextButton onClick={() => setEdit(true)} size="small" iconLeft="edit" variant="secondary">
                Edit
              </TextButton>
            )}
            {isEdit && (
              <>
                <TextButton onClick={handleCancel} size="small" variant="secondary">
                  Cancel
                </TextButton>
                <Spacer margin={{ right: 2 }} />
                <TextButton onClick={handleSave} size="small">
                  Save
                </TextButton>
              </>
            )}
          </Aligner>
        </Aligner>
      </Spacer>
      <Divider />
      <Spacer margin={{ left: 6, top: 5, bottom: 5 }}>
        {!isEdit && (
          <>
            <Typography size="medium" weight="semibold">
              {start_month} - {end_month}
            </Typography>
            <Spacer margin={{ top: 1 }}>
              <Typography size="tiny" color="secondary">
                The financial year is currently set to start on{' '}
                <Typography size="tiny" weight="bold">
                  {dayjs().month(startMonth).startOf('M').format('D MMMM')}
                </Typography>{' '}
                and ends on{' '}
                <Typography size="tiny" weight="bold">
                  {dayjs()
                    .month(startMonth - 1)
                    .endOf('M')
                    .format('DD MMMM')}
                  .
                </Typography>
              </Typography>
            </Spacer>

            <Spacer margin={{ top: 5, bottom: 5 }}>
              <Aligner hAlign="flex-start">
                <LabeledTag label="Q1" value={getQuarter(1)} />
                <Spacer margin={{ left: 3 }} />
                <LabeledTag label="Q2" value={getQuarter(2)} />
                <Spacer margin={{ left: 3 }} />
                <LabeledTag label="Q3" value={getQuarter(3)} />
                <Spacer margin={{ left: 3 }} />
                <LabeledTag label="Q4" value={getQuarter(4)} />
              </Aligner>
            </Spacer>
          </>
        )}
        {isEdit && (
          <>
            <Typography size="tiny" color="secondary">
              Start of financial year
            </Typography>
            <Spacer margin={{ top: 2, bottom: 5 }}>
              <DateSelect
                width="155px"
                sortOrder="ASCENDING"
                monthOnly
                start={dayjs().month(0).startOf('month').toDate()}
                end={dayjs().month(12).startOf('month').toDate()}
                data-testid="month-select"
                aria-label="Month selector"
              />
            </Spacer>
          </>
        )}
      </Spacer>
    </Card>
  );
};

export default FinancialYearCard;
