import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface CardProps extends InputHTMLAttributes<HTMLInputElement> {
  media?: string;
  width?: string;
  height?: string;
  strongShadow?: boolean;
}

const StyledCard = styled.div<CardProps>`
  padding: 0;
  box-sizing: content-box;
  overflow: hidden;
  ${({ theme, width = '280px', height = '400px', strongShadow }) => css`
    width: ${width};
    max-height: ${height};
    overflow-y: auto;
    border: 1px solid ${theme.palette.silver};
    border-radius: ${theme.rounding.normal};
    background-color: ${theme.palette.white};
    box-shadow: ${strongShadow ? theme.shadow.medium : theme.shadow.normal};
  `}
`;

const StyledImageContainer = styled.div`
  width: 280px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const StyledImage = styled.img`
  max-height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Card: React.FC<React.PropsWithChildren<CardProps>> = ({ media, children, ...other }) => {
  return (
    <StyledCard {...other}>
      {media && (
        <StyledImageContainer>
          <StyledImage src={media} alt="" />
        </StyledImageContainer>
      )}
      {children}
    </StyledCard>
  );
};

export default Card;
