import { useState } from 'react';
import { MetricSchema2Operator } from 'scalexp/utils/metrics/metricSchema2';
import styled, { css } from 'styled-components';

import Icon from '../../../components/atoms/Icon';
import Typography from '../../../components/atoms/Typography';
import Tooltip from '../../../components/molecules/Tooltip';

export interface FormulaEditorOperatorProps {
  operator: MetricSchema2Operator;
  handleOperatorChange: (operator: MetricSchema2Operator) => void;
  isSelected?: boolean;
}

const OperatorIconMap: { [icon: string]: string } = {
  add: 'add',
  multiply: 'close',
  subtract: 'remove',
};

const StyledTooltipContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const StyledItem = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(4)};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.offwhite};
  }
`;

const StyledIconContainer = styled.div<{ isSelected?: boolean }>`
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.palette.primary.main : '#f6c155')};

  border-radius: ${({ theme }) => theme.spacing(1)};
  ${({ isSelected, theme }) => {
    if (isSelected) {
      return css`
        & > span,
        & > div {
          color: ${theme.palette.white};
        }
      `;
    }
  }}
`;

const DivideIcon = styled.div`
  font-size: 20px;
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  color: #7c5f23;
`;

const FormulaEditorOperator: React.FC<React.PropsWithChildren<FormulaEditorOperatorProps>> = ({
  operator,
  handleOperatorChange,
  isSelected,
}) => {
  const [closeTooltip, setCloseTooltip] = useState(false);

  const operatorOptions: MetricSchema2Operator[] = ['add', 'divide', 'multiply', 'subtract'];

  return (
    <Tooltip
      content={
        <StyledTooltipContainer>
          {operatorOptions.map(operatorOption => (
            <StyledItem
              onClick={() => {
                setCloseTooltip(true);
                handleOperatorChange(operatorOption);
              }}
            >
              <StyledIconContainer>
                {operatorOption === 'divide' ? (
                  <DivideIcon>÷</DivideIcon>
                ) : (
                  <Icon size={5} color="#7C5F23" name={OperatorIconMap[operatorOption]} rounded marginRight={0} />
                )}
              </StyledIconContainer>
              <Typography>{`${operatorOption[0].toUpperCase()}${operatorOption.slice(1)}`}</Typography>
            </StyledItem>
          ))}
        </StyledTooltipContainer>
      }
      color="white"
      trigger="click"
      placement="bottom"
      noPadding
      closeTooltipOptions={{ closeTooltip, setCloseTooltip }}
    >
      <StyledIconContainer isSelected={isSelected}>
        {operator === 'divide' ? (
          <DivideIcon>÷</DivideIcon>
        ) : (
          <Icon size={5} name={OperatorIconMap[operator]} rounded marginRight={0} color={'#7C5F23'} />
        )}
      </StyledIconContainer>
    </Tooltip>
  );
};

export default FormulaEditorOperator;
