import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useMemo } from 'react';
import Divider from 'scalexp/components/atoms/Divider';
import {
  MetricSchema2Data,
  MetricSchema2DataSourceSalesMetric,
  MetricSchema2DataType,
  MetricSchema2Decimals,
  MetricSchema2Operator,
  MetricSchema2Window,
} from 'scalexp/utils/metrics/metricSchema2';
import { getSimpleMetricSchemaFromNode, recursiveRemoveIds } from 'scalexp/utils/metrics/ms2utils';
import styled from 'styled-components';

import Icon from '../../../../components/atoms/Icon';
import Typography from '../../../../components/atoms/Typography';
import useActiveOrganisation from '../../../../components/contexts/OrganisationContext/useActiveOrganisation';
import useCurrencySelection from '../../../../components/molecules/CurrencySelect/useCurrencySelection';
import { useDateSelectionDate } from '../../../../components/molecules/DateSelect/useDateSelection';
import Tooltip from '../../../../components/molecules/Tooltip';
import WindowingIndicator from '../../../../components/molecules/WindowingIndicator';
import { SALES_METRIC_DISPLAY_NAMES } from '../../../../service/types/metricSchema';
import { PipelineCategory, PipelineMetricsTypes } from '../../../../store/state/pipelines/types';
import { StyleRowProperties } from '../../../../store/state/reports/types';
import { theme } from '../../../../theme';
import useMetricSchemaSeries from '../../../../utils/metrics/useMetricSchemaSeries';
import FormulaEditorOperator from '../../../formula-editor/FormulaEditorOperator';
import { DISPLAY_COLUMNS, formatValue } from '../../ReportEditor/helpers';
import { FormulaPickerOption } from '../../ReportEditorAddRowSelect';
import { DragHandle } from '../../ReportEditorContent';
import ReportEditorCustomName from '../../ReportEditorCustomName';
import ReportEditorDelete from '../../ReportEditorDelete';
import ReportEditorUpdateDecimals from '../../ReportEditorSelectDecimals';
import ReportEditorSimpleSelect from '../../ReportEditorSimpleSelect';
import ReportEditorRowConfig from '../../report-editor-row-config/ReportEditorRowConfig';
import { reportEditorcalculationRequest } from '../ReportEditorCustomRow';
import ReportEditorCustomRowStyles from '../ReportEditorCustomRowStyles';
import ReportEditorDisplayAllPipelinesRow from '../ReportEditorDisplayAllPipelinesRow';
import { rowBorderStyles } from '../ReportEditorRow/helpers';

interface ReportEditorSalesMetricRowProps {
  id: string;
  node: MetricSchema2Data<MetricSchema2DataSourceSalesMetric>;
  isSelected: boolean;
  pickerOptions: FormulaPickerOption[];
  handleIsSelectedChange: (isSelected: boolean) => void;
  handleChange: (value: string) => void;
  handleDelete: () => void;
  showGroups?: boolean;
  handleToggleShowGroups?: () => void;
  styles?: StyleRowProperties;
  handleUpdateRowStyles?: (styles: Partial<StyleRowProperties>) => void;
  customName?: string;
  handleRename?: (newName?: string) => void;
  operator?: MetricSchema2Operator;
  handleOperatorChange?: (operator: MetricSchema2Operator) => void;
  window?: MetricSchema2Window;
  handleUpdateWindow: (window?: MetricSchema2Window) => void;
  decimals: MetricSchema2Decimals;
  handleUpdateDecimals?: (decimals: MetricSchema2Decimals) => void;
  parentPath?: string;
}

const StyledContainer = styled.div<{ isSelected: boolean; styles: Omit<StyleRowProperties, 'bold'> }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: ${({ styles: { border_top } }) => rowBorderStyles[border_top || 'NONE']};
  border-bottom: ${({ styles: { border_bottom } }) => rowBorderStyles[border_bottom || 'NONE']};
  border-radius: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
`;

const StyledSalesMetric = styled.div<{
  isSelected: boolean;
  backgroundColor?: StyleRowProperties['color'];
  operator?: boolean;
}>`
  width: 100%;
  min-height: 40px;
  display: grid;
  grid-template-columns: ${({ operator }) => `20px ${operator ? '20px' : ''} 1fr 160px`};
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};
  border-radius: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme, isSelected, backgroundColor }) =>
    backgroundColor
      ? `hsl(${backgroundColor.h}, ${backgroundColor.s}%, ${backgroundColor.l}%)`
      : isSelected
      ? theme.palette.silver
      : theme.palette.white};

  span:nth-last-child(-n + 2) {
    text-align: end;
  }
`;

const StyledGroupsContainer = styled.div<{ isExpanded?: boolean; backgroundColor?: StyleRowProperties['color'] }>`
  width: 100%;
  max-height: ${({ isExpanded }) => (isExpanded ? '1000px' : 0)};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? `hsl(${backgroundColor.h}, ${backgroundColor.s}%, ${backgroundColor.l}%)` : theme.palette.white};
`;

const StyledSalesMetricDivider = styled.div<{ backgroundColor?: StyleRowProperties['color'] }>`
  width: calc(100% + 2px);
  height: 1px;
  position: relative;
  right: 1px;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? `hsl(${backgroundColor.h}, ${backgroundColor.s}%, ${backgroundColor.l}%)` : theme.palette.silver};
`;

const StyledActions = styled.div`
  overflow-x: auto;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
  margin-left: -9px;

  span:first-child {
    max-width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
  }
`;

const METRIC_TO_CATEGORY_MAPPING: { [metric: string]: PipelineCategory } = {
  NEW_SALES_IN_PROGRESS: 'NEW_SALES',
  NEW_SALES_IN_PROGRESS_ACV: 'NEW_SALES',
  NEW_SALES_IN_PROGRESS_WEIGHTED_ACV: 'NEW_SALES',
  NEW_SALES_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE: 'NEW_SALES',
  NEW_SALES_WON: 'NEW_SALES',
  NEW_SALES_WON_ACV: 'NEW_SALES',
  NEW_SALES_LOST: 'NEW_SALES',
  NEW_SALES_LOST_ACV: 'NEW_SALES',
  RENEWALS_IN_PROGRESS: 'RENEWALS',
  RENEWALS_IN_PROGRESS_ACV: 'RENEWALS',
  RENEWALS_IN_PROGRESS_WEIGHTED_ACV: 'RENEWALS',
  RENEWALS_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE: 'NEW_SALES',
  RENEWALS_WON: 'RENEWALS',
  RENEWALS_WON_ACV: 'RENEWALS',
  RENEWALS_LOST: 'RENEWALS',
  RENEWALS_LOST_ACV: 'RENEWALS',
};
const METRIC_TO_PIPELINE_METRIC_MAPPING: { [metric: string]: PipelineMetricsTypes } = {
  NEW_SALES_IN_PROGRESS: 'metric_deals_in_progress',
  NEW_SALES_IN_PROGRESS_ACV: 'metric_deals_in_progress_acv',
  NEW_SALES_IN_PROGRESS_WEIGHTED_ACV: 'metric_deals_in_progress_weighted_acv',
  NEW_SALES_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE: 'metric_deals_in_progress_forecasted_weighted_value',
  NEW_SALES_WON: 'metric_deals_won',
  NEW_SALES_WON_ACV: 'metric_deals_won_acv',
  NEW_SALES_LOST: 'metric_deals_lost',
  NEW_SALES_LOST_ACV: 'metric_deals_lost_acv',
  RENEWALS_IN_PROGRESS: 'metric_deals_in_progress',
  RENEWALS_IN_PROGRESS_ACV: 'metric_deals_in_progress_acv',
  RENEWALS_IN_PROGRESS_WEIGHTED_ACV: 'metric_deals_in_progress_weighted_acv',
  RENEWALS_IN_PROGRESS_FORECASTED_WEIGHTED_VALUE: 'metric_deals_in_progress_forecasted_weighted_value',
  RENEWALS_WON: 'metric_deals_won',
  RENEWALS_WON_ACV: 'metric_deals_won_acv',
  RENEWALS_LOST: 'metric_deals_lost',
  RENEWALS_LOST_ACV: 'metric_deals_lost_acv',
};

const ReportEditorSalesMetricRow: React.FC<React.PropsWithChildren<ReportEditorSalesMetricRowProps>> = ({
  id,
  node,
  isSelected,
  pickerOptions,
  handleChange,
  handleIsSelectedChange,
  handleDelete,
  showGroups,
  handleToggleShowGroups,
  styles,
  handleUpdateRowStyles,
  customName,
  handleRename,
  operator,
  handleOperatorChange,
  window,
  handleUpdateWindow,
  decimals,
  handleUpdateDecimals,
  parentPath,
}) => {
  const path = parentPath ? `${parentPath}:${id}` : id;

  const date = useDateSelectionDate();
  const [currencyCode] = useCurrencySelection();

  const organisation = useActiveOrganisation();
  const { financial_year_start, default_currency_code } = organisation;

  const metricSchema = getSimpleMetricSchemaFromNode(node);
  const metricSchemaWithoutIds = recursiveRemoveIds(metricSchema);
  const seriesVS = useMetricSchemaSeries(
    metricSchemaWithoutIds,
    reportEditorcalculationRequest(date, currencyCode || default_currency_code),
  );

  const values: (number | null)[] = useMemo(() => {
    const series = seriesVS.value;
    if (!series) {
      return [];
    }

    return series.map(serie => (serie ? Number(serie.value) : null));
  }, [seriesVS.value, DISPLAY_COLUMNS, financial_year_start]);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, data: { path } });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const handleSelectedRowIdChange = () => {
    handleIsSelectedChange(!isSelected);
  };

  const handleToggleShowGroupsClick: React.MouseEventHandler<HTMLSpanElement> = e => {
    e.stopPropagation();
    handleToggleShowGroups?.();
  };

  const salesMetricName = SALES_METRIC_DISPLAY_NAMES[node.data.metricId];

  let salesMetricColor: StyleRowProperties['color'];
  let accountsColor: StyleRowProperties['color'];
  if (styles?.color) {
    salesMetricColor = { ...styles.color };
    accountsColor = { ...salesMetricColor };
    salesMetricColor.l += Math.floor((100 - salesMetricColor.l) / 1.5);
    salesMetricColor.l += Math.floor((100 - salesMetricColor.l) / 1.5);
    accountsColor.l = salesMetricColor.l + Math.floor((100 - salesMetricColor.l) / 1.5);

    // ignoring white color
    if (salesMetricColor?.h === 0 && salesMetricColor?.s === 0) {
      salesMetricColor = undefined;
    }
  }

  const dataType: MetricSchema2DataType =
    (seriesVS.value?.[0]?.data_type.toLowerCase() as MetricSchema2DataType) || 'monetary';

  const isValuePeriod = (window && window.before === -window.after) || !window;
  const hasWindow = !isValuePeriod || (isValuePeriod && window && window.before !== 0);

  const hasDecimals = decimals !== undefined && handleUpdateDecimals;
  const hasStyles = styles && handleUpdateRowStyles;

  return (
    <StyledContainer
      ref={setNodeRef}
      style={style}
      isSelected={isSelected}
      onClick={handleSelectedRowIdChange}
      styles={{ ...styles }}
    >
      <StyledSalesMetric isSelected={isSelected} backgroundColor={salesMetricColor} operator={Boolean(operator)}>
        <DragHandle {...attributes} {...listeners} />
        {operator && !handleOperatorChange && <div />}
        {operator && handleOperatorChange && (
          <FormulaEditorOperator operator={operator} handleOperatorChange={handleOperatorChange} />
        )}
        <StyledActions>
          {!isSelected && (
            <>
              <Typography color="secondary" weight={styles?.bold ? 'semibold' : 'regular'}>
                {customName || salesMetricName}
              </Typography>
              {hasWindow && <WindowingIndicator />}
            </>
          )}
          {isSelected && (
            <>
              <ReportEditorSimpleSelect
                pickerOptions={pickerOptions}
                value={`sm:${node.data.metricId}`}
                handleChange={handleChange}
                bold={styles?.bold}
                customDisplayLabel={customName}
              />
              {handleToggleShowGroups && (
                <Tooltip content="Expand breakdown of row on report">
                  <Icon
                    name="library_books"
                    color={showGroups ? theme.palette.primary.light : theme.palette.granite}
                    size={6}
                    onClick={handleToggleShowGroupsClick}
                    clickable
                  />
                </Tooltip>
              )}
              <ReportEditorRowConfig window={window} handleUpdateWindow={handleUpdateWindow} />

              {(handleRename || hasDecimals || hasStyles) && (
                <Divider direction="vertical" height="20px" noMargin color={theme.palette.fieldGrey} />
              )}
              {handleRename && (
                <ReportEditorCustomName
                  handleRename={handleRename}
                  customName={customName}
                  defaultName={salesMetricName}
                />
              )}
              {hasDecimals && (
                <ReportEditorUpdateDecimals decimals={decimals} handleUpdateDecimals={handleUpdateDecimals} />
              )}
              {hasStyles && (
                <ReportEditorCustomRowStyles styles={styles} handleUpdateRowStyles={handleUpdateRowStyles} />
              )}

              <Divider direction="vertical" height="20px" noMargin color={theme.palette.fieldGrey} />
              <ReportEditorDelete handleDelete={handleDelete} />
            </>
          )}
        </StyledActions>
        <Typography color="secondary" weight={styles?.bold ? 'semibold' : 'regular'}>
          {formatValue(values[0], currencyCode || default_currency_code, dataType, decimals)}
        </Typography>
      </StyledSalesMetric>
      <StyledGroupsContainer isExpanded={showGroups} backgroundColor={accountsColor}>
        <StyledSalesMetricDivider backgroundColor={accountsColor} />
        <ReportEditorDisplayAllPipelinesRow
          category={METRIC_TO_CATEGORY_MAPPING[node.data.metricId]}
          metric={METRIC_TO_PIPELINE_METRIC_MAPPING[node.data.metricId]}
          decimals={decimals}
        />
      </StyledGroupsContainer>
    </StyledContainer>
  );
};

export default ReportEditorSalesMetricRow;
