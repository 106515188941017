import { ImportJobResponse } from './types';

const calculateCompletedSteps = (jobResponse: ImportJobResponse) => {
  let completedSteps = 0;
  for (const step in jobResponse.steps) {
    if (jobResponse.steps[step].status === 'Completed') {
      ++completedSteps;
    }
  }

  return completedSteps;
};

export default calculateCompletedSteps;
