import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useMemo } from 'react';
import Divider from 'scalexp/components/atoms/Divider';
import { theme } from 'scalexp/theme';
import {
  MetricSchema2Data,
  MetricSchema2DataSourceEntityAccount,
  MetricSchema2DataType,
  MetricSchema2Operator,
  MetricSchema2Window,
  MetricSchema2Decimals,
} from 'scalexp/utils/metrics/metricSchema2';
import { getSimpleMetricSchemaFromNode, recursiveRemoveIds } from 'scalexp/utils/metrics/ms2utils';
import styled from 'styled-components';

import Typography from '../../../../components/atoms/Typography';
import useActiveOrganisation from '../../../../components/contexts/OrganisationContext/useActiveOrganisation';
import useCurrencySelection from '../../../../components/molecules/CurrencySelect/useCurrencySelection';
import { useDateSelectionDate } from '../../../../components/molecules/DateSelect/useDateSelection';
import WindowingIndicator from '../../../../components/molecules/WindowingIndicator';
import { useAccounts } from '../../../../store/state/accounts/hooks';
import { Account } from '../../../../store/state/accounts/types';
import { StyleRowProperties } from '../../../../store/state/reports/types';
import useMetricSchemaSeries from '../../../../utils/metrics/useMetricSchemaSeries';
import FormulaEditorOperator from '../../../formula-editor/FormulaEditorOperator';
import { DISPLAY_COLUMNS, formatValue } from '../../ReportEditor/helpers';
import { FormulaPickerOption } from '../../ReportEditorAddRowSelect';
import { DragHandle } from '../../ReportEditorContent';
import ReportEditorCustomName from '../../ReportEditorCustomName';
import ReportEditorDelete from '../../ReportEditorDelete';
import ReportEditorUpdateDecimals from '../../ReportEditorSelectDecimals';
import ReportEditorSimpleSelect from '../../ReportEditorSimpleSelect';
import ReportEditorRowConfig from '../../report-editor-row-config/ReportEditorRowConfig';
import { reportEditorcalculationRequest } from '../ReportEditorCustomRow';
import ReportEditorCustomRowStyles from '../ReportEditorCustomRowStyles';
import { PAndLBalanceSheetNativeMetrics } from '../ReportEditorNativeMetricRow/PAndLBalanceSheetNativeMetrics';
import { rowBorderStyles } from '../ReportEditorRow/helpers';

interface ReportEditorAccountRowProps {
  id: string;
  node: MetricSchema2Data<MetricSchema2DataSourceEntityAccount>;
  isSelected: boolean;
  pickerOptions: FormulaPickerOption[];
  handleIsSelectedChange: (isSelected: boolean) => void;
  handleChange: (value: string) => void;
  handleDelete: () => void;
  styles?: StyleRowProperties;
  handleUpdateRowStyles?: (styles: Partial<StyleRowProperties>) => void;
  customName?: string;
  handleRename?: (newName?: string) => void;
  operator?: MetricSchema2Operator;
  handleOperatorChange?: (operator: MetricSchema2Operator) => void;
  window?: MetricSchema2Window;
  handleUpdateWindow: (window?: MetricSchema2Window) => void;
  decimals: MetricSchema2Decimals;
  handleUpdateDecimals?: (decimals: MetricSchema2Decimals) => void;
  parentPath?: string;
  handleRevenueTypeChange?: (value: boolean) => void;
  handleTrackingCategoryOptionsChange?: (value?: string[]) => void;
}

const StyledContainer = styled.div<{
  isSelected: boolean;
  styles: Omit<StyleRowProperties, 'bold'>;
  operator?: boolean;
  handleRevenueTypeChange?: (value: boolean) => void;
}>`
  width: 100%;
  min-height: 40px;
  display: grid;
  grid-template-columns: ${({ operator }) => `20px ${operator ? '20px' : ''} 1fr 160px`};
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};
  background-color: ${({ theme, isSelected, styles: { color } }) =>
    color ? `hsl(${color.h}, ${color.s}%, ${color.l}%)` : isSelected ? theme.palette.silver : theme.palette.white};
  border-top: ${({ styles: { border_top } }) => rowBorderStyles[border_top || 'NONE']};
  border-bottom: ${({ styles: { border_bottom } }) => rowBorderStyles[border_bottom || 'NONE']};
  border-radius: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;

  span:nth-last-child(-n + 2) {
    text-align: end;
  }
`;

const StyledActions = styled.div`
  overflow-x: auto;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
  margin-left: -9px;

  span:first-child {
    max-width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
  }
`;

const ReportEditorAccountRow: React.FC<React.PropsWithChildren<ReportEditorAccountRowProps>> = ({
  id,
  node,
  isSelected,
  pickerOptions,
  handleChange,
  handleIsSelectedChange,
  handleDelete,
  styles,
  handleUpdateRowStyles,
  customName,
  handleRename,
  operator,
  handleOperatorChange,
  window,
  handleUpdateWindow,
  decimals,
  handleUpdateDecimals,
  parentPath,
  handleRevenueTypeChange,
  handleTrackingCategoryOptionsChange,
}) => {
  const path = parentPath ? `${parentPath}:${id}` : id;
  const date = useDateSelectionDate();
  const [currencyCode] = useCurrencySelection();

  const organisation = useActiveOrganisation();
  const { organisation_id, financial_year_start, default_currency_code } = organisation;

  const metricSchema = getSimpleMetricSchemaFromNode(node);
  const metricSchemaWithoutIds = recursiveRemoveIds(metricSchema);
  const seriesVS = useMetricSchemaSeries(
    metricSchemaWithoutIds,
    reportEditorcalculationRequest(date, currencyCode || default_currency_code),
  );
  const accountsVS = useAccounts(organisation_id);

  const values: (number | null)[] = useMemo(() => {
    const series = seriesVS.value;
    if (!series) {
      return [];
    }

    return series.map(serie => (serie ? Number(serie.value) : null));
  }, [seriesVS.value, DISPLAY_COLUMNS, financial_year_start]);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, data: { path } });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const handleSelectedRowIdChange = () => {
    handleIsSelectedChange(!isSelected);
  };

  let rowColor: StyleRowProperties['color'];
  if (styles?.color) {
    rowColor = { ...styles.color };
    rowColor.l += Math.floor((100 - rowColor.l) / 1.5);
    rowColor.l += Math.floor((100 - rowColor.l) / 1.5);

    // ignoring white color
    if (rowColor?.h === 0 && rowColor?.s === 0) {
      rowColor = undefined;
    }
  }

  const account: Account | undefined = accountsVS.value?.[node.data.accountId];

  let accountTitle = '';
  if (account) {
    accountTitle = account.name;
    if (account.code) {
      accountTitle = account.code + ' - ' + accountTitle;
    }
    const subsidiaryOrganisation = organisation.children.find(org => org.organisation_id === account.organisation_id);

    if (subsidiaryOrganisation?.name_short) {
      accountTitle = subsidiaryOrganisation?.name_short + ': ' + accountTitle;
    }
  }

  const dataType: MetricSchema2DataType =
    (seriesVS.value?.[0]?.data_type.toLowerCase() as MetricSchema2DataType) || 'monetary';
  let accountName = customName || accountTitle;
  if (!customName && account?.status === 'Archived') {
    accountName += ' (Archived)';
    accountTitle += ' (Archived)';
  }
  const isValuePeriod = (window && window.before === -window.after) || !window;
  const hasWindow = !isValuePeriod || (isValuePeriod && window && window.before !== 0);

  const hasDecimals = decimals !== undefined && handleUpdateDecimals;
  const hasStyles = styles && handleUpdateRowStyles;

  const canUseTrackingCategory = PAndLBalanceSheetNativeMetrics.includes(account?.tag || '');

  return (
    <StyledContainer
      ref={setNodeRef}
      style={style}
      isSelected={isSelected}
      onClick={handleSelectedRowIdChange}
      styles={{ ...styles, color: rowColor }}
      operator={Boolean(operator)}
    >
      <DragHandle {...attributes} {...listeners} />
      {operator && !handleOperatorChange && <div />}
      {operator && handleOperatorChange && (
        <FormulaEditorOperator operator={operator} handleOperatorChange={handleOperatorChange} />
      )}
      <StyledActions>
        {!isSelected && (
          <>
            <Typography color={accountName ? 'secondary' : 'warning'} weight={styles?.bold ? 'semibold' : 'regular'}>
              {accountName || 'Deleted account'}
            </Typography>
            {hasWindow && <WindowingIndicator />}
          </>
        )}
        {isSelected && (
          <>
            <ReportEditorSimpleSelect
              pickerOptions={pickerOptions}
              value={`account:${node.data.accountId}`}
              handleChange={handleChange}
              bold={styles?.bold}
              customDisplayLabel={customName}
            />
            <ReportEditorRowConfig
              isCashflow={node.data.isCashflow}
              handleRevenueTypeChange={handleRevenueTypeChange}
              trackingCategoryOptions={node.data.trackingCategoryOptions}
              handleTrackingCategoryOptionsChange={
                canUseTrackingCategory ? handleTrackingCategoryOptionsChange : undefined
              }
              window={window}
              handleUpdateWindow={handleUpdateWindow}
            />

            {(handleRename || hasDecimals || hasStyles) && (
              <Divider direction="vertical" height="20px" noMargin color={theme.palette.fieldGrey} />
            )}
            {handleRename && (
              <ReportEditorCustomName handleRename={handleRename} customName={customName} defaultName={accountTitle} />
            )}
            {hasDecimals && (
              <ReportEditorUpdateDecimals decimals={decimals} handleUpdateDecimals={handleUpdateDecimals} />
            )}
            {hasStyles && <ReportEditorCustomRowStyles styles={styles} handleUpdateRowStyles={handleUpdateRowStyles} />}

            <Divider direction="vertical" height="20px" noMargin color={theme.palette.fieldGrey} />
            <ReportEditorDelete handleDelete={handleDelete} />
          </>
        )}
      </StyledActions>
      <Typography color="secondary" weight={styles?.bold ? 'semibold' : 'regular'}>
        {formatValue(values[0], currencyCode || default_currency_code, dataType, decimals)}
      </Typography>
    </StyledContainer>
  );
};

export default ReportEditorAccountRow;
