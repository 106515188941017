import { MetricSchema2DataType } from 'scalexp/utils/metrics/metricSchema2';

import { Column } from '../../../store/state/columnsPresets/types';

export const DISPLAY_COLUMNS: Column[] = [
  {
    id: '1',
    type: 'AGGREGATION',
    name: 'First month',
    aggregate_over: { value_basis: 'ACTUAL', period: 'MONTH', relative_month_offset: -1 },
  },
  {
    id: '2',
    type: 'AGGREGATION',
    name: 'Second month',
    aggregate_over: { value_basis: 'ACTUAL', period: 'MONTH', relative_month_offset: 0 },
  },
];

export const formatValue = (
  value: number | null,
  currency: string,
  dataType: MetricSchema2DataType = 'monetary',
  decimals = 0,
) => {
  let displayValue: string = '';
  if (value == null || typeof value === 'string') {
    displayValue = value || '-';
  } else {
    switch (dataType) {
      case 'numerical':
        displayValue = new Intl.NumberFormat('en-GB', {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(value);
        break;
      case 'percentage':
        displayValue = new Intl.NumberFormat('en-GB', {
          style: 'percent',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(value);
        break;
      case 'monetary':
        displayValue = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
          currencyDisplay: currency === 'USD' ? 'narrowSymbol' : 'symbol',
          currency,
        }).format(value);
        break;
    }
    if (currency === 'SGD') {
      displayValue = displayValue.replace('SGD', 'SG$');
    } else if (currency === 'ZAR') {
      displayValue = displayValue.replace('ZAR', 'R');
    }
  }

  return displayValue;
};

export interface PickerOption {
  label: string;
  value: string;
  group: string;
  name: string;
  id: string;
  role: string;
  source_id: string;
}
