import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectExchangeRates } from './selectors';
import { ExchangeRates } from './types';

export const ORGANISATIONS_EXCHANGE_RATES_LOAD = '[exchangeRates] LOAD';

export type ExchangeRatesActions = PromiseAction<
  typeof ORGANISATIONS_EXCHANGE_RATES_LOAD,
  ExchangeRates,
  { organisationId: number }
>;

type ExchangeRateResponse = {
  rates: ExchangeRates;
};

export const loadExchangeRatesAction = (organisationId: number): ThunkAction<ExchangeRatesActions> => async (
  dispatch: ThunkDispatch<ExchangeRatesActions>,
  getState,
) => {
  const state = getState();
  const exchangeRates = selectExchangeRates(state, organisationId);

  if (exchangeRates?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_EXCHANGE_RATES_LOAD,
        () =>
          http<ExchangeRates, ExchangeRateResponse>(
            `/api/v1/organisations/${organisationId}/exchange_rates/`,
            {},
            response => response.rates,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
