import React from 'react';

import { useUserContext } from '../UserContext';

export interface OnboardingContextProps {
  onboardingConfig: OnboardingConfigType;
  setOnboardingConfig: (config: OnboardingConfigType) => void;
}

export interface OnboardingOrganisation {
  organisationId: number;
  name: string;
  default_currency_code: string;
  name_short?: string;
  sector?: string;
  children: OnboardingOrganisation[];
}
export interface OnboardingConfigType {
  user: {
    first_name: string;
    last_name: string;
    email: string;
    useCases: string[];
    job_title: string;
    phone_number: string;
  };
  organisation: OnboardingOrganisation;
}

export const OnboardingContext = React.createContext<OnboardingContextProps | undefined>(undefined!);

const defaultConfig: OnboardingConfigType = {
  user: {
    first_name: '',
    last_name: '',
    email: 'mr.simth@gmail.com',
    useCases: [],
    job_title: '',
    phone_number: '',
  },
  organisation: {
    organisationId: 0,
    name: '',
    default_currency_code: '',
    children: [] as OnboardingOrganisation[],
  },
};

export const OnboardingProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { first_name, last_name, email, job_title, phone_number } = useUserContext();
  const [onboardingConfig, setOnboardingConfig] = React.useState({
    ...defaultConfig,
    user: {
      ...defaultConfig.user,
      first_name: first_name || '',
      last_name: last_name || '',
      email: email || '',
      job_title: job_title || '',
      phone_number: phone_number || '',
    },
  });

  return (
    <OnboardingContext.Provider value={{ onboardingConfig, setOnboardingConfig }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => {
  const value = React.useContext(OnboardingContext);

  if (!value) {
    throw new Error('OnboardingProvider not found!');
  }

  return value;
};
