import React, { HTMLAttributes } from 'react';
import { useHistory } from 'react-router-dom';
import { Notification } from 'rsuite';
import styled from 'styled-components';

import colors from '../../../colors';
import { useHasWriteAllPermission } from '../../../service/permissions';
import { useDeleteChart } from '../../../store/state/newDashboards/hooks';
import { theme } from '../../../theme';
import Divider from '../../atoms/Divider';
import IconButton from '../../atoms/IconButton';
import { useDashboardContext } from '../../contexts/NewDashboardContext';
import useActiveOrganisationId from '../../contexts/OrganisationContext/useActiveOrganisationId';
import { useChartData } from '../../organisms/DashboardChart/useChartData';
import { downloadChartAsPNG } from '../../organisms/MetricSchemaChart/downloadChart';
import { MetricDetails } from '../../organisms/MetricSchemaChart/types';
import DropDown from '../DropDown';

export interface DashboardChartOptionsProps {
  chartCardRef: React.RefObject<HTMLDivElement>;
  chartId: string;
  metrics: MetricDetails[];
  isKPIChart?: boolean;
}
export interface StyledDashboardChartOptionsProps extends HTMLAttributes<HTMLDivElement> {}
export const StyledDashboardChartOptions = styled.div<StyledDashboardChartOptionsProps>``;
const DashboardChartOptions: React.FC<DashboardChartOptionsProps> = ({
  metrics,
  chartCardRef,
  chartId,
  isKPIChart,
}) => {
  const history = useHistory();
  const { chartSetId, isEditMode, setSelectedDPMetric } = useDashboardContext();
  const organisationId = useActiveOrganisationId();
  const deleteChart = useDeleteChart(organisationId);
  const { chartData } = useChartData(chartId);
  const hasWriteAllPermission = useHasWriteAllPermission();
  const handleDeleteCard = async () => {
    if (chartSetId) {
      try {
        await deleteChart(chartSetId, chartId);
        Notification.success({ title: 'Chart deleted successfully' });
      } catch (error) {
        Notification.error({ title: "Couldn't delete chart" });
      }
    }
  };

  return (
    <StyledDashboardChartOptions>
      <DropDown
        customButton={
          <IconButton
            className={chartData?.y === 0 ? 'dashboard_edit_chart_button' : ''}
            name="more_horiz"
            color={colors.granite}
          />
        }
        placement="bottomEnd"
        className="hide-on-download"
      >
        {hasWriteAllPermission && (
          <DropDown.Item
            icon="settings"
            onClick={() =>
              history.push(
                `/charts-editor/${chartId}/edit?dashboardId=${chartSetId}&isEditMode=${isEditMode ? '1' : '0'}`,
              )
            }
          >
            Configure {isKPIChart ? 'KPI' : 'Chart'}
          </DropDown.Item>
        )}
        <DropDown.Item icon="calculate" onClick={() => setSelectedDPMetric(metrics)}>
          Chart Data
        </DropDown.Item>
        <DropDown.Item icon="download" onClick={() => downloadChartAsPNG(chartCardRef)}>
          Download as PNG
        </DropDown.Item>
        <Divider />
        <DropDown.Item icon="delete" color={theme.palette.danger.light} onClick={handleDeleteCard}>
          Delete Chart
        </DropDown.Item>
      </DropDown>
    </StyledDashboardChartOptions>
  );
};
export default DashboardChartOptions;
