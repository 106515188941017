import dayjs from 'dayjs';

import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectDeferredSeriesByAccount } from './selectors';
import { DeferredSeriesByAccount } from './types';

export const ORGANISATIONS_DEFERRED_SERIES_BY_ACCOUNT_LOAD = '[deferredSeriesByAccount] LOAD';

export type DeferredSeriesByAccountActions = PromiseAction<
  typeof ORGANISATIONS_DEFERRED_SERIES_BY_ACCOUNT_LOAD,
  DeferredSeriesByAccount,
  { organisationId: number }
>;

type DeferredDataResponse = DeferredSeriesByAccount;

export const loadDeferredSeriesByAccountAction = (
  organisationId: number,
  fromDate: Date,
  toDate: Date,
  accountsFilter: string[],
): ThunkAction<DeferredSeriesByAccountActions> => async (
  dispatch: ThunkDispatch<DeferredSeriesByAccountActions>,
  getState,
) => {
  const state = getState();
  const deferredSeriesByAccount = selectDeferredSeriesByAccount(state, organisationId);

  if (deferredSeriesByAccount?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_DEFERRED_SERIES_BY_ACCOUNT_LOAD,
        () =>
          http<DeferredSeriesByAccount, DeferredDataResponse>(
            `/api/v1/organisations/${organisationId}/invoices/deferred/by_account`,
            {
              body: {
                start: dayjs(fromDate).format('YYYY-MM'),
                end: dayjs(toDate).format('YYYY-MM'),
                account_ids: accountsFilter,
                include_issued: true,
                include_pending: false,
                include_renewals: false,
              },
            },
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
