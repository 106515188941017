import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import Typography from '../../atoms/Typography';
import Row from '../../layout/Row';

type DataItem<Value> = {
  label: string;
  value: Value;
};
export interface SwitcherProps<Value> {
  size?: 'small' | 'medium';
  width?: string;
  data: DataItem<Value>[];
  value: Value;
  defaultColor?: string;
  activeColor?: string;
  onChange: (value: Value) => void;
}

const StyledContainer = styled.div<{ width?: string }>`
  ${({ width }) => (width ? `width: ${width}` : '')};
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.silver};
  padding: 2px 2px;
`;

const StyledItem = styled.div<Partial<SwitcherProps<unknown>> & { selected?: boolean }>`
  ${({ theme, selected, activeColor, defaultColor }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${theme.spacing(4)} ${theme.spacing(1)};
    line-height: 18px;
    flex: 1;
    background-color: ${selected ? activeColor ?? theme.palette.primary.main : defaultColor ?? theme.palette.silver};
    color: ${selected ? theme.palette.white : theme.palette.midnight};
    border-radius: 3px;
    cursor: pointer;
    white-space: nowrap;

  `}

  ${({ size, theme }) => {
    switch (size) {
      case 'small': {
        return `
          height: ${theme.spacing(4)};
          font-size: ${theme.font.size.small};
          line-height: ${theme.font.lineheight.tiny};
          border-radius: ${theme.spacing(0.5)}
        `;
      }
      case 'medium': {
        return `
          padding: ${theme.spacing(1.7)} ${theme.spacing(3)};
          font-size: ${theme.font.size.tiny};
          line-height: ${theme.font.lineheight.tiny};
          border-radius: ${theme.spacing(1)}
        `;
      }
      default:
        return '';
    }
  }};
`;

function Switcher<Value = string>({
  size = 'medium',
  width,
  data,
  value,
  defaultColor,
  activeColor,
  onChange,
}: PropsWithChildren<SwitcherProps<Value>>) {
  const handleChange = (selectedValue: Value) => {
    onChange(selectedValue);
  };

  return (
    <StyledContainer width={width}>
      {data.map((item, index) => {
        const isFixedPeriod = item.value === 'Fixed Period';
        const isSelected = value === item.value;
        return (
          <StyledItem
            size={size}
            selected={value === item.value}
            defaultColor={defaultColor}
            activeColor={activeColor}
            key={index.toString()}
            onClick={() => handleChange(item.value)}
          >
            {item.label}
            {isFixedPeriod && (
              <Row>
                <Typography weight="bold" color={isSelected ? 'white' : 'black'}>
                  NEW
                </Typography>
              </Row>
            )}
          </StyledItem>
        );
      })}
    </StyledContainer>
  );
}
export default Switcher;
