import * as React from 'react';
import styled from 'styled-components';

import { useChartConfig } from '../../../store/state/charts/hooks';
import Typography from '../../atoms/Typography';
import useActiveOrganisationId from '../../contexts/OrganisationContext/useActiveOrganisationId';

export interface ChartConfigWidgetProps {
  chartConfigId: number;
  name?: string;
}

const StyledItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.sizing(3)} ${theme.sizing(4)}`};
  cursor: pointer;
`;

const DescriptionContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  & > span:not(.material-icons-round) {
    white-space: normal;
  }
`;

export const StyledChartImageContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledChartImage = styled.img`
  height: 50px;
`;

const ChartConfigWidget: React.FC<React.PropsWithChildren<ChartConfigWidgetProps>> = ({ chartConfigId, name }) => {
  const organisationId = useActiveOrganisationId();
  const chartConfig = useChartConfig(organisationId, chartConfigId);

  if (chartConfig.status !== 'success' || !chartConfig.value) {
    return null;
  }

  const { title, description } = chartConfig.value;
  const chartImage =
    chartConfig.value.chart_type === 'Pie Chart'
      ? 'pie_chart'
      : chartConfig.value.chart_type === 'Waterfall'
      ? 'waterfall_chart'
      : 'time_chart';

  return (
    <StyledItem>
      <DescriptionContainer>
        <Typography size="tiny" weight="bold">
          {name || title}
        </Typography>
        <Typography size="tiny" weight="regular">
          {description}
        </Typography>
      </DescriptionContainer>
      <StyledChartImageContainer>
        <StyledChartImage src={` /images/charts/${chartImage}.png`} alt="chart" />
      </StyledChartImageContainer>
    </StyledItem>
  );
};

export default ChartConfigWidget;
