export const PAndLBalanceSheetNativeMetrics = [
  // Profit and Loss Native Metrics:
  'DEPRECIATION_AMORTISATION',
  'REVENUE_TOTAL',
  'COST_OF_SALES',
  'NON_OPERATING_INCOME',
  'NON_OPERATING_EXPENSES',
  'OPERATING_EXPENSES',
  // Balance Sheet Native Metrics:
  'ACCOUNTS_PAYABLE',
  'ACCOUNTS_RECEIVABLE',
  'CASH_IN_BANK',
  'OTHER_CURRENT_ASSETS',
  'OTHER_CURRENT_LIABILITIES',
  'DEBT',
  'EQUITY',
  'FIXED_ASSETS',
  'NON_CURRENT_ASSETS',
  'NON_CURRENT_LIABILITIES',
  'RETAINED_EARNINGS',
];
