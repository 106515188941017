import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import { useHistory } from 'react-router-dom';
import { Notification } from 'rsuite';
import { OnboardingConfigType, useOnboardingContext } from 'scalexp/components/contexts/OnboardingContext';
import { useUserContext } from 'scalexp/components/contexts/UserContext';
import UserProfileForm from 'scalexp/components/forms/UserProfileForm';
import OnboardingPageTitle from 'scalexp/components/molecules/OnboardingPageTitle';
import useUpdateUserSettings from 'scalexp/pages/settings/general/useUpdateUserSettings';
import styled from 'styled-components';

const OnboardingUserInfoContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing(16)};
`;

const OnboardingUserInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { onboardingConfig, setOnboardingConfig } = useOnboardingContext();
  const updateUserSettings = useUpdateUserSettings();
  const user = useUserContext();
  const handleSubmit = async (values: Omit<OnboardingConfigType['user'], 'useCases'>) => {
    setIsLoading(true);

    // @ts-ignore
    window.initializeUserGuiding();
    // @ts-ignore
    window.userGuiding.identify(user.user_id, {
      email: values.email,
      name: `${values.first_name} ${values.last_name}`,
      job_title: values.job_title,
      phone_number: values.phone_number,
    });

    try {
      await updateUserSettings({
        first_name: values.first_name,
        last_name: values.last_name,
        job_title: values.job_title,
        phone_number: values.phone_number,
      });
      setOnboardingConfig({
        ...onboardingConfig,
        user: {
          ...onboardingConfig.user,
          ...values,
        },
      });
      history.push('/onboarding/organisation-info');
    } catch (err) {
      console.log(err);
      Notification.error({ title: 'Failed to Save' });
    }

    setIsLoading(false);
  };

  return (
    <OnboardingUserInfoContainer>
      <OnboardingPageTitle title="Tell us a little about yourself" subtitle="A few steps to get you set up." />
      <StyledContainer>
        <UserProfileForm
          showEmail
          handleSubmit={handleSubmit}
          defaultValues={onboardingConfig.user}
          isLoading={isLoading}
          width="454px"
          submitSize="large"
          inputSize="large"
          inputSpacing="xlarge"
        />
      </StyledContainer>
    </OnboardingUserInfoContainer>
  );
};

export default OnboardingUserInfo;
