import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export interface AlignerProps extends HTMLAttributes<HTMLDivElement> {
  direction?: 'horizontal' | 'vertical';
  vAlign?: 'center' | 'space-between' | 'flex-end' | 'flex-start' | 'stretch';
  width?: number | string;
  height?: number | string;
  hAlign?: 'center' | 'space-between' | 'flex-end' | 'flex-start' | 'stretch';
  clickable?: boolean;
  wrap?: boolean;
  gap?: string;
  flex?: string;
  active?: boolean;
}

const AlignerStyled = styled.div<Omit<AlignerProps, 'wrap'> & { $wrap: AlignerProps['wrap'] }>`
  display: flex;
  ${({ $wrap }) => ($wrap ? 'flex-wrap: wrap;' : '')}
  ${({ gap }) => (gap ? `gap: ${gap};` : '')}
  ${({ flex }) => (flex ? `flex: 1;` : '')}
  ${({ theme, width }) => {
    if (width && typeof width === 'number') {
      return `
        width: ${theme.spacing(width)};
      `;
    }
    if (width && typeof width === 'string') {
      return `
        width: ${width};
      `;
    }
  }}
  ${({ theme, height }) => {
    if (height && typeof height === 'number') {
      return `
        height: ${theme.spacing(height)};
      `;
    }
    if (height && typeof height === 'string') {
      return `
        height: ${height};
      `;
    }
  }}
  ${({ direction, vAlign, hAlign }) => {
    if (direction === 'horizontal') {
      return `
        justify-content: ${hAlign};
        align-items: ${vAlign};
    `;
    }
    return `
        flex-direction: column;
        justify-content: ${vAlign};
        align-items: ${hAlign};
    `;
  }}
  ${({ theme, clickable }) =>
    clickable
      ? `
        &:hover {
          background: ${theme.palette.primary.offwhite};
          cursor: pointer;

        }
        &:hover span {
          color: ${theme.palette.primary.main};
        }
      `
      : ''}

      ${({ theme, active }) =>
    active
      ? `
          background: ${theme.palette.primary.offwhite};
          cursor: pointer;
          & span {
            color: ${theme.palette.primary.main};
          }
        `
      : ''}
`;

const Aligner: React.FC<React.PropsWithChildren<AlignerProps>> = ({
  direction = 'horizontal',
  vAlign = 'center',
  hAlign = 'center',
  width,
  height,
  wrap,
  ...other
}) => {
  return (
    <AlignerStyled
      height={height}
      width={width}
      vAlign={vAlign}
      hAlign={hAlign}
      direction={direction}
      $wrap={wrap}
      {...other}
    />
  );
};

export default Aligner;
