import _ from 'lodash';
import React, { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

import Input from '../../atoms/Input';
import Typography from '../../atoms/Typography';
import Aligner from '../../layout/Aligner';
import Column from '../../layout/Column';
import Spacer from '../../layout/Spacer';

export interface FormFieldInputProps extends InputHTMLAttributes<HTMLInputElement> {
  width?: string;
  type: string;
  label?: string;
  hint?: string;
  rules?: RegisterOptions;
  hideErrorMessage?: boolean;
  customSize?: 'small' | 'medium' | 'large';
  suffix?: string;
}
const FormFieldInput: React.FC<React.PropsWithChildren<FormFieldInputProps>> = ({
  children,
  label,
  rules = {},
  hint,
  hideErrorMessage = false,
  width,
  min = '1970-01-01',
  max = '2040-12-31',
  name,
  ...inputProps
}) => {
  const methods = useFormContext();
  const isCheckboxOrRadio = ['checkbox', 'radio'].includes(inputProps?.type);
  if (!methods) {
    throw new Error('FormFieldInput should only be used within a Form component');
  }
  let {
    register,
    formState: { errors },
  } = methods;
  let { [name ?? '']: error } = errors;
  if (name?.includes('.')) {
    error = _.get(errors, name);
  }
  return (
    <Column width={width} spacing="small">
      {/** @ts-ignore this can be fixed once we upgrade to react-hook-form v7 **/}
      {!isCheckboxOrRadio ? (
        <Input
          {...register(name!, { required: true })}
          width={width}
          label={label}
          hasError={!!error}
          min={min}
          max={max}
          {...inputProps}
        />
      ) : (
        <Aligner hAlign="flex-start">
          <Input hasError={!!error} {...inputProps} {...register(name!, { required: true })} />
          <Spacer margin={{ left: 2 }} display="inline">
            <Typography size={'small'} color={'secondary'}>
              {label}
            </Typography>
          </Spacer>
        </Aligner>
      )}

      {rules.required && (
        <Typography size={'small'} color={'secondary'}>
          Required
        </Typography>
      )}

      {error && !hideErrorMessage && (
        <Typography size="small" color="danger">
          {error?.message as string}
        </Typography>
      )}
    </Column>
  );
};

export default FormFieldInput;
