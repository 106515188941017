import produce from 'immer';

import {
  budgetSetsActions,
  ORGANISATIONS_BUDGETSETS_DELETE,
  ORGANISATIONS_BUDGETSETS_LOAD,
  ORGANISATIONS_BUDGETSETS_RENAME,
} from './types';
import { OrganisationBudgetSets } from './types';

const budgetsets = (prevState: OrganisationBudgetSets = {}, action: budgetSetsActions): OrganisationBudgetSets => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_BUDGETSETS_LOAD:
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload,
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }

      case ORGANISATIONS_BUDGETSETS_RENAME: {
        const { organisationId, budgetsetId, budgetsetName } = action.params;
        switch (action.status) {
          case 'pending':
            if (state[organisationId].value) {
              state[organisationId].value = state[organisationId]?.value?.map(budget => {
                if (budget.id === budgetsetId) {
                  return {
                    ...budget,
                    name: budgetsetName,
                  };
                }
                return budget;
              });
            }
            return;
          default:
            return state;
        }
      }

      case ORGANISATIONS_BUDGETSETS_DELETE: {
        const { organisationId, budgetsetId } = action.params;
        switch (action.status) {
          case 'pending':
            if (state[organisationId].value) {
              state[organisationId].value = state[organisationId]?.value?.filter(budget => budget.id !== budgetsetId);
            }
            return;
          default:
            return state;
        }
      }
      default:
        return state;
    }
  });
};

export default budgetsets;
