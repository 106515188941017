import html2canvas from 'html2canvas';
import { RefObject } from 'react';

export const downloadChartAsPNG = (chartContainerRef: RefObject<HTMLDivElement>) => {
  const chartContainer = chartContainerRef.current;
  const chart = chartContainerRef.current?.childNodes?.[0] as HTMLDivElement | null;
  if (!chartContainer || !chart) {
    return;
  }

  const hideChartElements = (chartContainer: HTMLDivElement) => {
    let scrollbar = chartContainer.querySelector(`[role="scrollbar"]`) as HTMLDivElement | null;
    if (scrollbar) {
      scrollbar.style.opacity = '0';
    }

    let itemsToHide = chartContainer.querySelectorAll(`.hide-on-download`) as NodeListOf<HTMLElement>;
    for (const item of itemsToHide) {
      item.style.opacity = '0';
    }

    return { scrollbar, itemsToHide };
  };

  const showChartElements = (chartContainer: HTMLDivElement, itemsToHide: NodeListOf<HTMLElement>) => {
    if (scrollbar) {
      scrollbar.style.opacity = '1';
    }
    for (const item of itemsToHide) {
      item.style.opacity = '1';
    }
  };

  //updating the style before the clone

  const { scrollbar, itemsToHide } = hideChartElements(chartContainer);
  window.scrollTo(0, 0);

  html2canvas(chart, {
    scrollX: 0,
    scrollY: 0,
    scale: 5,
    onclone: (document: Document, element: Element) => {
      element.querySelectorAll('tspan').forEach(e => {
        let existingStyle = e.getAttribute('style') || '';
        e.setAttribute('style', existingStyle + ';fill:#4B5D78;font-family: Inter, sans-serif');
      });
      element.querySelectorAll('tspan').forEach(tspan => {
        tspan.style.fontWeight = '600';
        tspan.style.fontSize = '14px';
      });
    },
  }).then((img: HTMLCanvasElement) => {
    let data = img.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    let link = document.createElement('a');
    link.href = data;
    link.download = 'chart.png';
    link.click();
    showChartElements(chartContainer, itemsToHide);
  });
};
