import { Dayjs } from 'dayjs';

const generateDateRanges = (start: Dayjs, end: Dayjs, format = 'YYYY-MM-DD'): string[] => {
  const range: string[] = [];
  let month = end;
  range.push(month.format(format));

  while (month.isAfter(start)) {
    month = month.subtract(1, 'month');
    range.push(month.format(format));
  }

  return range;
};

export { generateDateRanges };
