import {
  MetricSchema2Complex,
  MetricSchema2Data,
  MetricSchema2DataSource,
  MetricSchema2Decimals,
  MetricSchema2Nodes,
  MetricSchema2Simple,
} from './metricSchema2';

export const recursiveRemoveIds = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(recursiveRemoveIds);
  }

  const newObj = { ...obj };
  if (newObj.id) {
    delete newObj.id;
  }
  if (newObj.data?.id) {
    delete newObj.data.id;
  }
  if (newObj.nodes) {
    return {
      ...newObj,
      nodes: newObj.nodes.map(recursiveRemoveIds),
    };
  } else {
    return newObj;
  }
};

export const getSimpleMetricSchemaFromNode = <T extends MetricSchema2DataSource = MetricSchema2DataSource>(
  node: MetricSchema2Data<T>,
  decimals?: MetricSchema2Decimals,
): MetricSchema2Simple<T> => {
  return {
    schemaType: 'simple',
    nodes: [node],
    decimals: decimals ?? 0,
  };
};

export const getComplexMetricSchemaFromNode = (
  node: MetricSchema2Nodes,
  complexMetricSchemaParams?: Omit<MetricSchema2Complex, 'schemaType' | 'nodes'>,
): MetricSchema2Complex => {
  const defaultComplexMetricSchemaParams: Omit<MetricSchema2Complex, 'schemaType' | 'nodes'> = {
    decimals: 0,
    dataType: 'monetary',
    impact: 'neutral',
    aggregation: 'sum',
  };

  return {
    ...(complexMetricSchemaParams ? complexMetricSchemaParams : defaultComplexMetricSchemaParams),
    schemaType: 'complex',
    nodes: node.nodes,
  };
};
