import { promiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectDashboards } from './selectors';
import {
  CreateDashboardActionResult,
  CreateDashboardResponse,
  Dashboard,
  DashboardsActions,
  Dashboards,
  DeleteDashboardActionResult,
  DeleteDashboardResponse,
  LoadDashboardsResponse,
  UpdateDashboardActionResult,
  UpdateDashboardResponse,
  DashboardChartCard,
} from './types';

export const ACTION_DASHBOARDS_LOAD = '[dashboards-v2] LOAD';
export const ACTION_DASHBOARDS_CREATE = '[dashboards-v2] CREATE';
export const ACTION_DASHBOARDS_DELETE = '[dashboards-v2] DELETE';
export const ACTION_DASHBOARDS_UPDATE = '[dashboards-v2] UPDATE';
export const ACTION_DASHBOARDS_UPDATE_DETAILS = '[dashboards-v2] UPDATE_DETAILS';
export const ACTION_DASHBOARDS_CREATE_CHART = '[dashboards-v2] CREATE_CHART';
export const ACTION_DASHBOARDS_UPDATE_CHART = '[dashboards-v2] UPDATE_CHART';
export const ACTION_DASHBOARDS_DELETE_CHART = '[dashboards-v2] DELETE_CHART';

export const loadDashboardsAction = (organisationId: number): ThunkAction<DashboardsActions> => async (
  dispatch: ThunkDispatch<DashboardsActions>,
  getState,
) => {
  const state = getState();
  const dashboards = selectDashboards(state, organisationId);

  if (dashboards?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ACTION_DASHBOARDS_LOAD,
        () =>
          http<Dashboards, LoadDashboardsResponse>(
            `/api/v1/organisations/${organisationId}/dashboards/`,
            {},
            response => response.dashboards,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const createDashboardAction = (
  organisationId: number,
  dashboard: Dashboard,
): ThunkAction<DashboardsActions, Promise<CreateDashboardActionResult>> => async (
  dispatch: ThunkDispatch<DashboardsActions>,
) => {
  try {
    const createdDashboard = await dispatch(
      promiseAction(
        ACTION_DASHBOARDS_CREATE,
        () =>
          http<Dashboard, CreateDashboardResponse>(`/api/v1/organisations/${organisationId}/dashboards/`, {
            method: 'POST',
            body: {
              name: dashboard.name,
              pinned: dashboard.pinned,
              cards: dashboard.cards,
            },
          }),
        {
          organisationId,
        },
      ),
    );
    return {
      success: true,
      dashboard: createdDashboard,
    };
  } catch (e) {
    console.log('Error dispatching action', e);

    return {
      success: false,
    };
  }
};

export const updateDashboardAction = (
  organisationId: number,
  dashboard: Dashboard,
): ThunkAction<DashboardsActions, Promise<UpdateDashboardActionResult>> => async (
  dispatch: ThunkDispatch<DashboardsActions>,
  getState,
) => {
  const state = getState();
  const dashboards = selectDashboards(state, organisationId);

  if (dashboards?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const updatedDashboard = await dispatch(
      promiseAction(
        ACTION_DASHBOARDS_UPDATE,
        () =>
          http<Dashboard, UpdateDashboardResponse>(
            `/api/v1/organisations/${organisationId}/dashboards/${dashboard.id}`,
            {
              method: 'PUT',
              body: dashboard,
            },
          ),
        {
          organisationId,
          dashboard,
        },
      ),
    );
    return {
      success: true,
      dashboard: updatedDashboard,
    };
  } catch (e) {
    console.log('Error dispatching action', e);

    return {
      success: false,
    };
  }
};

export const updateDashboardDetailsAction = (
  organisationId: number,
  dashboard: Dashboard,
): ThunkAction<DashboardsActions, Promise<UpdateDashboardActionResult>> => async (
  dispatch: ThunkDispatch<DashboardsActions>,
  getState,
) => {
  const state = getState();
  const dashboards = selectDashboards(state, organisationId);

  if (dashboards?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const updatedDashboard = await dispatch(
      promiseAction(
        ACTION_DASHBOARDS_UPDATE_DETAILS,
        () =>
          http<Dashboard, UpdateDashboardResponse>(
            `/api/v1/organisations/${organisationId}/dashboards/${dashboard.id}`,
            {
              method: 'PUT',
              body: dashboard,
            },
          ),
        {
          organisationId,
          dashboardId: dashboard.id,
        },
      ),
    );
    return {
      success: true,
      dashboard: updatedDashboard,
    };
  } catch (e) {
    console.log('Error dispatching action', e);

    return {
      success: false,
    };
  }
};

export const deleteDashboardAction = (
  organisationId: number,
  dashboardId: number,
): ThunkAction<DashboardsActions, Promise<DeleteDashboardActionResult>> => async (
  dispatch: ThunkDispatch<DashboardsActions>,
  getState,
) => {
  const state = getState();
  const dashboards = selectDashboards(state, organisationId);

  if (dashboards?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    await dispatch(
      promiseAction(
        ACTION_DASHBOARDS_DELETE,
        () =>
          http<Dashboard, DeleteDashboardResponse>(
            `/api/v1/organisations/${organisationId}/dashboards/${dashboardId}`,
            {
              method: 'DELETE',
            },
          ),
        {
          organisationId,
          dashboardId,
        },
      ),
    );
    return {
      success: true,
    };
  } catch (e) {
    console.log('Error dispatching action', e);

    return {
      success: false,
    };
  }
};

export const loadDashboardAction = (organisationId: number): ThunkAction<DashboardsActions> => async (
  dispatch: ThunkDispatch<DashboardsActions>,
  getState,
) => {
  const state = getState();
  const dashboards = selectDashboards(state, organisationId);

  if (dashboards?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ACTION_DASHBOARDS_LOAD,
        () => http<Dashboard, Dashboard>(`/api/v1/organisations/${organisationId}/chartsets`, {}, response => response),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const createChartAction = (
  organisationId: number,
  dashboardId: number,
  card: DashboardChartCard,
  // @ts-ignore
): ThunkAction<DashboardsActions> => async (dispatch: ThunkDispatch<DashboardsActions>) => {
  try {
    const chart = await dispatch(
      promiseAction(
        ACTION_DASHBOARDS_CREATE_CHART,
        () =>
          http<DashboardChartCard, DashboardChartCard>(
            `/api/v1/organisations/${organisationId}/dashboards/${dashboardId}`,
            {
              method: 'POST',
              body: card,
            },
          ),
        {
          organisationId,
          dashboardId,
        },
      ),
    );

    return {
      success: true,
      chart: chart,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
    return {
      success: false,
      error: e,
    };
  }
};

export const updateChartAction = (
  organisationId: number,
  dashboardId: number,
  chartId: string,
  chart: DashboardChartCard,
): ThunkAction<DashboardsActions> => async (dispatch: ThunkDispatch<DashboardsActions>) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_DASHBOARDS_UPDATE_CHART,
        () =>
          http<DashboardChartCard, DashboardChartCard>(`/api/v1/organisations/${organisationId}/charts/${chartId}`, {
            method: 'POST',
            body: chart,
          }),
        {
          chartId,
          organisationId,
          dashboardId,
          chart,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const deleteChartAction = (
  organisationId: number,
  dashboardId: number,
  chartId: string,
): ThunkAction<DashboardsActions> => async (dispatch: ThunkDispatch<DashboardsActions>) => {
  try {
    await dispatch(
      promiseAction(
        ACTION_DASHBOARDS_DELETE_CHART,
        () =>
          http<DashboardChartCard, DashboardChartCard>(`/api/v1/organisations/${organisationId}/charts/${chartId}`, {
            method: 'DELETE',
          }),
        {
          organisationId,
          dashboardId,
          chartId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
