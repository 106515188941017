import React, { useRef, useState } from 'react';
import { PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Notification } from 'rsuite';
import styled from 'styled-components';

import { API_URL } from '../../../config';
import { useUpdateOrganisationLogo } from '../../../store/state/organisations/hooks';
import fetcher from '../../../utils/fetcher';
import Typography from '../../atoms/Typography';
import useActiveOrganisation from '../../contexts/OrganisationContext/useActiveOrganisation';
import Column from '../../layout/Column';
import Card from '../../molecules/Card';
import FileInput from '../../molecules/FileInput';
import LogoCropModal from './LogoCropModal';
import OrganisationBrandColorsCard from './OrganisationBrandColors';
import { canvasPreview, getFileFromCanvas } from './croppedImageUtils';

export interface OrganisationLogoUploadCardProps {}

const StyledOrganisationLogoUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(6)} ${theme.spacing(5)} ${theme.spacing(6)}`};

  &:first-of-type {
    padding-top: ${({ theme }) => theme.spacing(5)};
  }
`;

const useLogoUpload = (organisationId: number) => {
  const [isUploading, setIsUploading] = useState(false);
  const updateOrganisationLogo = useUpdateOrganisationLogo(organisationId);
  const handleLogoUpload = async (file: File) => {
    setIsUploading(true);
    let form_data = new FormData();
    form_data.append('logo', file);
    try {
      const response = await fetcher(`/api/v1/organisations/${organisationId}/settings/logo`, {
        method: 'PUT',
        body: form_data,
      });
      if (response.logo) {
        Notification.success({
          title: 'Logo uploaded successfully',
          placement: 'bottomEnd',
        });
        updateOrganisationLogo(response.logo);
      }
    } catch (error) {
      Notification.error({
        title: 'Logo upload failed',
        placement: 'bottomEnd',
      });
      console.error('Logo upload failed', error);
    } finally {
      setIsUploading(false);
    }
  };

  return { isUploading, handleLogoUpload };
};

function getAbsoluteLogoURL(logo_url: string) {
  if (logo_url.startsWith('http')) {
    return logo_url;
  }
  return `${API_URL}${logo_url}`;
}

const ImageContainer = styled.div`
  width: 350px;
  height: 150px;
  & > img {
    display: block;
    width: auto;
    height: auto;
    max-width: 350px;
    max-height: 150px;
  }
`;

const DEFAULT_LOGO_URL = '/images/image_placeholder.svg';

const OrganisationLogoUploadCard: React.FC<React.PropsWithChildren<OrganisationLogoUploadCardProps>> = () => {
  const organisation = useActiveOrganisation();
  const organisationId = organisation.organisation_id;
  const currentOrganisationLogo = organisation.logo_url ? getAbsoluteLogoURL(organisation.logo_url) : DEFAULT_LOGO_URL;
  const [logoUrl, setLogoURL] = useState(currentOrganisationLogo);
  const previewImageRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<PixelCrop>();
  const [showCropModal, setShowCropModal] = useState(false);
  const { isUploading, handleLogoUpload } = useLogoUpload(organisationId);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const handleFileChange = async (file: File) => {
    const fileSizeLimit = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > fileSizeLimit) {
      // Notify the user that their file is too large
      Notification.error({
        title: 'File Size Exceeded, Please ensure your file is less than 2MB.',
        placement: 'bottomEnd',
      });
      return;
    }
    if (previewImageRef.current) {
      const url = URL.createObjectURL(file);
      previewImageRef.current.src = url;
      setLogoURL(url);
      setFileToUpload(file);
      setShowCropModal(true);
    }
  };

  const handleCroppedImageUpload = async (image: HTMLImageElement | null) => {
    if (image && crop) {
      const canvas = document.createElement('canvas');
      canvas.height = image.height;
      canvas.width = image.width;

      await canvasPreview(image, canvas, crop);
      const file = await getFileFromCanvas(canvas);
      const url = URL.createObjectURL(file);
      setLogoURL(url);
      setShowCropModal(false);
      canvas.remove();
      await handleLogoUpload(file);
      setFileToUpload(null);
    }
  };

  const resetCompanyLogo = async () => {
    setFileToUpload(null);
    setLogoURL(currentOrganisationLogo);
  };
  return (
    <Card width="760px" height="420px">
      <StyledOrganisationLogoUploadContainer>
        <Typography size="medium" weight="semibold">
          {organisation.logo_url ? 'Change Company Logo' : 'Add Your Company Logo'}
        </Typography>

        <Column hAlign="flex-start" vAlign="space-between">
          <ImageContainer>
            <img ref={previewImageRef} crossOrigin="anonymous" src={logoUrl} alt="Organisation Logo" />
          </ImageContainer>
          <Column spacing="tiny">
            <FileInput
              accept="image/png, image/jpg, image/jpeg"
              icon="add"
              loading={isUploading}
              name="image-upload"
              onInputChange={handleFileChange}
            >
              {fileToUpload ? 'Change' : 'Select'} logo
            </FileInput>
            <Typography color="warning">Only png, jpg or jpeg files can be selected.</Typography>
          </Column>
        </Column>
        <LogoCropModal
          resetCompanyLogo={resetCompanyLogo}
          logoUrl={logoUrl}
          crop={crop}
          setCrop={setCrop}
          showCropModal={showCropModal}
          setShowCropModal={setShowCropModal}
          isUploading={isUploading}
          handleCroppedImageUpload={handleCroppedImageUpload}
        />
      </StyledOrganisationLogoUploadContainer>
      <OrganisationBrandColorsCard />
    </Card>
  );
};
export default OrganisationLogoUploadCard;
