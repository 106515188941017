import { SlideElementKey } from '../../../features/presentations/types';

export const ACTION_PRESENTATION_SET_ACTIVE_SLIDE = '[presentation-editor] SET_ACTIVE_SLIDE';
export const ACTION_PRESENTATION_SET_ACTIVE_ELEMENT_KEY = '[presentation-editor] SET_ACTIVE_ELEMENT';

export const setActiveSlide = (slideId: number | null) => ({
  type: ACTION_PRESENTATION_SET_ACTIVE_SLIDE,
  payload: slideId,
});

export const setActiveElementKey = (elementKey: SlideElementKey | null) => ({
  type: ACTION_PRESENTATION_SET_ACTIVE_ELEMENT_KEY,
  payload: elementKey,
});
