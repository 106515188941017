import styled, { css } from 'styled-components';

import { StyledLegendSeries } from '../../../features/chart/ChartLegendSeries';

const ShadowedChartCardContainer = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;
      background: ${theme.palette.white};
      height: 100%;
      width: 100%;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      box-shadow: ${theme.shadow.card};

      [role='figure'] {
        border-radius: 5px;
      }

      ${StyledLegendSeries} {
        padding: 2px 5px;
        font-size: 12px;
        line-height: 16px;
      }
    `;
  }}
`;

export default ShadowedChartCardContainer;
