const getDealTrackingSource = () => {
  // this logic is to get the custom tracking source from the cookie
  // the source cookie is set on the scalexp.com website by the scalexp.com WP website
  // eg. {"source":"(direct)","medium":"(none)","campaign":"(not set)"} for direct traffic
  // eg. {"source":"google","medium":"organic","campaign":"(not set)"} for organic traffic
  const getCookieValue = (name: string) => document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
  let customTrackingSource = {};
  try {
    customTrackingSource = JSON.parse(getCookieValue('__custom_source'));
  } catch (e) {
    console.error(e);
  }

  return customTrackingSource;
};

export { getDealTrackingSource };
