import React from 'react';
import { useHistory } from 'react-router-dom';
import { mutate } from 'swr';

import Notification from '../../../../components/atoms/Notification';
import useActiveOrganisationId from '../../../../components/contexts/OrganisationContext/useActiveOrganisationId';
import fetcher from '../../../../utils/fetcher';
import launchPopup from '../../../../utils/launchPopup';
import useConnection from '../../ConnectionsContext/useConnection';
import ConnectionCard from '../ConnectionCard';

export interface ZohoBooksConnectionCardProps {
  hide: 'connected' | 'notconnected';
}

const ZohoBooksConnectionCard: React.FC<React.PropsWithChildren<ZohoBooksConnectionCardProps>> = ({ hide }) => {
  const history = useHistory();

  const connectionName = 'zohobooks';
  const connection = useConnection(connectionName);
  const organisationId = useActiveOrganisationId();

  const handleConnect = async () => {
    try {
      const result: { url: string } = await fetcher(`/api/v1/organisations/${organisationId}/connections/codat`, {
        body: JSON.stringify({ name: connectionName }),
        method: 'POST',
      });

      launchPopup(result.url);
    } catch (err) {
      console.log(err);
    }
  };

  const handleConfigure = async () => {
    history.push(`/oauth/${connectionName}`);
  };

  const handleDisconnect = async () => {
    const result: { deleted: boolean } = await fetcher(`/api/v1/organisations/${organisationId}/connections/codat`, {
      body: JSON.stringify({ name: connectionName }),
      method: 'DELETE',
    });

    if (!result.deleted) {
      Notification.error({ title: 'Failed to disconnect' });
      return;
    }

    await mutate(`/api/v1/organisations/${organisationId}/connections/codat`);
  };

  if (connection.status === hide) {
    return null;
  }

  return (
    <ConnectionCard
      name={connectionName}
      logo={'/images/logos/zohobooks-banner.png'}
      handleConnect={handleConnect}
      handleConfigure={handleConfigure}
      handleDisconnect={handleDisconnect}
      error={connection.status === 'error' && connection.error}
    />
  );
};

export default ZohoBooksConnectionCard;
