import posthog from 'posthog-js';

import { getAccessToken } from './accessToken';

const authHeader = () => {
  const accessToken = getAccessToken();
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

const clearSession = () => {
  localStorage.clear();
  posthog.reset();
};

export { authHeader, clearSession };
