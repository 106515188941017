import { createContext, useContext, useEffect, useMemo } from 'react';
import { BLOCKED_PAGE_PATH } from 'scalexp/constants';
import { isRequireBlocking } from 'scalexp/pages/blocked';
import useSWR, { KeyedMutator } from 'swr';

import useActiveOrganisationId from '../OrganisationContext/useActiveOrganisationId';

interface TrialInfoContextProps {}
export interface TrialInfo {
  status: 'trial' | 'active' | 'expired' | 'cancelled' | 'late_payer' | 'trial_ended';
  started_on: string;
  has_trial_expired: boolean;
  num_of_trial_days_remaining: number;
}
type TrialInfoValue = {
  trialInfo: TrialInfo | undefined;
  mutate: KeyedMutator<TrialInfo>;
};
export const TrialInfoContext = createContext<TrialInfoValue>(undefined!);

export const TrialInfoProvider: React.FC<React.PropsWithChildren<TrialInfoContextProps>> = ({ children }) => {
  const organisationId = useActiveOrganisationId();
  const { data, mutate } = useSWR<TrialInfo>(`/api/v2/organisation/${organisationId}/plan_info`);
  const isBlocked = window.location.pathname !== BLOCKED_PAGE_PATH && !!data && isRequireBlocking(data.status);
  const value: TrialInfoValue = useMemo(() => ({ trialInfo: isBlocked ? undefined : data, mutate }), [
    data,
    mutate,
    isBlocked,
  ]);

  useEffect(() => {
    if (isBlocked) {
      window.location.href = BLOCKED_PAGE_PATH;
    }
  }, [isBlocked]);

  return <TrialInfoContext.Provider value={value}>{children}</TrialInfoContext.Provider>;
};

export const useTrialInfoContext = () => {
  const value = useContext(TrialInfoContext);

  if (!value) {
    throw new Error('TrialInfoProvider not found!');
  }

  return value;
};
