import { useAuth0 } from '@auth0/auth0-react';

const OrganisationInviteRedirect = () => {
  const { loginWithRedirect } = useAuth0();

  const params = new URLSearchParams(window.location.search);
  const organization = params.get('organization');
  const invitation = params.get('invitation');

  if (organization && invitation) {
    loginWithRedirect({
      invitation,
      organization,
    });
  }

  return null;
};

export default OrganisationInviteRedirect;
