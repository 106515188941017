import * as am4charts from '@amcharts/amcharts4/charts';
import React, { useEffect, useRef, useState } from 'react';
import { Loader } from 'rsuite';
import styled from 'styled-components';

import { useChartDataType } from '../../../components/contexts/NewEditChartContext/useChartDataType';
import Row from '../../../components/layout/Row';
import useCurrencySelection from '../../../components/molecules/CurrencySelect/useCurrencySelection';
import ChartLegend from '../ChartLegend';
import ChartTitle from '../ChartTitle';
import { getamWaterfallChartInstance } from './helpers';
import { WaterfallChart as IWaterfallChart } from './types';
import useWaterfallChartConfig from './useWaterfallChartConfig';

export type WaterfallChartProps = {
  config: IWaterfallChart;
  headerConfig?: React.ReactNode;
  rightContent?: React.ReactNode;
  parentCallback?: (chart?: am4charts.XYChart) => void;
};

export const StyledWaterfallContainer = styled.div`
  border-radius: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
`;

export const StyledFigureContainer = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  flex-grow: 1;
`;

const WaterfallChart: React.FC<WaterfallChartProps> = ({ config, parentCallback, headerConfig, rightContent }) => {
  const [chartState, setChartState] = useState<am4charts.XYChart | undefined>(undefined);
  const chartRef = useRef<HTMLDivElement>(null);
  const amChartRef = useRef<am4charts.XYChart | null>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { chartDataType } = useChartDataType(config);
  const chartConfig = useWaterfallChartConfig(config, chartDataType);
  const [currencyCode] = useCurrencySelection('currencyCode');
  const [chartReady, setChartReady] = useState(false);
  useEffect(() => {
    if (!chartConfig.value || chartReady) {
      return;
    }

    setChartReady(true);
    const cRef = chartRef.current;
    setTimeout(() => {
      const chart = getamWaterfallChartInstance(chartConfig.value, cRef!, chartDataType, currencyCode);
      amChartRef.current = chart;
      // The toolbar is not visible on the first render because the chart object is empty. So we add event to update the state and show the toolbar.
      chart.events.once('appeared', () => {
        setChartState(chart);
      });
      parentCallback?.(chart);
    }, 0);
  }, [chartConfig.value]);

  useEffect(() => {
    return () => {
      let chart = amChartRef.current;
      if (chart) {
        chart.dispose();
      }
    };
  }, []);
  return (
    <StyledWaterfallContainer className="chart-card" ref={chartContainerRef}>
      {!chartReady && <Loader backdrop center content="Loading..." />}
      <ChartTitle title={config.name} headerConfig={headerConfig} rightContent={rightContent} />
      <ChartLegend chart={chartState} />
      {chartConfig.value?.isEmpty ? (
        <Row height="100%" width="100%" vAlign="center" hAlign="center">
          No data available
        </Row>
      ) : (
        <StyledFigureContainer ref={chartRef} role="figure" aria-label="Waterfall chart" />
      )}
    </StyledWaterfallContainer>
  );
};

export default WaterfallChart;
