import { useCallback } from 'react';
import Notification from 'scalexp/components/atoms/Notification';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import fetcher from 'scalexp/utils/fetcher';

import { formatConnectionName } from '../../ConnectionsContext/helpers';
import { useSyncContext } from '../../sync-context/SyncContext';
import { ImportJob } from '../../sync-context/types';

const useSync = () => {
  const { jobs, setJobs } = useSyncContext();

  const { organisation_id, name: organisationName } = useActiveOrganisation();

  const handleSync = useCallback(
    async (connectionName: string) => {
      const formatedFinancialConnectionName = formatConnectionName(connectionName);

      try {
        let res;
        if (connectionName === 'xero') {
          res = await fetcher(`/api/v1/organisations/${organisation_id}/connections/xero/import`, {
            method: 'post',
          });
        } else if (connectionName === 'quickbooks') {
          res = await fetcher(`/api/v1/organisations/${organisation_id}/connections/quickbooks/import`, {
            method: 'post',
          });
        } else if (connectionName === 'netvisor') {
          res = await fetcher(`/api/v1/organisations/${organisation_id}/connections/netvisor/import`, {
            method: 'post',
          });
        } else if (connectionName === 'hubspot') {
          res = await fetcher(`/api/v1/organisations/${organisation_id}/connections/hubspot/import`, {
            method: 'post',
          });
        } else if (connectionName === 'salesforce') {
          res = await fetcher(`/api/v1/organisations/${organisation_id}/connections/salesforce/import`, {
            method: 'post',
          });
        } else if (connectionName === 'pipedrive') {
          res = await fetcher(`/api/v1/organisations/${organisation_id}/connections/pipedrive/import`, {
            method: 'post',
          });
        } else {
          res = await fetcher(`/api/v1/organisations/${organisation_id}/connections/codat/import`, {
            method: 'post',
          });
        }
        if (res && res.import_job_id) {
          const newJob: ImportJob = {
            task: 'IMPORT',
            id: res.import_job_id,
            name: connectionName,
            status: 'Running',
            completedSteps: 0,
            organisationName,
          };
          const newJobs = [...jobs, newJob];
          setJobs(newJobs);
        }
      } catch (err) {
        Notification.error({
          title: `Failed to load your data from ${formatedFinancialConnectionName}.`,
          // description: 'If the problem persists, please contact us at support@scalexp.com ',
          placement: 'bottomEnd',
          duration: 0,
        });
        console.log(err);
      }
    },
    [organisation_id, organisationName, jobs, setJobs],
  );

  return handleSync;
};

export default useSync;
