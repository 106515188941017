import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import Tooltip, { TooltipPlacement } from '../../molecules/Tooltip';
import Icon, { StyledIcon } from '../Icon';

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary';
  name: string;
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacement;
  active?: boolean;
  rounded?: boolean;
  display?: 'flex' | 'inline-flex';
}

export const StyledIconButton = styled.button<IconButtonProps>`
  display: ${({ display }) => display};
  align-items: center;
  justify-content: center;
  padding: 0;
  color: ${({ theme, color }) => (color ? color : theme.palette.granite)};

  // Size
  ${({ theme, size }) => {
    if (size === 'small') {
      return `
        width: ${theme.sizing(7)};
        height: ${theme.sizing(7)};
        border-radius: ${theme.sizing(1.5)};
      `;
    }
    if (size === 'medium') {
      return `
        width: ${theme.sizing(9)};
        height: ${theme.sizing(9)};
        border-radius: ${theme.sizing(2)};
      `;
    }
    if (size === 'large') {
      return `
        width: ${theme.sizing(10)};
        height: ${theme.sizing(10)};
        border-radius: ${theme.sizing(2)};
      `;
    }
  }}

  // Variant
  ${({ theme, variant }) => {
    if (variant === 'primary') {
      return `
        background-color: ${theme.palette.white};
        border: 1px solid ${theme.palette.silver};
        box-shadow: 0px 1px 3px rgba(172, 186, 224, 0.25);

        &:hover,
        &:focus {
          border: 1px solid ${theme.palette.midnight};
        }
      `;
    }

    if (variant === 'secondary') {
      return `
        border: none;
        box-shadow: none;
        background-color: unset;

        &:hover,
        &:focus {
          background-color: ${theme.palette.silver};
        }
      `;
    }
  }}

  ${({ theme, active }) => {
    if (active) {
      return `
      background: ${theme.palette.white};
      border: 1px solid ${theme.palette.primary.main};
      & ${StyledIcon} {
        color: ${theme.palette.primary.main};
      }
    `;
    }
  }}

  &:focus {
    outline: ${({ theme }) => theme.palette.primary.light} solid 2px;
    outline-offset: 2px;
  }

  &:disabled {
    opacity: 0.4;
  }
`;

const IconButton: React.FC<React.PropsWithChildren<IconButtonProps>> = ({
  size = 'small',
  variant = 'primary',
  name,
  active,
  tooltipText,
  display = 'flex',
  tooltipPlacement,
  rounded,
  ...rest
}) => {
  const iconSize = size === 'small' ? 4.5 : size === 'medium' ? 5 : size === 'large' ? 5.5 : 4.5;

  return (
    <Tooltip display={display} content={tooltipText} placement={tooltipPlacement}>
      <StyledIconButton
        display={display}
        role="button"
        name={name}
        variant={variant}
        active={active}
        size={size}
        {...rest}
      >
        <Icon
          name={name}
          color="inherit"
          outlined={name?.endsWith('outlined')}
          size={iconSize}
          marginRight={0}
          rounded={rounded}
        />
      </StyledIconButton>
    </Tooltip>
  );
};

export default IconButton;
