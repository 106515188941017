import produce from 'immer';

import {
  AccountsActions,
  ORGANISATIONS_ACCOUNTS_LOAD,
  ORGANISATIONS_ACCOUNTS_ACKNOWLEDGE,
  ORGANISATIONS_ACCOUNTS_ELIMINATE_ACCOUNT,
  ORGANISATIONS_ACCOUNTS_UNELIMINATE_ACCOUNT,
  ORGANISATIONS_ACCOUNTS_MANUAL_CREATE,
  ORGANISATIONS_ACCOUNTS_MANUAL_DELETE,
  ORGANISATIONS_ACCOUNTS_MANUAL_UPDATE,
  ORGANISATIONS_ACCOUNTS_SET_GROUP_ID,
} from './actions';
import { Account, Accounts, OrganisationAccounts } from './types';

const accounts = (prevState: OrganisationAccounts = {}, action: AccountsActions): OrganisationAccounts => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_ACCOUNTS_LOAD:
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload.reduce((acc: Accounts, account: Account) => {
                acc[account.account_id] = account;
                return acc;
              }, {} as Accounts),
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      case ORGANISATIONS_ACCOUNTS_ACKNOWLEDGE: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            return;

          case 'success':
            const { accountIds } = action.params;
            const { acknowledged_at: acknowledgedAt, acknowledged_by: acknowledgedBy } = action.payload;
            for (const accountId of accountIds) {
              state[organisationId].value![accountId].acknowledged_at = acknowledgedAt;
              state[organisationId].value![accountId].acknowledged_by = acknowledgedBy;
            }
            return;

          case 'error':
            return;

          default:
            return state;
        }
      }

      case ORGANISATIONS_ACCOUNTS_ELIMINATE_ACCOUNT: {
        const { organisationId, accountId } = action.params;
        const accounts = state[organisationId].value;
        if (accounts) {
          const account = accounts[accountId];
          account.is_eliminated = true;
        }
        return;
      }

      case ORGANISATIONS_ACCOUNTS_UNELIMINATE_ACCOUNT: {
        const { organisationId, accountId } = action.params;
        const accounts = state[organisationId].value;
        if (accounts) {
          const account = accounts[accountId];
          account.is_eliminated = false;
        }
        return;
      }

      case ORGANISATIONS_ACCOUNTS_MANUAL_CREATE: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'success':
            const newAccount = action.payload;
            state[organisationId].value![newAccount.account_id] = newAccount;
            return;
        }
        return;
      }

      case ORGANISATIONS_ACCOUNTS_MANUAL_UPDATE: {
        const { organisationId, accountId, name } = action.params;
        switch (action.status) {
          case 'success':
            state[organisationId].value![accountId] = {
              ...state[organisationId].value![accountId],
              name,
            };
            return;
        }
        return;
      }
      case ORGANISATIONS_ACCOUNTS_MANUAL_DELETE: {
        const { organisationId, accountId } = action.params;
        switch (action.status) {
          case 'success':
            delete state[organisationId].value![accountId];
            return;
        }
        return;
      }
      case ORGANISATIONS_ACCOUNTS_SET_GROUP_ID: {
        const { organisationId, accountIds, groupId, tag } = action.params;
        switch (action.status) {
          case 'success':
            for (const accountId of accountIds) {
              // Value of null means the group should be unset
              if (groupId === null) {
                state[organisationId].value![accountId].group_id = undefined;
              } else if (groupId !== undefined) {
                state[organisationId].value![accountId].group_id = groupId;
              }

              if (tag !== null) {
                state[organisationId].value![accountId].tag = tag;
              }
            }
            return;
        }
        return;
      }

      default:
        return state;
    }
  });
};

export default accounts;
