import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import styled from 'styled-components';

import Input from '../../../../components/atoms/Input';
import Typography from '../../../../components/atoms/Typography';
import Row from '../../../../components/layout/Row';
import { StyleRowProperties } from '../../../../store/state/reports/types';
import { DragHandle } from '../../ReportEditorContent';
import ReportEditorDelete from '../../ReportEditorDelete';
import ReportEditorCustomRowStyles from '../ReportEditorCustomRowStyles';
import { rowBorderStyles } from '../ReportEditorRow/helpers';

export interface ReportEditorDividerRowProps {
  name?: string;
  styles?: StyleRowProperties;
  id: string;
  isSelected: boolean;
  handleIsSelectedChange: (isSelected: boolean) => void;
  handleRename: (value: string) => void;
  handleDelete: () => void;
  handleUpdateRowStyles: (styles: Partial<StyleRowProperties>) => void;
}

const StyledContainer = styled.div<{
  isSelected: boolean;
  styles: Omit<StyleRowProperties, 'bold'>;
}>`
  width: 100%;
  min-height: 40px;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding-left: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.palette.silver : theme.palette.white)};
  background-color: ${({ theme, isSelected, styles: { color } }) =>
    color ? `hsl(${color.h}, ${color.s}%, ${color.l}%)` : isSelected ? theme.palette.silver : theme.palette.white};
  border-top: ${({ styles: { border_top } }) => rowBorderStyles[border_top || 'NONE']};
  border-bottom: ${({ styles: { border_bottom } }) => rowBorderStyles[border_bottom || 'NONE']};

  border-radius: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
`;

const StyledActions = styled.div`
  overflow-x: auto;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
  margin-left: ${({ theme }) => theme.spacing(1)};

  span:first-child {
    max-width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
  }
`;

const ReportEditorDividerRow: React.FC<React.PropsWithChildren<ReportEditorDividerRowProps>> = ({
  id,
  name,
  styles,
  isSelected,
  handleIsSelectedChange,
  handleUpdateRowStyles,
  handleRename,
  handleDelete,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  let rowColor: StyleRowProperties['color'];
  if (styles?.color) {
    rowColor = { ...styles.color };
    rowColor.l += Math.floor((100 - rowColor.l) / 1.5);
    rowColor.l += Math.floor((100 - rowColor.l) / 1.5);

    // ignoring white color
    if (rowColor?.h === 0 && rowColor?.s === 0) {
      rowColor = undefined;
    }
  }

  const handleSelectedRowIdChange = () => {
    handleIsSelectedChange(!isSelected);
  };

  return (
    <StyledContainer
      ref={setNodeRef}
      style={style}
      styles={
        {
          ...styles,
          color: rowColor,
        } as Omit<StyleRowProperties, 'bold'>
      }
      isSelected={isSelected}
      onClick={handleSelectedRowIdChange}
    >
      <DragHandle {...attributes} {...listeners} />
      <Row vAlign="center" hAlign="flex-start">
        {isSelected && (
          <Input
            onClick={e => e.stopPropagation()}
            onChange={e => {
              e.stopPropagation();
              handleRename(e.currentTarget.value);
            }}
            value={name}
            width="200px"
            placeholder="Divider"
          />
        )}
        {!isSelected && <Typography color="secondary">{name || 'Divider'}</Typography>}
        {isSelected && (
          <StyledActions>
            <ReportEditorDelete handleDelete={handleDelete} />
            {styles && <ReportEditorCustomRowStyles styles={styles} handleUpdateRowStyles={handleUpdateRowStyles} />}
          </StyledActions>
        )}
      </Row>
    </StyledContainer>
  );
};

export default ReportEditorDividerRow;
