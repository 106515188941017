import * as am4charts from '@amcharts/amcharts4/charts';
import React from 'react';
import Column from 'scalexp/components/layout/Column';
import styled, { css } from 'styled-components';

import LegendSeries from '../ChartLegendSeries';

const ChartLegendContainer = styled(Column)`
  padding-top: 7px;
  padding-bottom: 15px;
  padding-left: 14px;
  padding-right: 20px;
  width: 100%;
`;

const StyledChartLegend = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 1px 4px;
      margin-bottom: 8px;
    `;
  }}
`;

export interface ChartLegendProps {
  chart?: am4charts.XYChart | am4charts.PieChart;
  hideZeroValues?: boolean;
}

const ChartLegend: React.FC<React.PropsWithChildren<ChartLegendProps>> = ({ chart, hideZeroValues = false }) => {
  const seriesMarkup: React.ReactNode[] = [];
  try {
    let allSeries = chart?.series;
    if (!allSeries) {
      return null;
    }
    allSeries.each((series: am4charts.XYSeries | am4charts.PieSeries, index) => {
      if (series instanceof am4charts.PieSeries) {
        series.mainDataSet.each((dataPoint, i) => {
          // @ts-ignore
          const name = dataPoint.legendDataItem?._label.currentText;
          const color = dataPoint.legendDataItem?.color?.toString();
          // @ts-ignore
          const value = dataPoint.legendDataItem?._valueLabel.currentText;
          if (dataPoint.value === 0 && hideZeroValues) {
            return null;
          }
          seriesMarkup.push(
            <LegendSeries key={`${index}-${i}`} color={color || ''} name={name} value={value} series={dataPoint} />,
          );
        });
        return null;
      }

      if (!series.name) {
        return null;
      }

      const name = series.name.toString();
      const color = series.fill?.toString() ?? 'black';
      seriesMarkup.push(<LegendSeries key={index} color={color} name={name} series={series} />);
    });
  } catch (error) {}
  if (seriesMarkup.length === 0) {
    return null;
  }
  return (
    <ChartLegendContainer spacing="tiny">
      <StyledChartLegend>{seriesMarkup}</StyledChartLegend>
    </ChartLegendContainer>
  );
};

export default ChartLegend;
