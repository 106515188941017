import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { Chart } from '../../../features/chart/ChartCard/types';
import fetcher from '../../../utils/fetcher';
import Loader from '../../atoms/Loader';
import Notification from '../../atoms/Notification';
import { useChartData } from '../../organisms/DashboardChart/useChartData';
import useActiveOrganisationId from '../OrganisationContext/useActiveOrganisationId';

export interface EditChartContextProps {
  chartId: string;
}

export const EditChartContext = React.createContext<{
  chart: Chart;
  setConfig: (config: Chart | undefined) => void;
  saveChart: () => void;
  activeSeries: string;
  setActiveSeries: React.Dispatch<React.SetStateAction<string>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  isDirty: boolean;
}>(undefined!);

export const EditChartProvider: React.FC<PropsWithChildren<EditChartContextProps>> = ({ chartId, children }) => {
  const { chartData, isValidating, mutate: updateChartData } = useChartData(chartId);
  const [activeSeries, setActiveSeries] = useState('');
  const [config, setConfig] = useState(chartData);
  const [isDirty, setIsDirty] = useState(false);
  const organisationId = useActiveOrganisationId();
  const saveChart = useCallback(async () => {
    try {
      const endpoint = `/api/v1/organisations/${organisationId}/charts/`;
      const copyConfig = { ...config };
      if ('x' in copyConfig) {
        // @ts-ignore
        delete copyConfig['x'];
      }
      if ('y' in copyConfig) {
        // @ts-ignore
        delete copyConfig['y'];
      }
      if ('width' in copyConfig) {
        // @ts-ignore
        delete copyConfig['width'];
      }
      if ('height' in copyConfig) {
        // @ts-ignore
        delete copyConfig['height'];
      }
      const result = await fetcher(endpoint, {
        method: 'POST',
        body: JSON.stringify({ ...copyConfig, id: chartId }),
      });
      updateChartData(result);
      if (result) {
        Notification.success({ title: 'Chart was updated successfully!' });
      }
      setIsDirty(false);
    } catch (error) {
      Notification.error({ title: "Couldn't save the new chart config!" });
    }
  }, [config, organisationId, chartId]);
  useEffect(() => {
    if (chartData) {
      setConfig(chartData);
    }
  }, [chartData]);
  useEffect(() => {
    const activeSeriesEl = document.getElementById(`series-${activeSeries}`);
    if (activeSeriesEl) {
      document.getElementById('series-wrapper')?.scrollTo({
        top: activeSeriesEl.offsetTop - 140,
        left: activeSeriesEl.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, [activeSeries]);
  if (!config || !chartData || isValidating) {
    return <Loader content="Loading chart config..." center vertical />;
  }

  const handleSetConfig = (config: Chart | undefined) => {
    setConfig(config);
    setIsDirty(true);
  };

  return (
    <EditChartContext.Provider
      value={{
        chart: config,
        activeSeries,
        setActiveSeries,
        setConfig: handleSetConfig,
        saveChart,
        isDirty,
        setIsDirty,
      }}
    >
      {children}
    </EditChartContext.Provider>
  );
};

export const useEditChartContext = () => {
  const value = React.useContext(EditChartContext);

  if (!value) {
    throw new Error('EditChartProvider not found!');
  }

  return value;
};
