import dayjs from 'dayjs';

import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { BillsFilter } from '../prepaymentsPresets/types';
import { selectDeferredBills } from './selectors';
import { DeferredBills } from './types';

export const ORGANISATIONS_DEFERRED_BILLS_LOAD = '[deferredBills] LOAD';

export type DeferredBillsActions = PromiseAction<
  typeof ORGANISATIONS_DEFERRED_BILLS_LOAD,
  DeferredBills,
  { organisationId: number }
>;

type DeferredDataResponse = DeferredBills;

export const loadDeferredBillsPageAction = (
  organisationId: number,
  fromDate: Date,
  toDate: Date,
  accountsFilter: string[],
  billsFilter: BillsFilter | null,
  originalAccount: boolean = false,
): ThunkAction<DeferredBillsActions> => async (dispatch: ThunkDispatch<DeferredBillsActions>, getState) => {
  const state = getState();
  const deferredBills = selectDeferredBills(state, organisationId);

  if (deferredBills?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_DEFERRED_BILLS_LOAD,
        () =>
          http<DeferredBills[], DeferredDataResponse>(
            `/api/v1/organisations/${organisationId}/bills/deferred/by_customer`,
            {
              body: {
                start: dayjs(fromDate).format('YYYY-MM'),
                end: dayjs(toDate).format('YYYY-MM'),
                account_ids: accountsFilter,
                bill_filter: billsFilter,
                original_account: true,
              },
            },
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
