import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  * {
    font-family: Inter, serif;
  }

  .clickable {
    cursor: pointer;
    opacity: 1;
  }

  .clickable:hover {
    opacity: 0.7;
  }

  @page {
    size: 960px 540px !important;
    margin: 0px;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  @media print {
    * {
      transition: none !important;
    }
    header, footer, #toolbar, #hidden-bar {
      display: none !important;
    }
    body, .container {
      min-width: auto !important;
    }
  }
`;
export default GlobalStyles;
