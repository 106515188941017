import React from 'react';
import styled, { css } from 'styled-components';

import { CalculationResultImpact } from '../../../utils/metrics/calculationRequestManager';
import Icon from '../Icon';
import Typography from '../Typography';

export interface ChangeTextProps {
  value: number;
  period?: string;
  impact: CalculationResultImpact;
  formattedValue: string;
}
export interface StyledChangeTextProps {
  impact: CalculationResultImpact;
}
export const StyledChangeText = styled.div<StyledChangeTextProps>`
  ${({ theme, impact }) =>
    css`
      & * {
        line-height: unset;
      }
      span:first-child {
        font-weight: ${theme.font.weight.semibold};
        ${impact === 'positive'
          ? css`
              color: ${theme.palette.success.main};
            `
          : ''}

        ${impact === 'negative'
          ? css`
              color: ${theme.palette.danger.main};
            `
          : ''}
      }
    `}
`;
const ChangeText: React.FC<React.PropsWithChildren<ChangeTextProps>> = ({
  value,
  period = 'previous month',
  impact,
  formattedValue,
}) => {
  let impactAfterValue = impact;
  if (value < 0 && impact !== 'neutral') {
    impactAfterValue = impact === 'positive' ? 'negative' : 'positive';
  }
  return (
    <StyledChangeText impact={impactAfterValue}>
      <Typography size="tiny">
        <Icon marginRight={0.5} color="inherit" name={value >= 0 ? 'north_east' : 'south_east'} />
        {formattedValue}{' '}
      </Typography>
      <Typography size="tiny" color="secondary">
        vs {period}
      </Typography>
    </StyledChangeText>
  );
};
export default ChangeText;
