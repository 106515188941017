import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import Typography from '../../atoms/Typography';

interface LabeledTagProps extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  value: string;
  labelColor?: string;
  children?: never;
}

const StyledLabeledTag = styled.div`
  ${({ theme }) =>
    `
      display: flex;
      align-content: center;
      justify-content: space-between;
      border: 1px solid ${theme.palette.silver};
      border-radius: ${theme.spacing(1)};
      width: fit-content;
      overflow: hidden;
      & > span {
        padding: ${theme.spacing(1)} ${theme.spacing(3)};
      }
  `}
`;

const StyledLabel = styled.div`
  ${({ theme }) =>
    `
    height: 100%;
    padding: ${theme.spacing(1)}  ${theme.spacing(2)};
    background-color: ${theme.palette.primary.main};
  `}
`;

const LabeledTag: React.FC<React.PropsWithChildren<LabeledTagProps>> = ({ value, label, ...other }) => {
  return (
    <StyledLabeledTag {...other}>
      {label && (
        <StyledLabel>
          <Typography color="white" weight="bold">
            {label}
          </Typography>
        </StyledLabel>
      )}
      <Typography color="secondary"> {value} </Typography>
    </StyledLabeledTag>
  );
};

export default LabeledTag;
