import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import useActiveOrganisation from '../../../components/contexts/OrganisationContext/useActiveOrganisation';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import Spacer from '../../../components/layout/Spacer';
import BlurredModal from '../../../components/molecules/BlurredModal';
import { getCurrencyName } from '../../../components/molecules/CurrencySelect/currencySupport';
import OnboardingOrganisationCard from '../../../components/molecules/OnboardingOrganisationCard';
import OnboardingPageTitle from '../../../components/molecules/OnboardingPageTitle';
import fetcher from '../../../utils/fetcher';
import { useConnectionsContext } from '../../connections/ConnectionsContext';
import ClearBooksConnectionCard from '../../connections/cards/ClearBooksConnectionCard';
import QuickbooksConnectionCard from '../../connections/cards/QuickbooksConnectionCard';
import QuickbooksDesktopConnectionCard from '../../connections/cards/QuickbooksDesktopConnectionCard';
import SageFiftyConnectionCard from '../../connections/cards/SageFiftyConnectionCard';
import SageOneConnectionCard from '../../connections/cards/SageOneConnectionCard';
import SageTwoHundredConnectionCard from '../../connections/cards/SageTwoHundredConnectionCard';
import XeroConnectionCard from '../../connections/cards/XeroConnectionCard';
import ZohoBooksConnectionCard from '../../connections/cards/ZohoBooksConnectionCard';

const Container = styled.div`
  padding-top: 100px;
`;

const StyledConnectionsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(5)};
  flex-wrap: wrap;
`;
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(15)};
  padding: ${({ theme }) => `${theme.spacing(15)} ${theme.spacing(24)}`};
`;

interface PauseOnboardingProps {
  event: {
    preventDefault: () => void;
    stopPropagation: () => void;
  };
  connectionName: 'Sage' | 'Sage50' | 'Zohobooks' | 'Quickbooksdesktop' | 'Sage200cloud' | 'Clearbooks';
}

const UnsupportedSelfServeAccountingSystem = styled.div``;

const OnboardingConnections = () => {
  const history = useHistory();
  const { consolidatedConnections } = useConnectionsContext();
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const [selectedOrganisationId, setSelectedOrganisationId] = useState<number | null>(null);
  const organisation = useActiveOrganisation();

  const pauseOnboarding = async ({ event, connectionName }: PauseOnboardingProps) => {
    event.stopPropagation();
    event.preventDefault();
    await fetcher(`/api/v2/organisation/${organisation.organisation_id}/pause_onboarding`, {
      method: 'PUT',
      body: JSON.stringify({ connection_name: connectionName }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    window.location.href = '/onboarding/rejection';
  };

  let hasConnectedAccount = false;
  if (consolidatedConnections) {
    hasConnectedAccount = consolidatedConnections.some(org => {
      return org.connections['xero'].status === 'connected' || org.connections['quickbooks'].status === 'connected';
    });
  }

  return (
    <Container>
      <OnboardingPageTitle title="Set up your organisation" subtitle="Connecting your financial data into ScaleXP" />
      <Spacer margin={{ top: 12, bottom: 4 }}>
        {organisation.consolidation_type === 'PARENT' && (
          <Typography color="secondary" size="medium">
            Consolidated “Parent” Organisation
          </Typography>
        )}
      </Spacer>
      {organisation.consolidation_type !== 'PARENT' && (
        <OnboardingOrganisationCard organistion={organisation} handleConnect={() => setShowConnectionModal(true)} />
      )}
      {organisation.consolidation_type === 'PARENT' && (
        <Spacer margin={{ top: 5, bottom: 5 }}>
          <Typography color="black" size="h3">
            {organisation.name}
          </Typography>
          <Spacer margin={{ top: 4 }} />
          <Typography size="medium" weight="regular">
            {getCurrencyName(organisation.default_currency_code)}
          </Typography>
        </Spacer>
      )}
      {organisation.consolidation_type === 'PARENT' && (
        <>
          <Spacer margin={{ top: 12, bottom: 4 }}>
            <Typography color="secondary" size="medium">
              Subsidiaries
            </Typography>
          </Spacer>
          <Column>
            {organisation.consolidation_type === 'PARENT' &&
              organisation.children.map(organisationsChild => (
                <OnboardingOrganisationCard
                  organistion={organisationsChild}
                  connections={
                    consolidatedConnections?.find(
                      ({ organisation_id }) => organisation_id === organisationsChild.organisation_id,
                    )?.connections
                  }
                  handleConnect={() => {
                    setSelectedOrganisationId(organisationsChild.organisation_id);
                    setShowConnectionModal(true);
                  }}
                />
              ))}
          </Column>
        </>
      )}
      <Spacer margin={{ top: 14 }} padding={{ bottom: 20 }}>
        <Row width="850px" hAlign="flex-end" vAlign="center">
          <TextButton
            width="130px"
            variant="primary"
            size="large"
            loading={false}
            disabled={!hasConnectedAccount}
            onClick={() => history.push('/connections/onboarding/settings')}
          >
            Next
          </TextButton>
        </Row>
      </Spacer>
      <BlurredModal showModal={showConnectionModal} onHide={() => setShowConnectionModal(false)} width="70%">
        <StyledContainer>
          <Typography size="h6">Connect an Connection</Typography>
          <StyledConnectionsContainer>
            <>
              <XeroConnectionCard
                hide="connected"
                isOnboarding={true}
                selectedOrganisationId={selectedOrganisationId || undefined}
              />
              <QuickbooksConnectionCard
                hide="connected"
                isOnboarding={true}
                selectedOrganisationId={selectedOrganisationId || undefined}
              />
              <UnsupportedSelfServeAccountingSystem
                onClickCapture={event =>
                  pauseOnboarding({
                    event,
                    connectionName: 'Sage',
                  })
                }
              >
                <SageOneConnectionCard hide="connected" />
              </UnsupportedSelfServeAccountingSystem>
              <UnsupportedSelfServeAccountingSystem
                onClickCapture={event =>
                  pauseOnboarding({
                    event,
                    connectionName: 'Sage50',
                  })
                }
              >
                <SageFiftyConnectionCard hide="connected" />
              </UnsupportedSelfServeAccountingSystem>
              <UnsupportedSelfServeAccountingSystem
                onClickCapture={event =>
                  pauseOnboarding({
                    event,
                    connectionName: 'Zohobooks',
                  })
                }
              >
                <ZohoBooksConnectionCard hide="connected" />
              </UnsupportedSelfServeAccountingSystem>
              <UnsupportedSelfServeAccountingSystem
                onClickCapture={event =>
                  pauseOnboarding({
                    event,
                    connectionName: 'Quickbooksdesktop',
                  })
                }
              >
                <QuickbooksDesktopConnectionCard hide="connected" />
              </UnsupportedSelfServeAccountingSystem>
              <UnsupportedSelfServeAccountingSystem
                onClickCapture={event =>
                  pauseOnboarding({
                    event,
                    connectionName: 'Sage200cloud',
                  })
                }
              >
                <SageTwoHundredConnectionCard hide="connected" />
              </UnsupportedSelfServeAccountingSystem>
              <UnsupportedSelfServeAccountingSystem
                onClickCapture={event =>
                  pauseOnboarding({
                    event,
                    connectionName: 'Clearbooks',
                  })
                }
              >
                <ClearBooksConnectionCard hide="connected" />
              </UnsupportedSelfServeAccountingSystem>
            </>
          </StyledConnectionsContainer>
        </StyledContainer>
      </BlurredModal>
    </Container>
  );
};

export default OnboardingConnections;
