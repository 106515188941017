import React, { useCallback, useState } from 'react';
import useGetRunningJobs from 'scalexp/features/connections/sync-context/useGetRunningJobs';

import { Job } from '../types';
import useGetRunningJobsStatus from '../useGetRunningJobsStatus';

interface SyncContextProps {
  jobs: Job[];
  setJobs: (jobs: Job[]) => void;
  addJob: (job: Job) => void;
}

const SyncContext = React.createContext<SyncContextProps | undefined>(undefined);

export const SyncContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [jobs, setJobs] = useState([] as Job[]);

  useGetRunningJobs(jobs, setJobs);
  useGetRunningJobsStatus(jobs, setJobs);

  const addJob = useCallback((job: Job) => {
    setJobs(_jobs => [..._jobs, job]);
  }, []);

  return <SyncContext.Provider value={{ jobs, setJobs, addJob }}>{children}</SyncContext.Provider>;
};

export const useSyncContext = () => {
  const value = React.useContext(SyncContext);

  if (!value) {
    throw new Error('SyncProvider not found!');
  }

  return value;
};
