import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export interface OptionsContainerProps extends HTMLAttributes<HTMLDivElement> {
  width?: string | number;
}
export const StyledOptionsContainer = styled.div<OptionsContainerProps>`
  ${({ theme, width }) =>
    `
    padding: ${theme.spacing(2)} 0px;
    position: absolute;
    width: ${width};
    z-index: ${theme.zIndex.highest};
    max-height: ${theme.spacing(80)};
    overflow-y: scroll;
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.silver};
    box-shadow: ${theme.shadow.medium};
    border-radius: ${theme.spacing(1)};
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 4px 0px

  `}
`;
const OptionsContainer: React.FC<React.PropsWithChildren<OptionsContainerProps>> = ({
  width = '140px',
  children,
  ...other
}) => {
  return (
    <StyledOptionsContainer width={width} {...other}>
      {children}
    </StyledOptionsContainer>
  );
};
export default OptionsContainer;
