import { logUndefinedOrgFunc } from '../../../utils/logger';
import { getCurrentOrganisation, setCurrentOrganisation } from '../../../utils/organisation';
import { useUserContext } from '../UserContext';

/**
 * Returns the organisation identifier for the currently active tenant
 */
const useActiveOrganisationId = (): number => {
  const user = useUserContext();
  if (getCurrentOrganisation() === null) {
    // user has not currently selected an organisation, so we will return the first organisation
    if (user.available_organisations && user.available_organisations.length > 0) {
      const availableOrgs = user.available_organisations;
      // for users with consolidated organisations we will default to the first subsidiary org
      if (availableOrgs.length === 1 && availableOrgs[0].length > 1) {
        setCurrentOrganisation(availableOrgs[0][1]);
        availableOrgs[0][1] === undefined && logUndefinedOrgFunc('useActiveOrganisationId- single consolidated org');
      } else {
        setCurrentOrganisation(availableOrgs[0][0]);
        availableOrgs[0][0] === undefined && logUndefinedOrgFunc('useActiveOrganisationId- multiple orgs');
      }
    } else {
      return 0;
    }
  }
  const currentOrganisation = getCurrentOrganisation();
  return currentOrganisation ? currentOrganisation.organisation_id : 0;
};

export default useActiveOrganisationId;
