import Row from 'scalexp/components/layout/Row';
import Select from 'scalexp/components/molecules/Select';

type FinancialDataType = 'CASHFLOW' | 'ACTUALS';

const ReportEditorCashflowSelect: React.FC<
  React.PropsWithChildren<{
    value: 'CASHFLOW' | 'ACTUALS';
    onChange: (value: FinancialDataType) => void;
  }>
> = ({ value, onChange }) => {
  return (
    <Row onMouseDown={e => e.stopPropagation()}>
      <Select<FinancialDataType>
        data={[
          {
            value: 'CASHFLOW',
            label: 'Cashflow',
          },
          {
            value: 'ACTUALS',
            label: 'Actuals',
          },
        ]}
        value={value}
        onChange={value => onChange(value)}
        zIndex="highest"
      />
    </Row>
  );
};

export default ReportEditorCashflowSelect;
