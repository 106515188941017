export const CURRENT_ORGANISATION_KEY = 'currentOrg';

export const setCurrentOrganisation = (organisation: Object): void => {
  localStorage.setItem(CURRENT_ORGANISATION_KEY, JSON.stringify(organisation));
};

export const getCurrentOrganisation = () => {
  const organisation = localStorage.getItem(CURRENT_ORGANISATION_KEY);
  try {
    return organisation ? JSON.parse(organisation) : null;
  } catch (e) {
    console.log(`Warning, invalid organisation found in local storage: '${organisation}'`);
    return null;
  }
};

export const clearCurrentOrganisation = () => {
  localStorage.removeItem(CURRENT_ORGANISATION_KEY);
};
