import { useConnectionsContext } from '.';

const FormalSourceNames = {
  xero: 'Xero',
  quickbooks: 'QuickBooks',
  sageone: 'Sageone',
  zohobooks: 'Zohobooks',
  sage50: 'Sage50',
  sage200cloud: 'Sage200cloud',
  netvisor: 'Netvisor',
  clearbooks: 'Clearbooks',
  quickbooksdesktop: 'QuickBooksdesktop',
  salesforce: 'Salesforce',
  pipedrive: 'Pipedrive',
  hubspot: 'Hubspot',
  stripe: 'Stripe',
};
export const getFormalSystemName = (value: string) => {
  if (value) {
    return FormalSourceNames[value as keyof typeof FormalSourceNames];
  }
  return value;
};

export const CRM_CONNECTIONS = ['hubspot', 'salesforce', 'pipedrive'];
const useGetAccountingAndSalesSystem = () => {
  const { connections, isLoaded } = useConnectionsContext();

  const orgConnection = isLoaded ? Object.entries(connections) : [];
  const [accountingSystemName] = orgConnection.find(
    ([connectionName, connection]) =>
      !CRM_CONNECTIONS.includes(connectionName) && connectionName !== 'stripe' && connection.status !== 'notconnected',
  ) ?? [''];
  const [salesSystemName] = orgConnection.find(
    ([connectionName, connection]) => CRM_CONNECTIONS.includes(connectionName) && connection.status !== 'notconnected',
  ) ?? [''];

  return {
    isLoading: !isLoaded,
    accountingSystem: getFormalSystemName(accountingSystemName),
    salesSystem: getFormalSystemName(salesSystemName),
    isXero: accountingSystemName === 'xero',
  };
};

export default useGetAccountingAndSalesSystem;
