import React from 'react';
import styled from 'styled-components';

import TextButton from '../../atoms/TextButton';
import Typography from '../../atoms/Typography';
import Column from '../../layout/Column';
import Row from '../../layout/Row';
import BlurredModal from '../BlurredModal';

export interface CodatTroubleShootModalProps {
  showModal: boolean;
  onHide: VoidFunction;
  syncing: boolean;
  handleSync: () => void;
}

const StyledModalBody = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(6)}`};
  padding-top: ${({ theme }) => theme.spacing(8)};
`;

const CodatTroubleShootModal: React.FC<CodatTroubleShootModalProps> = ({ showModal, onHide, syncing, handleSync }) => {
  return (
    <BlurredModal showModal={showModal} onHide={onHide} showCloseButton={true}>
      <StyledModalBody>
        <Column spacing="medium">
          <Row vAlign="center" spacing="small" style={{ marginRight: 60 }}>
            <Typography color="secondary" size="h5">
              You are receiving this warning because the on-premise connector has not pushed data to ScaleXP for over 24
              hours.
            </Typography>
          </Row>
          <Typography weight="regular" size="h6">
            1. Verify that the on-premise connector is running.
          </Typography>
          <Typography weight="regular" size="medium" style={{ marginLeft: 20 }}>
            - Make sure you are on the same machine that is hosting your accounting software. If you do not have access
            to this machine then please contact your IT department for support.
          </Typography>
          <Typography weight="regular" size="medium" style={{ marginLeft: 20 }}>
            - In the Windows tray in the bottom right corner of your screen, you should find a white icon called
            "ScaleXP Connector", click it to open the connector ( You may have to click to arrow to see more icons ). If
            you don't have this icon, search for the application called "ScaleXP Connector" and start it.
          </Typography>
          <Typography weight="regular" size="h6">
            2. Press the "Sync" button in the connector.
          </Typography>
          <Typography weight="regular" size="medium" style={{ marginLeft: 20 }}>
            - Occasionally when the connector has been shut down, it needs a nudge to start syncing again. Please press
            the "Sync" button in the connector you would have opened in Step 1.
          </Typography>
          <Typography weight="regular" size="medium" style={{ marginLeft: 20 }}>
            - Once the connector has finished syncing, you can start the sync process to ScaleXP by pressing the "Sync
            Now" button here.
          </Typography>
          <TextButton
            variant="primary"
            iconLeft="refresh"
            onClick={() => {
              handleSync();
              onHide();
            }}
          >
            Sync Now
          </TextButton>
          <Typography weight="regular" size="h6">
            If these steps have not resolved your issue, or you need further assistance, please Contact ScaleXP support
            at support@scalexp.com
          </Typography>
        </Column>
      </StyledModalBody>
    </BlurredModal>
  );
};

export default CodatTroubleShootModal;
