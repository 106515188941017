import { useEffect, useRef } from 'react';
import useActiveOrganisationId from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisationId';
import { API_URL } from 'scalexp/config';
import {
  DownloadJob,
  DownloadJobResponse,
  ImportJob,
  ImportJobResponse,
  Job,
  DOWNLOAD_JOB_TASKS,
} from 'scalexp/features/connections/sync-context/types';
import fetcher from 'scalexp/utils/fetcher';

import calculateCompletedSteps from './calculateCompletedSteps';

const useGetRunningJobsStatus = async (jobs: Job[], setJobs: (jobs: Job[]) => void) => {
  const organisationId = useActiveOrganisationId();

  let refreshIntervalId = useRef(0);

  const getRunningJobsStatus = async () => {
    const newJobs = await Promise.all(
      jobs.map<Promise<Job>>(async job => {
        // download jobs
        if (DOWNLOAD_JOB_TASKS.find(jobTask => jobTask === job.task)) {
          const downloadJobResponse: DownloadJobResponse = await fetcher(
            `${API_URL}/api/v1/organisations/${organisationId}/file_download_status/${job.id}`,
          );

          if (downloadJobResponse.status === 'Completed') {
            const fileUrl = downloadJobResponse.data.file_url.startsWith('http')
              ? downloadJobResponse.data.file_url
              : `${API_URL}${downloadJobResponse.data.file_url}`;
            return {
              ...job,
              status: downloadJobResponse.status,
              payload: {
                fileUrl,
              },
            } as DownloadJob;
          }

          return { ...job, status: downloadJobResponse.status };
        }

        // import jobs
        const importJobResponse: ImportJobResponse = await fetcher(
          `/api/v1/${organisationId}/connections/import/${job.id}`,
        );

        const completedSteps = calculateCompletedSteps(importJobResponse);

        return { ...job, status: importJobResponse.status, completedSteps } as ImportJob;
      }),
    );

    setJobs(newJobs);
  };

  useEffect(() => {
    if (jobs?.findIndex(job => job.status === 'Running') > -1) {
      refreshIntervalId.current = window.setInterval(getRunningJobsStatus, 3000);
    }

    return () => clearInterval(refreshIntervalId.current);
  }, [jobs]);
};

export default useGetRunningJobsStatus;
