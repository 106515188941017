import React, { useMemo } from 'react';
import { MetricSchema2Decimals } from 'scalexp/utils/metrics/metricSchema2';
import { getSimpleMetricSchemaFromNode } from 'scalexp/utils/metrics/ms2utils';
import styled from 'styled-components';

import Typography from '../../../../components/atoms/Typography';
import useActiveOrganisation from '../../../../components/contexts/OrganisationContext/useActiveOrganisation';
import useCurrencySelection from '../../../../components/molecules/CurrencySelect/useCurrencySelection';
import { useDateSelectionDate } from '../../../../components/molecules/DateSelect/useDateSelection';
import {
  REVENUE_METRIC_DATA_TYPE,
  INVOICED_REVENUE_METRIC_DISPLAY_NAMES,
  InvoicedRevenueMetricId,
} from '../../../../service/types/metricSchema';
import { Organisation } from '../../../../store/state/organisations/types';
import useMetricSchemaSeries from '../../../../utils/metrics/useMetricSchemaSeries';
import { DISPLAY_COLUMNS, formatValue } from '../../ReportEditor/helpers';
import { reportEditorcalculationRequest } from '../ReportEditorCustomRow';

interface ReportEditorDisplaySubsidiaryRevenueRowProps {
  metricId: InvoicedRevenueMetricId;
  subsidiaryId: Organisation['organisation_id'];
  decimals: MetricSchema2Decimals;
}

const StyledGroupContainer = styled.div`
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: 20px 1fr 160px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};

  span:last-child {
    text-align: end;
  }
`;

const ReportEditorDisplaySubsidiaryRevenueRow: React.FC<
  React.PropsWithChildren<ReportEditorDisplaySubsidiaryRevenueRowProps>
> = ({ metricId, decimals, subsidiaryId }) => {
  const date = useDateSelectionDate();
  const [currencyCode] = useCurrencySelection();

  const organisation = useActiveOrganisation();
  const { default_currency_code, financial_year_start } = organisation;
  const subsidiary = organisation.children.find(subsidiary => subsidiary.organisation_id === subsidiaryId)!;
  const seriesVS = useMetricSchemaSeries(
    getSimpleMetricSchemaFromNode({
      data: {
        operator: 'invoiced-revenue',
        metricId: metricId,
        subsidiaryId: subsidiaryId,
      },
      operator: 'add',
    }),
    reportEditorcalculationRequest(date, currencyCode || default_currency_code),
  );

  const values: (number | null)[] = useMemo(() => {
    const series = seriesVS.value;
    if (!series) {
      return [];
    }

    return series.map(serie => (serie ? Number(serie.value) : null));
  }, [seriesVS.value, DISPLAY_COLUMNS, financial_year_start]);

  const name = subsidiary.name_short + ': ' + INVOICED_REVENUE_METRIC_DISPLAY_NAMES[metricId];

  return (
    <StyledGroupContainer>
      <div />
      <Typography color="secondary">{name}</Typography>
      <Typography color="secondary">
        {formatValue(values[0], currencyCode || default_currency_code, REVENUE_METRIC_DATA_TYPE[metricId], decimals)}
      </Typography>
    </StyledGroupContainer>
  );
};

export default ReportEditorDisplaySubsidiaryRevenueRow;
