import React from 'react';
import { MetricSchema2Complex } from 'scalexp/utils/metrics/metricSchema2';
import styled, { css } from 'styled-components';

import FormulaEditor from '../../../features/formula-editor/FormulaEditor';
import usePickerOptions from '../../../features/report-editor/ReportEditor/usePickerOptions';
import Typography from '../../atoms/Typography';
import { ValidationContextProvider } from '../../contexts/ValidationContext';
import Row from '../../layout/Row';
import BlurredModal from '../../molecules/BlurredModal';

interface StyledFormulaEditorModalProps {
  configureDetails: {
    metricSchema: MetricSchema2Complex;
    updateMetricSchema: (metricSchema: MetricSchema2Complex) => void;
  };
  onHide: () => void;
}

const StyledModalHeader = styled.div`
  ${({ theme }) => css`
    height: 80px;
    padding: ${theme.spacing(8)};
  `}
`;

const FormulaEditorModal: React.FC<StyledFormulaEditorModalProps> = ({
  configureDetails: { metricSchema, updateMetricSchema },
  onHide,
}) => {
  const pickerOptions = [
    {
      label: 'Fixed Number',
      value: 'constant',
      group: 'Constant',
    },
    ...usePickerOptions().map(option => ({
      ...option,
      label: option.name,
      value: option.id,
      group: option.role,
    })),
  ];
  const saveMetricSchema = (metricSchema: MetricSchema2Complex) => {
    updateMetricSchema(metricSchema);
    onHide();
  };

  return (
    <BlurredModal showCloseButton={false} showSideBanner width="1300px" showModal onHide={onHide}>
      <StyledModalHeader>
        <Row width="100%" height="100%" vAlign="center" hAlign="start">
          <Typography size="h5"> Update series formula</Typography>
        </Row>
      </StyledModalHeader>
      <ValidationContextProvider hasError={false}>
        <FormulaEditor
          pickerOptions={pickerOptions}
          metricSchema={metricSchema}
          handleSave={saveMetricSchema}
          handleCancel={onHide}
          report={null}
          hideTotalRow
        />
      </ValidationContextProvider>
    </BlurredModal>
  );
};

export default FormulaEditorModal;
