import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React, { PropsWithChildren } from 'react';

interface SortableContainerProps {
  itemsIds: { id: string }[];
}

const SortableContainer: React.FC<PropsWithChildren<SortableContainerProps>> = ({ itemsIds, children }) => {
  return (
    <SortableContext items={itemsIds} strategy={verticalListSortingStrategy}>
      {children}
    </SortableContext>
  );
};

export default SortableContainer;
