import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import colors from '../../../colors';
import IconButton from '../../../components/atoms/IconButton';
import Input from '../../../components/atoms/Input';
import SubHeader from '../../../components/atoms/SubHeader';
import TextButton from '../../../components/atoms/TextButton';
import { useDashboardEditorContext } from '../../../components/contexts/DashboardEditorContext';
import Row from '../../../components/layout/Row';
import { theme } from '../../../theme';

export interface DashboardEditorHeaderProps {}
export interface StyledDashboardEditorHeaderProps extends HTMLAttributes<HTMLDivElement> {}
export const StyledDashboardEditorHeader = styled.div<StyledDashboardEditorHeaderProps>`
  ${({ theme }) =>
    `
  `}
`;
const DashboardEditorHeader: React.FC<DashboardEditorHeaderProps> = () => {
  const { dashboardName, isPinned, setIsPinned, setDashboardName, saveDashboard } = useDashboardEditorContext();
  const nameInputRef = React.useRef<HTMLInputElement>(null);
  return (
    <SubHeader height={theme.sizing(14.5)}>
      <Row hAlign="space-between" vAlign="center" width="100%">
        <Row hAlign="flex-start" vAlign="center">
          <IconButton
            name={isPinned ? 'star' : 'star_outline'}
            rounded
            color={isPinned ? colors.blue[600] : 'unset'}
            size="small"
            tooltipText={isPinned ? 'Unpin' : 'Pin'}
            tooltipPlacement="bottom"
            onClick={() => {
              setIsPinned(!isPinned);
            }}
          />
          <Input
            ref={nameInputRef}
            defaultValue={dashboardName || nameInputRef.current?.value}
            onChange={e => {
              if (nameInputRef.current) {
                nameInputRef.current.value = e.target.value;
                setDashboardName(e.target.value);
              }
            }}
            maxLength={50}
          />
        </Row>
        <TextButton width="120px" color="secondary" onClick={saveDashboard}>
          Save
        </TextButton>
      </Row>
    </SubHeader>
  );
};
export default DashboardEditorHeader;
