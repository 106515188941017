import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import Typography, { TypographyProps } from '../../atoms/Typography';
import Tooltip from '../Tooltip';

export interface LimitedTextWithTooltipProps {
  textProps?: Omit<TypographyProps, 'children'>;
  text: string;
  wordsCount?: number;
}
export interface StyledLimitedTextWithTooltipProps extends HTMLAttributes<HTMLDivElement> {}
export const StyledLimitedTextWithTooltip = styled.div<StyledLimitedTextWithTooltipProps>`
  ${({ theme }) =>
    `
    cursor: pointer;
    word-break: break-word;
  `}
`;
const LimitedTextWithTooltip: React.FC<React.PropsWithChildren<LimitedTextWithTooltipProps>> = ({
  wordsCount = 9,
  text,
  textProps,
  ...other
}) => {
  let splittedText = text.split(' ');
  let numberOfWords = splittedText.length;
  let textExceedsLimit = numberOfWords > wordsCount;
  let truncatedText = textExceedsLimit ? text.split(' ').slice(0, wordsCount).join(' ') + '...' : text;
  return (
    <StyledLimitedTextWithTooltip {...other}>
      <Tooltip
        placement="bottom"
        content={
          <Typography {...textProps} color="white">
            {text}
          </Typography>
        }
      >
        <Typography {...textProps}>{truncatedText}</Typography>
      </Tooltip>
    </StyledLimitedTextWithTooltip>
  );
};
export default LimitedTextWithTooltip;
