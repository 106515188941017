import dayjs from 'dayjs';

import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectDeferredBillsRemaining } from './selectors';
import { DeferredBillsRemaining } from './types';

export const ORGANISATIONS_DEFERRED_BILLS_REMAINING_LOAD = '[deferredBillsRemaining] LOAD';

export type deferredBillsRemainingActions = PromiseAction<
  typeof ORGANISATIONS_DEFERRED_BILLS_REMAINING_LOAD,
  DeferredBillsRemaining,
  { organisationId: number }
>;

type DeferredDataResponse = DeferredBillsRemaining;

export const loadDeferredBillsRemainingAction = (
  organisationId: number,
  fromDate: Date,
  toDate: Date,
  accountsFilter: string[],
  originalAccount: boolean = false,
): ThunkAction<deferredBillsRemainingActions> => async (
  dispatch: ThunkDispatch<deferredBillsRemainingActions>,
  getState,
) => {
  const state = getState();
  const deferredBillsRemaining = selectDeferredBillsRemaining(state, organisationId);

  if (deferredBillsRemaining?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_DEFERRED_BILLS_REMAINING_LOAD,
        () =>
          http<DeferredBillsRemaining, DeferredDataResponse>(
            `/api/v1/organisations/${organisationId}/bills/deferred/remaining`,
            {
              body: {
                start: dayjs(fromDate).format('YYYY-MM'),
                end: dayjs(toDate).format('YYYY-MM'),
                account_ids: accountsFilter,
                original_account: originalAccount,
              },
            },
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
