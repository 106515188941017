import produce from 'immer';

import {
  AccountGroupsActions,
  ORGANISATIONS_ACCOUNT_GROUPS_CREATE_GROUP,
  ORGANISATIONS_ACCOUNT_GROUPS_CREATE_SUBGROUP,
  ORGANISATIONS_ACCOUNT_GROUPS_DELETE_GROUP,
  ORGANISATIONS_ACCOUNT_GROUPS_DELETE_SUBGROUP,
  ORGANISATIONS_ACCOUNT_GROUPS_LOAD,
  ORGANISATIONS_ACCOUNT_GROUPS_RENAME_GROUP,
  ORGANISATIONS_ACCOUNT_GROUPS_RENAME_SUBGROUP,
  ORGANISATIONS_ACCOUNT_GROUPS_REORDER_GROUP,
  ORGANISATIONS_ACCOUNT_GROUPS_REORDER_SUBGROUP,
} from './actions';
import { AccountGroup, AccountGroups, OrganisationAccountGroups } from './types';

const accountGroups = (
  prevState: OrganisationAccountGroups = {},
  action: AccountGroupsActions,
): OrganisationAccountGroups => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_ACCOUNT_GROUPS_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload.reduce((acc, accountGroup) => {
                acc[accountGroup.id] = accountGroup;
                return acc;
              }, {} as AccountGroups),
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }

      case ORGANISATIONS_ACCOUNT_GROUPS_CREATE_GROUP: {
        const { organisationId } = action.params;
        const newGroup: AccountGroup | undefined = action.payload;
        if (!newGroup) {
          return state;
        }
        state[organisationId].value![newGroup.id] = newGroup;
        return state;
      }

      case ORGANISATIONS_ACCOUNT_GROUPS_RENAME_GROUP: {
        const { organisationId, groupId, name } = action.params;
        if (action.status !== 'success') {
          return state;
        }
        state[organisationId].value![groupId].name = name;
        return state;
      }

      case ORGANISATIONS_ACCOUNT_GROUPS_REORDER_GROUP: {
        const { organisationId, orderedGroupIds } = action.params;
        if (action.status !== 'success') {
          return state;
        }

        for (const [index, groupId] of orderedGroupIds.entries()) {
          state[organisationId].value![groupId].order = index;
        }

        return state;
      }

      case ORGANISATIONS_ACCOUNT_GROUPS_DELETE_GROUP: {
        const { organisationId, groupId } = action.params;
        if (action.status !== 'success') {
          return state;
        }
        delete state[organisationId].value![groupId];
        return state;
      }

      case ORGANISATIONS_ACCOUNT_GROUPS_CREATE_SUBGROUP: {
        const { organisationId, groupId } = action.params;
        const newSubGroup: AccountGroup | undefined = action.payload;
        if (!newSubGroup) {
          return state;
        }
        state[organisationId].value![groupId].groups.push(newSubGroup.id);
        state[organisationId].value![newSubGroup.id] = newSubGroup;
        return state;
      }

      case ORGANISATIONS_ACCOUNT_GROUPS_RENAME_SUBGROUP: {
        const { organisationId, subGroupId, name } = action.params;
        if (action.status !== 'success') {
          return state;
        }
        state[organisationId].value![subGroupId].name = name;
        return state;
      }

      case ORGANISATIONS_ACCOUNT_GROUPS_REORDER_SUBGROUP: {
        const { organisationId, groupId, orderedSubGroupIds } = action.params;
        if (action.status !== 'success') {
          return state;
        }

        state[organisationId].value![groupId].groups = orderedSubGroupIds;
        for (const [index, subGroupId] of orderedSubGroupIds.entries()) {
          state[organisationId].value![subGroupId].order = index;
        }
        return state;
      }

      case ORGANISATIONS_ACCOUNT_GROUPS_DELETE_SUBGROUP: {
        const { organisationId, subGroupId } = action.params;
        if (action.status !== 'success') {
          return state;
        }
        delete state[organisationId].value![subGroupId];
        // Delete it from the parent group subgroups
        const parentGroup = Object.values(state[organisationId].value!).find(group =>
          group.groups.includes(subGroupId),
        )!;
        parentGroup.groups = parentGroup.groups.filter(groupId => groupId !== subGroupId);
        return state;
      }

      default: {
        return state;
      }
    }
  });
};

export default accountGroups;
