import React from 'react';
import styled, { css } from 'styled-components';

import { DownloadJob, Job, ProcessingJob } from '../../connections/sync-context/types';
import DataProcessingSyncStatusBar from '../DataProcessingSyncStatusBar';
import DownloadSyncStatusBar from '../DownloadSyncStatusBar';
import ImportSyncStatusBar from '../ImportSyncStatusBar';
import { DOWNLOAD_JOBS, PROCESSING_JOBS } from '../jobsInfo';

type Props = {
  job: Job;
  onHide: () => void;
};

const StyledSyncStatusBar = styled.div<{ status: Job['status'] }>`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(8)};

  ${({ status, theme }) => {
    switch (status) {
      case 'Running':
        return css`
          background-color: ${theme.palette.primary.offwhite};
          & > div > span {
            color: ${theme.palette.primary.dark};
          }
        `;
      case 'Completed':
        return css`
          background-color: ${theme.palette.success.offwhite};
          & > div > span {
            color: ${theme.palette.success.dark};
          }
        `;
      case 'Error':
        return css`
          background-color: ${theme.palette.danger.offwhite};
          & > div > span {
            color: ${theme.palette.danger.dark};
          }
        `;
    }
  }}

  .rs-loader-spin::before {
    border: unset;
  }
  .rs-loader-spin::after {
    border-color: ${({ theme }) => theme.palette.primary.dark} transparent transparent;
  }

  & > div {
    gap: ${({ theme }) => theme.spacing(3)};
  }
`;

const SyncStatusBar: React.FC<React.PropsWithChildren<Props>> = ({ job, onHide }) => {
  const { status, task } = job;

  return (
    <StyledSyncStatusBar status={status}>
      {/* Import Jobs */}
      {task === 'IMPORT' && <ImportSyncStatusBar job={job} onHide={onHide} />}

      {/* Data Processing Jobs */}
      {Object.keys(PROCESSING_JOBS).includes(task) && (
        <DataProcessingSyncStatusBar
          job={job as ProcessingJob}
          onHide={onHide}
          getTitles={PROCESSING_JOBS[task as ProcessingJob['task']]}
        />
      )}

      {/* Download Jobs */}
      {Object.keys(DOWNLOAD_JOBS).includes(task) && (
        <DownloadSyncStatusBar
          job={job as DownloadJob}
          onHide={onHide}
          downloadTitle={DOWNLOAD_JOBS[task as DownloadJob['task']].downloadTitle}
          fileName={DOWNLOAD_JOBS[task as DownloadJob['task']].fileName}
        />
      )}
    </StyledSyncStatusBar>
  );
};

export default SyncStatusBar;
