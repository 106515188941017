import React from 'react';
import { MetricSchema2Decimals } from 'scalexp/utils/metrics/metricSchema2';
import { getSimpleMetricSchemaFromNode } from 'scalexp/utils/metrics/ms2utils';
import styled from 'styled-components';

import Typography from '../../../../components/atoms/Typography';
import { useAccountGroupById, useAccountGroups } from '../../../../store/state/accountGroups/hooks';
import { AccountGroup, AccountGroups } from '../../../../store/state/accountGroups/types';
import { useAccountsInGroup } from '../../../../store/state/accounts/hooks';
import { Account } from '../../../../store/state/accounts/types';
import ReportEditorDisplayAccountRow from '../ReportEditorDisplayAccountRow';
import ReportEditorDisplaySubGroupRow from '../ReportEditorDisplaySubGroupRow';

interface ReportEditorDisplayAllSubGroupsRowProps {
  groupId: AccountGroup['id'];
  decimals: MetricSchema2Decimals;
}

const StyledGroupsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing(1)} 0`};
  border-radius: ${({ theme }) => `0 0 ${theme.spacing(2)} ${theme.spacing(2)}`};
`;

const StyledNoData = styled.div`
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: 20px 1fr 160px 160px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding-left: ${({ theme }) => theme.spacing(4)};
`;

const ReportEditorDisplayAllSubGroupsRow: React.FC<
  React.PropsWithChildren<ReportEditorDisplayAllSubGroupsRowProps>
> = ({ groupId, decimals }) => {
  const accountsInGroupVS = useAccountsInGroup(groupId);
  const groupsVS = useAccountGroups();
  const groupVS = useAccountGroupById(groupId);

  if (
    accountsInGroupVS.status !== 'success' ||
    groupVS.status !== 'success' ||
    groupsVS.status !== 'success' ||
    !groupVS.value
  ) {
    return null;
  }

  const accountsInGroup: Account[] = accountsInGroupVS.value;
  const group: AccountGroup = groupVS.value;
  const groups: AccountGroups = groupsVS.value;
  const hasNoData: boolean = group.groups.length === 0 && accountsInGroup.length === 0;
  const ungroupedAccountsInTag: Account[] = accountsInGroup.filter(account => account.group_id === groupId);

  return (
    <StyledGroupsContainer>
      {hasNoData ? (
        <StyledNoData>
          <div />
          <Typography>There are no sub groups configured for {group.name}</Typography>
        </StyledNoData>
      ) : group.groups.length > 0 ? (
        <>
          {group.groups.map(subGroupId => {
            const subGroup = groups[subGroupId];
            let groupName: string = subGroup ? subGroup.name : 'Deleted Sub Group';
            const metricSchema = getSimpleMetricSchemaFromNode({
              operator: 'add',
              data: {
                operator: 'entity',
                entity: 'group',
                groupId: subGroupId,
                isCashflow: false,
              },
            });

            return <ReportEditorDisplaySubGroupRow name={groupName} metricSchema={metricSchema} decimals={decimals} />;
          })}
          {ungroupedAccountsInTag.length > 0 && (
            <ReportEditorDisplaySubGroupRow
              name={`${group.name}: All Other`}
              metricSchema={getSimpleMetricSchemaFromNode({
                operator: 'add',
                data: {
                  operator: 'entity',
                  entity: 'ungrouped-accounts-in-group',
                  groupId: groupId,
                  isCashflow: false,
                },
              })}
              decimals={decimals}
            />
          )}
        </>
      ) : (
        accountsInGroup.map(account => {
          return <ReportEditorDisplayAccountRow accountId={account.account_id} decimals={decimals} />;
        })
      )}
    </StyledGroupsContainer>
  );
};

export default ReportEditorDisplayAllSubGroupsRow;
