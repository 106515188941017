import { applyMiddleware, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';

import rootReducer from './state';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const INITIAL_STATE = {};

export const setupStore = () => createStore(rootReducer, INITIAL_STATE, composeEnhancers(applyMiddleware(reduxThunk)));
