import React, { useState } from 'react';
import styled from 'styled-components';

import { HSL } from '../../../features/report/rows/ReportRowCell';
import ColorCircle from '../../atoms/ColorCircle';

export const COLOR_OPTIONS: { [rgb: string]: HSL } = {
  '#ffffff': {
    h: 0,
    s: 0,
    l: 100,
  },
  '#a5cd1e': {
    h: 73,
    s: 74,
    l: 46,
  },
  '#f96400': {
    h: 24,
    s: 100,
    l: 48,
  },
  '#ffc325': {
    h: 43,
    s: 100,
    l: 57,
  },
  '#44d7b5': {
    h: 166,
    s: 64,
    l: 55,
  },
  '#31c5ff': {
    h: 196,
    s: 100,
    l: 59,
  },
  '#3849ac': {
    h: 231,
    s: 50,
    l: 44,
  },
  '#556c81': {
    h: 208,
    s: 20,
    l: 41,
  },
  '#b620e0': {
    h: 286,
    s: 75,
    l: 50,
  },
};

export type ColorPickerOptionKey = keyof typeof COLOR_OPTIONS;

export interface ColorPickerProps {
  value?: ColorPickerOptionKey;
  onChange?: (value: ColorPickerOptionKey, hsl: HSL) => void;
}

export const StyledColorPicker = styled.div`
  display: flex;
  width: 250px;
  justify-content: flex-end;
`;

const ColorPicker: React.FC<React.PropsWithChildren<ColorPickerProps>> = ({ value = '#ffffff', onChange }) => {
  const [selected, setSelected] = useState<ColorPickerOptionKey>(value);

  const handleChange = (value: ColorPickerOptionKey) => {
    setSelected(value);
    onChange && onChange(value, COLOR_OPTIONS[value]);
  };

  return (
    <StyledColorPicker>
      {Object.entries(COLOR_OPTIONS).map(([rgb, hsl]) => (
        <ColorCircle key={rgb} selected={selected === rgb} onClick={() => handleChange(rgb)} hsl={hsl} />
      ))}
    </StyledColorPicker>
  );
};

export default ColorPicker;
