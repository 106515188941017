import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import IconButton from '../../atoms/IconButton';
import { useLayoutContext } from '../../contexts/LayoutContext';
import Row from '../../layout/Row';

interface WidePageProps {
  header?: ReactNode;
  menuLeft?: ReactNode;
  wide?: boolean;
  onClick?: () => void;
}

const StyledBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const SidebarHeader = styled(Row)`
  width: 100%;
  height: 56px;
  padding-left: 10px;
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  border-right: 1px solid ${({ theme }) => theme.palette.primary.offwhite};
  border-bottom: 1px solid ${({ theme }) => theme.palette.silver};
`;

const StyledMenuLeft = styled.div<{ wide?: boolean; collapse?: boolean }>`
  ${({ collapse }) => {
    if (collapse) {
      return css`
        & > span > div:last-child > * {
          display: none;
          content: '';
        }
      `;
    }
  }}

  ${({ wide, collapse }) => css`
    min-width: ${wide ? '375px' : collapse ? '40px' : '260px'};
    max-width: ${wide ? '375px' : collapse ? '40px' : '260px'};
  `}
  transition:  .5s;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const StyledWrapper = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const CollapseButton = styled(IconButton)`
  background-color: #f8f9fc;
  border-radius: 50%;
  display: flex;
  position: absolute;
  top: 24px;
  z-index: 100;
  right: -13px;
  justify-content: center;
  align-items: center;
  outline: none !important;
  text-align: center;
  border: 1px solid #e5e5e5 !important;
`;

const WidePage: React.FC<React.PropsWithChildren<WidePageProps>> = ({ header, menuLeft, children, wide, onClick }) => {
  const { showSideBar, setShowSideBar } = useLayoutContext();
  const [hideButton, setHideButton] = useState(false);
  const showSideBarRef = useRef(showSideBar);

  useEffect(() => {
    return () => {
      setShowSideBar(showSideBarRef.current);
    };
  }, []);
  return (
    <StyledBody onClick={onClick}>
      {menuLeft && (
        <StyledMenuLeft id="app-sidebar" wide={wide} collapse={!showSideBar}>
          <SidebarHeader width="100%" hAlign="flex-start" vAlign="center" spacing="small">
            <img alt="dock_to_right_icon" src="/images/icons/dock_to_right.svg" />
          </SidebarHeader>
          {!wide && !hideButton && (
            <CollapseButton
              onMouseLeave={e => e.stopPropagation()}
              name={showSideBar ? 'navigate_before' : 'navigate_next'}
              onClick={() => {
                setShowSideBar(!showSideBar);
                showSideBarRef.current = !showSideBar;
              }}
            />
          )}
          <StyledWrapper
            onMouseEnter={() => {
              if (!showSideBarRef.current && !wide) {
                setShowSideBar(true);
                setHideButton(true);
              }
            }}
            onMouseLeave={() => {
              if (!showSideBarRef.current && !wide) {
                setShowSideBar(false);
                setHideButton(false);
              }
            }}
          >
            {menuLeft}
          </StyledWrapper>
        </StyledMenuLeft>
      )}
      <StyledContent>
        {header}
        {children}
      </StyledContent>
    </StyledBody>
  );
};

export default WidePage;
