import React, { useState } from 'react';

export interface LayoutContextProps {}

export const LayoutContext = React.createContext<{
  showSideBar: boolean;
  setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
}>(undefined!);

export const LayoutProvider: React.FC<React.PropsWithChildren<LayoutContextProps>> = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true);

  return <LayoutContext.Provider value={{ showSideBar, setShowSideBar }}>{children}</LayoutContext.Provider>;
};

export const useLayoutContext = () => {
  const value = React.useContext(LayoutContext);

  if (!value) {
    throw new Error('LayoutProvider not found!');
  }

  return value;
};
