import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { BLOCKED_PAGE_PATH } from 'scalexp/constants';
import styled from 'styled-components';

import { LayoutProvider } from '../../contexts/LayoutContext';
import GlobalFixedElementContainer from '../../organisms/GlobalFixedElementsContainer';
import Header from '../Header';
import usePosthogOrganisationGroup from './usePosthogOrganisationGroup';

export interface MainLayoutProps {}

const StyledPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledHeaderContainer = styled.div`
  flex: 0 0 auto;
`;
const StyledContent = styled.div`
  position: relative;
  flex: 0 1 auto;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const MainLayout: React.FC<React.PropsWithChildren<MainLayoutProps>> = ({ children }) => {
  const routesWithoutHeader = ['/boards', '/connections', '/presentations/', BLOCKED_PAGE_PATH];
  usePosthogOrganisationGroup();
  const location = useLocation();

  const hideHeader = useMemo(() => routesWithoutHeader.filter(route => location.pathname.startsWith(route)).length, [
    location,
  ]);

  return (
    <LayoutProvider>
      <StyledPage>
        {!hideHeader && (
          <StyledHeaderContainer>
            <Header />
            <GlobalFixedElementContainer />
          </StyledHeaderContainer>
        )}
        <StyledContent>{children}</StyledContent>
      </StyledPage>
    </LayoutProvider>
  );
};

export default MainLayout;
