import React from 'react';
import { useLocation } from 'react-router-dom';
import Row from 'scalexp/components/layout/Row';
import styled from 'styled-components';

import Notification from '../../../components/atoms/Notification';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import Column from '../../../components/layout/Column';
import fetcher from '../../../utils/fetcher';
import OnboardingHeader from '../OnboardingHeader';

const OnboardingContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 60px);
  padding-left: 207px;
  padding-top: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.palette.white};
`;

const OnboardingEmailVerification = ({ performLogout }: { performLogout: VoidFunction }) => {
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const userEmail = new URLSearchParams(location.search).get('error_description');
  const sendVerificationEmail = async () => {
    setLoading(true);
    try {
      await fetcher('/userconfig/settings/resend-verification-email', {
        method: 'POST',
        body: JSON.stringify({ email: userEmail }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setLoading(false);
      Notification.success({
        title: 'Verification email has been re-sent.',
      });
    } catch (error) {
      setLoading(false);
      Notification.error({
        title: 'Sorry but we are unable to re-send the verification email.',
      });
    }
  };

  return (
    <>
      <OnboardingHeader />
      <OnboardingContainer>
        <Column width="100%" height="80%" vAlign="center">
          <Typography size="h2" weight="regular" color="black">
            Verify email to continue
          </Typography>
          <Typography size="h6" weight="regular" color="secondary">
            Please verify your email, we sent an email to{' '}
            <Typography size="h6" weight="semibold" color="secondary">
              {userEmail}
            </Typography>
          </Typography>
          <Row>
            <TextButton
              variant="secondary"
              onClick={sendVerificationEmail}
              size="medium"
              loading={loading}
              disabled={loading}
            >
              Resend verification email
            </TextButton>
            <TextButton
              width="100px"
              variant="danger"
              onClick={performLogout}
              size="medium"
              loading={loading}
              disabled={loading}
            >
              Logout
            </TextButton>
          </Row>
        </Column>
      </OnboardingContainer>
    </>
  );
};

export default OnboardingEmailVerification;
