import React from 'react';
import { MetricSchema2Decimals } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import useActiveOrganisation from '../../../../components/contexts/OrganisationContext/useActiveOrganisation';
import { InvoicedRevenueMetricId } from '../../../../service/types/metricSchema';
import ReportEditorDisplaySubsidiaryRevenueRow from '../ReportEditorDisplaySubsidiaryRevenueRow';

interface ReportEditorDisplayAllSubsidiaryRevenueRowProps {
  metricId: InvoicedRevenueMetricId;
  decimals: MetricSchema2Decimals;
}

const StyledGroupsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing(1)} 0`};
  border-radius: ${({ theme }) => `0 0 ${theme.spacing(2)} ${theme.spacing(2)}`};
`;

const ReportEditorDisplayAllSubsidiaryRevenueRow: React.FC<
  React.PropsWithChildren<ReportEditorDisplayAllSubsidiaryRevenueRowProps>
> = ({ metricId, decimals }) => {
  const organisation = useActiveOrganisation();

  return (
    <StyledGroupsContainer>
      {organisation.children.map(subsidiary => (
        <ReportEditorDisplaySubsidiaryRevenueRow
          subsidiaryId={subsidiary.organisation_id}
          metricId={metricId}
          decimals={decimals}
        />
      ))}
    </StyledGroupsContainer>
  );
};

export default ReportEditorDisplayAllSubsidiaryRevenueRow;
