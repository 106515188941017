import produce from 'immer';

import { ORGANISATIONS_MANUAL_JOURNALS_LOAD, ORGANISATIONS_MANUAL_JOURNAL_POST } from './actions';
import { OrganisationManualJournals, ManualJournalActions } from './types';

const manualJournals = (
  prevState: OrganisationManualJournals = {},
  action: ManualJournalActions,
): OrganisationManualJournals => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_MANUAL_JOURNALS_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: Object.fromEntries(action.payload.journals.map(journal => [journal.id, journal])),
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      case ORGANISATIONS_MANUAL_JOURNAL_POST: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: {
                ...(state[organisationId]?.value || {}),
                [action.payload.id]: action.payload,
              },
            };

            return state;
        }
        return state;
      }

      default:
        return state;
    }
  });
};

export default manualJournals;
