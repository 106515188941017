import tinycolor from 'tinycolor2';

export class ColorPalette {
  static LUMINANCE_LOWER_BOUND: number = 0.25;
  static LUMINANCE_UPPER_BOUND: number = 0.85;

  static SATURATION_LOWER_BOUND: number = 0.55;
  static SATURATION_UPPER_BOUND: number = 1.0;

  static generate_tones(base_color: string, count: number) {
    if (count === 1) {
      return [base_color];
    }
    let base_color_obj: tinycolor.Instance = tinycolor(base_color);
    let luminance_range = this.LUMINANCE_UPPER_BOUND - this.LUMINANCE_LOWER_BOUND;
    let saturation_range = this.SATURATION_UPPER_BOUND - this.SATURATION_LOWER_BOUND;
    let luminance_step = luminance_range / (count - 1);
    let saturation_step = saturation_range / (count - 1);
    let luminance_list = Array(count)
      .fill(null)
      .map((_, index) => this.LUMINANCE_LOWER_BOUND + luminance_step * index);
    let saturation_list = Array(count)
      .fill(null)
      .map((_, index) => this.SATURATION_LOWER_BOUND + saturation_step * index);
    let color_hue = base_color_obj.toHsl().h;
    return luminance_list.map((l, index) =>
      tinycolor({
        h: color_hue,
        s: saturation_list[index],
        l,
      }).toHexString(),
    );
  }
}
