// Read environment specific configuration from the public config.js file
// the config.js is replaced during run-time per environment by the NginX server

let envConfig = (window as any)._env;

if (!envConfig) {
  // check if code is being executed in a Vitest environment
  if (process.env.VITEST_WORKER_ID !== undefined) {
    envConfig = {
      API_URL: 'http://localhost:3001',
      ENV: 'VITEST',
      POSTHOG_TOKEN: 'none',
      SENTRY_SDN: '',
      SENTRY_RELEASE: '',
      AUTH0_DOMAIN: 'none',
      AUTH0_CLIENT_ID: 'none',
      AUTH0_AUDIENCE: 'none',
      AUTH0_REDIRECT_URI: 'none',
      AUTH0_SCOPE: 'none',
    };
  } else {
    throw new Error('Fatal error, environment configuration not found');
  }
}

export const {
  ENV,
  API_URL,
  POSTHOG_TOKEN,
  SENTRY_SDN,
  SENTRY_RELEASE,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
  AUTH0_REDIRECT_URI,
  AUTH0_SCOPE,
} = envConfig;

if (ENV === undefined) {
  throw new Error('ENV was undefined');
}

if (API_URL === undefined) {
  throw new Error('API_URL was undefined');
}

if (POSTHOG_TOKEN === undefined) {
  throw new Error('POSTHOG_TOKEN was undefined');
}

if (AUTH0_DOMAIN === undefined) {
  throw new Error('AUTH0_DOMAIN was undefined');
}

if (AUTH0_CLIENT_ID === undefined) {
  throw new Error('AUTH0_CLIENT_ID was undefined');
}

if (AUTH0_AUDIENCE === undefined) {
  throw new Error('AUTH0_AUDIENCE was undefined');
}

if (AUTH0_REDIRECT_URI === undefined) {
  throw new Error('AUTH0_REDIRECT_URI was undefined');
}

if (AUTH0_SCOPE === undefined) {
  throw new Error('AUTH0_SCOPE was undefined');
}

// Delete the environment config from the DOM and window object
if ((window as any)._env) {
  delete (window as any)._env;
  window.document.getElementById('_envBootstrap')!.remove();
}

if (ENV !== 'PROD' && ENV !== 'VITEST') {
  console.log('Environment loaded');
  console.log('ENV', ENV);
  console.log('API_URL', API_URL);
  console.log('POSTHOG_TOKEN', POSTHOG_TOKEN);
  console.log('SENTRY_SDN', SENTRY_SDN);
  console.log('SENTRY_RELEASE', SENTRY_RELEASE);
  console.log('AUTH0_DOMAIN', AUTH0_DOMAIN);
  console.log('AUTH0_CLIENT_ID', AUTH0_CLIENT_ID);
  console.log('AUTH0_AUDIENCE', AUTH0_AUDIENCE);
  console.log('AUTH0_REDIRECT_URI', AUTH0_REDIRECT_URI);
  console.log('AUTH0_SCOPE', AUTH0_SCOPE);
}
