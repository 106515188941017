import { useEffect, useState, createContext, useContext } from 'react';
import useSWR, { KeyedMutator } from 'swr';

import { useConnectionsContext } from '../../../features/connections/ConnectionsContext';
import useGetAccountingAndSalesSystem from '../../../features/connections/ConnectionsContext/useGetAccountingAndSalesSystem';
import fetcher from '../../../utils/fetcher';
import useActiveOrganisation from '../OrganisationContext/useActiveOrganisation';
import { OnboardingJourneys } from './types';

interface OnboardingJourneysContextProps {}

export const OnboardingJourneysContext = createContext<{
  journeys: OnboardingJourneys | undefined;
  mutate: KeyedMutator<OnboardingJourneys>;
  error: Error;
}>(undefined!);

export const OnboardingJourneysProvider: React.FC<React.PropsWithChildren<OnboardingJourneysContextProps>> = ({
  children,
}) => {
  const { accountingSystem } = useGetAccountingAndSalesSystem();
  const organisation = useActiveOrganisation();
  const organisationId = organisation.organisation_id;
  const { consolidatedConnections, isLoaded } = useConnectionsContext();
  const [journeys, setJourneys] = useState<OnboardingJourneys | undefined>(undefined);
  const res = useSWR<OnboardingJourneys>(`/api/v2/organisation/${organisationId}/onboarding_goals/`, (key: string) =>
    fetcher(key),
  );

  useEffect(() => {
    if (isLoaded && res.data) {
      const list = JSON.parse(JSON.stringify(res.data));
      if (
        consolidatedConnections.findIndex(org => org.connections.xero.status === 'connected') === -1 &&
        accountingSystem.toLocaleLowerCase() !== 'xero'
      ) {
        delete list?.stripe_integration;
      }
      if (organisation.consolidation_type === 'SUBSIDIARY') {
        delete list?.crm_integration;
      }
      setJourneys(list);
    } else {
      setJourneys(res.data);
    }
  }, [accountingSystem, res.data, isLoaded]);

  return (
    <OnboardingJourneysContext.Provider value={{ journeys, mutate: res.mutate, error: res.error }}>
      {children}
    </OnboardingJourneysContext.Provider>
  );
};

export const useOnboardingJourneys = () => {
  const value = useContext(OnboardingJourneysContext);

  if (!value) {
    throw new Error('OnboardingJourneysProvider not found!');
  }

  return value;
};
