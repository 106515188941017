import posthog from 'posthog-js';
import React from 'react';

import { usePosthogContext } from '../components/contexts/PosthogContext';

export const posthogContext = React.createContext<Partial<typeof posthog> | undefined>(undefined);

export const useFeatureFlag = (featureId: string): boolean => {
  const posthog = usePosthogContext();

  // err on the side of openness. Someone not being able to do their job is
  // going to be more annoying than accidentally seeing an unbaked feature
  if (!posthog || !posthog.isFeatureEnabled) {
    return true;
  }

  return !!posthog.isFeatureEnabled(featureId);
};
