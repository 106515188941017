import dayjs, { Dayjs } from 'dayjs';

export const MONTHS_IN_YEAR = 12;
export const MONTHS_IN_QUARTER = 3;

export const getFiscalQuarter = (focalDate: Dayjs, relativeMonthOffset: number = 0, financialYearStart: number = 0) => {
  let selectedDate = focalDate.add(relativeMonthOffset, 'month');
  let financialYearStartMonth = selectedDate.set('month', financialYearStart);
  let isFiscalYearAfterFocalDate = financialYearStartMonth.isAfter(selectedDate);
  financialYearStartMonth = isFiscalYearAfterFocalDate
    ? financialYearStartMonth.subtract(1, 'year')
    : financialYearStartMonth;
  let monthsBetween = Math.abs(selectedDate.diff(financialYearStartMonth, 'month')) % MONTHS_IN_YEAR;
  let FiscalQuarter = Math.floor(monthsBetween / MONTHS_IN_QUARTER) + 1;
  const orderInQuarter = monthsBetween % MONTHS_IN_QUARTER;
  const startOfFiscalQuarter = selectedDate.subtract(orderInQuarter, 'month').startOf('month');
  const endOfFiscalQuarter = startOfFiscalQuarter.add(2, 'months');

  return {
    lowerBound: startOfFiscalQuarter,
    upperBound: endOfFiscalQuarter.endOf('month'),
    fiscalYearStart: financialYearStartMonth,
    fiscalYearEnd: financialYearStartMonth.add(11, 'month').endOf('month'),
    fiscalYear: financialYearStartMonth.format('YYYY'),
    fiscalQuarter: FiscalQuarter,
  };
};

export const monthsBetween = (from: dayjs.Dayjs, to: dayjs.Dayjs): dayjs.Dayjs[] => {
  /**
   * Generates a list of months from the provided start and end date, inclusive.
   */
  if (to.isBefore(from)) {
    return [];
  }
  const months: dayjs.Dayjs[] = [];
  for (let i = from; i <= to; i = i.add(1, 'month')) {
    months.push(i);
  }
  return months;
};
