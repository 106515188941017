import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import Input from 'scalexp/components/atoms/Input';
import TextButton from 'scalexp/components/atoms/TextButton';
import styled, { css } from 'styled-components';

import { useOutsideAlerter } from '../../molecules/MultiSelect/hooks';

export interface InputRenameProps {
  customSize?: 'small' | 'medium' | 'large';
  defaultValue?: string;
  hasError?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  isLoading?: boolean;
  onDone: (value: string) => void;
  noBorder?: boolean;
  hideNumberArrows?: boolean;
  suffix?: string;
  selectAllOnMount?: boolean;
  width?: string;
}

type ContainerProps = {
  customSize?: 'small' | 'medium' | 'large';
  width: string | undefined;
  ref: React.RefObject<HTMLDivElement>;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ width }) => (width ? `width: ${width};` : '')};
  & input {
    height: 25px;
  }
  & button {
    outline: none !important;
  }

  ${({ theme }) => css`
    padding-right: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: ${theme.palette.fieldGrey};
    background-color: ${theme.palette.white};
    color: ${theme.palette.midnight};
    &:hover {
      border-color: ${theme.palette.midnight};
      outline: 0;
    }
    &:active,
    &:focus {
      border-color: ${theme.palette.primary.main};
      outline: 0;
    }

    &:invalid {
      border-color: ${theme.palette.danger.main};
      border-width: ${theme.sizing(0.5)};
    }
  `}
  ${({ customSize, theme }) => {
    switch (customSize) {
      case 'small': {
        return css`
          height: ${theme.sizing(7)};
          font-size: ${theme.font.size.small};
          line-height: ${theme.font.lineheight.small};
          border-radius: ${theme.sizing(1)};
          & button {
            height: 22px;
            span {
              line-height: 0.375rem;
              font-size: 0.8rem;
            }
          }
        `;
      }
      case 'medium': {
        return css`
          height: ${theme.sizing(9)};
          font-size: ${theme.font.size.medium};
          line-height: ${theme.font.lineheight.medium};
          border-radius: ${theme.sizing(2)};
        `;
      }
      case 'large': {
        return css`
          height: ${theme.sizing(11)};
          font-size: ${theme.font.size.medium};
          line-height: ${theme.font.lineheight.medium};
          border-radius: ${theme.sizing(2)};
        `;
      }
      default:
        return '';
    }
  }};
`;

const RenameInput: React.FC<InputRenameProps> = ({
  customSize = 'small',
  selectAllOnMount,
  width,
  isLoading,
  suffix,
  hideNumberArrows = false,
  disabled = false,
  autoFocus = false,
  onDone,
  defaultValue = '',
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const [isFocused, setIsFocused] = useState(false);
  useOutsideAlerter(containerRef, () => handleClose());

  const handleClose = () => {
    inputRef.current.blur();
    setIsFocused(false);
  };
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const handleDone = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    inputRef.current.blur();
    await onDone(inputRef.current.value);
    setIsFocused(false);
  };
  return (
    <Container ref={containerRef} width={width} customSize={customSize} onClick={() => inputRef.current.focus()}>
      <Input
        width="100%"
        onFocus={() => setIsFocused(true)}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            handleDone(event);
          }
        }}
        disabled={disabled || isLoading}
        ref={inputRef}
        maxLength={50}
        noBorder
      />
      {isFocused && (
        <TextButton
          disabled={disabled || isLoading}
          loading={isLoading}
          variant="secondary"
          width="90px"
          onClick={handleDone}
        >
          Done
        </TextButton>
      )}
    </Container>
  );
};

export default RenameInput;
