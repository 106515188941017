import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface SidebarWrapperProps extends HTMLAttributes<HTMLDivElement> {}

const StyledSidebarWrapper = styled.div<SidebarWrapperProps>`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(3)} 0;
  border-right: 1px solid ${({ theme }) => theme.palette.primary.offwhite};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.24s linear;
`;

const SidebarWrapper: React.FC<React.PropsWithChildren<SidebarWrapperProps>> = ({ children, ...other }) => {
  return <StyledSidebarWrapper {...other}>{children}</StyledSidebarWrapper>;
};

export default SidebarWrapper;
