import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '../../atoms/Icon';
import Typography from '../../atoms/Typography';
import Column from '../../layout/Column';
import Row from '../../layout/Row';

interface OnboardingPageTitlePropsTitle {
  title: string;
  subtitle: string;
  backLink?: string;
}
const BackButton = styled(Link)`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #030b23;
  border-radius: 8px;
  display: none;
  text-decoration: none !important;
`;
const OnboardingPageTitle: React.FC<OnboardingPageTitlePropsTitle> = ({ title, subtitle, backLink }) => {
  return (
    <Row spacing="large">
      {backLink ? (
        <BackButton to={backLink}>
          <Icon name="keyboard_backspace" clickable size={4.5} />
        </BackButton>
      ) : null}
      <Column spacing="small" width="70%">
        <Typography color="black" size="h1" weight="regular">
          {title}
        </Typography>
        <Typography color="secondary" weight="regular" size="medium">
          {subtitle}
        </Typography>
      </Column>
    </Row>
  );
};

export default OnboardingPageTitle;
