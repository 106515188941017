import React from 'react';
import styled from 'styled-components';

import Row from '../../../components/layout/Row';
import Select from '../../../components/molecules/Select';
import { iconLookup } from '../../../components/organisms/OauthSidebar';
import useCRMPipeLinesConnections from '../../connections/ConnectionsContext/useCRMPipeLinesConnections';
import useFinancialAccountsConnections from '../../connections/ConnectionsContext/useFinancialAccountsConnections';
import { FormulaPickerOption } from '../ReportEditorAddRowSelect';

interface ReportEditorSelectAccountProps {
  width?: string;
  value: string;
  pickerOptions: FormulaPickerOption[];
  handleChange: (value: string) => void;
  bold?: boolean;
  customDisplayLabel?: string;
}

const StyledIconsContainer = styled.div<{ iconsCount: number }>`
  position: relative;
  left: -${({ iconsCount }) => iconsCount * 6}px;
  display: flex;
`;

const StyledImage = styled.div<{ order: number }>`
  position: relative;
  left: ${({ order }) => (order + 1) * 6}px;
  z-index: ${({ order }) => order};
`;

const ReportEditorSimpleSelect: React.FC<React.PropsWithChildren<ReportEditorSelectAccountProps>> = ({
  width = 'clamp(180px, 35%, 300px)',
  value,
  pickerOptions,
  handleChange,
  bold,
  customDisplayLabel,
}) => {
  const financialConnections = useFinancialAccountsConnections();
  const crmConnections = useCRMPipeLinesConnections();

  const icons = (category: string) => {
    if (['financial', 'crm'].includes(category)) {
      const usedConnections = category === 'financial' ? financialConnections : crmConnections;
      return (
        <StyledIconsContainer iconsCount={usedConnections.length}>
          {usedConnections.map((connection, index) => {
            return (
              <StyledImage key={connection} order={usedConnections.length - index}>
                {iconLookup[connection]?.()}
              </StyledImage>
            );
          })}
        </StyledIconsContainer>
      );
    }

    return iconLookup[category]?.();
  };

  return (
    <Select
      width={width}
      selectWidth={600}
      groupBy="group"
      data={pickerOptions}
      value={value}
      onChange={handleChange}
      renderSelectItem={(_, item) => {
        return (
          <Row>
            {icons(item.source_id)}
            {item && item.label}
          </Row>
        );
      }}
      placeholder="Select"
      searchable
      onClick={e => e.stopPropagation()}
      bold={bold}
      customDisplayLabel={customDisplayLabel}
    />
  );
};

export default ReportEditorSimpleSelect;
