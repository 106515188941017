import React from 'react';
import {
  MetricSchema2Data,
  MetricSchema2DataOrNodesWithId,
  MetricSchema2DataSourceConstant,
  MetricSchema2DataSourceDerivedMetric,
  MetricSchema2DataSourceEntityAccount,
  MetricSchema2DataSourceEntityGroup,
  MetricSchema2DataSourceEntityPipeline,
  MetricSchema2DataSourceEntityPipelineStage,
  MetricSchema2DataSourceInvoicedRevenue,
  MetricSchema2DataSourceNativeMetric,
  MetricSchema2DataSourceReferenceRow,
  MetricSchema2DataSourceSalesMetric,
  MetricSchema2DataWithId,
  MetricSchema2Decimals,
  MetricSchema2NodesWithId,
  MetricSchema2Operator,
  MetricSchema2Window,
} from 'scalexp/utils/metrics/metricSchema2';

import SortableContainer from '../../../components/molecules/SortableContainer';
import { Organisation } from '../../../store/state/organisations/types';
import { PipelineMetricsTypes, PipelineStageMetricsTypes } from '../../../store/state/pipelines/types';
import { Report } from '../../../store/state/reports/types';
import { DeferredRevenueConfig } from '../../deferred-revenue/types';
import { FormulaPickerOption } from '../../report-editor/ReportEditorAddRowSelect';
import ReportEditorGroupRow from '../../report-editor/rows/ReportEditorAccountGroupRow';
import ReportEditorAccountRow from '../../report-editor/rows/ReportEditorAccountRow';
import ReportEditorConstantRow from '../../report-editor/rows/ReportEditorConstantRow';
import ReportEditorDerivedRow from '../../report-editor/rows/ReportEditorDerivedRow';
import ReportEditorInvoicedRevenueRow from '../../report-editor/rows/ReportEditorInvoicedRevenueRow';
import ReportEditorNativeMetricRow from '../../report-editor/rows/ReportEditorNativeMetricRow';
import ReportEditorPipelineRow from '../../report-editor/rows/ReportEditorPipelineRow';
import ReportEditorPipelineStageRow from '../../report-editor/rows/ReportEditorPipelineStageRow';
import ReportEditorReferenceRow from '../../report-editor/rows/ReportEditorReferenceRow';
import ReportEditorSalesMetricRow from '../../report-editor/rows/ReportEditorSalesMetricRow';
import FormulaEditorCustomRow from '../FormulaEditorCustomRow';

interface FormulaEditorRowsProps {
  pickerOptions: FormulaPickerOption[];
  id: string;
  nodes: MetricSchema2DataOrNodesWithId[];
  updateNodes: (nodes: MetricSchema2DataOrNodesWithId[]) => void;
  selectedNodeId: string | null;
  setSelectedNodeId: (selectedNodeId: string | null) => void;
  addedNodeId: string | null;
  setAddedNodeId: (addedNodeId: string | null) => void;
  parentPath?: string;
  report: Report | null;
  includeRows?: boolean;
  decimals: MetricSchema2Decimals;
}

const FormulaEditorRows: React.FC<FormulaEditorRowsProps> = ({
  pickerOptions,
  id,
  nodes,
  updateNodes,
  selectedNodeId,
  setSelectedNodeId,
  addedNodeId,
  setAddedNodeId,
  parentPath,
  report,
  includeRows,
  decimals,
}) => {
  const path = parentPath ? `${parentPath}:${id}` : id;

  const handleChangeSimpleMetric = (index: number, value: string) => {
    const updatedNodes: MetricSchema2DataOrNodesWithId[] = [...nodes];
    const changedNode = updatedNodes[index] as MetricSchema2DataWithId;

    if (value === 'constant') {
      const newNode: MetricSchema2DataWithId = {
        id: changedNode.id,
        operator: changedNode.operator,
        window: changedNode.window,
        data: {
          operator: 'constant',
          value: 0,
        },
      };
      updatedNodes.splice(index, 1, newNode);
      updateNodes(updatedNodes);
      return;
    }

    let node: MetricSchema2DataWithId;
    const [type, newId, newIdPartTwo] = value.split(':');
    switch (type) {
      case 'nm': {
        node = {
          id: changedNode.id,
          operator: changedNode.operator,
          window: changedNode.window,
          data: {
            operator: 'native',
            metricId: newId,
            isCashflow: false,
          },
        };
        break;
      }
      case 'dm': {
        node = {
          id: changedNode.id,
          operator: changedNode.operator,
          window: changedNode.window,
          data: {
            operator: 'derived',
            metricId: newId,
          },
        };
        break;
      }
      case 'ir': {
        node = {
          id: changedNode.id,
          operator: changedNode.operator,
          window: changedNode.window,
          data: {
            operator: 'invoiced-revenue',
            metricId: newId,
          },
        };
        break;
      }
      case 'sm': {
        node = {
          id: changedNode.id,
          operator: changedNode.operator,
          window: changedNode.window,
          data: {
            operator: 'sales',
            metricId: newId,
          },
        };
        break;
      }
      case 'pm': {
        node = {
          id: changedNode.id,
          operator: changedNode.operator,
          window: changedNode.window,
          data: {
            operator: 'entity',
            entity: 'pipeline',
            pipelineMetricId: 'metric_deals_in_progress_acv',
            pipelineId: newId,
          },
        };
        break;
      }
      case 'ps': {
        node = {
          id: changedNode.id,
          operator: changedNode.operator,
          window: changedNode.window,
          data: {
            operator: 'entity',
            entity: 'stage',
            stageMetricId: 'metric_deals_acv',
            pipelineId: newIdPartTwo,
            stageId: newId,
          },
        };
        break;
      }
      case 'account': {
        node = {
          id: changedNode.id,
          operator: changedNode.operator,
          window: changedNode.window,
          data: {
            operator: 'entity',
            entity: 'account',
            accountId: parseInt(newId),
            isCashflow: false,
          },
        };
        break;
      }
      case 'group': {
        node = {
          id: changedNode.id,
          operator: changedNode.operator,
          window: changedNode.window,
          data: {
            operator: 'entity',
            entity: 'group',
            groupId: parseInt(newId),
            isCashflow: false,
          },
        };
        break;
      }
      case 'row': {
        node = {
          id: changedNode.id,
          operator: changedNode.operator,
          window: changedNode.window,
          data: {
            operator: 'reference',
            reference: 'row',
            rowId: newId,
          },
        };
        break;
      }
      default: {
        throw new Error(`Unknown case '${type}' from '${value}'`);
      }
    }

    updatedNodes.splice(index, 1, node);
    updateNodes(updatedNodes);
  };

  const handleOperatorChange = (index: number, updatedOperator: MetricSchema2Operator) => {
    const updatedNodes = [...nodes];
    const changedNode = updatedNodes[index] as MetricSchema2DataWithId;
    updatedNodes.splice(index, 1, {
      ...changedNode,
      operator: updatedOperator,
    });
    updateNodes(updatedNodes);
  };

  const handleRevenueTypeChange = (index: number, isCashflow: boolean) => {
    const updatedNodes = [...nodes];
    const changedNode = updatedNodes[index] as MetricSchema2DataWithId;
    updatedNodes.splice(index, 1, {
      ...updatedNodes[index],
      data: {
        ...changedNode.data,
        // @ts-ignore
        isCashflow,
        // reset tracking category options, because we can't have tracking categories and cashflow together
        trackingCategoryOptions: undefined,
      },
    });
    updateNodes(updatedNodes);
  };

  const handleTrackingCategoryOptionsChange = (index: number, trackingCategoryOptions?: string[]) => {
    const updatedNodes = [...nodes];
    const changedNode = updatedNodes[index] as MetricSchema2DataWithId;
    updatedNodes.splice(index, 1, {
      ...updatedNodes[index],
      data: {
        ...changedNode.data,
        // @ts-ignore
        trackingCategoryOptions,
        // reset cash flow, because we can't have tracking categories and cashflow together
        isCashflow: false,
      },
    });
    updateNodes(updatedNodes);
  };

  const handleChangePipelineMetric = (index: number, pipelineMetricId: PipelineMetricsTypes) => {
    const updatedNodes = [...nodes];
    const changedNode = updatedNodes[index] as MetricSchema2DataWithId<MetricSchema2DataSourceEntityPipeline>;
    updatedNodes.splice(index, 1, {
      ...changedNode,
      data: {
        ...changedNode.data,
        pipelineMetricId,
      },
    });
    updateNodes(updatedNodes);
  };

  const handleChangePipelineStageMetric = (index: number, stageMetricId: PipelineStageMetricsTypes) => {
    const updatedNodes = [...nodes];
    const changedNode = updatedNodes[index] as MetricSchema2DataWithId<MetricSchema2DataSourceEntityPipelineStage>;
    updatedNodes.splice(index, 1, {
      ...changedNode,
      data: {
        ...changedNode.data,
        stageMetricId,
      },
    });
    updateNodes(updatedNodes);
  };

  const handleDelete = (index: number) => {
    const updatedNodes = [...nodes];
    updatedNodes.splice(index, 1);
    updateNodes(updatedNodes);
  };

  const handleIsSelectedChange = (index: number, isSelected: boolean) => {
    setSelectedNodeId(isSelected ? nodes[index].id : null);
  };

  return (
    <SortableContainer itemsIds={nodes}>
      {nodes.map((node, index) => {
        const isSelected = selectedNodeId === nodes[index].id;

        const handleUpdateNodes = (passedNodes: MetricSchema2DataOrNodesWithId[]) => {
          const updatedNodes: MetricSchema2DataOrNodesWithId[] = [...nodes];
          const changedNode = updatedNodes[index] as MetricSchema2NodesWithId;
          updatedNodes.splice(index, 1, { ...changedNode, nodes: passedNodes });
          updateNodes(updatedNodes);
        };

        const isInvalidWindow = (window: MetricSchema2Window | undefined) => window?.after === 0 && window.before === 0;

        const handleUpdateWindow = (updatedWindow: MetricSchema2Window | undefined) => {
          const updatedNodes: MetricSchema2DataOrNodesWithId[] = [...nodes];
          const changedNode = updatedNodes[index] as MetricSchema2DataWithId;
          updatedNodes[index] = {
            ...changedNode,
            window: isInvalidWindow(updatedWindow) ? undefined : updatedWindow,
          };
          updateNodes(updatedNodes);
        };

        const handleChangeRevenueSubsidiary = (subsidiaryId?: Organisation['organisation_id']) => {
          const updatedNodes: MetricSchema2DataOrNodesWithId[] = [...nodes];
          const changedNode = updatedNodes[index] as MetricSchema2DataWithId<MetricSchema2DataSourceInvoicedRevenue>;
          updatedNodes.splice(index, 1, {
            ...changedNode,
            data: {
              ...changedNode.data,
              subsidiaryId,
            },
          });
          updateNodes(updatedNodes);
        };

        const handleChangeConfigId = (configId?: DeferredRevenueConfig['id']) => {
          const updatedNodes: MetricSchema2DataOrNodesWithId[] = [...nodes];
          const changedNode = updatedNodes[index] as MetricSchema2DataWithId<MetricSchema2DataSourceInvoicedRevenue>;
          updatedNodes.splice(index, 1, {
            ...changedNode,
            data: {
              ...changedNode.data,
              configId,
            },
          });
          updateNodes(updatedNodes);
        };

        // brackets to be handled later
        if ('nodes' in node) {
          return (
            <FormulaEditorCustomRow
              key={nodes[index].id}
              id={nodes[index].id}
              nodes={node.nodes}
              updateNodes={handleUpdateNodes}
              isSelected={isSelected}
              handleIsSelectedChange={isSelected => handleIsSelectedChange(index, isSelected)}
              pickerOptions={pickerOptions}
              handleDelete={() => handleDelete(index)}
              operator={node.operator}
              handleOperatorChange={index !== 0 ? value => handleOperatorChange(index, value) : undefined}
              selectedNodeId={selectedNodeId}
              setSelectedNodeId={setSelectedNodeId}
              addedNodeId={addedNodeId}
              setAddedNodeId={setAddedNodeId}
              parentPath={path}
              report={report}
              includeRows={includeRows}
              decimals={decimals}
            />
          );
        }

        switch (node.data.operator) {
          case 'derived': {
            return (
              <ReportEditorDerivedRow
                key={nodes[index].id}
                id={nodes[index].id}
                node={node as MetricSchema2Data<MetricSchema2DataSourceDerivedMetric>}
                isSelected={isSelected}
                handleIsSelectedChange={isSelected => handleIsSelectedChange(index, isSelected)}
                pickerOptions={pickerOptions}
                handleChange={value => handleChangeSimpleMetric(index, value)}
                handleDelete={() => handleDelete(index)}
                operator={node.operator}
                handleOperatorChange={index !== 0 ? value => handleOperatorChange(index, value) : undefined}
                window={node.window}
                handleUpdateWindow={handleUpdateWindow}
                parentPath={path}
                report={null}
                decimals={decimals}
              />
            );
          }
          case 'native': {
            return (
              <ReportEditorNativeMetricRow
                key={nodes[index].id}
                id={nodes[index].id}
                node={node as MetricSchema2Data<MetricSchema2DataSourceNativeMetric>}
                isSelected={isSelected}
                pickerOptions={pickerOptions}
                handleIsSelectedChange={isSelected => handleIsSelectedChange(index, isSelected)}
                handleChange={value => handleChangeSimpleMetric(index, value)}
                handleDelete={() => handleDelete(index)}
                operator={node.operator}
                handleOperatorChange={index !== 0 ? value => handleOperatorChange(index, value) : undefined}
                window={node.window}
                handleUpdateWindow={handleUpdateWindow}
                handleRevenueTypeChange={(isCashflow: boolean) => handleRevenueTypeChange(index, isCashflow)}
                handleTrackingCategoryOptionsChange={trackingCategoryOptions =>
                  handleTrackingCategoryOptionsChange(index, trackingCategoryOptions)
                }
                parentPath={path}
                decimals={decimals}
              />
            );
          }
          case 'sales': {
            return (
              <ReportEditorSalesMetricRow
                key={nodes[index].id}
                id={nodes[index].id}
                node={node as MetricSchema2Data<MetricSchema2DataSourceSalesMetric>}
                isSelected={isSelected}
                pickerOptions={pickerOptions}
                handleIsSelectedChange={isSelected => handleIsSelectedChange(index, isSelected)}
                handleChange={value => handleChangeSimpleMetric(index, value)}
                handleDelete={() => handleDelete(index)}
                operator={node.operator}
                handleOperatorChange={index !== 0 ? value => handleOperatorChange(index, value) : undefined}
                window={node.window}
                handleUpdateWindow={handleUpdateWindow}
                decimals={decimals}
                parentPath={path}
              />
            );
          }
          case 'invoiced-revenue': {
            return (
              <ReportEditorInvoicedRevenueRow
                key={nodes[index].id}
                id={nodes[index].id}
                node={node as MetricSchema2Data<MetricSchema2DataSourceInvoicedRevenue>}
                isSelected={isSelected}
                pickerOptions={pickerOptions}
                handleIsSelectedChange={isSelected => handleIsSelectedChange(index, isSelected)}
                handleChange={value => handleChangeSimpleMetric(index, value)}
                handleDelete={() => handleDelete(index)}
                operator={node.operator}
                handleOperatorChange={index !== 0 ? value => handleOperatorChange(index, value) : undefined}
                window={node.window}
                handleUpdateWindow={handleUpdateWindow}
                handleChangeRevenueSubsidiary={handleChangeRevenueSubsidiary}
                handleChangeConfigId={handleChangeConfigId}
                parentPath={path}
                decimals={decimals}
              />
            );
          }
          case 'entity': {
            switch (node.data.entity) {
              case 'account': {
                return (
                  <ReportEditorAccountRow
                    key={nodes[index].id}
                    id={nodes[index].id}
                    node={node as MetricSchema2Data<MetricSchema2DataSourceEntityAccount>}
                    isSelected={isSelected}
                    pickerOptions={pickerOptions}
                    handleIsSelectedChange={isSelected => handleIsSelectedChange(index, isSelected)}
                    handleChange={value => handleChangeSimpleMetric(index, value)}
                    handleDelete={() => handleDelete(index)}
                    operator={node.operator}
                    handleOperatorChange={index !== 0 ? value => handleOperatorChange(index, value) : undefined}
                    window={node.window}
                    handleUpdateWindow={handleUpdateWindow}
                    handleRevenueTypeChange={isCashflow => handleRevenueTypeChange(index, isCashflow)}
                    handleTrackingCategoryOptionsChange={trackingCategoryOptions =>
                      handleTrackingCategoryOptionsChange(index, trackingCategoryOptions)
                    }
                    parentPath={path}
                    decimals={decimals}
                  />
                );
              }
              case 'group': {
                return (
                  <ReportEditorGroupRow
                    key={nodes[index].id}
                    id={nodes[index].id}
                    node={node as MetricSchema2Data<MetricSchema2DataSourceEntityGroup>}
                    isSelected={isSelected}
                    pickerOptions={pickerOptions}
                    handleIsSelectedChange={isSelected => handleIsSelectedChange(index, isSelected)}
                    handleChange={value => handleChangeSimpleMetric(index, value)}
                    handleDelete={() => handleDelete(index)}
                    operator={node.operator}
                    handleOperatorChange={index !== 0 ? value => handleOperatorChange(index, value) : undefined}
                    window={node.window}
                    handleUpdateWindow={handleUpdateWindow}
                    handleRevenueTypeChange={isCashflow => handleRevenueTypeChange(index, isCashflow)}
                    handleTrackingCategoryOptionsChange={trackingCategoryOptions =>
                      handleTrackingCategoryOptionsChange(index, trackingCategoryOptions)
                    }
                    parentPath={path}
                    decimals={decimals}
                  />
                );
              }
              case 'pipeline': {
                return (
                  <ReportEditorPipelineRow
                    key={nodes[index].id}
                    id={nodes[index].id}
                    node={node as MetricSchema2Data<MetricSchema2DataSourceEntityPipeline>}
                    isSelected={isSelected}
                    pickerOptions={pickerOptions}
                    handleIsSelectedChange={isSelected => handleIsSelectedChange(index, isSelected)}
                    handleChange={value => handleChangeSimpleMetric(index, value)}
                    handleChangePipelineMetric={value => handleChangePipelineMetric(index, value)}
                    handleDelete={() => handleDelete(index)}
                    operator={node.operator}
                    handleOperatorChange={index !== 0 ? value => handleOperatorChange(index, value) : undefined}
                    window={node.window}
                    handleUpdateWindow={handleUpdateWindow}
                    decimals={decimals}
                    parentPath={path}
                  />
                );
              }
              case 'stage': {
                return (
                  <ReportEditorPipelineStageRow
                    key={nodes[index].id}
                    id={nodes[index].id}
                    node={node as MetricSchema2Data<MetricSchema2DataSourceEntityPipelineStage>}
                    isSelected={isSelected}
                    pickerOptions={pickerOptions}
                    handleIsSelectedChange={isSelected => handleIsSelectedChange(index, isSelected)}
                    handleChange={value => handleChangeSimpleMetric(index, value)}
                    handleChangePipelineStageMetric={value => handleChangePipelineStageMetric(index, value)}
                    handleDelete={() => handleDelete(index)}
                    operator={node.operator}
                    handleOperatorChange={index !== 0 ? value => handleOperatorChange(index, value) : undefined}
                    window={node.window}
                    handleUpdateWindow={handleUpdateWindow}
                    parentPath={path}
                    decimals={decimals}
                  />
                );
              }
              default: {
                return null;
              }
            }
          }
          case 'constant': {
            const handleNumberChange = (value: number) => {
              const updatedNodes: MetricSchema2DataOrNodesWithId[] = [...nodes];
              const changedNode = updatedNodes[index] as MetricSchema2DataWithId<MetricSchema2DataSourceConstant>;
              updatedNodes.splice(index, 1, {
                ...changedNode,
                data: {
                  ...changedNode.data,
                  value,
                },
              });
              updateNodes(updatedNodes);
            };
            return (
              <ReportEditorConstantRow
                key={nodes[index].id}
                id={nodes[index].id}
                node={node as MetricSchema2Data<MetricSchema2DataSourceConstant>}
                isSelected={isSelected}
                pickerOptions={pickerOptions}
                handleIsSelectedChange={isSelected => handleIsSelectedChange(index, isSelected)}
                handleChange={value => handleChangeSimpleMetric(index, value)}
                handleDelete={() => handleDelete(index)}
                handleNumberChange={handleNumberChange}
                operator={node.operator}
                handleOperatorChange={index !== 0 ? value => handleOperatorChange(index, value) : undefined}
                addedNodeId={addedNodeId}
                setAddedNodeId={setAddedNodeId}
                parentPath={path}
                decimals={2}
              />
            );
          }
          case 'reference': {
            return (
              <ReportEditorReferenceRow
                key={nodes[index].id}
                id={nodes[index].id}
                node={nodes[index] as MetricSchema2Data<MetricSchema2DataSourceReferenceRow>}
                isSelected={isSelected}
                pickerOptions={pickerOptions}
                handleIsSelectedChange={isSelected => handleIsSelectedChange(index, isSelected)}
                handleChange={value => handleChangeSimpleMetric(index, value)}
                handleDelete={() => handleDelete(index)}
                operator={node.operator}
                handleOperatorChange={index !== 0 ? value => handleOperatorChange(index, value) : undefined}
                window={node.window}
                handleUpdateWindow={handleUpdateWindow}
                parentPath={path}
                report={report}
                decimals={decimals}
              />
            );
          }
          default: {
            return null;
          }
        }
      })}
    </SortableContainer>
  );
};
export default FormulaEditorRows;
