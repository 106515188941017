import React, { useMemo } from 'react';
import Typography from 'scalexp/components/atoms/Typography';

import { useTrackingCategories } from '../../../store/state/trackingCategories/hooks';
import useActiveOrganisation from '../../contexts/OrganisationContext/useActiveOrganisation';
import MultiSelect from '../MultiSelect';

interface SelectTrackingCategoryProps {
  selectedTrackingCategoryOptions: string[];
  setSelectedTrackingCategoryOptions: (value?: string[]) => void;
  note?: string;
}

const SelectTrackingCategory: React.FC<React.PropsWithChildren<SelectTrackingCategoryProps>> = ({
  selectedTrackingCategoryOptions,
  setSelectedTrackingCategoryOptions,
  note,
}) => {
  const organisation = useActiveOrganisation();
  const organisationId = organisation.organisation_id;
  const trackingCategoriesVS = useTrackingCategories(organisationId);
  const organisationNames: { [organisationId: number]: string } = useMemo(() => {
    // Create mapping of organisation id to name
    return organisation.children.reduce(
      (acc, org) => ({
        ...acc,
        [org.organisation_id]: org.name_short,
      }),
      { [organisation.organisation_id]: organisation.name_short },
    );
  }, [organisation]);

  const trackingCategoriesOptions = useMemo(
    () =>
      [
        ...Object.values(trackingCategoriesVS.value || {}).flatMap(tc => {
          if (tc.options.length > 49) {
            return [];
          }
          if (tc.source_name === 'quickbooks') {
            return tc.options.map(tco => {
              const trackingOptionName =
                organisation.consolidation_type === 'PARENT'
                  ? `${organisationNames[tc.organisation_id]}: ${tco.name}`
                  : tco.name;

              return {
                label: trackingOptionName,
                value: String(tco.id),
                category: 'quickbooks',
              };
            });
          } else {
            const trackingCategoryName =
              organisation.consolidation_type === 'PARENT'
                ? `${organisationNames[tc.organisation_id]}: ${tc.name}`
                : tc.name;

            return tc.options.map(tco => ({
              label: String(tco.name),
              value: String(tco.id),
              group: trackingCategoryName,
              category: trackingCategoryName,
            }));
          }
        }),
      ].sort((a, b) => a.label.localeCompare(b.label)),
    [trackingCategoriesVS.value, organisation.consolidation_type, organisationNames],
  );

  const handleConsolidatedCategoryChange = (values: string[]) => {
    const allSelectedCategories = new Set(
      values.map(value => {
        return trackingCategoriesOptions.find(tc => tc.value === value)?.category;
      }),
    );

    // getting the last category from each subsidary
    const selectedSubsidariesCategories = [...allSelectedCategories].reduce((agg, category) => {
      if (!category) {
        return agg;
      }

      const categorySplit = category.split(':');
      // 2 means it's a subsidary category (so we allow 1 category options per subsidary)
      // 1 means we don't have subsidaries (so we allow only one category options to be selected)
      const subsidary = categorySplit.length === 2 ? categorySplit[0] : '';

      agg[subsidary] = category;
      return agg;
    }, {} as { [subsidary: string]: string });

    // getting the values that are in the selected subsidaries categories
    const selectedSubsidariesCategoriesValues = values.filter(value => {
      const valueCategory = trackingCategoriesOptions.find(tc => tc.value === value)?.category;
      if (!valueCategory) {
        return false;
      }

      if (Object.values(selectedSubsidariesCategories).find(selectedCategory => selectedCategory === valueCategory)) {
        return true;
      }

      return false;
    });

    setSelectedTrackingCategoryOptions(
      selectedSubsidariesCategoriesValues.length > 0 ? selectedSubsidariesCategoriesValues : undefined,
    );
  };

  if (trackingCategoriesOptions.length === 0) {
    return null;
  }

  return (
    <>
      <MultiSelect
        width="100%"
        placeholder="Tracking Category"
        data={trackingCategoriesOptions}
        values={selectedTrackingCategoryOptions}
        selectWidth={300}
        expandableGroups={true}
        searchable
        placement="bottomStart"
        onChange={handleConsolidatedCategoryChange}
      />
      {note && <Typography color="secondary">{note}</Typography>}
    </>
  );
};

export default SelectTrackingCategory;
