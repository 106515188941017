import { promiseAction, PromiseAction, ThunkAction, ThunkDispatch } from '../../actions';
import http from '../../http';
import { selectTrackingCategories } from './selectors';
import { TrackingCategory } from './types';

export const ORGANISATIONS_TRACKING_CATEGORIES_LOAD = '[trackingCategories] LOAD';

export type TrackingCategoriesActions = PromiseAction<
  typeof ORGANISATIONS_TRACKING_CATEGORIES_LOAD,
  TrackingCategory[],
  { organisationId: number }
>;

type TrackingCategoryResponse = {
  tracking_categories: TrackingCategory[];
};

export const loadTrackingCategoriesAction = (organisationId: number): ThunkAction<TrackingCategoriesActions> => async (
  dispatch: ThunkDispatch<TrackingCategoriesActions>,
  getState,
) => {
  const state = getState();
  const trackingCategories = selectTrackingCategories(state, organisationId);

  if (trackingCategories?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_TRACKING_CATEGORIES_LOAD,
        () =>
          http<TrackingCategory[], TrackingCategoryResponse>(
            `/api/v1/organisations/${organisationId}/accounting/tracking_categories`,
            {},
            response => response.tracking_categories,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
