import * as am4charts from '@amcharts/amcharts4/charts';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Tooltip from 'scalexp/components/molecules/Tooltip';
import styled, { css } from 'styled-components';

const LegendSeriesSquare = styled.div<{ color?: string }>`
  ${({ theme, color }) => {
    return css`
      width: ${theme.spacing(2)};
      height: ${theme.spacing(2)};
      left: 2px;
      top: 2px;
      background: ${color};
      margin-right: ${theme.spacing(1)};
      border-radius: 2px;
    `;
  }}
`;

export const StyledLegendSeries = styled.div<{ isPresentation?: boolean }>`
  ${({ theme, isPresentation }) => {
    return css`
      font-size: 12px;
      line-height: 16px;
      font-weight: ${theme.font.weight.regular};
      cursor: pointer;
      color: ${theme.palette.granite};
      display: flex;
      align-items: center;
      margin-right: 4px;
      margin-top: 4px;
      padding: 2px 6px;
      background-color: ${theme.palette.silver};
      border-radius: 12px;
      transition: ${!isPresentation ? '0.3s' : 'none'};
      &:hover {
        transform: scale(1.06);
      }
    `;
  }}
`;

const LegendSeries = ({
  color,
  name,
  series,
  value,
}: {
  color: string;
  name: string;
  value?: string;
  series: am4charts.Series | am4charts.PieSeriesDataItem;
}) => {
  const [visible, setVisibility] = React.useState(true);
  const location = useLocation();
  const isPresentation = location.pathname.includes('presentation');
  const handleSeries = () => {
    visible ? series.hide() : series.show();
    setVisibility(!visible);
  };
  return (
    <Tooltip content={`Click to ${visible ? 'hide' : 'show'} ${name}`} placement="bottom">
      <StyledLegendSeries onClick={handleSeries} isPresentation={isPresentation}>
        <LegendSeriesSquare color={color} />
        <span style={{ textDecoration: visible ? 'none' : 'line-through' }}>
          {name}
          {value ? (
            <span>
              <span style={{ color: '#E5E5E5', margin: '0 2px' }}>| </span> {value}
            </span>
          ) : (
            ''
          )}
        </span>
      </StyledLegendSeries>
    </Tooltip>
  );
};

export default LegendSeries;
