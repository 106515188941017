import React from 'react';
import { DownloadJob } from 'scalexp/features/connections/sync-context/types';

import Icon from '../../../components/atoms/Icon';
import Loader from '../../../components/atoms/Loader';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import Row from '../../../components/layout/Row';

type Props = {
  job: DownloadJob;
  downloadTitle: string;
  fileName: string;
  onHide: () => void;
};

const DownloadSyncStatusBar: React.FC<React.PropsWithChildren<Props>> = ({ job, onHide, downloadTitle, fileName }) => {
  const { status } = job;

  const handleFinish = () => {
    onHide();
  };

  return (
    <Row vAlign="center">
      {status === 'Running' && <Loader />}
      {status === 'Completed' && <Icon name="check_circle" outlined marginRight={0} />}
      {status === 'Error' && <Icon name="cancel" marginRight={0} />}
      <Typography size="tiny">
        {status === 'Completed'
          ? `Your ${downloadTitle} download is ready.`
          : status === 'Error'
          ? `Something went wrong with your download. Please try again.`
          : `Preparing your ${downloadTitle} download`}
      </Typography>
      {status === 'Completed' && (
        <a href={job.payload?.fileUrl} download={fileName}>
          <TextButton iconLeft="download" variant="secondary">
            Click here to download
          </TextButton>
        </a>
      )}
      <TextButton variant="secondary" onClick={handleFinish}>
        Close
      </TextButton>
    </Row>
  );
};

export default DownloadSyncStatusBar;
