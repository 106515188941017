import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { DashboardEditorProvider } from '../../components/contexts/DashboardEditorContext';
import WidePage from '../../components/templates/WidePage';
import DashboardEditorContent from './dashboardEditorContent';
import DashboardEditorHeader from './dashboardEditorHeader';
import DashboardEditorSidebar from './dashboardEditorSidebar';

export interface DashboardEditorProps {}
export interface StyledDashboardEditorProps extends HTMLAttributes<HTMLDivElement> {}

export const StyledDashboardEditor = styled.div<StyledDashboardEditorProps>`
  ${({ theme }) =>
    `
  `}
`;

const DashboardEditor: React.FC<DashboardEditorProps> = () => {
  return (
    <DashboardEditorProvider>
      <StyledDashboardEditor>
        <WidePage menuLeft={<DashboardEditorSidebar />}>
          <DashboardEditorHeader />
          <DashboardEditorContent />
        </WidePage>
      </StyledDashboardEditor>
    </DashboardEditorProvider>
  );
};

export default DashboardEditor;
