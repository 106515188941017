import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '..';
import { loadPipelinesAction, updatePipelineAction, updatePipelineStageAction } from './actions';
import { selectPipelines } from './selectors';
import { PipelinesVS } from './types';

export const usePipelines = (organisationId: number): PipelinesVS => {
  const pipelines = useSelector<Store, ReturnType<typeof selectPipelines>>(state =>
    selectPipelines(state, organisationId),
  );

  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadPipelinesAction(organisationId));
  }, [organisationId]);

  if (pipelines === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...pipelines,
    reload,
  };
};

export const useUpdatePipeline = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(
    (pipelineId: string, field: string, value: string) => {
      dispatch(updatePipelineAction(organisationId, pipelineId, field, value));
    },
    [organisationId],
  );
};

export const useUpdatePipelineStage = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(
    async (pipelineId: string, stageId: string, field: string, value: string | boolean | number) => {
      const res = await dispatch(updatePipelineStageAction(organisationId, stageId, pipelineId, field, value));
      return res;
    },
    [organisationId],
  );
};
