import { PayloadAction, PromiseAction } from '../../actions';
import { ValueState } from '../../values';
import { OrganisationData } from '../types';

export type OrganisationConsolidatedBudgetSets = OrganisationData<ConsolidatedBudgetSetsVS>;

export type ConsolidatedBudgetSetsVS = ValueState<ConsolidatedBudgetSet[]>;

export interface ConsolidatedBudgetSet {
  id: number;
  name: string;
  budgets: { [organisation_id: number]: number };
}

export interface ListConsolidatedBudgetSetsResponse {
  budgets: ConsolidatedBudgetSet[];
}

export type PatchBudgetSetBody = ConsolidatedBudgetSet;

export const ORGANISATIONS_CONSOLIDATED_BUDGETSETS_LOAD = '[ConsolidatedBudgetSet] LOAD';
export const ORGANISATIONS_CONSOLIDATED_BUDGETSETS_CREATE = '[ConsolidatedBudgetSet] CREATE';
export const ORGANISATIONS_CONSOLIDATED_BUDGETSETS_UPDATE = '[ConsolidatedBudgetSet] UPDATE';
export const ORGANISATIONS_CONSOLIDATED_BUDGETSETS_DELETE = '[ConsolidatedBudgetSet] DELETE';

export type consolidatedBudgetSetsActions =
  | loadBudgetSetsAction
  | createBudgetSetAction
  | deleteBudgetSetAction
  | updateBudgetSetAction;

export type loadBudgetSetsAction = PromiseAction<
  typeof ORGANISATIONS_CONSOLIDATED_BUDGETSETS_LOAD,
  ConsolidatedBudgetSet[],
  { organisationId: number }
>;

export type createBudgetSetAction = PayloadAction<
  typeof ORGANISATIONS_CONSOLIDATED_BUDGETSETS_CREATE,
  { organisationId: number },
  ConsolidatedBudgetSet
>;

export type updateBudgetSetAction = PayloadAction<
  typeof ORGANISATIONS_CONSOLIDATED_BUDGETSETS_UPDATE,
  { organisationId: number; budgetSetId: number },
  ConsolidatedBudgetSet
>;

export type deleteBudgetSetAction = PromiseAction<
  typeof ORGANISATIONS_CONSOLIDATED_BUDGETSETS_DELETE,
  undefined,
  { organisationId: number; budgetsetId: number }
>;
