import useActiveOrganisationId from '../../../components/contexts/OrganisationContext/useActiveOrganisationId';
import { useAccounts } from '../../../store/state/accounts/hooks';

const useFinancialAccountsConnections = (): string[] => {
  const organisationId = useActiveOrganisationId();
  const accountsVS = useAccounts(organisationId);
  const financialConnections = [
    ...new Set(Object.values(accountsVS.value || {}).map(account => account.source_name)),
  ].filter(source_name => !['hubspot', 'salesforce', 'pipedrive', 'manual', 'generated'].includes(source_name));

  return financialConnections;
};

export default useFinancialAccountsConnections;
