import { useAuth0 } from '@auth0/auth0-react';
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';

import { setAccessToken } from '../../utils/accessToken';

/*
 * This component is used to redirect the user to the organisation
 *  oauth page after successful redirect callback from Auth0. It is responsible
 * for setting the access token in the local storage.
 */
const Auth0Redirect = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, error } = useAuth0();

  if (isLoading) {
    return null;
  }

  if (error) {
    console.log('error in auth0 hook-', error);
  }

  if (!isAuthenticated) {
    window.location.href = '/';
  }

  getAccessTokenSilently({ detailedResponse: true }).then((token: GetTokenSilentlyVerboseResponse) => {
    setAccessToken(token.access_token);
    window.location.href = '/connections/onboarding';
  });

  return null;
};

export default Auth0Redirect;
