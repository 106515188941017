import React, { useMemo } from 'react';
import TextButton from 'scalexp/components/atoms/TextButton';
import { MetricSchema2Complex } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import BlurredModal from '../../../../components/molecules/BlurredModal';
import { Report } from '../../../../store/state/reports/types';
import FormulaEditor from '../../../formula-editor/FormulaEditor';
import usePickerOptions from '../../ReportEditor/usePickerOptions';
import { FormulaPickerOption } from '../../ReportEditorAddRowSelect';

interface ReportEditorCustomRowComputationProps {
  rowId: string;
  showComputationModal: boolean;
  setShowComputationModal: (showComputationModal: boolean) => void;
  metricSchema: MetricSchema2Complex;
  handleChangeMetricSchema: (metricSchema: MetricSchema2Complex) => void;
  name?: string;
  handleNameChange?: (name: string) => void;
  report: Report | null;
}

const StyledContainer = styled.div``;

const ReportEditorCustomRowComputation: React.FC<React.PropsWithChildren<ReportEditorCustomRowComputationProps>> = ({
  rowId,
  showComputationModal,
  setShowComputationModal,
  metricSchema,
  handleChangeMetricSchema,
  name,
  handleNameChange,
  report,
}) => {
  const pickerOptions = usePickerOptions(report || undefined);
  const normalizedPickerOptions: FormulaPickerOption[] = useMemo(() => {
    return [
      {
        label: 'Fixed Number',
        value: 'constant',
        group: 'Constant',
      },
      ...pickerOptions
        .filter(option => option.id !== 'number' && option.id !== `row:${rowId}`)
        .map(option => ({ ...option, label: option.name, value: option.id, group: option.role })),
    ];
  }, [pickerOptions]);

  const handleShowModal: React.MouseEventHandler<HTMLSpanElement> = e => {
    e.stopPropagation();
    setShowComputationModal(true);
  };

  const onHideModal = () => {
    setShowComputationModal(false);
  };

  const handleSave = (metricSchema: MetricSchema2Complex) => {
    handleChangeMetricSchema(metricSchema);
    setShowComputationModal(false);
  };

  return (
    <>
      <TextButton variant="secondary" iconRight="settings" onClick={handleShowModal}>
        Edit Formula
      </TextButton>
      <BlurredModal
        width="80%"
        showModal={showComputationModal}
        onHide={onHideModal}
        showCloseButton={false}
        isBlurred={false}
      >
        <StyledContainer>
          <FormulaEditor
            pickerOptions={normalizedPickerOptions}
            metricSchema={metricSchema}
            handleSave={handleSave}
            name={name}
            handleNameChange={handleNameChange}
            handleCancel={() => setShowComputationModal(false)}
            report={report}
            includeRows
          />
        </StyledContainer>
      </BlurredModal>
    </>
  );
};

export default ReportEditorCustomRowComputation;
