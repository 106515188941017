import produce from 'immer';

import { ExchangeRatesActions, ORGANISATIONS_EXCHANGE_RATES_LOAD } from './actions';
import { ConversionRates, ConversionRatesForDates, ExchangeRates, SystemExchangeRates } from './types';

const exchangeRates = (prevState: SystemExchangeRates = {}, action: ExchangeRatesActions): SystemExchangeRates => {
  return produce(prevState, state => {
    switch (action.type) {
      case ORGANISATIONS_EXCHANGE_RATES_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending': {
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;
          }

          case 'success': {
            const exchangeRates: ExchangeRates = action.payload;
            const DATE_LENGTH = 'YYYY-MM'.length;
            for (const fromKey in exchangeRates) {
              for (const toKey in exchangeRates[fromKey]) {
                for (const rateType of ['AVERAGE', 'LAST_DAY'] as (keyof ConversionRates)[]) {
                  // Trim dates to YYYY-MM
                  const rates: ConversionRatesForDates = exchangeRates[fromKey][toKey][rateType];
                  exchangeRates[fromKey][toKey][rateType] = Object.fromEntries(
                    Object.entries(rates).map(([date, rate]) => [date.substr(0, DATE_LENGTH), rate]),
                  );
                }
              }
            }
            state[organisationId] = {
              status: 'success',
              value: action.payload,
              error: undefined,
            };
            return;
          }

          case 'error': {
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;
          }

          default: {
            return state;
          }
        }
      }
      default:
        return state;
    }
  });
};

export default exchangeRates;
