import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export interface SpacerProps extends HTMLAttributes<HTMLDivElement> {
  margin?: { left?: number; right?: number; top?: number; bottom?: number };
  padding?: { left?: number; right?: number; top?: number; bottom?: number };
  flex?: boolean;
  display?: 'inline' | 'block' | 'flex';
  width?: string;
}

const StyledSpacer = styled.div<SpacerProps>`
  display: ${({ display }) => display};
  ${({ flex }) => (flex ? 'flex: 1;' : '')}
  ${({ width = '' }) => (width ? `width: ${width};` : ``)}
  ${({ theme, margin }) => {
    if (margin?.left) {
      return `
        margin-left: ${theme.spacing(margin.left)};
      `;
    }
  }}
  ${({ theme, margin }) => {
    if (margin?.right) {
      return `
      margin-right: ${theme.spacing(margin.right)};
      `;
    }
  }}
    ${({ theme, margin }) => {
    if (margin?.top) {
      return `
      margin-top: ${theme.spacing(margin.top)};
      `;
    }
  }}
    ${({ theme, margin }) => {
    if (margin?.bottom) {
      return `
      margin-bottom: ${theme.spacing(margin.bottom)};
      `;
    }
  }};
  ${({ theme, padding }) => {
    if (padding?.left) {
      return `
        padding-left: ${theme.spacing(padding.left)};
      `;
    }
  }}
  ${({ theme, padding }) => {
    if (padding?.right) {
      return `
        padding-right: ${theme.spacing(padding.right)};
      `;
    }
  }}
    ${({ theme, padding }) => {
    if (padding?.top) {
      return `
        padding-top: ${theme.spacing(padding.top)};
      `;
    }
  }}
    ${({ theme, padding }) => {
    if (padding?.bottom) {
      return `
        padding-bottom: ${theme.spacing(padding.bottom)};
      `;
    }
  }};
`;

const Spacer: React.FC<React.PropsWithChildren<SpacerProps>> = ({
  margin = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  padding = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  display = 'block',
  ...other
}) => {
  return <StyledSpacer display={display} margin={margin} padding={padding} {...other} />;
};

export default Spacer;
