import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import Loader from '../../components/atoms/Loader';
import FinancialConfigPage from '../../components/organisms/FinancialConfigPage';
import WidePage from '../../components/templates/WidePage';
import OnboardingConnections from '../../features/onboarding/OnboardingConnections';
import OnboardingHeader from '../../features/onboarding/OnboardingHeader';
import OnboardingHoldingScreen from '../../features/onboarding/OnboardingHoldingScreen';
import OnboardingOrganisationSettings from '../../features/onboarding/OnboardingOrganisationSettings';
import SyncStatusBarContainer from '../../features/sync-status-bar/sync-status-bar-container/SyncStatusBarContainer';
import SelectSource from '../../pages/oauth/selectSource';

const ConnectionContainer = styled.div`
  width: 100vw;
  height: 100%;
  padding-left: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.palette.white};
`;

const ConnectionsPage = () => {
  // removing subheader height

  return (
    <WidePage>
      <OnboardingHeader />
      <SyncStatusBarContainer />
      <ConnectionContainer>
        <Switch>
          <Route path="/connections/onboarding/settings">
            <OnboardingOrganisationSettings />
          </Route>
          <Route path="/connections/onboarding/holding-screen">
            <OnboardingHoldingScreen />
          </Route>
          <Route path="/connections/onboarding">
            <OnboardingConnections />
          </Route>
          <Route path="/connections/select/:organisationId">
            <SelectSource />
          </Route>
          <Route path="/connections/integration/:connection">
            <React.Suspense fallback={<Loader center vertical />}>
              <FinancialConfigPage />
            </React.Suspense>
          </Route>
        </Switch>
      </ConnectionContainer>
    </WidePage>
  );
};

export default ConnectionsPage;
