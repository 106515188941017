import React from 'react';
import PanelPage from 'scalexp/components/organisms/PanelPage';

const ScalexpError: React.FC = () => (
  <PanelPage
    title="Oops - something went wrong"
    text="If the issue reoccurs, just email support@scalexp.com. These errors are automatically monitored, and our tech team will be working on the issue."
    action={{
      onClick: () => window.location.reload(),
      children: 'Reload ScaleXP',
    }}
    image={{
      src: '/images/scalexp_error.png',
      alt: 'scalexp error',
    }}
  />
);

export default ScalexpError;
