import { useSyncContext } from 'scalexp/features/connections/sync-context/SyncContext';

import SyncStatusBar from '../../SyncStatusBar';
import getProcessingInvoiceAccountJobToShow from '../getProcessingInvoiceAccountJobToShow';

const SyncStatusBarContainer = () => {
  const { jobs, setJobs } = useSyncContext();

  const jobsExceptProcessingInvoiceAccountRules = jobs.filter(job => job.task !== 'PROCESSING_INVOICE_ACCOUNT_RULES');
  // we don't want to show all processing invoice account rules jobs, only the running one or the last successful one
  const processingInvoiceAccountRulesJobToShow = getProcessingInvoiceAccountJobToShow(
    jobs.filter(job => job.task === 'PROCESSING_INVOICE_ACCOUNT_RULES'),
  );

  return (
    <>
      {[...jobsExceptProcessingInvoiceAccountRules, ...processingInvoiceAccountRulesJobToShow].map(job => (
        <SyncStatusBar key={job.id} job={job} onHide={() => setJobs(jobs.filter(j => j.id !== job.id))} />
      ))}
    </>
  );
};

export default SyncStatusBarContainer;
